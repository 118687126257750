import { Box, Container, Divider, Typography } from '@mui/material'
import React from 'react'
import Codebox from '../../Components/Codebox'

export default function DocsHomepage(props) {


    return (
        <Container sx={{paddingBlock:2}}>
            <Typography variant='h3'>Introduction to the Blitz Third Party API Documentation</Typography>
            <Divider></Divider>
            <br />
            Welcome to the Developer API Documentation! <br />
            This guide provides all the necessary information to help you integrate and utilize the Blitz APIs for building applications for creators and agencies.
            Our APIs offer the tools to bring your projects to life.<br /><br />

            <Typography variant='h5'>What is the Blitz Third Party API?</Typography>
            The Blitz Third Party  API is a suite of endpoints designed to facilitate communication between your application and our platform.
            For now, it providds functionality for retrieving data from thousands of creators around the globe,
            enabling you to build applications for influencers marketing. <br /><br />

            <b>On the Blitz Third Party platform, you can:</b><br />

            Create and manage services, the Apps our influencer audience will explore.<br />
            Retrieve creator data for analysis and display<br />
            Generate and manage API keys for secure access<br /><br />

            <Typography variant='h5'>Getting Started</Typography>
            To begin using the Developer API, contact one of our representatives to get a Blitz Account and follow the steps outlined in the documentation.
        </Container>
    )
}
