import { ListItem, ListItemText } from '@mui/material'
import React from 'react'
import WhiteListButton from './WhiteListButton'
import { Link as RouterLink, useLocation } from "react-router-dom";
import ThemeListLinkButton from './ThemeListLinkButton';

export default function NavbarLink({ to, primary }) {
    const location = useLocation();
    const isActive = location.pathname === to;
    return (
        <ListItem disablePadding>
            <ThemeListLinkButton component={RouterLink} to={to} isLocation={isActive}>
                <ListItemText primary={primary}/>
            </ThemeListLinkButton>
        </ListItem>
    )
}
