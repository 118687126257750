import { Box, Chip, Container, Divider, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import Codebox from '../../Components/Codebox'
import { Link as RouterLink } from 'react-router-dom'
import routes from '../../Config/routes'
import tutorial_1 from '../../Components/globalAssets/docs_apikeys_1.png'
import { StyledTableRow } from '../../Utils/styledcell'

export default function DocsBasics(props) {

    return (
        <Container sx={{ paddingBlock: 2 }}>
            <Typography variant='h3'>Basic API Usage</Typography>
            <Divider></Divider>
            <br />
            Our API is designed to be loosely RESTful, providing a consistent and predictable interface for developers. <br />Here are the key features and conventions:<br />

            <ul>
                <li>
                    <b>HTTP Status Codes:</b><br />
                    The API uses standard HTTP status codes to indicate the success or failure of requests. For example:

                    <ul>
                        <li>200 OK||201 for successful requests.</li>
                        <li>401 Unauthorized when authentication fails.</li>
                        <li>403 Forbidden for invalid API keys.</li>
                        <li>400 Bad Request||412 for improperly formatted requests.</li>
                    </ul>
                </li>
                <li>
                    <b>JSON Responses:</b><br />

                    All responses are in JSON format. For errors, the API returns a JSON object with details about the issue, such as:<br />
                    <Codebox>
                        <Typography variant='codeblock'>
                            "error": "Invalid or inactive API key"
                        </Typography>
                    </Codebox>
                </li>
                <li>
                    <b>JSON Requests:</b><br />
                    The API only accepts JSON-formatted request bodies, if needed. Requests with other formats will result in a 400 Bad Request response. Ensure that the Content-Type header is set to application/json.
                </li>
                <li>
                    <b>Stateless Architecture:</b><br />
                    No requests needs state or preconditions, other than a valid API key and the existence of the related resources.
                </li>
            </ul>
            <br />
            <Typography variant='h6'>URL</Typography>
            <Divider></Divider><br />
            The main URL where our API is served from is:
            <Typography component='span' variant='subtitle2' color='secondary'> https://blitz-app-u3rzf.ondigitalocean.app/external</Typography><br />
            You can call this end point to receive a simple Hello World message, even if you don't supply an Authorization header.
            <Codebox>
                <Typography variant='codeblock'>
                    &#123;<br />
                    &emsp;"success": "Hello Third Parties"<br />
                    &#125;
                </Typography>
            </Codebox><br />
            <Typography variant='h6'>Authorization</Typography>
            <Divider></Divider><br />
            To access the API, you must include an API key in the Authorization header of your HTTP requests. API keys are provided as Bearer tokens.<br />
            API keys can be of type Sandbox or Production. Sandbox will provide responses built out of Mock data for you to test the API.<br />
            <Typography variant='h6'>Format</Typography>
            The Authorization header must follow this format:
            <Codebox>
                <Typography variant='codeblock'>
                    Authorization: Bearer YOUR_API_KEY
                </Typography>
            </Codebox><br />
            <Typography variant='h6'>Error Responses</Typography>
            The API enforces authentication and validates the provided API key. Below are the potential errors and their causes:<br></br>
            <b>401</b><br/>
            Missing API Key
            <Codebox>
                <Typography variant='codeblock'>
                    &#123;<br />
                    &emsp;"error": "API key missing"<br />
                    &#125;
                </Typography>
            </Codebox><br />
            Malformed API key (e.g., missing Bearer prefix or an empty token):
            <Codebox>
                <Typography variant='codeblock'>
                    &#123;<br />
                    &emsp;"error": "Malformed API Key"<br />
                    &#125;
                </Typography>
            </Codebox><br />
            <b>403 Forbidden</b><br/>
            Invalid or inactive API key or terminated subscription:
            <Codebox>
                <Typography variant='codeblock'>
                    &#123;<br />
                    &emsp;"error": "Invalid or inactive API key"<br />
                    &#125;
                </Typography>
            </Codebox><br />

            <Typography variant='h6'>Endpoints</Typography>
            <Divider></Divider>
            <Paper variant='outlined' sx={{ marginBlockStart: 2, padding: 3 }}>
                <Typography component='span' variant='subtitle2'><Chip label='GET' color='warning'></Chip> /thirdparty</Typography><br />
                <br />
                The root endpoint verifies the provided API key and returns a success message along with the type of key (sandbox or production).<br/>
                <b>Response</b><br/>
                <Typography component='span' variant='subtitle2'>Success (200)</Typography><br />
                <Codebox>
                    <Typography variant='codeblock'>
                        &#123;<br />
                        &emsp;"success": "production"<br/>
                        &#125;
                    </Typography>
                </Codebox>

            </Paper>
            <br />
        </Container>
    )
}
