import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Container,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Card,
  CardContent,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Toolbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import HomeHeader from '../../../../Components/HomeHeader';
import HomeFooter from '../../../../Components/HomeFooter';
import { globalStyles } from '../../../../Utils/Styles';
import { lightTheme } from '../../../../Utils/baseTheme';
import { ThemeProvider } from '@mui/material/styles';
import client from '../../../../API';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CampaignOfferPage = () => {
  const { creator, campaignId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [offerDetails, setOfferDetails] = useState(null);

  const fetchOfferDetails = async () => {
    try {
      const response = await client.campaigns.getCreatorSpecs({
        creator: encodeURIComponent(creator),
        campaignId
      });

      if (response.status === 'success') {
        setOfferDetails(response.data);
      } else {
        setError(response.error || 'Failed to load offer details');
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleOfferResponse = async (accepted) => {
    try {
      setLoading(true);
      const response = await client.campaigns.updateCampaignCreatorSpecs({
        campaignId,
        creators: [{
          id: creator,
          status: accepted ? 'Accepted' : 'Declined'
        }]
      });

      if (response.status === 'success') {
        navigate(accepted ? '/offer-accepted' : '/offer-declined');
      } else {
        setError(response.error || 'Failed to submit response');
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message || 'Submission failed');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOfferDetails();
  }, [creator, campaignId]);

  const handleAuthRedirect = (action) => {
    navigate(`/login?redirect=${encodeURIComponent(window.location.pathname)}&action=${action}`);
  };

  if (loading) {
    return (
      <Backdrop open={loading} style={{ color: '#fff', zIndex: 1300 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(to bottom, #ffffff, #e0e0e0)',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <HomeHeader />
        <Toolbar />
        
        <Container sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          py: 2,
          maxWidth: 'md',
          flex: 1
        }}>
          {error ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ) : (
            <Paper elevation={3} sx={{ p: 4 }}>
              <Box sx={{ margin: 2 }}>
                <Stepper activeStep={0}>
                  <Step>
                    <StepLabel>Campaign Offer Details</StepLabel>
                  </Step>
                </Stepper>
              </Box>

              <Typography variant="h4" gutterBottom align="center">
                {offerDetails.campaignName} for {creator}
              </Typography>
              
              <Card variant="outlined" sx={{ mt: 3, mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Campaign Overview
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography>Type:</Typography>
                    <Typography fontWeight="bold">
                      {offerDetails.campaignType}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>Due Date:</Typography>
                    <Typography fontWeight="bold">
                      {new Date(offerDetails.idealDueDate).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>

              <Divider sx={{ my: 3 }} />

              <Box sx={{ mb: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Financial Terms
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography>Offer Value:</Typography>
                  <Typography fontWeight="bold">
                    ${(offerDetails.agencyRate || offerDetails.price)?.toFixed(2) || '0.00'}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>Payment Terms:</Typography>
                  <Typography fontWeight="bold">
                    30 days standard or instant via BlitzPay
                  </Typography>
                </Box>
              </Box>

              {offerDetails.postingInstructions && (
                <>
                  <Divider sx={{ my: 3 }} />
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                      Content Requirements
                    </Typography>
                    <Typography variant="body1" whiteSpace="pre-wrap">
                      {offerDetails.postingInstructions}
                    </Typography>
                  </Box>
                </>
              )}

              {offerDetails.status === 'Accepted' ? (
                <Typography 
                  variant="h5" 
                  align="center" 
                  sx={{ 
                    my: 4, 
                    color: 'success.dark',
                    fontWeight: 'medium',
                    p: 2,
                    backgroundColor: 'rgba(46, 125, 50, 0.1)',
                    borderRadius: 2
                  }}
                >
                  You have accepted this offer
                </Typography>
              ) : offerDetails.status === 'Counter' ? (
                <Box sx={{ 
                  textAlign: 'center', 
                  mt: 4,
                  p: 3,
                  backgroundColor: 'action.hover',
                  borderRadius: 2
                }}>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    This Deal Is Under Negotiation, Login to Blitz to see more details
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/login')}
                  >
                    Login
                  </Button>
                </Box>
              ) : (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: 2,
                  mt: 4
                }}>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    onClick={() => handleOfferResponse(true)}
                    disabled={loading}
                    sx={{ flex: 1 }}
                  >
                    Accept Offer
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    size="large"
                    onClick={() => handleAuthRedirect('negotiate')}
                    sx={{ flex: 1 }}
                  >
                    Negotiate Offer
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => handleAuthRedirect('chat')}
                    sx={{ flex: 1 }}
                  >
                    Chat with Brand
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    onClick={() => handleOfferResponse(false)}
                    disabled={loading}
                    sx={{ flex: 1 }}
                  >
                    Decline
                  </Button>
                </Box>
              )}
            </Paper>
          )}

          <Snackbar 
            open={!!error} 
            autoHideDuration={6000} 
            onClose={() => setError('')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={() => setError('')} severity="error">
              {error}
            </Alert>
          </Snackbar>
        </Container>
        
        <HomeFooter />
      </Box>
    </ThemeProvider>
  );
};

export default CampaignOfferPage;