import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  CircularProgress,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  ResponsiveContainer,
  BarChart,
  LineChart,
  PieChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Line,
  Pie,
  Cell,
} from "recharts";
import client from "../../../../../API"; // <-- Update path as needed

/**
 * This matches the logic from MediaKit:
 *  - We store each platform’s data in creator.creator.public
 *  - For the "main" or "largest" platform, we read:
 *      avg_likes / avg_comments / audience_data / country_data
 *    etc.
 *
 * If your data shape differs, adjust these references.
 */

// Helper color palette for the Pie chart slices
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#A83731",
  "#5A30B5",
  "#34A853",
  "#FF1A75",
];

/**
 * Based on MediaKit's approach, we identify a "main" platform by:
 *  - Searching `creatorPublic` (the array of platform objects).
 *  - Possibly the platform with the largest "followers" or "avg_likes."
 *  - Feel free to adapt the logic below.
 */
function findMainPlatform(creatorPublic = []) {
  if (!creatorPublic.length) return null;

  let mainIndex = 0;
  let maxFollowers = 0;
  for (let i = 0; i < creatorPublic.length; i++) {
    const pData = creatorPublic[i];
    // Some platforms store total followers, or maybe we must check `pData.followers`.
    const followerCount = pData?.followers ?? 0;
    if (followerCount > maxFollowers) {
      maxFollowers = followerCount;
      mainIndex = i;
    }
  }
  return creatorPublic[mainIndex];
}

/**
 * From the chosen platform, compute top country, top age group,
 * and male/female percentages. This ensures sums ~100%.
 */
function computePlatformSegments(platformData) {
  if (!platformData) {
    return {
      topCountry: { code: "N/A", value: 0 },
      topAge: { name: "N/A", value: 0 },
      malePercent: 0,
      femalePercent: 0,
    };
  }

  // ----- Country distribution -----
  const countryMap = {};
  platformData.country_data?.forEach(({ code, value }) => {
    countryMap[code] = (countryMap[code] || 0) + value;
  });
  const countryArray = Object.entries(countryMap).map(([code, val]) => ({ code, val }));
  countryArray.sort((a, b) => b.val - a.val);
  const topCountry = countryArray[0] || { code: "N/A", val: 0 };

  // ----- Age distribution -----
  const ageMap = {};
  platformData.audience_data?.forEach(({ age_range, value }) => {
    ageMap[age_range] = (ageMap[age_range] || 0) + value;
  });
  const ageArray = Object.entries(ageMap).map(([name, val]) => ({ name, val }));
  ageArray.sort((a, b) => b.val - a.val);
  const topAge = ageArray[0] || { name: "N/A", val: 0 };

  // ----- Gender distribution -----
  let maleVal = 0;
  let femaleVal = 0;
  platformData.audience_data?.forEach(({ gender, value }) => {
    if (gender?.toLowerCase() === "male") maleVal += value;
    else if (gender?.toLowerCase() === "female") femaleVal += value;
  });
  const total = maleVal + femaleVal;
  const malePercent = total ? Math.round((maleVal / total) * 100) : 0;
  const femalePercent = 100 - malePercent;

  return {
    topCountry: { code: topCountry.code, value: topCountry.val },
    topAge: { name: topAge.name, value: topAge.val },
    malePercent,
    femalePercent,
  };
}

const CampaignReportingPanel = ({
  costPerCreatorData,
  viewsByDayData,
  reachData,
  creatorIds = [],
}) => {
  const [creatorDetails, setCreatorDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  // Menu anchor for export (optional)
  const [exportMenuAnchor, setExportMenuAnchor] = useState(null);
  const handleOpenExportMenu = (event) => setExportMenuAnchor(event.currentTarget);
  const handleCloseExportMenu = () => setExportMenuAnchor(null);

  // 1. Fetch each creator's data (like MediaKit)
  useEffect(() => {
    const fetchCreators = async () => {
      if (!creatorIds.length) return;
      setLoading(true);
      try {
        const details = await Promise.all(
          creatorIds.map((id) => client.creators.fetchDetails(id))
        );
        setCreatorDetails(details);
      } catch (error) {
        console.error("Error fetching creator details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCreators();
  }, [creatorIds]);

  // (Optional) If you have a formula for CPM, define it here
  const computeEstimatedCPM = (mainPlatform) => {
    // e.g. read directly from mainPlatform?.calcCpm or do a custom formula
    return mainPlatform?.calcCpm ?? 0;
  };

  // CSV Export logic (Optional)
  const convertCreatorDetailsToCSV = () => {
    if (!creatorDetails.length) return "No data to export";
    // Headers
    const headers = [
      "Creator",
      "Location",
      "Avg Likes (Main)",
      "Avg Comments (Main)",
      "Top Country (%)",
      "Gender Split (M-F)",
      "Top Age (%)",
    ];
    // Rows
    const rows = creatorDetails.map((detail, idx) => {
      const cName = detail?.creator?.creator || `Creator #${idx + 1}`;
      const location = detail?.creator?.geolocation || detail?.creator?.region || "N/A";
      const mainPlatform = findMainPlatform(detail?.creator?.public);
      const avgLikes = mainPlatform?.avg_likes || 0;
      const avgComments = mainPlatform?.avg_comments || 0;
      const { topCountry, topAge, malePercent, femalePercent } = computePlatformSegments(mainPlatform);
      const topCountryStr = `${topCountry.code} (${topCountry.value.toFixed(0)}%)`;
      const genderSplitStr = `${malePercent}% / ${femalePercent}%`;
      const topAgeStr = `${topAge.name} (${topAge.value.toFixed(0)}%)`;
      return [
        `"${cName}"`,
        `"${location}"`,
        avgLikes,
        avgComments,
        `"${topCountryStr}"`,
        `"${genderSplitStr}"`,
        `"${topAgeStr}"`,
      ].join(",");
    });
    const csvContent = [headers.join(","), ...rows].join("\n");
    return csvContent;
  };
  const exportCreatorBreakdownCSV = () => {
    const csvContent = convertCreatorDetailsToCSV();
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "creator_breakdown.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleCloseExportMenu();
  };

  const exportCreatorBreakdownSheets = async () => {
    try {
      // Example: client.campaigns.exportSheetCreators(...) or whatever your route is
      const response = await client.campaigns.exportSheetCreators({
        data: creatorDetails,
      });
      if (response.sheetLink) {
        window.open(response.sheetLink, "_blank");
      } else {
        console.error("Error exporting to Google Sheets:", response.error);
      }
    } catch (error) {
      console.error("Failed to export to Google Sheets:", error);
    }
    handleCloseExportMenu();
  };

  return (
    <>
      {/* Optional export button */}
      <Box mb={2} display="flex" justifyContent="flex-end">
        <Button variant="outlined" onClick={handleOpenExportMenu}>
          Export
        </Button>
        <Menu
          anchorEl={exportMenuAnchor}
          open={Boolean(exportMenuAnchor)}
          onClose={handleCloseExportMenu}
        >
          <MenuItem onClick={exportCreatorBreakdownCSV}>Export to CSV</MenuItem>
          <MenuItem onClick={exportCreatorBreakdownSheets}>
            Export to Google Sheets
          </MenuItem>
        </Menu>
      </Box>

      {/* Loading */}
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" my={4}>
          <CircularProgress />
        </Box>
      )}

      {/* "See in List" style table */}
      {!loading && creatorDetails.length > 0 && (
        <Box mb={6}>
          <Typography variant="h5" gutterBottom>
            Creators Summary (Main Platform Stats)
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Creator</strong></TableCell>
                  <TableCell><strong>Location</strong></TableCell>
                  <TableCell><strong>Avg Likes<br />(Main)</strong></TableCell>
                  <TableCell><strong>Avg Comments<br />(Main)</strong></TableCell>
                  <TableCell><strong>Top Country (%)<br />(Main)</strong></TableCell>
                  <TableCell><strong>Gender Split (M-F)<br />(Main)</strong></TableCell>
                  <TableCell><strong>Top Age (%)<br />(Main)</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {creatorDetails.map((detail, index) => {
                  const creatorName = detail?.creator?.creator || `Creator #${index + 1}`;
                  const location =
                    detail?.creator?.geolocation || detail?.creator?.region || "N/A";

                  // Identify single main platform
                  const mainPlatform = findMainPlatform(detail?.creator?.public);
                  // Get avg_likes & avg_comments from that platform
                  const avgLikes = mainPlatform?.avg_likes || 0;
                  const avgComments = mainPlatform?.avg_comments || 0;

                  // Derive audience breakdown from that single main platform
                  const { topCountry, topAge, malePercent, femalePercent } =
                    computePlatformSegments(mainPlatform);

                  // Turn them into strings
                  const countryStr = `${topCountry.code} (${topCountry.value.toFixed(0)}%)`;
                  const genderStr = `${malePercent}% / ${femalePercent}%`;
                  const ageStr = `${topAge.name} (${topAge.value.toFixed(0)}%)`;

                  // Optional CPM
                  const cpmValue = computeEstimatedCPM(mainPlatform).toFixed(2);

                  return (
                    <TableRow key={index}>
                      <TableCell>{creatorName}</TableCell>
                      <TableCell>{location}</TableCell>
                      <TableCell>{avgLikes.toLocaleString()}</TableCell>
                      <TableCell>{avgComments.toLocaleString()}</TableCell>
                      <TableCell>{countryStr}</TableCell>
                      <TableCell>{genderStr}</TableCell>
                      <TableCell>{ageStr}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Original Charts (Cost, Views, Reach, etc.) */}
      <Grid container spacing={4} justifyContent="center">
        {/* Cost Per Creator Chart */}
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardHeader
              title="Cost Per Creator"
              sx={{
                textAlign: "center",
                backgroundColor: "#646AFF",
                color: "#FFF",
              }}
            />
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={costPerCreatorData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="price" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Views by Day Chart */}
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardHeader
              title="Total Views by Day"
              sx={{
                textAlign: "center",
                backgroundColor: "#646AFF",
                color: "#FFF",
              }}
            />
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={viewsByDayData}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="views" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Reach Pie Chart */}
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardHeader
              title="Total Reach by Creator"
              sx={{
                textAlign: "center",
                backgroundColor: "#646AFF",
                color: "#FFF",
              }}
            />
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={reachData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label
                  >
                    {reachData.map((entry, idx) => (
                      <Cell key={`cell-${idx}`} fill={COLORS[idx % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CampaignReportingPanel;
