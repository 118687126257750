import { IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TextField, Tooltip } from '@mui/material'
import React from 'react'
import { dateToDatetimeLocal, serviceStatuses } from '../../../Utils/constants'
import FeedbackIcon from '@mui/icons-material/Feedback';
import EditIcon from '@mui/icons-material/Edit';
import Add from '@mui/icons-material/Add';
import { StyledTableRow } from '../../../Utils/styledcell';

export default function ServicesTable({ services = [], onUpdateState = (id, state) => 0, onRequestReview = (id) => 0, onEdit = (id) => 0, onNew = () => 0 }) {
    return (
        <TableContainer component={Paper} elevation={1}>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Status
                        </TableCell>
                        <TableCell>
                            Approval Status
                        </TableCell>
                        <TableCell>
                            Approval Date
                        </TableCell>
                        <TableCell align='right'>
                            Actions
                        </TableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {!!services && services.map((service) =>
                        <StyledTableRow key={service.id}>
                            <TableCell>
                                {service.name}
                            </TableCell>
                            <TableCell>
                                <TextField
                                    select
                                    SelectProps={{ sx: { textTransform: 'capitalize' } }}
                                    value={service.status}
                                    onChange={(e) => onUpdateState(service.id, e.target.value)}
                                >
                                    {serviceStatuses.map(status => <MenuItem key={status} value={status} sx={{ textTransform: 'capitalize' }}>{status}</MenuItem>)}
                                </TextField>
                            </TableCell>
                            <TableCell>
                                <TextField disabled value={service.approval_status} InputProps={{ inputProps: { style: { textTransform: 'capitalize' } } }}>
                                </TextField>
                            </TableCell>
                            <TableCell>
                                <TextField disabled type="datetime-local" value={dateToDatetimeLocal(service?.date_approved)}>
                                </TextField>
                            </TableCell>
                            <TableCell align='right'>
                                <Tooltip title='Edit service.'>
                                    <IconButton onClick={(e) => onEdit(service.id, e.target.value)}>
                                        <EditIcon></EditIcon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Request review of service.'>
                                    <span>
                                        <IconButton color='warning' disabled={service.approval_status == 'approved' || service.approval_status == 'pending'} onClick={(e) => onRequestReview(service.id)}>
                                            <FeedbackIcon></FeedbackIcon>
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </StyledTableRow>)}
                </TableBody>
                <TableFooter>
                    <StyledTableRow>
                        <TableCell colSpan={5} align='right'>
                            <IconButton color='success' onClick={onNew}>
                                <Add></Add>
                            </IconButton>
                        </TableCell>
                    </StyledTableRow>
                </TableFooter>

            </Table>
        </TableContainer>
    )
}
