import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Button, TextField, FormControl, Paper, InputLabel, Select, MenuItem, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, TablePagination,
  Link, Grid, Chip, Divider, useTheme
} from '@mui/material';
import { StyleButton, StyleContent, StyleStickyButtonContainter } from '../../../Utils/ThemedStyles';
import API from '../../../API';

const freeEmailDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'icloud.com', 'outlook.com'];

const isFreeEmail = (email) => {
  if (!email) return false;
  const domain = email.split('@')[1];
  return freeEmailDomains.includes(domain);
};

const CreatorCRMViewPublic = () => {
  const theme = useTheme();
  const [creatorCRMData, setCreatorCRMData] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  const [statusFilter, setStatusFilter] = useState('cold');
  const [newStatus, setNewStatus] = useState('EMAIL1');
  const [searchQuery, setSearchQuery] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [credits, setCredits] = useState(0);
  const [userId, setUserId] = useState(1);
  const [sortOrder, setSortOrder] = useState('newest');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const fetchCreatorCrmData = async (filter) => {
    try {
      let data = await API.crm.creator_creators();
      
      // Filter data based on the status 'coldCRM' and exclude public email domains
      data = data.filter(creator =>
        !isFreeEmail(creator.email) &&  // Check that the email is not a free public email
        (creator.status === 'coldCRM' || creator.status === 'cold')    // Filter to only include creators with 'coldCRM' or 'cold' status
      );
  
      if (searchQuery) {
        data = data.filter(creator => creator.email.includes(searchQuery));
      }
  
      if (sortOrder === 'followers') {
        data = data.sort((a, b) => b.following - a.following);
      } else if (sortOrder === 'avg_views') {
        data = data.sort((a, b) => b.avg_views - a.avg_views);
      } else if (sortOrder === 'newest') {
        data = data.sort((a, b) => new Date(b.date_sourced) - new Date(a.date_sourced));
      }
  
      setCreatorCRMData(data);
      console.log('Fetched CRM data:', data);
    } catch (error) {
      console.error('Failed to fetch CRM data:', error);
    }
  };

  const fetchCredits = async () => {
    try {
      const data = await API.credx.credits({ user_id: userId });
      setCredits(data.credits);
      console.log('Fetched credits:', data);
    } catch (error) {
      console.error('Failed to fetch credits:', error);
    }
  };

  useEffect(() => {
    if (tabValue === 0) {
      fetchCreatorCrmData(statusFilter);
    }
  }, [statusFilter, tabValue, searchQuery, sortOrder]);

  useEffect(() => {
    fetchCredits(); // Fetch credits on component mount
  }, []);

  const handleSelect = (id) => {
    setSelectedCreators(prevState =>
      prevState.includes(id) ? prevState.filter(creatorId => creatorId !== id) : [...prevState, id]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedCreators(creatorCRMData.map(creator => creator.id));
    } else {
      setSelectedCreators([]);
    }
  };

  const handleSendUpdate = async () => {
    if (selectedCreators.length > credits) {
      alert('Not enough credits to send emails.');
      return;
    }

    const updates = selectedCreators.map(id => {
      const creator = creatorCRMData.find(creator => creator.id === id);
      return {
        email: creator.email,
        subject: emailSubject.replace(/{first_name}/g, creator.username),
        body: emailBody.replace(/{first_name}/g, creator.username),
        cc_email: ccEmail // Include cc_email in the update
      };
    });

    try {
      const result = await API.crm.update(updates);
      console.log('Updates sent:', result);

      await API.crm.update_contacts({ updates, note: `last email - ${emailSubject}` });

      for (const id of selectedCreators) {
        await API.crm.creator_update_status({ id, status: 'EMAIL1' });
      }

      const response = await API.credx.subtract_credx({
        user_id: userId,
        email_count: selectedCreators.length,
      });
      
      console.log('Subtract credits result:', response);
      setCredits(Number(response.credits));
  
      setDialogOpen(false);
      setSelectedCreators([]);
      setEmailSubject('');
      setEmailBody('');
      setCcEmail(''); // Reset ccEmail
    } catch (error) {
      console.error('Failed to send updates:', error);
      alert(`Failed to send updates: ${error.message}`);
    }
  };
  

  const handleChangeStatus = async () => {
    for (const id of selectedCreators) {
      await client.crm.creator_update_status({ id, status: newStatus === 'REMOVE' ? 'removed' : newStatus });
    }
    setSelectedCreators([]);
    if (tabValue === 0) {
      fetchCreatorCrmData(statusFilter);
    }
  };

  const downloadCSV = () => {
    const headers = ['ID', 'Link', 'Username', 'Following', 'Likes', 'Avg Views', 'Date Sourced', 'Email', 'Bio Link', 'Date Added', 'Last Updated'];
    const csvRows = [headers.join(',')];

    creatorCRMData.forEach(creator => {
      const values = [
        creator.id,
        creator.link,
        creator.username,
        creator.following,
        creator.likes,
        creator.avg_views,
        creator.date_sourced,
        creator.email,
        creator.bio_link,
        creator.date_added,
        creator.last_updated
      ];
      csvRows.push(values.map(value => `${value}`).join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'creator_crm_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box sx={{ 
      ...StyleContent,
      backgroundColor: theme.palette.background.default,
      p: 3
    }}>
      <Paper elevation={3} sx={{ 
        mb: 3, 
        p: 3, 
        borderRadius: 4,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
              Creator CRM Dashboard
            </Typography>
            <Chip 
              label={`Available Credits: ${credits}`}
              color="secondary"
              sx={{ 
                fontSize: '1.1rem',
                p: 2,
                borderRadius: 2
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange} 
              centered
              sx={{
                '& .MuiTabs-indicator': {
                  height: 4,
                  borderRadius: 2
                }
              }}
            >
              <Tab label="Email Outreach" sx={{ fontSize: '1.1rem' }} />
            </Tabs>
          </Grid>
        </Grid>
      </Paper>

      {tabValue === 0 && (
        <Box>
          <Paper elevation={2} sx={{ mb: 3, p: 2, borderRadius: 3 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <Button 
                  variant="contained" 
                  onClick={downloadCSV}
                  sx={{
                    borderRadius: 2,
                    px: 4,
                    py: 1.5,
                    fontWeight: 700,
                    boxShadow: 3
                  }}
                >
                  Export CSV
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Sort By</InputLabel>
                  <Select
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="newest">Newest First</MenuItem>
                    <MenuItem value="followers">Follower Count</MenuItem>
                    <MenuItem value="avg_views">Average Views</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setDialogOpen(true)}
                  sx={{
                    borderRadius: 2,
                    px: 4,
                    py: 1.5,
                    fontWeight: 700,
                    boxShadow: 3,
                    width: '100%'
                  }}
                >
                  Send Campaign ({selectedCreators.length})
                </Button>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={2} sx={{ borderRadius: 3, overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '60vh' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" sx={{ bgcolor: theme.palette.primary.main }}>
                      <Checkbox
                        onChange={handleSelectAll}
                        checked={selectedCreators.length === creatorCRMData.length}
                        indeterminate={selectedCreators.length > 0 && selectedCreators.length < creatorCRMData.length}
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    </TableCell>
                    {['Profile Link', 'Username', 'Followers', 'Likes', 'Avg Views', 'Date Added', 'Bio Link', 'Last Updated'].map((header) => (
                      <TableCell key={header} sx={{ 
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        fontSize: '1rem'
                      }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creatorCRMData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((creator) => (
                    <TableRow 
                      key={creator.id} 
                      hover 
                      sx={{ 
                        '&:hover': { backgroundColor: theme.palette.action.hover },
                        '&.Mui-selected': { backgroundColor: theme.palette.action.selected }
                      }}
                      selected={selectedCreators.includes(creator.id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedCreators.includes(creator.id)}
                          onChange={() => handleSelect(creator.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Link 
                          href={creator.link} 
                          target="_blank" 
                          sx={{ 
                            color: theme.palette.secondary.main,
                            '&:hover': { textDecoration: 'underline' }
                          }}
                        >
                          View Profile
                        </Link>
                      </TableCell>
                      <TableCell>{creator.username}</TableCell>
                      <TableCell>{creator.following?.toLocaleString()}</TableCell>
<TableCell>{creator.likes?.toLocaleString()}</TableCell>
<TableCell>{creator.avg_views?.toLocaleString()}</TableCell>
                      <TableCell>{new Date(creator.date_sourced).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <Link 
                          href={creator.bio_link} 
                          target="_blank"
                          sx={{ 
                            color: theme.palette.text.secondary,
                            '&:hover': { color: theme.palette.primary.main }
                          }}
                        >
                          Bio Link
                        </Link>
                      </TableCell>
                      <TableCell>{new Date(creator.last_updated).toLocaleDateString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={creatorCRMData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
            />
          </Paper>
        </Box>
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { borderRadius: 4 } }}
      >
        <DialogTitle sx={{ 
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4
        }}>
          Create Email Campaign
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={0} sx={{ p: 2, borderRadius: 2, bgcolor: theme.palette.action.hover }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
                  Selected Creators ({selectedCreators.length})
                </Typography>
                <List dense sx={{ maxHeight: 150, overflow: 'auto' }}>
                  {selectedCreators.map(id => {
                    const creator = creatorCRMData.find(c => c.id === id);
                    return (
                      <ListItem key={id} sx={{ py: 0.5 }}>
                        <Chip
                          label={creator?.email}
                          size="small"
                          sx={{ mr: 1 }}
                        />
                        <Typography variant="body2" color="textSecondary">
                          {creator?.username}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email Subject"
                variant="outlined"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                sx={{ mb: 2 }}
                InputProps={{
                  sx: { borderRadius: 2 }
                }}
              />
              <TextField
                fullWidth
                multiline
                rows={6}
                label="Email Body"
                variant="outlined"
                value={emailBody}
                onChange={(e) => setEmailBody(e.target.value)}
                InputProps={{
                  sx: { borderRadius: 2 }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="CC Email"
                variant="outlined"
                value={ccEmail}
                onChange={(e) => setCcEmail(e.target.value)}
                InputProps={{
                  sx: { borderRadius: 2 }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: `1px solid ${theme.palette.divider}` }}>
          <Button 
            onClick={() => setDialogOpen(false)}
            sx={{ 
              borderRadius: 2,
              px: 3,
              textTransform: 'none'
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSendUpdate}
            sx={{ 
              borderRadius: 2,
              px: 4,
              fontWeight: 700,
              boxShadow: 3,
              textTransform: 'none'
            }}
          >
            Send Campaign ({selectedCreators.length})
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreatorCRMViewPublic;