import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import ZapFeed from './zapfeed/zapfeed';
import { useQuery } from 'react-query';
import API from '../../../API';
import routes from './../../../Config/routes';
import useIsDesktop from '../../../Hooks/useIsDesktop';
import CountUp from 'react-countup';
import '../../styles.css';

const CreatorStart = () => {
  const navigate = useNavigate();
  const { creatorToken } = useCreatorAuth();
  const [openUnreadMessagesDialog, setOpenUnreadMessagesDialog] = useState(false);
  const isDesktop = useIsDesktop();
  const theme = useTheme();

  if (!creatorToken) return <>Loading</>;

  const {
    isLoading: isLoadingDashboard,
    error: errorDashboard,
    data: dashboard,
  } = useQuery(
    ['creator', creatorToken.creator_user.username, 'dashboard'],
    API.creatorConnect.dashboard,
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30,
    }
  );

  if (isLoadingDashboard)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          background: `linear-gradient(to bottom right, ${theme.palette.primary.light}, ${theme.palette.background.default})`
        }}
      >
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
    );

  if (errorDashboard)
    return (
      <Typography sx={{ color: 'error.main', p: 4, textAlign: 'center' }}>
        Error getting dashboard data: {errorDashboard?.response?.data?.error || errorDashboard.message}
      </Typography>
    );

  // Filter payouts to exclude "Cancelled"
  const validPayouts = dashboard?.payouts?.filter((p) => p.status === 'Pending' || p.status === 'Approved') || [];

  // Calculating total earnings and status breakdown (excluding Cancelled)
  const totalEarnings = validPayouts.reduce((sum, payout) => sum + payout.amount, 0) || 0;
  const pendingEarnings = validPayouts
    .filter((payout) => payout.status === 'Pending')
    .reduce((sum, payout) => sum + payout.amount, 0) || 0;
  const approvedEarnings = validPayouts
    .filter((payout) => payout.status === 'Approved')
    .reduce((sum, payout) => sum + payout.amount, 0) || 0;

  // Filtering campaigns and partnerships
  const filteredCampaigns = dashboard.campaigns.filter((campaign) => {
    const creator = campaign.creators.find((c) => c.id === creatorToken.creator_user.username);
    return (
      campaign.campaign_status !== 'Archived' &&
      campaign.campaign_status !== 'Draft' &&
      campaign.campaign_status !== 'DELETED' &&
      creator?.status !== 'Declined' &&
      creator?.status !== 'Dropped'
    );
  });

  const filteredPartnerships = dashboard.partnerships.filter((partnership) => {
    return partnership.status !== 'Archived' && partnership.status !== 'Draft' && partnership.status !== 'DELETED';
  });

  // Calculating total deals
  const totalDeals = filteredCampaigns.length + filteredPartnerships.length;

  const handleUnreadMessagesClick = () => {
    setOpenUnreadMessagesDialog(true);
  };

  const handleUnreadMessagesClose = () => {
    setOpenUnreadMessagesDialog(false);
  };

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          background: `linear-gradient(to bottom right, ${theme.palette.grey[200]}, ${theme.palette.background.default})`,
          py: 6,
          px: { xs: 2, md: 8 },
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
            Your Blitz Earnings
          </Typography>
          <Typography variant="h3" component="div" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
            $
            <CountUp end={totalEarnings} duration={2} decimals={2} />
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'medium', mt: 1 }}>
              Pending: $
              <CountUp end={pendingEarnings} duration={2} decimals={2} />
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'medium', mt: 1 }}>
              Approved: $
              <CountUp end={approvedEarnings} duration={2} decimals={2} />
            </Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ mt: 3, color: 'text.secondary' }}>
            Contact us to learn how to earn more money through Blitz
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              variant="contained"
              sx={{
                bgcolor: theme.palette.primary.main,
                ':hover': { bgcolor: theme.palette.primary.dark },
                textTransform: 'none',
                fontWeight: 'bold',
                px: 3,
                py: 1
              }}
              onClick={() => handleNavigate(routes.creatorConnectPayoutsInvoicing)}
            >
              Invoice Someone
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            gap: 3,
          }}
        >
          {/* Left Side (ZapFeed) */}
          <Box sx={{ flex: 1 }}>
            <ZapFeed />
          </Box>

          {/* Right Side (Quick Access) */}
          <Box sx={{ flex: 2, minWidth: { xs: 'auto', sm: '25em' } }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
              Quick Access
            </Typography>

            <Grid container spacing={3}>
              {/* Total Earnings Card */}
              <Grid item xs={12} sm={6}>
                <Card
                  elevation={2}
                  sx={{
                    borderRadius: 2,
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    ':hover': { boxShadow: 8, transform: 'translateY(-4px)' },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                      Total Earnings
                    </Typography>
                    <Typography variant="h5" sx={{ mt: 1, fontWeight: 'bold' }}>
                      ${totalEarnings.toFixed(2)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                      Pending: ${pendingEarnings.toFixed(2)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Approved: ${approvedEarnings.toFixed(2)}
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{ mt: 2, textTransform: 'none', fontWeight: 'medium' }}
                      onClick={() => handleNavigate(routes.creatorConnectPayoutsInvoicing)}
                    >
                      View Earnings
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Total Deals Card */}
              <Grid item xs={12} sm={6}>
                <Card
                  elevation={2}
                  sx={{
                    borderRadius: 2,
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    ':hover': { boxShadow: 8, transform: 'translateY(-4px)' },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                      Total Deals
                    </Typography>
                    <Typography variant="h5" sx={{ mt: 1, fontWeight: 'bold' }}>
                      {totalDeals}
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{ mt: 2, textTransform: 'none', fontWeight: 'medium' }}
                      onClick={() => handleNavigate(routes.creatorCollabs)}
                    >
                      View Collaborations
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Unread Messages Card */}
              <Grid item xs={12}>
                <Card
                  elevation={2}
                  sx={{
                    borderRadius: 2,
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    ':hover': { boxShadow: 8, transform: 'translateY(-4px)' },
                    backgroundColor: theme.palette.warning.light,
                  }}
                >
                  <CardContent>
                    <Badge badgeContent={dashboard?.unreadMessages?.length || 0} color="secondary">
                      <Typography variant="h6" sx={{ fontWeight: 'medium', display: 'inline-block' }}>
                        Unread Messages
                      </Typography>
                    </Badge>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {dashboard?.unreadMessages?.length
                        ? `You have ${dashboard.unreadMessages.length} unread messages.`
                        : 'You have no unread messages.'}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{ mt: 2, textTransform: 'none', fontWeight: 'medium' }}
                      onClick={handleUnreadMessagesClick}
                      disabled={dashboard?.unreadMessages?.length === 0}
                    >
                      View Unread Messages
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {[
                { title: 'Pitch', route: routes.creatorConnectPitch, description: 'Craft your next opportunity.' },
                {
                  title: 'Mediakit',
                  route: `https://www.useblitz.co/creators/${creatorToken.creator_user.username}`,
                  external: true,
                  description: 'Manage your media and content assets.'
                },
                { title: 'Deals & Emails', route: routes.creatorConnectDealsEmails, description: 'Track your deals and communications.' },
                { title: 'More Options', route: routes.creatorConnectMore, description: 'Explore more features and tools.' },
              ].map((card, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    elevation={2}
                    sx={{
                      borderRadius: 2,
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      ':hover': { boxShadow: 8, transform: 'translateY(-4px)' },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                        {card.title}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                        {card.description}
                      </Typography>
                      <Button
                        variant="outlined"
                        sx={{ mt: 2, textTransform: 'none', fontWeight: 'medium' }}
                        onClick={() => {
                          if (card.external) {
                            window.open(card.route, '_blank');
                          } else {
                            handleNavigate(card.route);
                          }
                        }}
                      >
                        {card.title === 'Mediakit' ? 'View Mediakit' : `Go to ${card.title}`}
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* Unread Messages Dialog */}
      <Dialog open={openUnreadMessagesDialog} onClose={handleUnreadMessagesClose} fullWidth maxWidth="sm">
        <DialogTitle>Unread Messages</DialogTitle>
        <DialogContent>
          <List>
            {dashboard?.unreadMessages?.map((campaign) => (
              <Box key={campaign.campaign_id} sx={{ mt: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 1 }}>
                  {campaign.campaign_name}
                </Typography>
                {campaign.messages.map((message) => (
                  <ListItem
                    key={message.message_id}
                    button
                    onClick={() => {
                      handleNavigate(routes.creatorCollabs);
                      handleUnreadMessagesClose();
                    }}
                    sx={{
                      background: theme.palette.action.hover,
                      borderRadius: 1,
                      mb: 1,
                      ':hover': { background: theme.palette.action.selected },
                    }}
                  >
                    <ListItemText
                      primary={message.text}
                      secondary={`Received on ${new Date(message.created_at).toLocaleString()}`}
                    />
                  </ListItem>
                ))}
              </Box>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreatorStart;
