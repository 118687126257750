import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
  Box,
  TextField,
  Divider,
  MenuItem,
  FormControlLabel,
  Switch,
  Card,
  Snackbar,
  Alert,
  Container,
  Stepper,
  Step,
  StepLabel,
  CssBaseline,
  InputLabel,
  FormControl,
  Select,
  ThemeProvider,
  Tabs,
  Tab
} from '@mui/material';
import { keyframes } from '@emotion/react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import routes from '../../Config/routes';
import AlertDialog from '../../Components/AlertDialog';
import useAlertDialog from '../../Components/useAlertDialog';
import ConsentForm from '../../CreatorUser/CreatorPages/popups/consentform';
import client from '.././../API'
import { useGoogleLogin } from '@react-oauth/google';
import { niches, region } from '../../Utils/constants'
import { GoogleLogin } from '@react-oauth/google';
import GoogleButton from '../../Components/GoogleButton';
import { useMutation } from 'react-query';
import MuiPhoneInput from '../../Components/MuiPhoneInput';
import {
  validateEmail,
  validatePassword,
  capitalizeFirstLetter,
} from "../../Utils";
import { lightTheme } from "../../Utils/baseTheme";
import HomeHeader from "../../Components/HomeHeader";
import HomeFooter from "../../Components/HomeFooter";
import CreatorOnboard from '../../CreatorUser/creatorOnboard';

const pageTheme = lightTheme;

// The AllOnboard component
const AllOnboard = () => {
  const [selectedTab, setSelectedTab] = useState('brand'); // 'brand' or 'creator'
  const [searchParams, setSearchParams] = useSearchParams();
  const tabValue = searchParams.get("tab") || "brand";
  const setTabValue = (newValue) => {
    setSearchParams({ tab: newValue });
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => setOpenSnackbar(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <HomeHeader />
      <Toolbar />
      <Container maxWidth="md" sx={{ paddingTop: 2, paddingBottom: 4 }}>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
            Welcome to Blitz!
          </Typography>
          <Typography variant="body1" paragraph>
            Select your onboarding type and enter your information to get started.
          </Typography>
        </Box>

        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          centered
        >
          <Tab label="Sign up as a Brand User" value="brand" />
          <Tab label="Sign up as a Creator" value="creator" />
        </Tabs>

        <Box sx={{ mt: 4 }}>
          {tabValue === "brand" && <BrandRegisterForm />}
          {tabValue === "creator" && <CreatorOnboard/>}
        </Box>
      </Container>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <HomeFooter />
    </ThemeProvider>
  );
};

// Brand Register Form (adapted from DemoRegister)
const BrandRegisterForm = () => {
  const navigate = useNavigate();
  const { ref } = useParams();
  const referringUserId = ref && !isNaN(Number(ref)) ? Number(ref) : null;
  const referralSource = referringUserId === null ? ref : "";

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    phoneNumber: "",
    industry: "",
    bio: "",
    businessType: "",
    isAgency: "",
    monthlySpend: "",
    teamAccounts: "",
    paymentMethod: "",
    referralSource: referralSource,
    referringUserId: referringUserId,
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const [openDialog, setOpenDialog] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Personal Information", "Company Information", "Business Details"];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { mutate: register, isLoading } = useMutation(
    client.companies.demoRegister,
    {
      onSuccess: () => {
        navigate(routes.login);
      },
      onError: (error) => {
        console.error("An error occurred during registration: ", error);
        let errorMessage = "An error occurred, please try again.";
        if (error.code === "ERR_NETWORK") {
          errorMessage = "Network is disconnected!";
        } else {
          if (error.response && error.response.data) {
            errorMessage = error.response.data.error || errorMessage;
          }
        }
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      },
    }
  );

  const handleNext = () => {
    let bValidation = true;

    const requiredFields = getRequiredFieldsForStep(activeStep);

    requiredFields.forEach((item) => {
      if (bValidation && (!userInfo[item] || userInfo[item].length === 0)) {
        setSnackbarMessage(`${capitalizeFirstLetter(item)} field is required!`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        bValidation = false;
      }
    });

    if (!bValidation) return;

    if (activeStep === 0) {
      if (!validateEmail(userInfo.email)) {
        setSnackbarMessage("Invalid email address!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }

      if (!validatePassword(userInfo.password)) {
        setSnackbarMessage("Password should have 6 or more characters!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }

      if (userInfo.password !== userInfo.confirmPassword) {
        setSnackbarMessage("Passwords should match!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }
    }

    if (activeStep === steps.length - 1) {
      handleRegister();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRegister = async () => {
    let bValidation = true;

    const requiredFields = Object.keys(userInfo).filter(
      (field) => field !== "referralSource" && field !== "referringUserId"
    );

    requiredFields.forEach((item) => {
      if (bValidation && (!userInfo[item] || userInfo[item].length === 0)) {
        setSnackbarMessage(`${capitalizeFirstLetter(item)} field is required!`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        bValidation = false;
      }
    });

    if (
      bValidation &&
      !userInfo.referralSource &&
      !userInfo.referringUserId
    ) {
      setSnackbarMessage(
        "Either Referral Source or Referring User ID is required!"
      );
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      bValidation = false;
    }

    if (!bValidation) return;

    const params = { ...userInfo };

    register(params);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getRequiredFieldsForStep = (step) => {
    switch (step) {
      case 0:
        return [
          "firstName",
          "lastName",
          "email",
          "password",
          "confirmPassword",
          "phoneNumber",
        ];
      case 1:
        return [
          "companyName",
          "streetAddress",
          "city",
          "state",
          "country",
          "industry",
        ];
      case 2:
        return [
          "businessType",
          "isAgency",
          "monthlySpend",
          "teamAccounts",
          "paymentMethod",
        ];
      default:
        return [];
    }
  };

  const industryOptions = [
    "Fashion",
    "Tech",
    "Food",
    "Beauty",
    "Sports",
    "Travel",
    "Finance",
    "Health",
    "Education",
    "Entertainment",
    "Automotive",
    "Real Estate",
    "Hospitality",
    "Retail",
    "Manufacturing",
    "Telecommunications",
  ];

  const countryOptions = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "Germany",
    "France",
    "Italy",
    "Spain",
    "Japan",
    "China",
    "India",
    "Brazil",
    "Mexico",
    "Russia",
    "South Korea",
    "South Africa",
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <TextField
              disabled={isLoading}
              label="First Name"
              name="firstName"
              variant="outlined"
              value={userInfo.firstName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Last Name"
              name="lastName"
              variant="outlined"
              value={userInfo.lastName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Email"
              name="email"
              variant="outlined"
              value={userInfo.email}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Password"
              name="password"
              variant="outlined"
              type="password"
              value={userInfo.password}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Re-enter Password"
              name="confirmPassword"
              variant="outlined"
              type="password"
              value={userInfo.confirmPassword}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={userInfo.phoneNumber}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
          </div>
        );
      case 1:
        return (
          <div>
            <TextField
              disabled={isLoading}
              label="Company Name"
              name="companyName"
              variant="outlined"
              value={userInfo.companyName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Street Address"
              name="streetAddress"
              variant="outlined"
              value={userInfo.streetAddress}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="City"
              name="city"
              variant="outlined"
              value={userInfo.city}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="State"
              name="state"
              variant="outlined"
              value={userInfo.state}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>Country</InputLabel>
              <Select
                name="country"
                value={userInfo.country}
                onChange={handleChange}
                label="Country"
              >
                {countryOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>Industry</InputLabel>
              <Select
                name="industry"
                value={userInfo.industry}
                onChange={handleChange}
                label="Industry"
              >
                {industryOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Tell us about your company"
              name="bio"
              value={userInfo.bio}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
            />
          </div>
        );
      case 2:
        return (
          <div>
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>
                Is your business all under one company, or is it separate?
              </InputLabel>
              <Select
                name="businessType"
                value={userInfo.businessType}
                onChange={handleChange}
                label="Is your business all under one company, or is it separate?"
              >
                <MenuItem value="One Company">One Company</MenuItem>
                <MenuItem value="Separate">Separate</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>Are you an agency?</InputLabel>
              <Select
                name="isAgency"
                value={userInfo.isAgency}
                onChange={handleChange}
                label="Are you an agency?"
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="How much do you expect to spend every month?"
              name="monthlySpend"
              value={userInfo.monthlySpend}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
            <TextField
              label="How many accounts do you need (for your team)?"
              name="teamAccounts"
              value={userInfo.teamAccounts}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>
                What is your main method of payment?
              </InputLabel>
              <Select
                name="paymentMethod"
                value={userInfo.paymentMethod}
                onChange={handleChange}
                label="What is your main method of payment?"
              >
                <MenuItem value="Credit Card">Credit Card</MenuItem>
                <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                <MenuItem value="PayPal">PayPal</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="How did you hear about us?"
              name="referralSource"
              value={userInfo.referralSource}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required={!userInfo.referringUserId}
              fullWidth
            />
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", maxWidth: 600, margin: '0 auto' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box
          sx={{
            flexDirection: "column",
            display: "flex",
            minWidth: "100%",
            mt: 2
          }}
        >
          {getStepContent(activeStep)}

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              endIcon={
                isLoading &&
                activeStep === steps.length - 1 && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
              disabled={isLoading}
            >
              {activeStep === steps.length - 1 ? "Create Account" : "Next"}
            </Button>
          </Box>
          {userInfo.referringUserId && (
            <Box mt={2} textAlign="center">
              <Typography variant="body2" sx={{ color: "#000" }}>
                Referred by Creator ID: {userInfo.referringUserId}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Thanks for Booking a Creator!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Thanks for booking a creator through their media kit. To access your
            collaboration, make an account here.
          </Typography>
          <Typography variant="body2" mt={2}>
            For any questions or concerns, please contact{" "}
            <a href="mailto:jc@thecultureclub.us">jc@thecultureclub.us</a>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AllOnboard;
