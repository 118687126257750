import { Box, Container, Divider, Link, Typography } from '@mui/material'
import React from 'react'
import Codebox from '../../Components/Codebox'
import { Link as RouterLink } from 'react-router-dom'
import routes from '../../Config/routes'
import tutorial_1 from '../../Components/globalAssets/docs_services_1.png'

export default function DocsServices(props) {


    return (
        <Container sx={{ paddingBlock: 2 }}>
            <Typography variant='h3'>Services</Typography>
            <Divider></Divider>
            <br />
            <Typography variant='h6'>What are Services?</Typography>
            Services are applications (or "Apps") that influencers on the Blitz platform can search for, discover, and subscribe to.<br />
            These services provide tools, integrations, or solutions that help influencers enhance their workflow, connect with external platforms, and grow their presence.<br /><br />
            When you're given developer access to the Blitz Third Party API, you can go to your <Link component={RouterLink} to={`${routes.user}?tab=dev`}>User Information page</Link> to
            find a new 'Developer' tab, where you'll find
            your services and API Keys. To generate an API Key, you'll first need a service. Expand the Services section, and hit the Plus icon to create your first service.
            <br />
            <img src={tutorial_1} style={{ width: '75%' }}></img>
            <br />
            <Typography variant='h6'>New Service Form</Typography>
            <Divider></Divider>
            <ul>
                <li><b>ID</b>: Your service ID.</li>
                <li><b>Name</b>: The name of your service.</li>
                <li><b>Icon URL</b>: An URL linking to an image for your service Icon</li>
                <li><b>Homepage URL</b>: An URL linking to the homepage of your service</li>
                <li><b>Privacy Policy URL</b>: An URL linking to the privacy policy for your service</li>
                <li><b>Description</b>: A description for your service that our Creators will see.</li>
                <li><b>Subscription cost</b>: The cost for the subscription of your service monthly. You may have multiple subscription tiers on your homepage, and display
                    your preferred tier cost on Blitz.</li>
                <li><b>On Creator Authorize URL</b>: An URL creators will be redirect to with their token when using "Login with Blitz"</li>
                <li><b>Public Key</b>: Your unique key to verifying JWTs generated by our system</li>
            </ul>
            To get a Service started and being able to generate API keys, you only need to fill the name of the service. All the other fields will be necesary for <b>review and approval.</b>
            <br /><br/>
            <Typography variant='h6'>Service review and approval</Typography>
            <Divider></Divider>
            When you're done building your third party service and want to use production API keys, and to be available in the App search, you'll need to send your service for review.
            This is done by clicking the Feedback icon on your services list, and confirming. You won't be able to make changes on your services while a review is pending, and
            the approval will be undone if you make any future changes except for the status of your service.<br />
            Your service will then be flagged in our system for review, and the resolution will be sent to your email. The moment a service is approved, you will be subscribed to
            the Blitz Third Party API, and you'll be start getting billed the agreed amount.<br/> If a service is rejected you'll be informed the reason by email and
            be able to do the modifications needed and re-apply<br /><br/>
            <Typography variant='h6'>Service Status</Typography>
            <Divider></Divider>
            In the services list, you can change the current status of your service.
            <ul>
                <li><b>Inactive</b>: The default state. Your service is enabled but hidden in the Blitz App search.</li>
                <li><b>Active</b>: Your service can be found on the Blitz App search <b>You can only set this status when your service has been approved.</b></li>
                <li><b>Deleted</b>: This status deletes your service, terminating any of your API Keys. <Typography component='span' color='error'>This cannot be undone.</Typography>
                </li>
            </ul>
            <br />


        </Container>
    )
}
