import React, { useEffect, useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useDropzone } from 'react-dropzone';
import API from '../../API';

/**
 * A set of pre-defined color templates. Each has a `main` and `accent`.
 * Feel free to add/remove as needed.
 */
const COLOR_TEMPLATES = [
  { main: '#FF5733', accent: '#C70039' },   // Warm reds
  { main: '#6F72FF', accent: '#BA88FF' },   // Purple/Blue gradient
  { main: '#007BFF', accent: '#FFC107' },   // Classic bootstrap combo
  { main: '#000000', accent: '#FFFFFF' },   // Black and white
  { main: '#1E88E5', accent: '#E91E63' },   // Material design-ish
];

const FILE_SIZE_LIMIT = 4.5 * 1024 * 1024; // 4.5 MB

const RosterSettingsDialog = ({ open, onClose, agencyName }) => {
  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [description, setDescription] = useState("");
  const [mainColor, setMainColor] = useState("");
  const [accentColor, setAccentColor] = useState("");
  const [settingsExists, setSettingsExists] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const queryClient = useQueryClient();

  // ----------------------------
  // 1) Fetch existing settings
  // ----------------------------
  const { isLoading: isLoadingRosterSettings } = useQuery(
    ["rosterSettings", agencyName],
    () => API.agency.getRosterSettings(agencyName),
    {
      enabled: false, // manually fetch when dialog opens
      retry: false,
      onSuccess: (data) => {
        setSettingsExists(true);
        setName(data.name || "");
        setLogo(data.logo || "");
        setDescription(data.description || "");
        setMainColor(data.color_scheme?.main || "");
        setAccentColor(data.color_scheme?.accent || "");
      },
      onError: (err) => {
        if (err?.response?.status === 404) {
          // No settings exist yet
          setSettingsExists(false);
          setName("");
          setLogo("");
          setDescription("");
          setMainColor("");
          setAccentColor("");
        } else {
          console.error(err);
          setErrorMsg("Failed to fetch roster settings.");
        }
      }
    }
  );

  useEffect(() => {
    if (open) {
      setErrorMsg("");
      fetchRosterSettings();
    }
  }, [open]);

  const fetchRosterSettings = async () => {
    // Manually trigger the query
    await queryClient.fetchQuery(["rosterSettings", agencyName]);
  };

  // ----------------------------
  // 2) Mutations: create or update
  // ----------------------------
  const createMutation = useMutation(
    (payload) => API.agency.createRosterSettings(agencyName, payload),
    {
      onSuccess: () => {
        alert("Roster settings created successfully!");
        onClose();
      },
      onError: (err) => {
        console.error(err);
        setErrorMsg("Failed to create roster settings.");
      }
    }
  );

  const updateMutation = useMutation(
    (payload) => API.agency.updateRosterSettings(agencyName, payload),
    {
      onSuccess: () => {
        alert("Roster settings updated successfully!");
        onClose();
      },
      onError: (err) => {
        console.error(err);
        setErrorMsg("Failed to update roster settings.");
      }
    }
  );

  // ----------------------------
  // 3) File upload functionality
  // ----------------------------
  const { mutate: uploadFile } = useMutation(
    (fileData) => API.files.post(fileData),
    {
      onSuccess: (data) => {
        // data.url should hold the returned URL
        setLogo(data.url);
      },
      onError: (error) => {
        console.error("Error uploading file:", error);
        alert(`Error uploading file: ${error.message}`);
      },
    }
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      if (file.size > FILE_SIZE_LIMIT) {
        alert("File size exceeds the 4.5MB limit. Please upload a smaller file.");
        return;
      }

      // Read file as base64
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64File = reader.result.split(",")[1];
        uploadFile({
          file: {
            name: file.name,
            content: base64File,
            type: file.type,
          },
        });
      };
    },
    [uploadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // ----------------------------
  // 4) Handle form submission
  // ----------------------------
  const handleSave = () => {
    setErrorMsg("");
    const payload = {
      name,
      logo,
      description,
      color_scheme: {
        main: mainColor,
        accent: accentColor,
      },
    };

    if (!settingsExists) {
      createMutation.mutate(payload);
    } else {
      updateMutation.mutate(payload);
    }
  };

  // Quick color template helper
  const applyColorTemplate = (template) => {
    setMainColor(template.main);
    setAccentColor(template.accent);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Roster Settings</DialogTitle>
      <DialogContent dividers>
        {/* Loading indicator (optional) */}
        {isLoadingRosterSettings && (
          <Typography variant="body2" sx={{ mb: 2 }}>
            Loading...
          </Typography>
        )}

        {/* Display errors if any */}
        {errorMsg && (
          <Typography variant="body2" color="error">
            {errorMsg}
          </Typography>
        )}

        {/* Roster Name */}
        <TextField
          label="Roster Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {/* Drag & Drop area + manual field for Logo */}
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Upload or Paste Logo URL
        </Typography>
        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed #000",
            padding: "20px",
            cursor: "pointer",
            textAlign: "center",
            marginBottom: 2,
            marginTop: 1,
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here...</p>
          ) : (
            <p>Drag and drop or click to select a file (Max size: 4.5MB)</p>
          )}
        </Box>
        <TextField
          label="Logo URL"
          fullWidth
          margin="normal"
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
        />

        {/* If we have a logo, show a preview */}
        {logo && (
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Logo Preview:
            </Typography>
            <img
              src={logo}
              alt="Uploaded Logo"
              style={{ maxWidth: "100%", maxHeight: "150px" }}
            />
          </Box>
        )}

        {/* Description */}
        <TextField
          label="Description"
          fullWidth
          margin="normal"
          multiline
          minRows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        {/* Color Pickers */}
        <Box sx={{ display: "flex", gap: 2, mt: 2, flexWrap: "wrap" }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2">Main Color</Typography>
            <input
              type="color"
              value={mainColor}
              onChange={(e) => setMainColor(e.target.value)}
              style={{
                width: "100%",
                cursor: "pointer",
                height: "40px",
                border: "none",
                padding: 0,
              }}
            />
            <TextField
              size="small"
              margin="normal"
              fullWidth
              placeholder="#000000"
              value={mainColor}
              onChange={(e) => setMainColor(e.target.value)}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2">Accent Color</Typography>
            <input
              type="color"
              value={accentColor}
              onChange={(e) => setAccentColor(e.target.value)}
              style={{
                width: "100%",
                cursor: "pointer",
                height: "40px",
                border: "none",
                padding: 0,
              }}
            />
            <TextField
              size="small"
              margin="normal"
              fullWidth
              placeholder="#FFFFFF"
              value={accentColor}
              onChange={(e) => setAccentColor(e.target.value)}
            />
          </Box>
        </Box>

        {/* Pre-set templates */}
        <Typography variant="subtitle2" sx={{ mt: 3 }}>
          Or pick from these common color combos:
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 1 }}>
          {COLOR_TEMPLATES.map((tpl) => (
            <Box
              key={`${tpl.main}-${tpl.accent}`}
              sx={{
                width: 60,
                height: 30,
                background: `linear-gradient(to right, ${tpl.main} 50%, ${tpl.accent} 50%)`,
                borderRadius: "4px",
                cursor: "pointer",
                border: "1px solid #ccc",
              }}
              onClick={() => applyColorTemplate(tpl)}
              title={`Main: ${tpl.main} / Accent: ${tpl.accent}`}
            />
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RosterSettingsDialog;
