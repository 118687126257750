import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Button,
  Tabs,
  Tab,
  Alert,
  Paper,
  Backdrop,
  CircularProgress,
  Pagination,
  CardActionArea,
  CardActions,
  Chip,
  Avatar,
  Stack,
  Divider,
  useTheme
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Delete as DeleteIcon } from "@mui/icons-material";
import client from "../../../../API";
import CastingCallDialog from "./castingcallsdialog";
import CreateCallDialog from "./CreateCallDialog";
import { globalStyles } from "../../../../Utils/Styles";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAuth from "../../../../Hooks/use-auth";

const dummyPhotoLinks = [
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/287262.jpg",
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/wp2468668.jpg",
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/eev9l01j44ab1.jpg",
];

const rowsPerPage = 12;

const statusColorMap = {
  ACTIVE: 'success',
  COMPLETED: 'info',
  DELETED: 'error'
};

const CastingCalls = () => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState("all");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCastingCallId, setSelectedCastingCallId] = useState(null);
  const [open, setOpen] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);

  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();
  const handleOpen = () => {
    const hasOpenCastingCall = castingCalls?.casting_calls.some(
      (call) => call.status === "ACTIVE" && call.user_id === userInfo.id
    );
    if (hasOpenCastingCall) {
      setBannerVisible(true);
      return;
    }
    setOpen(true);
  };
  const { data: castingCalls, isFetching: loadingList, refetch: fetchCastingCalls, error: errorList } = useQuery({
    queryKey: ['castingCalls', 'list', { page, tabValue }],
    queryFn: () => client.castingCalls.list({ page, rows: rowsPerPage, status: tabValue }),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (castingCalls) {
      setRowCount(castingCalls.total);
    }
  }, [castingCalls]);

  useEffect(() => {
    if (!!errorList) {
      alert(`An error ocurred while obtaining Casting Calls: ${errorList?.response?.data?.error || errorList.message}`);
      console.log(errorList);
    }
  }, [errorList])

  const { mutate: updateStatus, isLoading: loadingUpdateState } = useMutation(
    ({ id, status }) => client.castingCalls.updateStatus(id, status),
    {
      onSuccess: () => {
        fetchCastingCalls();
      },
      onError: (error) => {
        console.error("Error updating casting call status:", error);
        alert(`An error ocurred while updating Casting Call: ${error?.response?.data?.error || error.message}`);
      },
    }
  );

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  

  const handleClose = () => setOpen(false);

  const handleDelete = (castingCallId) => {
    updateStatus({ id: castingCallId, status: "DELETED" });
  };

  const handleComplete = (castingCallId) => {
    updateStatus({ id: castingCallId, status: "COMPLETED" });
  };


  const handleDialogOpen = (castingCallId) => {
    setSelectedCastingCallId(castingCallId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedCastingCallId(null);
  };

  return (
    <Box sx={{ 
      ...globalStyles.wholeHeightMinusToolbar, 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      bgcolor: 'background.default'
    }}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={loadingList||loadingUpdateState}>
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <Paper elevation={4} square sx={{ 
        pt: 2,
        mb: 2,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8
      }}>
        <Typography
          variant="h3"
          component="div"
          sx={{ 
            textAlign: "center", 
            mb: 2,
            fontWeight: 'bold',
            color: 'primary.main'
          }}
        >
          Casting Calls
        </Typography>

        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 4,
          pb: 2
        }}>
          <Button 
            variant="contained" 
            onClick={handleOpen} 
            sx={{ 
              borderRadius: 2,
              px: 3,
              py: 1,
              fontWeight: 'bold',
              boxShadow: 3
            }}
          >
            + New Casting Call
          </Button>

          <Tabs 
            value={tabValue} 
            onChange={handleTabChange}
            sx={{
              '& .MuiTabs-indicator': {
                height: 4,
                borderRadius: 2
              }
            }}
          >
            <Tab label="All" value="all" sx={{ fontSize: '1rem' }} />
            <Tab label="Active" value="active" sx={{ fontSize: '1rem' }} />
            <Tab label="Completed" value="completed" sx={{ fontSize: '1rem' }} />
          </Tabs>
        </Box>
      </Paper>

      {bannerVisible && (
        <Alert severity="warning" sx={{ mx: 4, mb: 2, borderRadius: 2 }}>
          You may not have more than one open casting call. To create a new one,
          you must delete or archive the old one.
        </Alert>
      )}

      <CreateCallDialog open={open} handleClose={handleClose} fetchCastingCalls={fetchCastingCalls} />

      <Box sx={{ px: 4, flex: 1, overflow: 'auto' }}>
        <Grid container spacing={3}>
          {(!!castingCalls) && castingCalls.casting_calls.map((call) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={call.id}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 6
                  }
                }}
              >
                <CardActionArea onClick={() => handleDialogOpen(call.id)}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={dummyPhotoLinks[Math.round((call.date_created.getTime() / 1000) % dummyPhotoLinks.length)]}
                    alt="Casting Call Image"
                    sx={{ objectFit: 'cover' }}
                  />
                </CardActionArea>

                <CardContent sx={{ flexGrow: 1 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                    <Chip
                      label={call.status}
                      color={statusColorMap[call.status] || 'default'}
                      size="small"
                      sx={{ fontWeight: 'bold' }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      #{call.id}
                    </Typography>
                  </Stack>

                  <Typography variant="h6" gutterBottom>
                    {call.platforms.join(", ")}
                  </Typography>
                  
                  <Typography variant="body2" paragraph sx={{ 
                    height: 60,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 3,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical'
                  }}>
                    {call.brief}
                  </Typography>

                  <Divider sx={{ my: 1 }} />

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" fontWeight="bold">
                      Budget:
                    </Typography>
                    <Typography variant="body2">
                      ${call.budget_min || 0} - ${call.budget_max || 0}
                    </Typography>
                  </Stack>
                </CardContent>

                <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(call.id);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                  
                  {tabValue !== 'completed' && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleComplete(call.id);
                      }}
                      color={call.status === 'COMPLETED' ? "success" : 'default'}
                      size="small"
                    >
                      {call.status === 'COMPLETED' ? (
                        <CheckCircleIcon fontSize="small" />
                      ) : (
                        <CheckCircleOutlineIcon fontSize="small" />
                      )}
                    </IconButton>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Paper elevation={2} sx={{ 
        p: 2, 
        mt: 'auto',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8
      }}>
        <Pagination
          count={Math.ceil(rowCount / rowsPerPage)}
          page={page + 1}
          onChange={(event, value) => setPage(value - 1)}
          color="primary"
          shape="rounded"
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Paper>

      <CastingCallDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        castingCallId={selectedCastingCallId}
      />
    </Box>
  );
};

export default CastingCalls;