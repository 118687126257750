import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
  useMediaQuery
} from '@mui/material';
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import API from '../../../API';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';

const PayoutsInvoicing = () => {
  const { creatorToken } = useCreatorAuth();
  const username = creatorToken?.creator_user?.username;
  const isMobile = useMediaQuery('(max-width:600px)');

  // Invoice states
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [notes, setNotes] = useState('');
  const [poNumber, setPoNumber] = useState('');

  // Payment states
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({ payoutId: null, method: '' });

  // Tabs & filters
  const [activeTab, setActiveTab] = useState('all'); // 'all', 'payouts', 'invoices'
  const [statusFilter, setStatusFilter] = useState('all');

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // For storing a "Stripe Info" object or PaymentMethod references
  // We'll also show how to fetch existing Stripe accounts:
  const [stripeAccounts, setStripeAccounts] = useState([]);
  
  // React Query: fetch financial data (payouts/invoices)
  const { isLoading, error, data: financials } = useQuery(
    ['creator', 'financials'],
    () => API.creatorConnect.getFinancials(username),
    { keepPreviousData: true }
  );

  // ---------------------------
  // 1) FETCH STRIPE BANK ACCOUNTS
  // ---------------------------
  useEffect(() => {
    const fetchStripeBankAccounts = async () => {
      try {
        // Example: entity_type="creator", entity_id = username or user.id
        const data = await API.bank.getStripeBankAccounts({
          entity_type: 'creator',
          entity_id: username,
        });
        setStripeAccounts(data);
      } catch (err) {
        console.error('Error fetching Stripe bank accounts:', err);
      }
    };

    if (username) {
      fetchStripeBankAccounts();
    }
  }, [username]);

  // ---------------------------
  // 2) LINK A NEW BANK ACCOUNT (Stripe)
  // ---------------------------
  const handleLinkStripeBankAccount = async () => {
    try {
      // In real flow, you'd collect bank details via SetupIntent or Payment Element
      // For demonstration, let's assume you have PaymentMethod ID and Customer ID
      const examplePaymentMethodId = 'pm_ABC123';
      const exampleStripeCustomerId = 'cus_ABC456';

      await API.bank.linkStripeBankAccount({
        entity_type: 'creator',
        entity_id: username,
        stripe_customer_id: exampleStripeCustomerId,
        payment_method_id: examplePaymentMethodId,
      });
      setSnackbarMessage('Bank account (Stripe) linked successfully!');
      setSnackbarOpen(true);

      // Refresh the Stripe accounts list
      const data = await API.bank.getStripeBankAccounts({
        entity_type: 'creator',
        entity_id: username,
      });
      setStripeAccounts(data);

    } catch (err) {
      console.error('Error linking Stripe bank account:', err);
      alert('Error linking bank account: ' + err.message);
    }
  };

  // ---------------------------
  // 3) Invoice-handling logic
  // ---------------------------
  const handleGeneratePO = () => {
    setPoNumber(`TCC-${Math.floor(Math.random() * 1000000)}`);
  };

  const handleOpenInvoiceDialog = () => {
    setInvoiceDialogOpen(true);
    handleGeneratePO();
  };

  const handleCloseInvoiceDialog = () => {
    setInvoiceDialogOpen(false);
  };

  const handleInvoice = async () => {
    try {
      await API.creatorConnect.postInvoice({
        user_id: username,
        creator_id: username,
        po_number: poNumber,
        amount: parseFloat(amount),
        payout_date: new Date().toISOString(),
        status: 'Pending',
        blitzpay: true,
        unique_code: poNumber,
        interest_rate: 5,
        client_name: clientName,
        client_email: clientEmail,
        notes,
      });
      handleCloseInvoiceDialog();
      setSnackbarMessage('Invoice sent successfully');
      setSnackbarOpen(true);
    } catch (error) {
      alert(error);
      handleCloseInvoiceDialog();
    }
  };

  // ---------------------------
  // 4) Payment-handling logic
  // ---------------------------
  const handleOpenPaymentDialog = (payoutId) => {
    setPaymentDialogOpen(true);
    setPaymentMethod({ payoutId, method: '' });
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  };

  const handleConfirmPayment = async () => {
    try {
      await API.creatorConnect.confirmPayment({
        username,
        payoutId: paymentMethod.payoutId,
        paymentMethod: paymentMethod.method,
      });
      setSnackbarMessage('Payment method confirmed and payout approved successfully');
      setSnackbarOpen(true);
      handleClosePaymentDialog();
    } catch (error) {
      alert(error);
    }
  };

  // ---------------------------
  // 5) Tabs & Filtering
  // ---------------------------
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  // Loading & error states
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Typography sx={{ color: 'error' }}>
        Error fetching financials: {error.message}
      </Typography>
    );
  }

  // Data transformations
  const allPayouts = (financials?.payouts || []).sort(
    (a, b) => new Date(b.payout_date) - new Date(a.payout_date)
  );
  const allInvoices = (financials?.invoices || []).sort(
    (a, b) => new Date(b.payout_date) - new Date(a.payout_date)
  );

  const allData = [
    ...allPayouts.map((p) => ({ ...p, recordType: 'payout' })),
    ...allInvoices.map((i) => ({ ...i, recordType: 'invoice' })),
  ].sort((a, b) => new Date(b.payout_date) - new Date(a.payout_date));

  const filterByStatus = (items) => {
    if (statusFilter === 'all') return items;
    return items.filter((item) => item.status?.toLowerCase() === statusFilter);
  };

  let displayedData = [];
  if (activeTab === 'all') {
    displayedData = filterByStatus(allData);
  } else if (activeTab === 'payouts') {
    displayedData = filterByStatus(allPayouts);
  } else if (activeTab === 'invoices') {
    displayedData = filterByStatus(allInvoices);
  }

  // Calculate earnings
  const pendingEarnings = allPayouts
    .filter((p) => p.status === 'Pending')
    .reduce((sum, p) => sum + (p.amount || 0), 0);
  const approvedEarnings = allPayouts
    .filter((p) => p.status === 'Approved')
    .reduce((sum, p) => sum + (p.amount || 0), 0);
  const totalEarnings = pendingEarnings + approvedEarnings;

  const pendingInvoices = allInvoices.filter((invoice) => invoice.status === 'Pending');
  const unverifiedFunds = pendingInvoices.reduce((sum, invoice) => sum + (invoice.amount || 0), 0);

  // ---------------------------
  // 6) CSV Download
  // ---------------------------
  const handleDownloadCSV = () => {
    const csvData = [
      ['ID', 'Amount', 'Status', 'Payout Date', 'Blitzpay', 'Fee', 'Unique Code', 'Notes'],
      ...(financials?.invoices || []).map((invoice) => [
        invoice.id,
        invoice.amount?.toFixed(2) || '0.0',
        invoice.status,
        invoice.payout_date,
        invoice.blitzpay ? 'Yes' : 'No',
        ((invoice.interest_rate / 100) * invoice.amount).toFixed(2),
        invoice.unique_code,
        invoice.notes,
      ]),
      ...(financials?.payouts || []).map((payout) => [
        payout.id,
        payout.amount?.toFixed(2) || '0.0',
        payout.status,
        payout.payout_date,
        payout.blitzpay ? 'Yes' : 'No',
        ((payout.interest_rate / 100) * payout.amount).toFixed(2),
        payout.unique_code,
        payout.notes,
      ]),
    ];

    const csvString = csvData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `Financial_Statement_${new Date().toLocaleDateString()}.csv`);
  };

  // ---------------------------
  // 7) Snackbar
  // ---------------------------
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Top horizontal container */}
      <Paper
        elevation={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          mb: 2
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Blitz Balance
          </Typography>
          <Typography variant="h6" sx={{ color: 'text.secondary', mt: 1 }}>
            Approved + Pending: ${totalEarnings.toFixed(2)}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Unverified (Pending Invoices): ${unverifiedFunds.toFixed(2)}
          </Typography>
        </Box>

        <Box>
          {/* Invoice Someone */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenInvoiceDialog}
            sx={{ mr: 2 }}
          >
            Invoice Someone
          </Button>

          {/* Link New Stripe Bank Account */}
          <Typography variant="body2" sx={{ mt: 1 }}>
            To Transfer Payments directly to your bank account, go into settings and signup for BlitzPay
          </Typography>
        </Box>
      </Paper>

      {/* Tabs for All activity, Payouts, Invoices */}
      <Paper elevation={1} square sx={{ mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="All Activity" value="all" />
          <Tab label="Payouts" value="payouts" />
          <Tab label="Invoices" value="invoices" />
        </Tabs>
      </Paper>

      {/* Filter dropdown and download button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <FormControl size="small">
          <Select value={statusFilter} onChange={handleStatusChange}>
            <MenuItem value="all">All Statuses</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="scheduled">Scheduled</MenuItem>
          </Select>
        </FormControl>
        <Button variant="outlined" startIcon={<DownloadIcon />} onClick={handleDownloadCSV}>
          Download Financial Statement
        </Button>
      </Box>

      {/* Table of results */}
      <Paper elevation={0} sx={{ p: 2 }}>
        {displayedData.length === 0 ? (
          <Typography>No records found.</Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ borderBottom: '1px solid #ccc' }}>
                  <TableCell>Status</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Payout Date</TableCell>
                  <TableCell>Notes / Actions</TableCell>
                  <TableCell>Extra Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData.map((item) => {
                  const isInvoice = item.recordType === 'invoice';
                  const combinedStatusType = `${item.status} ${isInvoice ? 'Invoice' : 'Payout'}`;

                  return (
                    <TableRow
                      key={item.id}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' }
                      }}
                    >
                      <TableCell>{combinedStatusType}</TableCell>
                      <TableCell>${(item.amount || 0).toFixed(2)}</TableCell>
                      <TableCell>{item.payout_date}</TableCell>
                      <TableCell>
                        {isInvoice && (
                          <Button
                            variant="contained"
                            component={Link}
                            to={`/creatorinvoice/${item.id}`}
                            sx={{ mt: 1 }}
                          >
                            View Invoice
                          </Button>
                        )}
                        {item.recordType === 'payout' &&
                          item.status?.toLowerCase() === 'pending' && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleOpenPaymentDialog(item.id)}
                              sx={{ mt: 1, ml: 2 }}
                            >
                              Approve Payment
                            </Button>
                          )}
                        {item.recordType === 'payout' &&
                          item.status?.toLowerCase() === 'approved' && (
                            <Typography sx={{ mt: 1 }}>
                              {item.blitzpay ? 'BlitzPay' : 'Net30'}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell>{item.notes}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      {/* Payment Method Dialog */}
      <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
        <DialogTitle>Select Payment Method</DialogTitle>
        <DialogContent>
          <DialogContentText>Please choose your preferred payment method.</DialogContentText>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <Select
              value={paymentMethod.method}
              onChange={(e) =>
                setPaymentMethod({
                  ...paymentMethod,
                  method: e.target.value
                })
              }
            >
              <MenuItem value="BLITZPAY">BlitzPay (Immediate Payment)</MenuItem>
              <MenuItem value="NET30">Net 30 (30-Day Payment)</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentDialog}>Cancel</Button>
          <Button onClick={handleConfirmPayment}>Confirm</Button>
        </DialogActions>
      </Dialog>

      {/* Invoice Creation Dialog */}
      <Dialog open={invoiceDialogOpen} onClose={handleCloseInvoiceDialog}>
        <DialogTitle>Invoice Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details for the client you want to invoice.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Client Name"
            fullWidth
            variant="outlined"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            margin="dense"
            label="Client Email"
            fullWidth
            variant="outlined"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            margin="dense"
            label="Amount"
            fullWidth
            variant="outlined"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            variant="outlined"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInvoiceDialog}>Cancel</Button>
          <Button onClick={handleInvoice}>Send Invoice</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
      />
    </Box>
  );
};

export default PayoutsInvoicing;
