import React, { useState, useEffect, useMemo } from 'react';
import {
    Avatar, Backdrop, Box, CircularProgress, Divider,
    InputAdornment, List, ListItem, ListItemAvatar,
    ListItemButton, ListItemText, Pagination, Paper,
    Skeleton, TextField, Typography, Grid, Card, CardContent
} from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import useIsDesktop from '../../Hooks/useIsDesktop';
import { Link, useOutlet, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../API';

export const styles = {
    wholeHeightMinusTop: {
        '@media (min-width:0px)': {
            height: { xs: 'calc(100vh - 56px - 48px)', md: 'calc(100vh - 48px)' },
            '@media (orientation: landscape)': {
                height: { xs: 'calc(100vh - 48px - 48px)', md: 'calc(100vh - 48px)' },
            },
        },
        '@media (min-width:600px)': {
            height: { xs: 'calc(100vh - 64px - 48px)', md: 'calc(100vh - 48px)' }
        }
    }
};

function CreatorEntry({ creator, currentCreator }) {
    return (
        <ListItemButton
            component={Link}
            to={creator.creator}
            sx={{
                backgroundColor: creator.creator === currentCreator ? 'action.selected' : 'transparent'
            }}
        >
            <ListItemAvatar>
                <Avatar src={creator.pfphref || undefined}>
                    {!creator.pfphref && creator.creator.charAt(0).toUpperCase()}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={creator.creator}
                secondary={creator.notes_content_style || '\u00A0'}
                secondaryTypographyProps={{ sx: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }}
            />
        </ListItemButton>
    );
}

function EmptyCreator({ key }) {
    return (
        <ListItemButton key={key}>
            <ListItemAvatar>
                <Skeleton variant="circular">
                    <Avatar />
                </Skeleton>
            </ListItemAvatar>
            <ListItemText
                primary={<Skeleton animation='wave' />}
                secondary={<Skeleton animation='wave' />}
                secondaryTypographyProps={{ sx: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }}
            />
        </ListItemButton>
    );
}

export default function AgencyCreators() {
    const isDesktop = useIsDesktop();
    const { creatorId } = useParams();
    const outlet = useOutlet();
    const [page, setPage] = useState(1); // Pagination starts at 1
    const itemsPerPage = 20;
    const [currentSearch, setCurrentSearch] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const { data: creators, isLoading } = useQuery({
        queryKey: ['creators'],
        queryFn: () => API.creators.list({ only_agency: true }),
        refetchOnWindowFocus: false
    });

    // Debounce search input
    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setCurrentSearch(searchInput);
            setPage(1); // Reset to first page on new search
        }, 500);
        return () => clearTimeout(delayInputTimeoutId);
    }, [searchInput]);

    const filteredCreators = useMemo(
        () => !creators ? [] : creators.filter((creator) => {
            return currentSearch === "" || Object.values(creator).some((val) =>
                typeof val === "string" && val.toLowerCase().includes(currentSearch.toLowerCase())
            );
        }),
        [currentSearch, creators]
    );

    // Calculate summary data
    const summaryData = useMemo(() => {
        if (!creators) return { total: 0, active: 0, pending: 0 };
        const total = creators.length;
        const active = creators.filter(c => c.status === 'active').length; // Adjust based on your data structure
        const pending = creators.filter(c => c.status === 'pending').length; // Adjust based on your data structure
        return { total, active, pending };
    }, [creators]);

    return (
        <>
            {(!isDesktop && outlet) || (
                <Box
                    sx={{
                        ...styles.wholeHeightMinusTop,
                        paddingInline: { xs: 0, md: 2 },
                        paddingBlockStart: { xs: 0, md: 2 },
                        paddingBlockEnd: { xs: 0, md: 1 },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}
                >
                    {/* Header with Summary Information */}
                    <Box>
                        <Grid container spacing={2}>
                            {/* Total Creators */}
                            <Grid item xs={12} sm={4}>
                                <Card elevation={3}>
                                    <CardContent>
                                        <Typography variant="h6">Total Creators</Typography>
                                        <Typography variant="h4">{summaryData.total}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* Active Creators */}
                            <Grid item xs={12} sm={4}>
                                <Card elevation={3}>
                                    <CardContent>
                                        <Typography variant="h6">Active Creators</Typography>
                                        <Typography variant="h4">{summaryData.active}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* Pending Creators */}
                            <Grid item xs={12} sm={4}>
                                <Card elevation={3}>
                                    <CardContent>
                                        <Typography variant="h6">Pending Creators</Typography>
                                        <Typography variant="h4">{summaryData.pending}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flex: 1 }}>
                        {/* Creators List Panel */}
                        <Paper
                            sx={{
                                maxHeight: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                width: { xs: '100%', md: '22.5rem' },
                            }}
                            elevation={1}
                            square={!isDesktop}
                        >
                            {/* Search Bar */}
                            <Box sx={{ padding: 2 }}>
                                <TextField
                                    value={searchInput}
                                    onChange={(event) => setSearchInput(event.target.value)}
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    placeholder="Search creators..."
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <SearchRounded />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                            <Divider />
                            {/* Creators List */}
                            <Box sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                                <List>
                                    {!isLoading && filteredCreators.length === 0 && (
                                        <ListItem>
                                            <ListItemText primary="No creators found." />
                                        </ListItem>
                                    )}
                                    {filteredCreators.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((creator) => (
                                        <CreatorEntry
                                            key={creator.creator}
                                            creator={creator}
                                            currentCreator={creatorId}
                                        />
                                    ))}
                                    {isLoading && [0, 1, 2, 3, 4].map((index) => (
                                        <EmptyCreator key={index} />
                                    ))}
                                </List>
                            </Box>
                            <Divider />
                            {/* Pagination */}
                            <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
                                <Pagination
                                    siblingCount={1}
                                    count={Math.ceil(filteredCreators.length / itemsPerPage)}
                                    page={page}
                                    onChange={(event, value) => setPage(value)}
                                    color="primary"
                                />
                            </Box>
                        </Paper>

                        {/* Detail Panel */}
                        {isDesktop && (
                            <Paper sx={{ maxHeight: '100%', overflowY: 'auto', flex: 1 }} elevation={1}>
                                {outlet || (
                                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                        <Typography variant='h4' color="text.secondary">
                                            Select a Creator
                                        </Typography>
                                    </Box>
                                )}
                            </Paper>
                        )}
                    </Box>
                </Box>
            )}
        </>
    );
}
