import React, { useState, useEffect, useRef } from 'react';
import {
    Typography,
    Toolbar,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Backdrop,
    CircularProgress,
    Box,
    TextField,
    Divider,
    MenuItem,
    FormControlLabel,
    Switch,
    Card,
    ThemeProvider,
    CssBaseline,
    Alert,
    Avatar
} from '@mui/material';
import { keyframes } from '@emotion/react'; // Import keyframes
import { useNavigate, useSearchParams } from 'react-router-dom';
import routes from '../Config/routes';

import AlertDialog from '../Components/AlertDialog';
import useAlertDialog from '../Components/useAlertDialog';
import BlitzHeader from '../Components/BlitzHeader';
import ConsentForm from './CreatorPages/popups/consentform';
import client from '../API'
import { useGoogleLogin } from '@react-oauth/google';
import { niches, region } from '../Utils/constants';
import { GoogleLogin } from '@react-oauth/google';
import GoogleButton from '../Components/GoogleButton';
import { useMutation, useQuery } from 'react-query';
import MuiPhoneInput from '../Components/MuiPhoneInput';
import { lightTheme } from '../Utils/baseTheme';
import HomeHeader from '../Components/HomeHeader';
import API from '../API';

const CreatorOnboardApp = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const serviceId = searchParams.get('service_id');
    const state = searchParams.get('state');

    const { data: app, isLoading, error } = useQuery({
        queryKey: ['services', serviceId],
        queryFn: () => API.developerPublic.serviceGet(serviceId),
        refetchOnWindowFocus: false,
        enabled: !!serviceId
    })

    const [username, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [validationError, setValidationError] = useState('');
    const [creatorRegion, setCreatorRegion] = useState('');
    const [creatorNiche, setCreatorNiche] = useState('');
    const [instagram, setInstagram] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [youtube, setYoutube] = useState('');
    const [podcast, setPodcast] = useState('');
    const [twitch, setTwitch] = useState('');
    const [kick, setKick] = useState('');
    const [x, setX] = useState('');
    const [isPartner, setIsPartner] = useState(false);

    const { dialogState, openDialog, closeDialog } = useAlertDialog();
    const [consentForm, setConsentForm] = useState(false);
    const inputRef = useRef(null);
    const formRef = useRef(null);

    const [googleRequest, setGoogleRequest] = useState('');

    // Dialog state
    const [disclaimer, setOpenDisclaimer] = useState(false);
    const [loading, setLoading] = useState(false);

    const scope = [
        'https://www.googleapis.com/auth/gmail.modify',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email'
    ];

    const scopeString = scope.reduce((accum, currSco, currIdx) => (accum + (currIdx == 0 ? '' : ' ') + currSco), '');

    // Define keyframes for the gradient animation
    const moveGradient = keyframes`
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  `;

    const rainbowBorder = {
        position: 'relative',
        padding: '1em',
        marginBlock: 2,
        borderRadius: '8px',
        backgroundColor: '#fff', // Inner background
        overflow: 'hidden',
        zIndex: 0,
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '-2px',
            left: '-2px',
            right: '-2px',
            bottom: '-2px',
            background: 'linear-gradient(90deg, red, orange, yellow, green, cyan, blue, violet)',
            zIndex: -2,
            animation: `${moveGradient} 3s linear infinite`,
            backgroundSize: '200% 200%',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '4px',
            left: '4px',
            right: '4px',
            bottom: '4px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            zIndex: -1,
        },
    };

    useEffect(() => {
        setOpenDisclaimer(false);
    }, []);

    const GoToStart = () => {
        console.log('Going to start!');
        navigate(`${routes.login}?tab=creator`);
    }

    const redirectLogin = (jwt, link) => {
        console.log('Redirecting to', link);
        if (!link) {
            openDialog('Error', 'The creator of this App has misconfigured settings. Contact them. Error code: error_missing_redirect_url',
                GoToStart, GoToStart, 'Ok', null
            )
            return;
        }
        // Query parameters
        const queryParams = {
            jwt: jwt,
        };

        if (state) {
            queryParams['state'] = state
        }

        // Construct the query string
        const queryString = new URLSearchParams(queryParams).toString();

        // Redirect to the target URL with query parameters
        window.location.href = `${link}?${queryString}`;
    }

    const googleLogin = useGoogleLogin({
        onError: errorResponse => {
            openDialog("Error", `Error getting Partner permissions: ${errorResponse}`, closeDialog, closeDialog, "Ok", null);
            setLoading(false);
        },
        scope: scopeString,
        flow: "auth-code",
        ux_mode: 'redirect',
        redirect_uri: 'https://www.useblitz.co/creatorconnect/redirect',
        state: googleRequest
    });

    useEffect(() => {
        if (googleRequest && isPartner)
            googleLogin();
    }, [googleRequest]);

    function handleCredentialResponse(response) {
        SubmitCreator(response.credential);
    }

    const handleLoginFailure = (error) => {
        console.error('Failed to login with Google:', error);
        setLoading(false);
        openDialog('Error', <>There was an error creating your account: {error} <br />
            Your Google and payment information has been blanked. Try again.</>, closeDialog, closeDialog, 'Ok', null);
    };


    const handleConsentClose = (consented) => {
        setConsentForm(false);

        setIsPartner(consented);

    };

    const handlePartnerSwitch = (e) => {
        if (e.target.checked) {
            setConsentForm(true);
        } else {
            setIsPartner(false);
        }
    }

    const SubmitCreator = async (googleCode) => {

        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return;
        }


        if (username.includes(" ")) {
            setValidationError("Creator name cannot contain spaces.");
            inputRef.current.focus();
            return;
        }

        if (phoneNumber.length < 9) {
            openDialog('Error', 'You have to enter a valid phone number', closeDialog, closeDialog, 'Ok', null);
            return;
        }

        if (!youtube && !instagram && !tiktok && !podcast && !twitch && !kick && !x) {
            openDialog('Error', 'You have enter a link for at least one platform', closeDialog, closeDialog, 'Ok', null);
            return;
        }

        setLoading(true);
        const payload = {
            username,
            is_partner: isPartner,
            region: creatorRegion,
            niche: creatorNiche,
            youtube: youtube || null,
            instagram: instagram || null,
            tiktok: tiktok || null,
            podcast: podcast || null,
            x: x || null,
            twitch: twitch || null,
            kick: kick || null,
            google_code: googleCode,
            phone_number: phoneNumber,
            service_id: serviceId,
            state: state
        };

        try {
            const data = await client.creatorConnect.onboardWithService(payload);
            if (isPartner) {
                setGoogleRequest(data.request_id);
                return;
            }
            openDialog('Success',
                'You have succesfully created your CreatorConnect account. you will now be redirect to the App.',
                () => redirectLogin(data.jwt, data.link), () => redirectLogin(data.jwt, data.link),
                'Ok',
                null);
        } catch (error) {
            console.error("Error during form submission:", error);
            openDialog('Error', <>There was an error creating your account: {error.response?.data?.error || error.message} <br />
                Try again.</>, closeDialog, closeDialog, 'Ok', null);
            setLoading(false);
        }
    }

    const OnSubmit = (e) => {
        e.preventDefault();

        SubmitCreator(null);
    };

    if (error) {
        console.log('Error', error);
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
                <Alert severity="error">
                    Error processing the sharing request: {
                        error?.response?.data?.error ||
                        error?.message
                    }
                </Alert>
            </Box>
        )
    }

    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <HomeHeader />
            <Toolbar />
            <AlertDialog alertState={dialogState}></AlertDialog>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading || isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {app && app.status != 'active' && <Alert severity="warning">
                This third party app has not been approved by Blitz yet.
            </Alert>}
            <Box sx={{ paddingInline: 2 }}>
                <form onSubmit={OnSubmit} ref={formRef} noValidate>
                    <Box sx={{ marginInline: 'auto', marginBlockStart: 4, marginBlockEnd: 2, display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '30em' }}>
                        <Box sx={{ marginBlockEnd: 1, textAlign: 'center' }}>
                            <Avatar sx={{ width: 72, height: 72, marginBlockStart: 1, boxShadow: 9, marginInline: 'auto' }} variant='rounded' src={app?.icon}>S</Avatar>
                            <Typography variant='h4' sx={{ marginBlockStart: 3, marginBlockEnd: 2 }}>Make a CreatorConnect account to use <Typography variant='inherit' color='secondary' component='span'>{app?.name}</Typography>!</Typography>
                            <Typography variant='body1' color='text.secondary'>And build your Media Kit for global brands to reach you!</Typography>
                        </Box>
                        <TextField
                            label='Username'
                            name='onboard-name'
                            helperText={validationError || 'Use the username of your biggest social media.'}
                            required
                            value={username}
                            error={!!validationError}
                            onChange={e => setUsername(e.target.value)}
                            inputRef={inputRef}
                        >
                        </TextField>
                        <TextField
                            label='Your Region'
                            name='onboard-region'
                            select
                            required
                            value={creatorRegion}
                            onChange={e => setCreatorRegion(e.target.value)}
                        >
                            {region.map((value) => (<MenuItem key={value} value={value}>{value == 'NACAUKAUS' ? 'USA, Canada, UK, Australia' : value}</MenuItem>))}
                        </TextField>
                        <TextField
                            label='Your Niche'
                            name='onboard-niche'
                            select
                            required
                            value={creatorNiche}
                            onChange={e => setCreatorNiche(e.target.value)}
                        >
                            {niches.map((market) => (
                                <MenuItem key={market} value={market}>
                                    {market}
                                </MenuItem>
                            ))}
                        </TextField>
                        <MuiPhoneInput
                            country={'us'}
                            preferredCountries={['us']}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            isValid={(value) => value.length >= 10}
                            required
                        />
                        <Divider></Divider>
                        <TextField
                            label='Youtube Link (If applicable)'
                            name='onboard-youtube'
                            inputProps={{
                                pattern: "^(https?://)?(www\\.)?(youtube\\.com|youtu\\.be)/(@?[\\w\\-]+)$",
                                title: 'Input a valid Youtube link'
                            }}
                            value={youtube}
                            onChange={e => setYoutube(e.target.value)}
                        />
                        <TextField
                            label='Instagram Link (If applicable)'
                            name='onboard-instagram'
                            inputProps={{
                                pattern: "^(https?://)?(www\\.)?instagram\\.com/[a-zA-Z0-9._]+/?$",
                                title: 'Input a valid Instagram link'
                            }}
                            value={instagram}
                            onChange={e => setInstagram(e.target.value)}
                        />
                        <TextField
                            label='Tiktok Link (If applicable)'
                            name='onboard-tiktok'
                            inputProps={{
                                pattern: "^(https?://)?(www\\.)?tiktok\\.com/@[a-zA-Z0-9._]+/?$",
                                title: 'Input a valid Tiktok link'
                            }}
                            value={tiktok}
                            onChange={e => setTiktok(e.target.value)}
                        />
                        <TextField
                            label='Podcast Link (If applicable)'
                            name='onboard-tiktok'
                            type='url'
                            value={podcast}
                            onChange={e => setPodcast(e.target.value)}
                        />
                        <TextField
                            label='X Link (If applicable)'
                            name='onboard-x'
                            inputProps={{
                                pattern: "^(https?://)?(www\\.)?(x|twitter)\\.com/[a-zA-Z0-9_]+/?$",
                                title: 'Input a valid X link'
                            }}
                            value={x}
                            onChange={e => setX(e.target.value)}
                        />
                        <TextField
                            label='Kick Link (If applicable)'
                            name='onboard-kick'
                            inputProps={{
                                pattern: "^(https?://)?(www\\.)?kick\\.com/[a-zA-Z0-9_]+/?$",
                                title: 'Input a valid Kick link'
                            }}
                            value={kick}
                            onChange={e => setKick(e.target.value)}
                        />
                        <TextField
                            label='Twitch Link (If applicable)'
                            name='onboard-twitch'
                            inputProps={{
                                pattern: "^(https?://)?(www\\.)?twitch\\.tv/[a-zA-Z0-9_]+/?$",
                                title: 'Input a valid Twitch link'
                            }}
                            value={twitch}
                            onChange={e => setTwitch(e.target.value)}
                        />
                        {/* Adjusted the Card component */}
                        <Card elevation={2} sx={rainbowBorder}>
                            <Box sx={{ position: 'relative', zIndex: 2 }}>
                                <FormControlLabel
                                    sx={{ marginInlineStart: 0, color: 'black' }} // Added color: 'black' here
                                    labelPlacement='start'
                                    checked={isPartner}
                                    onChange={handlePartnerSwitch}
                                    label='Enable pitch feature, AI inbound assistance (can be toggled off later), lower Blitz instant pay fee, and high priority on marketplace here.'
                                    control={<Switch />}
                                />
                            </Box>
                        </Card>
                        {/* Added footnote */}
                        <Typography variant='caption' color='text.secondary' sx={{ mt: -1, ml: 2 }}>
                            Creators who enable this feature get 2-5x more deals
                        </Typography>
                        {!isPartner && <Box sx={{ alignSelf: 'flex-end' }}>
                            <GoogleLogin
                                size='large'
                                onSuccess={handleCredentialResponse}
                                onError={handleLoginFailure} />
                        </Box>}
                        {isPartner && <Box sx={{ alignSelf: 'flex-end' }}>
                            <GoogleButton type='submit'></GoogleButton>
                        </Box>
                        }
                    </Box>
                </form>
            </Box>

            <ConsentForm open={consentForm} onClose={handleConsentClose}></ConsentForm>

            <Dialog open={disclaimer} onClose={() => setOpenDisclaimer(false)}>
                <DialogTitle>Signup Form Information</DialogTitle>
                <DialogContent>
                    <p>
                        This signup form is for joining Blitz's CreatorConnect. You will be available in the marketplace for companies to send campaigns directly to your email and phone number. By joining, you are joining a community of thousands of creators worldwide!
                    </p>
                    <p>
                        <strong>Why Google Login?</strong><br />
                        We use Google login to secure creator accounts with industry-leading security features, ensuring your data and login credentials remain safe.
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDisclaimer(false)} color="primary" variant="text">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </ThemeProvider>
    );
};

export default CreatorOnboardApp;
