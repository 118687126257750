import React from 'react';
import { Dialog, DialogContent } from "@mui/material";
import { useNavigate, useSearchParams } from 'react-router-dom';
import CreatorAuth from '../Components/CreatorAuth';
import routes from '../Config/routes';

const BlitzAuth = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get('service_id');
  const state = searchParams.get('state');

  const HandleDeny = () => {
    navigate(routes.creatorConnectStart);
  }

  return (
    <Dialog open PaperProps={{sx:{maxWidth:'none'}}}>
      <DialogContent sx={{maxWidth:'none'}}>
        <CreatorAuth serviceId={serviceId} state={state} onDeny={HandleDeny}></CreatorAuth>
      </DialogContent>
    </Dialog>
  );
};

export default BlitzAuth;
