import { Navigate, useLocation } from "react-router-dom";
import routes from "../Config/routes";
import { useCreatorAuth } from "../Hooks/creator-use-auth";

export default function CreatorRoute({ children }) {
  const { creatorToken } = useCreatorAuth();
  const location = useLocation();

  if (creatorToken == null) {
    const currentPath = location.pathname + location.search; // Get current route and query params
    const isOauthRoute = location.pathname === routes.creatorConnectOauth; // Check if the current route is creatorOauth
    
    // Build the query parameters for redirect
    const searchParams = new URLSearchParams(location.search);
    if (isOauthRoute) {
      searchParams.set("redirect", "oauth"); // Add or update the "redirect" query parameter
    }

    searchParams.set("tab", "creator");
    
    // Construct the login URL with the updated query parameters
    const loginUrl = `${routes.login}?${searchParams.toString()}`;

    return <Navigate to={loginUrl} />;
  }

  return <>{children}</>;
}