import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../../API"; // Adjust the path to your API client
import { isCompanyBalanceValid, isValidCompanySeats } from "../../../Utils";
import useAlertDialog from "../../../Components/useAlertDialog";

const CompanyDialog = ({ open, onClose, info = undefined }) => {
  const [balance, setBalance] = useState("");
  const [accountStatus, setAccountStatus] = useState("Free");
  const [companyName, setCompanyName] = useState("");
  const [creditLine, setCreditLine] = useState("");
  const [seats, setSeats] = useState("");
  const [phphref, setPhphref] = useState("");
  const [nominalInterestRate, setNominalInterestRate] = useState('10');
  const {dialogState, openDialog} = useAlertDialog();

  useEffect(()=>{
    if(open && info){
      setBalance(info?.balance);
      setAccountStatus(info.accountStatus || "Free");
      setCompanyName(info?.name || '');
      setCreditLine(info?.credit_line || '');
      setSeats(info?.seats?.join(",") || '');
      setPhphref(info?.phphref || '');
      setNominalInterestRate(info?.nominal_interest_rate || '');
    }
  },[open, info])

  // Define the options for account status
  const accountStatusOptions = [
    "AlphaFree",
    "Free",
    "Silver",
    "Gold",
    "Platinum",
    "Agency"
  ];

  const { mutate: editCompany, isLoading } = useMutation(client.companies.edit, {
    onSuccess: async (data) => {
      onClose(true);
    },
    onError: (error) => {
      openDialog('Error', error?.response?.data?.error || error.message);
      console.error("Error editing company:", error);
    },
  });

  const { mutate: createCompany, isLoading: createLoading } = useMutation(client.companies.create, {
    onSuccess: async (data) => {
      onClose(true);
    },
    onError: (error) => {
      openDialog('Error', error?.response?.data?.error || error.message);
      console.error("Error creating company:", error);
    },
  });

  const handleSubmit = () => {
    if (!balance&&balance!==0 || !accountStatus || !seats&&seats!==0) {
      openDialog("Error", "Please fill in all required fields.");
      return;
    }

    if (!isCompanyBalanceValid(balance)) {
      openDialog("Error", "Please input a valid balance! (Max Length is 8 digits)");
      return;
    }

    if (!isValidCompanySeats(seats)) {
      openDialog("Error", "Please input valid seats! (Format: 1,2,3)");
      return;
    }

    if (accountStatus.length === 0) {
      openDialog("Error", "Please input a valid account status!");
      return;
    }

    const parsedNominalInterest = parseFloat(nominalInterestRate);
    if (isNaN(parsedNominalInterest) || parsedNominalInterest < 0 || parsedNominalInterest > 100) {
      openDialog("Error", "Please input a valid nominal interest rate (0-100)!");
      return;
    }

    // Structure the form data according to backend expectations
    const submissionData = {
      balance: parseFloat(balance),
      seats: seats.split(","),
      account_status: accountStatus,
      company_name: companyName,
      credit_line: accountStatus !== "Free" ? parseFloat(creditLine) : 0,
      phphref,
      nominal_interest_rate: parsedNominalInterest,
    };

    if (info) {
      // Editing existing company
      editCompany({ companyId: info.id, params: submissionData });
    } else {
      // Creating a new company
      createCompany(submissionData);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {info ? "Edit Company" : "Create Company"}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Company Name"
          fullWidth
          margin="dense"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
        />
        <TextField
          label="Balance"
          type="number"
          fullWidth
          placeholder="12345678.12"
          sx={{ marginTop: "12px" }}
          value={balance}
          onChange={(e) => setBalance(e.target.value)}
          variant="outlined"
          required
        />
        <TextField
          label="Seats"
          sx={{ marginTop: "12px" }}
          fullWidth
          placeholder="1,2,3,4"
          value={seats}
          onChange={(e) => setSeats(e.target.value)}
          variant="outlined"
          required
        />
        <TextField
          select
          label="Account Status"
          fullWidth
          margin="dense"
          value={accountStatus}
          onChange={(e) => setAccountStatus(e.target.value)}
          required
        >
          {accountStatusOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        {accountStatus !== "Free" && (
          <TextField
            label="Credit Line"
            type="number"
            fullWidth
            margin="dense"
            value={creditLine}
            onChange={(e) => setCreditLine(e.target.value)}
            required
          />
        )}
        <TextField
          label="PHP href"
          fullWidth
          margin="dense"
          value={phphref}
          onChange={(e) => setPhphref(e.target.value)}
        />
        <TextField
          label="Nominal Interest Rate (%)"
          type="number"
          fullWidth
          margin="dense"
          value={nominalInterestRate}
          onChange={(e) => setNominalInterestRate(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary" disabled={isLoading||createLoading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={isLoading||createLoading}>
          {(isLoading||createLoading) ? <CircularProgress size={'24px'}></CircularProgress> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyDialog;
