import { Alert, Avatar, Box, Button, CircularProgress, DialogActions, Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import API from '../API'

export default function CreatorAuth({ serviceId, state, onDeny=()=>0 }) {
    const [customError, setCustomError] = useState(null);
    const [requestStarted, setRequestStarted] = useState(false)

    const { data: app, isLoading, error } = useQuery({
        queryKey: ['creator', 'services', serviceId],
        queryFn: () => API.developerPublic.serviceGet(serviceId),
        refetchOnWindowFocus: false,
        enabled: !!serviceId
    })

    const { data: sub, isLoading: subIsLoading, error: subError } = useQuery({
        queryKey: ['creator', 'subscriptions', serviceId],
        queryFn: () => API.developerPublic.subscriptionGet(serviceId),
        refetchOnWindowFocus: false,
        enabled: !!app,
        retry: 1
    })

    const RedirectToConsumer = (jwt, link) => {
        console.log('Redirecting to', link);
        if (!link) {
            setCustomError('The creator of this App has misconfigured settings. Contact them. Error code: error_missing_redirect_url');
            return;
        }
        // Query parameters
        const queryParams = {
            jwt: jwt,
        };

        if (state) {
            queryParams['state'] = state
        }

        // Construct the query string
        const queryString = new URLSearchParams(queryParams).toString();

        // Redirect to the target URL with query parameters
        window.location.href = `${link}?${queryString}`;
    }

    const { mutate: subscribe, error: subscribeError, isLoading: subscribeLoading } = useMutation({
        mutationFn: () => API.developerPublic.subscriptionPost(serviceId),
        onSuccess: (data) => {
            RedirectToConsumer(data.jwt, data.link);
        }
    })

    const { mutate: log, error: logError, isLoading: logLoading } = useMutation({
        mutationFn: () => API.developerPublic.subscriptionLog(serviceId),
        onSuccess: (data) => {
            setTimeout(() => {
                RedirectToConsumer(data.jwt, data.link);
            }, 5000);
        }
    })

    useEffect(() => {
        if (sub && !requestStarted) {
            setRequestStarted(true);
            log();
        }
    }, [sub])


    if (customError || error || subscribeError || logError || (subError && (!subError.response || subError.response.status != 404))) {
        console.log('Error', error);
        console.log('subError', subError);
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
                <Alert severity="error">
                    Error processing the sharing request: {
                        customError || error?.response?.data?.error || subscribeError?.response?.data?.error || subError?.response?.data?.error || logError?.response?.data?.error ||
                        error?.message || subscribeError?.message || logError?.message || subError?.message
                    }
                </Alert>
            </Box>
        )
    }

    if (isLoading || subIsLoading || !app) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent: 'center', width: '50vw', minHeight: '50vh', maxWidth: '552px' }}>
                <CircularProgress></CircularProgress>
            </Box>
        )
    }

    if (!sub) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', maxWidth: '552px' }}>
                {app.status != 'active' && <Alert severity="warning">
                    This third party app has not been approved by Blitz yet.
                </Alert>}
                <Avatar sx={{ width: 56, height: 56, marginBlockStart: 3, boxShadow: 9 }} variant='rounded' src={app.icon}>S</Avatar>
                <Typography variant='h4' sx={{ marginBlockStart: 5, marginBlockEnd: 2 }}>Allow <Typography variant='inherit' color='secondary' component='span'>{app.name}</Typography> to access your account?</Typography>
                <Typography variant='body'><Typography component='span' color='secondary' variant='inherit'>{app.name}</Typography> will be able to:<br /></Typography>
                <Box sx={{ alignSelf: 'flex-start' }}>
                    <Typography variant='body' sx={{ textAlign: 'left' }}>
                        <ul>
                            <li>Read your basic account information</li>
                            <li>Gain access to your registered email</li>
                        </ul>
                    </Typography>
                </Box>
                <Typography variant='body'>You can consult their privacy policy over at <Link target="_blank" rel="noopener noreferrer" href={app.privacy_policy_url}>this link.</Link></Typography>
                <Box sx={{ alignSelf: 'flex-end', display: 'flex', flexDirection: 'row-reverse', marginBlockStart: 2, gap: 2 }}>
                    <Button
                        variant='contained'
                        color='secondary'
                        disabled={subscribeLoading}
                        onClick={subscribe}>
                        {subscribeLoading ? <CircularProgress size={24}></CircularProgress> : 'Agree'}
                    </Button>
                    <Button
                        variant='text'
                        color='primary'
                        disabled={subscribeLoading}
                        onClick={onDeny}
                        >
                        Decline
                    </Button>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', maxWidth: '552px' }}>
                {app.status != 'active' && <Alert severity="warning">
                    This third party app has not been approved by Blitz yet.
                </Alert>}
                <Avatar sx={{ width: 56, height: 56, marginBlockStart: 3, boxShadow: 9 }} variant='rounded' src={app.icon}>S</Avatar>
                <Typography variant='h4' sx={{ marginBlockStart: 5, marginBlockEnd: 2 }}>Logging you into <Typography variant='inherit' color='secondary' component='span'>{app.name}</Typography></Typography>
                <Typography variant='body' sx={{ marginBlockEnd: 6 }}>You can revoke your permissions to <Typography variant='inherit' color='secondary' component='span'>{app.name}</Typography> on your <Link target="_blank" rel="noopener noreferrer" href={app.privacy_policy_url}>account settings</Link></Typography>
                <CircularProgress sx={{ marginBlockEnd: 5 }}></CircularProgress>
            </Box>
        )
    }
}
