import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Avatar, Backdrop, Badge, Box, CircularProgress, FormControlLabel, Grid, InputAdornment, Pagination, Paper, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { globalStyles } from '../../../Utils/Styles';
import CheckIcon from '@mui/icons-material/Check';
import { useQuery } from 'react-query';
import API from '../../../API';
import { Link } from 'react-router-dom';

function AppLogo({ appName, appIcon, appId, subbed }) {
    return (
        <Link to={`${appId}`} style={{textDecoration: 'none'}}>
            <Box sx={{
                width: { xs: 96, md: 144 },
                minWidth: { xs: 96, md: 144 },
                maxWidth: { xs: 96, md: 144 },
                height: { xs: 96, md: 144 },
                minHeight: { xs: 96, md: 144 },
                maxHeight: { xs: 96, md: 144 },
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'stretch',
                transition: 'transform 0.2s',
                ':hover': {
                    transform: 'scale(1.1)'
                },
                marginInline: 'auto'
            }}>
                <Box sx={{ marginInline: 'auto' }}>
                    <Badge
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={<CheckIcon sx={{ fontSize: 10, padding: 0 }}></CheckIcon>}
                        color='success'
                        invisible={!subbed}
                    >
                        <Avatar
                            sx={{
                                width: { xs: 56, md: 84 },
                                height: { xs: 56, md: 84 },
                                boxShadow: 9,
                                outline: theme => subbed ? `4px solid ${theme.palette.success.main}` : 'none',
                                outlineOffset: '-4px'
                            }} variant='rounded' src={appIcon}>{!!appName ? appName[0] : 'A'}</Avatar>
                    </Badge>
                </Box>
                <Typography
                    variant='subtitle1'
                    color='text.primary'
                    sx={{
                        textAlign: 'center',
                        marginBlockStart: { xs: 1, md: 1.5 },
                        fontSize: { xs: '16px', md: '24px' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                    }}>
                    {appName}
                </Typography>
            </Box >
        </Link>
    )
}



export default function Apps({ }) {
    const theme = useTheme();
    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [onlySubbed, setOnlySubbed] = useState(false);
    const isXs = useMediaQuery(theme.breakpoints.up('xs'));
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    let itemsPerPage = useMemo(() => {
        if (isMd)
            return 24;
        if (isSm)
            return 16;
        if (isXs)
            return 12
    }, [isXs, isSm, isMd])

    const { data: appQuery, isLoading } = useQuery({
        queryKey: ['apps', 'list', { page, filter, itemsPerPage }],
        queryFn: () => API.developerPublic.serviceGetAll({ page, rows: itemsPerPage, search: filter }),
        refetchOnWindowFocus: false,
    });

    // Debounce search input
    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setFilter(search);
            setPage(0);
        }, 1000);
        return () => clearTimeout(delayInputTimeoutId);
    }, [search]);

    return (
        <Box
            id="app-search-holder"
            sx={{
                ...globalStyles.wholeHeightMinusTop,
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                position: "relative",
            }}>
            <Backdrop open={isLoading}>
                <CircularProgress></CircularProgress>
            </Backdrop>
            <Paper elevation={4} square>
                <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
                    <Box
                        sx={{
                            overflowX: "auto",
                            paddingLeft: 2,
                            marginBlockEnd: 1,
                            width: "100%",
                            paddingInline: 1,
                            paddingBlock: 2,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: { xs: "nowrap", md: "wrap" },
                            minWidth: { xs: "max-content", md: 'auto' },
                            gap: 2,
                        }}
                    >
                        {true && <TextField
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                            label="Search"
                            sx={{ width: "20rem" }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <SearchRoundedIcon />
                                    </InputAdornment>
                                )
                            }}
                        />}
                        <FormControlLabel
                            checked={onlySubbed}
                            onChange={(event) => setOnlySubbed(event.target.checked)}
                            control={<Switch />}
                            label="Show subscribed only"
                        />
                    </Box>

                </Box>
            </Paper>
            <Box sx={{
                marginTop: 3,
                width: '100%',
                flex: 1
            }}>
                {appQuery && appQuery.apps.length > 0 && <Grid
                    container
                    columnSpacing={1}
                    rowSpacing={2}

                >
                    {appQuery.apps.map(app => (<Grid key={app.app.id} item xs={4} sm={3} md={2}>
                        <AppLogo appName={app.app.name} appIcon={app.app.icon} appId={app.app.id} subbed={!!app.subscription}></AppLogo>
                    </Grid>))}

                </Grid>}
                {
                    appQuery && appQuery.apps.length == 0 && <Typography variant='subtitle1'>No Apps found. Try changing your search terms. Come back periodically to find more Apps!</Typography>
                }
            </Box>
            <Paper
                elevation={2}
                sx={{ paddingBlock: 2, paddingInline: 1, marginBlockStart: "auto" }}
                square
            >
                <Pagination
                    sx={{ marginInline: "auto", maxWidth: "fit-content" }}
                    count={!!appQuery ? Math.ceil(appQuery.total / itemsPerPage) : 0}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)}
                />
            </Paper>
        </Box>

    )
}
