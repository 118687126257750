import React, { useState, useCallback, useEffect, useRef } from "react";
import client from "../../../../API";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Paper,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Container,
  Stepper,
  Step,
  StepLabel,
  Divider,
  TextField,
  Checkbox,
  Avatar,
  ListItemAvatar,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  LinearProgress,
  Grid,
  Card,
  CardContent,
  Collapse,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DownloadIcon from "@mui/icons-material/Download";
import GoogleIcon from "@mui/icons-material/Google";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Share } from "@mui/icons-material";
import profilePhoto from "../../../../Components/globalAssets/ppfLogo.png";
import TabPanel from "../../../../Components/TabPanel.js";
import CampaignProjectionsPanel from "../individualcampaigns/campaignTabs/campaignProjections.js";
import useIsDesktop from "../../../../Hooks/useIsDesktop.js";
import useAuth from "../../../../Hooks/use-auth.js";
import SelectAssetDialog from "../../../../Components/tools/selectfile";
import Tools from "./tools.js";
import TimelineTab from "./timeline";
import PageTitle from "../../../../Components/PageTitle";
import {
  CreatorCampaignStatus,
  parseLocalFloat,
  TrackLinks,
  wait,
} from "../../../../Utils/constants.js";
import { TimelineStatus } from "../../../../Utils/constants";
import { RateField } from "../../../../Components/RateField.js";
import BrandNegotiationDialog from "./brandnegotiatedialog.js";
import ShareCampaignDialog from "./share.js";

const RainbowButton = styled(Button)(({ theme }) => ({
  background:
    "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)",
  backgroundSize: "400% 400%",
  animation: "rainbow 5s ease infinite",
  color: "white",
  "@keyframes rainbow": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
}));

const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: "green",
  color: "white",
  "&:hover": {
    backgroundColor: "darkgreen",
  },
}));

const ActionCard = ({ title, value, color }) => (
  <Card sx={{ 
    minWidth: 200, 
    flex: 1, 
    border: `2px solid ${color}`,
    backgroundColor: 'transparent'
  }}>
    <CardContent>
      <Typography variant="subtitle2" gutterBottom color="text.primary">
        {title}
      </Typography>
      <Typography variant="h6" color="text.primary">{value}</Typography>
    </CardContent>
  </Card>
);

const ActionCards = ({ 
  campaignName, 
  brief, 
  totalSpend, 
  totalViews, 
  totalEngagement, 
  creatorCount,
  isEditable,
  isEditingBrief,
  editedBrief,
  setEditedBrief,
  handleSaveCampaignDetails,
  setEditingBrief
}) => (
  <Grid container spacing={2} sx={{ mb: 3 }}>
    <Grid item xs={12} md={6}>
      <Card sx={{ height: '100%', position: 'relative' }}>
        <CardContent>
          <Box display="flex" alignItems="center" gap={1} mb={2}>
            <Typography variant="subtitle2" color="text.primary">
              Campaign Brief
            </Typography>
            {isEditable && !isEditingBrief && (
              <IconButton 
                size="small" 
                onClick={() => setEditingBrief(true)}
                sx={{ ml: 'auto' }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
            {isEditingBrief && (
              <IconButton 
                size="small" 
                onClick={handleSaveCampaignDetails}
                sx={{ ml: 'auto' }}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
          
          {isEditingBrief ? (
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={editedBrief}
              onChange={(e) => setEditedBrief(e.target.value)}
              InputProps={{ sx: { fontSize: '0.875rem' } }}
            />
          ) : (
            <Typography variant="body2" whiteSpace="pre-wrap">
              {brief || "No brief available"}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} md={6}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ActionCard
            title="Total Spend"
            value={`$${totalSpend}`}
            color="#4CAF50"
          />
        </Grid>
        <Grid item xs={6}>
          <ActionCard
            title="Total Views"
            value={totalViews}
            color="#FFEB3B"
          />
        </Grid>
        <Grid item xs={6}>
          <ActionCard
            title="Total Engagement"
            value={totalEngagement}
            color="#2196F3"
          />
        </Grid>
        <Grid item xs={6}>
          <ActionCard
            title="Creators"
            value={creatorCount}
            color="#E91E63"
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const ActionsButton = ({
  isDraft,
  isLaunched,
  handleAddCreatorsClick,
  handleConfirmCompleteCampaign,
  handleDownloadCSV,
  handleExportToGoogleSheets,
  isTcc,
  isLoading,
  isEditable,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreActionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="flex-end" gap={2}>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <Button
            onClick={handleMoreActionsClick}
            startIcon={<MoreVertIcon />}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMoreActionsClose}
          >
            {isDraft && (
              <MenuItem onClick={handleAddCreatorsClick}>
                <ListItemIcon>
                  <AddCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add Creators</ListItemText>
              </MenuItem>
            )}
            {isLaunched && isTcc && (
              <MenuItem onClick={handleConfirmCompleteCampaign}>
                <ListItemIcon>
                  <CheckIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Complete Campaign</ListItemText>
              </MenuItem>
            )}
            <MenuItem onClick={handleDownloadCSV}>
              <ListItemIcon>
                <DownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download as CSV</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleExportToGoogleSheets}>
              <ListItemIcon>
                <GoogleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export to Google Sheets</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};

const EditCampaignPage = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isDesktop = useIsDesktop();
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const isTcc = currentUser?.company_name === "TCC";

  const [campaignDetails, setCampaignDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isEditingName, setEditingName] = useState(false);
  const [isEditingBrief, setEditingBrief] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedBrief, setEditedBrief] = useState("");
  const [creatorsToRemove, setCreatorsToRemove] = useState([]);
  const [showLaunchConfirmDialog, setShowLaunchConfirmDialog] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [expandedCreator, setExpandedCreator] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timelineEvents, setTimelineEvents] = useState(undefined);
  const changedEvents = useRef(false);

  // State for assets functionality
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [currentCreatorIndex, setCurrentCreatorIndex] = useState(null);
  const [negotiationDialogOpen, setNegotiationDialogOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [isResponding, setIsResponding] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isNegotiating, setIsNegotiating] = useState(false); // Add this line

  const { isFetching, data, refetch: refetchCampaign } = useQuery(
    ["campaign", campaignId],
    () => client.campaigns.details(campaignId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: files, refetch: refetchFiles } = useQuery("files", () =>
    client.files.fetch("")
  );

  const { data: projectionsData } = useQuery(
    ["campaignProjections", campaignId],
    () => client.campaigns.fetch(campaignId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const InvalidateCampaign = () => {
    queryClient.invalidateQueries(["campaign", campaignId]);
  };

  const handleRefreshStats = async () => {
    if (unsavedChanges) {
      alert("Please save all changes before tracking");
      return;
    }
    const foundLiveLinks = campaignDetails.creators.reduce(
      (found, currentCreator) => found || !!currentCreator.liveLink,
      false
    );
    if (!foundLiveLinks) {
      alert("None of the creators in this campaigns has any Live Links to track");
      return;
    }

    setIsTracking(true);
    const engagement = await TrackLinks(campaignDetails.creators);
    setProgress(100);
    const foundError = Object.values(engagement).reduce(
      (found, currentCreator) =>
        found || (currentCreator ? !!currentCreator.error : false),
      false
    );
    if (foundError) alert("Failed tracking one or more links. Please try again");
    const payload = {
      campaignId,
      creators: campaignDetails.creators.map((creator) => ({
        id: creator.id,
        comments: engagement[creator.id]?.comments || creator.comments,
        totalViews: engagement[creator.id]?.view_count || creator.totalViews,
        likes: engagement[creator.id]?.like_count || creator.likes,
        postDate: engagement[creator.id]?.upload_date || creator.postDate,
      })),
    };
    try {
      await updateCreatorSpecsAsync(payload);
    } catch (error) {
      alert(error);
    }
    setIsTracking(false);
    await wait(1000);
    setProgress(0);
  };

  useEffect(() => {
    if (data) {
      setCampaignDetails({
        ...data,
        creators: data.creators.map((creator) => ({
          ...creator,
          price:
            typeof creator?.price === "number"
              ? creator.price.toFixed(2)
              : "",
          agencyRate:
            typeof creator?.agencyRate === "number"
              ? creator.agencyRate.toFixed(2)
              : "",
        })),
      });
      setEditedName(data.name || "");
      setEditedBrief(data.brief || "");
      setUnsavedChanges(false);
      setTimelineEvents(
        data.timelineEvents.map((event) => ({
          ...event,
          projected_date: new Date(event.projected_date),
          will_delete: false,
        }))
      );
    }
  }, [data]);

  const AddTimelineEvent = (creator) => {
    changedEvents.current = true;
    setTimelineEvents((prev) => [
      ...prev,
      {
        campaign_id: campaignId,
        id: null,
        last_updated: null,
        manager_completed: false,
        objective: "",
        projected_date: new Date(Date.now()),
        status: TimelineStatus[0],
        notes: "",
        manager_notes: "",
        username: creator,
        will_delete: false,
      },
    ]);
  };

  const OnEditTimelineField = (targetIndex, target, value) => {
    changedEvents.current = true;
    setTimelineEvents(
      timelineEvents.map((event, index) => {
        if (index === targetIndex) {
          return { ...event, [target]: value };
        } else return event;
      })
    );
  };

  const { mutateAsync: updateTimeline, isLoading: isUpdatingTimeline } =
    useMutation(
      ({ id, input }) => client.campaigns.timeline_edit(id, input),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["campaign", campaignDetails.id]);
          alert("Timeline updated successfully.");
        },
        onError: (error) => {
          if (error.response && error.response.data && error.response.data.error)
            alert(`Error: ${error.response.data.error}`);
          else alert(`Error: ${error.message}`);
        },
      }
    );

  const estimatedTime = campaignDetails?.creators?.length * 4000 || 1000;
  const intervalDuration = 1000;

  useEffect(() => {
    if (!isTracking) return;

    const interval = setInterval(() => {
      setProgress((prev) => {
        const newValue =
          prev + 100 / (estimatedTime / intervalDuration);
        if (newValue >= 100) {
          clearInterval(interval);
          return 100;
        }
        return newValue;
      });
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [isTracking, estimatedTime]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleFieldChange = (creatorId, field, value) => {
    const updatedCreators = campaignDetails.creators.map((creator) =>
      creator.id === creatorId ? { ...creator, [field]: value } : creator
    );
    setCampaignDetails((prevState) => ({
      ...prevState,
      creators: updatedCreators,
    }));
    setUnsavedChanges(true);
  };

  const saveChanges = () => {
    if (changedEvents.current === true) {
      updateTimeline({
        id: campaignDetails.id,
        input: timelineEvents
          .filter((event) => event.will_delete === false)
          .map(({ will_delete, ...event }) => ({
            ...event,
            projected_date: event.projected_date.toUTCString(),
          })),
      });
      changedEvents.current = false;
    }
    const payload = {
      campaignId,
      creators: campaignDetails.creators.map((creator) => ({
        id: creator.id,
        status: creatorsToRemove.includes(creator.id)
          ? "Dropped"
          : creator.status,
        price: parseLocalFloat(creator.price),
        agencyRate: parseLocalFloat(creator.agencyRate),
        assetsSent: creator.assetsSent,
        assetsApproved: creator.assetsApproved,
        likes: creator.likes,
        comments: creator.comments,
        totalViews: creator.totalViews,
        postDate: creator.postDate,
        liveLink: creator.liveLink,
        skipPayout: creator.skipPayout,
        promotionPlatform: creator.promotionPlatform,
        promotionType: creator.promotionType,
        creatorBrief: creator.creatorBrief,
        postingInstructions: creator.postingInstructions,
        boostCode: creator.boostCode,
      })),
    };

    updateCampaignCreatorSpecs(payload);
    setUnsavedChanges(false);
  };

  const saveMetaData = () => {
    const payload = {
      id: campaignId,
      name: editedName,
      brief: editedBrief,
      campaign_sum: totalCampaignSum,
    };
    updateMetaData(payload);
    setUnsavedChanges(false);
  };

  const handleSaveCampaignDetails = () => {
    saveMetaData();
    setEditingName(false);
    setEditingBrief(false);
  };

  const totalCampaignSum = campaignDetails?.creators.reduce((acc, creator) => {
    const price = parseFloat(
      (creator.price || "0").toString().replace(/[^\d.]/g, "")
    );
    return acc + price;
  }, 0);

  const showSkipPayment = campaignDetails?.creators.some(
    (creator) => creator.skipPayout === true
  );

  const totalCreatorSum = campaignDetails?.creators.reduce((acc, creator) => {
    const cost = parseFloat(
      (creator.agencyRate || "0").toString().replace(/[^\d.]/g, "")
    );
    return acc + cost;
  }, 0);

  const {
    mutate: updateCampaignCreatorSpecs,
    mutateAsync: updateCreatorSpecsAsync,
    isLoading: isLoadingUpateSpecs,
  } = useMutation(
    (payload) => client.campaigns.updateCampaignCreatorSpecs(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        refetchCampaign();
        alert("Creator details updated successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    }
  );

  const { mutate: updateMetaData, isLoading: isLoadingUpdateMetadata } =
    useMutation((payload) => client.campaigns.updateMetaData(payload), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Campaign metadata updated successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    });

  const { mutate: launchCampaign, isLoading: isLoadingLaunch } = useMutation(
    (payload) => client.campaigns.launch(payload),
    {
      onSuccess: () => {
        alert("Campaign launched successfully!");
        queryClient.invalidateQueries(["campaign", campaignId]);
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    }
  );

  const handleLaunchCampaign = () => {
    setShowLaunchConfirmDialog(true);
  };

  const handleConfirmLaunch = async () => {
    setIsLaunching(true);
    setShowLaunchConfirmDialog(false);

    const creatorNames =
      campaignDetails?.creators?.map((creator) => creator.name) || [];
    const creatorPromotionTypes =
      campaignDetails?.creators?.map((creator) => ({
        name: creator.name,
        promotionType: creator.promotionType,
      })) || [];
    const creatorPrices =
      campaignDetails?.creators?.reduce((acc, creator) => {
        acc[creator.name] = parseLocalFloat(creator.price);
        return acc;
      }, {}) || {};

    const agencyRates =
      campaignDetails?.creators?.reduce((acc, creator) => {
        acc[creator.name] = parseLocalFloat(creator.agencyRate || "0");
        return acc;
      }, {}) || {};

    const payload = {
      campaignId: campaignDetails?.id,
      campaignName: campaignDetails?.name,
      campaignBrief: campaignDetails?.brief,
      creatorNames,
      creatorPromotionTypes,
      creatorPrices,
      agencyRates,
      blitzautocampaign: true,
      idealduedate: campaignDetails?.ideal_duedate,
      userEmail: campaignDetails?.user_email,
      sharedWithEmail: campaignDetails?.shared_with_email,
    };

    try {
      launchCampaign(payload);
    } finally {
      setIsLaunching(false);
    }
  };

  const handleCancelLaunch = () => {
    setShowLaunchConfirmDialog(false);
  };

  const { mutate: completeCampaign, isLoading: isLoadingComplete } =
    useMutation((input) => client.campaigns.complete(input), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Campaign completed successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    });

  const { mutate: completeCreator, isLoading: isLoadingCompleteCreator } =
    useMutation((input) => client.campaigns.completeCreator(campaignId, input), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Creator completed successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    });

  const handleConfirmCompleteCampaign = async () => {
    completeCampaign({ index: campaignId, params: {} });
  };

  const downloadCSV = () => {
    const csvContent = convertToCSV(campaignDetails);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `campaign_details_${campaignId}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToSheets = async () => {
    try {
      const response = await client.campaigns.exportSheet({
        campaignId,
        campaignDetails,
      });
      if (response.sheetLink) {
        window.open(response.sheetLink, "_blank");
      } else {
        console.error(
          "Error exporting to Google Sheets:",
          response.error
        );
      }
    } catch (error) {
      console.error(
        "Failed to export to Google Sheets:",
        error.response?.data?.error || error.message
      );
    }
  };

  const convertToCSV = (data) => {
    if (!data || !Array.isArray(data.creators)) return "";

    const headers = [
      "Name",
      "Following",
      "Status",
      "Price",
      "Offer Sent",
      "Assets Sent",
      "Assets Approved",
      "Promotion Type",
      "Likes",
      "Comments",
      "Total Views",
      "Post Date",
      "Platform Link",
      "Live Link",
    ];

    const rows = data.creators.map((creator) => [
      creator.name ?? "",
      creator.following ?? "",
      creator.status ?? "",
      creator.price ?? "",
      creator.offerSent ? "Yes" : "No",
      creator.assetsSent ? "Yes" : "No",
      creator.assetsApproved ? "Yes" : "No",
      creator.promotionType ?? "",
      creator.likes ?? 0,
      creator.comments ?? 0,
      creator.totalViews ?? 0,
      creator.postDate ?? "",
      creator.platformLink ?? "",
      creator.liveLink ?? "",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");
    return csvContent;
  };

  const handleExpandClick = (creatorId) => {
    setExpandedCreator(expandedCreator === creatorId ? null : creatorId);
  };

  const checkConversation = useMutation(client.conversations.check, {
    onSuccess: (data, variables) => {
      if (data.exists) {
        navigateToConversation(variables.creatorName);
      } else {
        createConversation.mutate(variables);
      }
    },
    onError: (error) => {
      console.error("Check response error:", error);
    },
  });

  const createConversation = useMutation(client.conversations.create, {
    onSuccess: (data, variables) => {
      navigateToConversation(variables.creatorName);
    },
    onError: (error) => {
      console.error("Create response error:", error);
    },
  });

  const startConversation = (creatorId, creatorName) => {
    const payload = {
      campaign_id: campaignId,
      creator_id: creatorId,
      creatorName: creatorName,
    };
    checkConversation.mutate(payload);
  };

  const navigateToConversation = (creatorName) => {
    navigate(`/campaigns/convo/${campaignId}/${creatorName}`);
  };

  const renderOptionalDetail = (
    label,
    value,
    isEditing,
    onEditClick,
    onChange
  ) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "flex-end",
        }}
      >
        {isEditing ? (
          <TextField
            value={value}
            onChange={(e) => onChange(e.target.value)}
            size="small"
            variant="outlined"
            fullWidth
          />
        ) : (
          <Typography variant="body1">{`${label}: ${value}`}</Typography>
        )}
        {isEditable && !isEditing && (
          <IconButton size="small" onClick={onEditClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        {isEditing && (
          <IconButton size="small" onClick={handleSaveCampaignDetails}>
            <CheckIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  };
  const { mutate: handleNegotiationResponse } = useMutation(
    (payload) => client.campaigns.handleNegotiationResponse(campaignId, payload),
    {
      onMutate: () => setIsNegotiating(true),  // Use setIsNegotiating here
      onSuccess: () => {
        refetchCampaign();
        setNegotiationDialogOpen(false);
        alert('Response submitted successfully');
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
      onSettled: () => setIsNegotiating(false)  // And here
    }
  );
  const renderSummary = (creator) => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: 2,
          padding: 2,
          flexWrap: "wrap",
        }}
      >
        {creator.price ? (
          <Typography variant="body2">
            <strong>Price:</strong> {creator.price}
          </Typography>
        ) : null}
        {creator.totalViews ? (
          <Typography variant="body2">
            <strong>Total Views:</strong> {creator.totalViews}
          </Typography>
        ) : null}
        {creator.comments ? (
          <Typography variant="body2">
            <strong>Comments:</strong> {creator.comments}
          </Typography>
        ) : null}
        {creator.shares ? (
          <Typography variant="body2">
            <strong>Shares:</strong> {creator.shares}
          </Typography>
        ) : null}
        {creator.likes ? (
          <Typography variant="body2">
            <strong>Likes:</strong> {creator.likes}
          </Typography>
        ) : null}
        {creator.postDate ? (
          <Typography variant="body2">
            <strong>Post Date:</strong> {creator.postDate}
          </Typography>
        ) : null}
      </Box>
    );
  };

  const handleDialogOpen = (index) => {
    setCurrentCreatorIndex(index);
    setDialogOpen(true);
  };

  const handleDialogClose = async (confirmed) => {
    if (confirmed && selectedAsset) {
      const updatedCreators = campaignDetails.creators.map((creator, idx) => {
        if (idx === currentCreatorIndex) {
          return {
            ...creator,
            creatorBrief: `${creator.creatorBrief}\n${selectedAsset.file_url}`,
          };
        }
        return creator;
      });

      const updatedDetails = { ...campaignDetails, creators: updatedCreators };
      setCampaignDetails(updatedDetails);

      const payload = {
        campaignId: updatedDetails.id,
        creators: updatedDetails.creators.map((creator) => ({
          id: creator.id,
          creatorBrief: creator.creatorBrief,
        })),
      };

      updateCampaignCreatorSpecs(payload);
    }
    setDialogOpen(false);
    setSelectedAsset(null);
  };

  const handleAssetClick = (asset) => {
    setSelectedAsset(asset);
  };

  if (!campaignDetails) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading campaign details...
      </Typography>
    );
  }

  const totalSpend = campaignDetails.creators.reduce(
    (acc, creator) => acc + parseFloat(creator.price || 0),
    0
  ).toFixed(2);

  const totalViews = campaignDetails.creators.reduce(
    (acc, creator) => acc + (creator.totalViews || 0),
    0
  );

  const totalEngagement = campaignDetails.creators.reduce(
    (acc, creator) => acc + (creator.likes || 0) + (creator.comments || 0),
    0
  );

  const creatorCount = campaignDetails.creators.length;

  const isDraft = campaignDetails?.campaign_status === "Draft";
  const isEditable = isTcc || (isDraft && isDesktop);

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={
          isFetching ||
          isLaunching ||
          isLoadingComplete ||
          isLoadingCompleteCreator ||
          isLoadingLaunch ||
          isLoadingUpateSpecs ||
          isLoadingUpdateMetadata
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isTracking}
      >
        <Box sx={{ width: "75%" }}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={progress}
          />
        </Box>
      </Backdrop>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
          centered
          sx={{ width: "100%" }}
        >
          <Tab label="Overview" />
          <Tab label="Automations" />
          <Tab label="Projections" />
          <Tab label="Tools" />

        </Tabs>
      </AppBar>

      <Container maxWidth="xl">
        <Box sx={{ margin: 2 }}>
          <Stepper
            activeStep={
              campaignDetails.campaign_status === "Completed"
                ? 2
                : campaignDetails.campaign_status === "Launched"
                ? 1
                : 0
            }
          >
            <Step><StepLabel>Draft</StepLabel></Step>
            <Step><StepLabel>Launched</StepLabel></Step>
            <Step><StepLabel>Completed</StepLabel></Step>
          </Stepper>
        </Box>
        <PageTitle title={`Edit Campaign - ${campaignDetails.name} - Blitz`} />
        <Box sx={{ 
  display: 'flex', 
  alignItems: 'center', 
  gap: 2, 
  mb: 3,
  px: 2
}}>
  <Typography variant="h4">
    {isEditingName ? (
      <TextField
        value={editedName}
        onChange={(e) => setEditedName(e.target.value)}
        variant="outlined"
        size="small"
      />
    ) : (
      campaignDetails.name
    )}
  </Typography>
  {isEditable && (
    <IconButton 
      onClick={() => isEditingName ? handleSaveCampaignDetails() : setEditingName(true)}
    >
      {isEditingName ? <CheckIcon /> : <EditIcon />}
    </IconButton>
  )}
</Box>
<ActionCards
  campaignName={campaignDetails.name}
  brief={editedBrief}
  totalSpend={totalSpend}
  totalViews={totalViews}
  totalEngagement={totalEngagement}
  creatorCount={creatorCount}
  isEditable={isEditable}
  isEditingBrief={isEditingBrief}
  editedBrief={editedBrief}
  setEditedBrief={setEditedBrief}
  handleSaveCampaignDetails={handleSaveCampaignDetails}
  setEditingBrief={setEditingBrief}
/>
<Box sx={{ 
  px: 2, 
  mb: 4,
  display: 'flex',
  gap: 2,
  alignItems: 'center',
  flexWrap: 'wrap',
  rowGap: 2
}}>
  {/* Campaign Status Buttons */}
  {isDraft && (
    <RainbowButton
      variant="contained"
      onClick={handleLaunchCampaign}
    >
      Launch Campaign
    </RainbowButton>
  )}
  
  {/* Action Buttons */}
  <ActionsButton
    isDraft={isDraft}
    isLaunched={campaignDetails.campaign_status === "Launched"}
    handleAddCreatorsClick={() => navigate(`/add-creators/${campaignId}`)}
    handleConfirmCompleteCampaign={handleConfirmCompleteCampaign}
    handleDownloadCSV={downloadCSV}
    handleExportToGoogleSheets={exportToSheets}
    isTcc={isTcc}
    isLoading={isLaunching}
    isEditable={isEditable}
  />

  {/* Save Button */}
  {isEditable && (
    <Button
      variant="contained"
      color="primary"
      onClick={saveChanges}
    >
      Save Changes
    </Button>
  )}

  {/* Share Buttons */}
  <Button
  variant="contained"
  color="info"
  onClick={() => setShareDialogOpen(true)}
  startIcon={<Share />}  // Changed from ShareIcon to Share
>
  Share Campaign
</Button>
  <Button
    variant="contained"
    color="secondary"
    onClick={() => navigate(`/campaigns/${campaignId}`)}
  >
   View Public Page
  </Button>
  
  {/* Deliverables Drive Button */}
  {campaignDetails.drive_link ? (
    <GreenButton
      variant="contained"
      href={campaignDetails.drive_link}
      target="_blank"
      rel="noopener noreferrer"
    >
      Open Deliverables Drive
    </GreenButton>
  ) : (
    <Typography variant="body2" color="text.secondary">
      Deliverables Drive: Not Launched
    </Typography>
  )}
{campaignDetails?.creators.some((creator) => creator.liveLink) &&
  (campaignDetails.campaign_status === "Launched" ||
    campaignDetails.campaign_status === "Completed") && (
    <Box sx={{ px: 2, mb: 2 }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleRefreshStats}
      >
        Refresh Campaign Stats
      </Button>
    </Box>
)}
  {/* Unsaved Changes Warning */}
  {unsavedChanges && (
    <Typography variant="subtitle2" color="error">
      You have unsaved changes
    </Typography>
  )}
</Box>

        <Divider sx={{ marginBlockStart: 2 }} />
        <Box
          sx={{
            paddingInline: { xs: 1, md: 4 },
            marginBlockStart: { xs: 1, md: 2 },
          }}
        >
          <TabPanel value={selectedTab} index={0}>
            <Typography variant="h6">Creators</Typography>
            <List sx={{ width: { xs: "100%", md: "auto" } }}>
              {campaignDetails.creators.map((creator, index) => (
                <Paper
                  elevation={1}
                  key={index}
                  sx={{
                    borderRadius: expandedCreator === creator.id ? 4 : 6,
                    marginBlockEnd: 1,
                    width: { xs: "100%", md: "auto" },
                    border: `2px solid ${
                      creator.status === 'Accepted' ? '#4CAF50' :
                      creator.status === 'Counter' ? '#FFEB3B' : 
                      'transparent'
                    }`
                  }}
                >
                  <ListItem sx={{ backgroundColor: 'transparent' }}>
                    <ListItemAvatar>
                      <Avatar src={creator.pfphref || profilePhoto} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box display="flex" alignItems="center" gap={2}>
                          <Typography variant="body1">{creator.name}</Typography>
                          <Chip 
                            label={creator.status} 
                            size="small"
                            sx={{
                              backgroundColor: 'rgba(255,255,255,0.8)',
                              border: '1px solid rgba(0,0,0,0.1)'
                            }}
                          />
                        </Box>
                      }
                      secondary={`Following: ${creator.following ?? 0}`}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {creator.status === 'Counter' && (
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          setSelectedCreator(creator);
                          setNegotiationDialogOpen(true);
                        }}
                        sx={{ ml: 1 }}
                      >
                        Negotiation Response
                      </Button>
                    )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => startConversation(creator.id, creator.name)}
                      >
                        Chat
                      </Button>
                      <IconButton onClick={() => handleExpandClick(creator.id)}>
                        {expandedCreator === creator.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Box>
                  </ListItem>

                  {expandedCreator === creator.id && (
                    <>
                      {creator.submissionLink && (
                        <Box sx={{ padding: 2 }}>
                          <Card
                            sx={{
                              cursor: "pointer",
                              border: "2px solid blue",
                            }}
                            onClick={() => {
                              if (creator.submissionLink) {
                                window.open(creator.submissionLink, "_blank");
                              }
                            }}
                          >
                            <CardContent>
                              <Typography variant="h6">
                                {creator.name}'s Submission
                              </Typography>
                              <Typography variant="body2">
                                Click to view submission
                              </Typography>
                            </CardContent>
                          </Card>
                        </Box>
                      )}

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: 2,
                          paddingInline: 2,
                          width: "100%",
                          marginInlineStart: 2,
                          borderLeft: "2px solid #ddd",
                          minHeight: "8em",
                          alignItems: "flex-start",
                        }}
                      >
                        {isEditable && (
                          <>
                            <RateField
                              label="Price"
                              value={creator.price ?? 0}
                              onChange={(value) =>
                                handleFieldChange(creator.id, "price", value)
                              }
                              sx={{ width: "10em" }}
                              variant="outlined"
                              size="small"
                            />
                            {isTcc && (
                              <RateField
                                label="Agency Rate"
                                value={creator.agencyRate ?? 0}
                                onChange={(value) =>
                                  handleFieldChange(
                                    creator.id,
                                    "agencyRate",
                                    value
                                  )
                                }
                                sx={{ width: "10em" }}
                                variant="outlined"
                                size="small"
                              />
                            )}
                            <TextField
                              label="Status"
                              select
                              value={creator.status || "Pitched"}
                              onChange={(event) =>
                                handleFieldChange(
                                  creator.id,
                                  "status",
                                  event.target.value
                                )
                              }
                              sx={{ width: "10em" }}
                              variant="outlined"
                              size="small"
                            >
                              {CreatorCampaignStatus.map((status) => (
                                <MenuItem value={status} key={status}>
                                  {status === "Dropped" && (
                                    <ListItemIcon>
                                      <DeleteIcon fontSize="small" />
                                    </ListItemIcon>
                                  )}
                                  {status === "Dropped" ? "Drop Creator" : status}
                                </MenuItem>
                              ))}
                            </TextField>

                            <FormControlLabel
                              label="Deliverables Sent"
                              checked={creator.assetsSent}
                              onChange={() =>
                                handleFieldChange(
                                  creator.id,
                                  "assetsSent",
                                  !creator.assetsSent
                                )
                              }
                              color="primary"
                              control={<Checkbox></Checkbox>}
                            />

                            {creator.assetsSent && (
                              <>
                                <FormControlLabel
                                  label="Deliverables Approved"
                                  checked={creator.assetsApproved}
                                  onChange={() =>
                                    handleFieldChange(
                                      creator.id,
                                      "assetsApproved",
                                      !creator.assetsApproved
                                    )
                                  }
                                  color="primary"
                                  control={<Checkbox></Checkbox>}
                                />

                                {creator.assetsApproved && (
                                  <>
                                    <TextField
                                      label="Posting Instructions"
                                      value={
                                        creator.postingInstructions ?? ""
                                      }
                                      onChange={(e) =>
                                        handleFieldChange(
                                          creator.id,
                                          "postingInstructions",
                                          e.target.value
                                        )
                                      }
                                      sx={{ width: "10em" }}
                                      variant="outlined"
                                      size="small"
                                      multiline
                                    />
                                  </>
                                )}
                              </>
                            )}

                            {creator.assetsApproved && (
                              <TextField
                                label="Live Link"
                                value={creator.liveLink ?? ""}
                                onChange={(e) =>
                                  handleFieldChange(
                                    creator.id,
                                    "liveLink",
                                    e.target.value
                                  )
                                }
                                sx={{ width: "10em" }}
                                variant="outlined"
                                size="small"
                              />
                            )}

                            {creator.assetsApproved && creator.liveLink && (
                              <TextField
                                label="Boost Code"
                                value={creator.boostCode ?? ""}
                                onChange={(e) =>
                                  handleFieldChange(
                                    creator.id,
                                    "boostCode",
                                    e.target.value
                                  )
                                }
                                sx={{ width: "10em" }}
                                variant="outlined"
                                size="small"
                              />
                            )}

                            {isTcc && showSkipPayment && creator.liveLink && (
                              <FormControlLabel
                                label="Skip Payment"
                                checked={creator.skipPayout}
                                disabled={creator.payoutComplete}
                                onChange={() =>
                                  handleFieldChange(
                                    creator.id,
                                    "skipPayout",
                                    !creator.skipPayout
                                  )
                                }
                                color="primary"
                                control={<Checkbox></Checkbox>}
                              />
                            )}

                            {isTcc && creator.liveLink && (
                              <Button
                                color={"secondary"}
                                sx={{
                                  ...(creator.payoutComplete && {
                                    "&.Mui-disabled": {
                                      background: "success.dark",
                                      borderColor: "success.dark",
                                      color: "success.dark",
                                    },
                                  }),
                                }}
                                disabled={
                                  unsavedChanges ||
                                  creator.skipPayout ||
                                  creator.payoutComplete ||
                                  isLoadingCompleteCreator ||
                                  !creator.liveLink
                                }
                                onClick={() =>
                                  completeCreator({ creator: creator.id })
                                }
                                variant={
                                  creator.payoutComplete ? "outlined" : "contained"
                                }
                              >
                                {creator.payoutComplete
                                  ? "Completed"
                                  : "Complete Creator"}
                              </Button>
                            )}

                            <Box sx={{ width: "100%" }}>
                              <Button
                                variant="text"
                                onClick={() =>
                                  handleFieldChange(
                                    creator.id,
                                    "showDetails",
                                    !creator.showDetails
                                  )
                                }
                              >
                                {creator.showDetails
                                  ? "Hide Details"
                                  : "Show Details"}
                              </Button>
                              <Collapse in={creator.showDetails}>
                                <TextField
                                  label="Creator Brief"
                                  value={creator.creatorBrief ?? ""}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      creator.id,
                                      "creatorBrief",
                                      e.target.value
                                    )
                                  }
                                  sx={{ width: "100%", mt: 1 }}
                                  variant="outlined"
                                  size="small"
                                  multiline
                                />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleDialogOpen(index)}
                                    sx={{ mt: 1 }}
                                  >
                                    Add File
                                  </Button>
                                <TextField
                                  label="Promotion Platform"
                                  value={creator.promotionPlatform ?? ""}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      creator.id,
                                      "promotionPlatform",
                                      e.target.value
                                    )
                                  }
                                  sx={{ width: "100%", mt: 1 }}
                                  variant="outlined"
                                  size="small"
                                />
                                <TextField
                                  label="Promotion Type"
                                  value={creator.promotionType ?? ""}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      creator.id,
                                      "promotionType",
                                      e.target.value
                                    )
                                  }
                                  sx={{ width: "100%", mt: 1 }}
                                  variant="outlined"
                                  size="small"
                                />
                              </Collapse>
                            </Box>
                          </>
                        )}
                      </Box>
                    </>
                  )}
                </Paper>
              ))}
            </List>
          </TabPanel>

          
          <TabPanel value={selectedTab} index={1}>
            <TimelineTab
              campaignDetails={campaignDetails}
              timelineEvents={timelineEvents}
              onEditField={OnEditTimelineField}
              onCreateRow={AddTimelineEvent}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            {projectionsData ? (
              <CampaignProjectionsPanel campaignDetails={projectionsData} />
            ) : (
              <Typography>Loading projections...</Typography>
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Tools campaignDetails={campaignDetails} onUpdate={InvalidateCampaign} />
          </TabPanel>
        </Box>
      </Container>

      <Dialog open={showLaunchConfirmDialog} onClose={handleCancelLaunch}>
        <DialogTitle>Confirm Launch</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to launch this campaign? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLaunch} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLaunch} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <SelectAssetDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        files={files}
        selectedAsset={selectedAsset}
        onAssetClick={handleAssetClick}
        refetchFiles={refetchFiles}
      />
      <BrandNegotiationDialog
  open={negotiationDialogOpen}
  handleClose={() => setNegotiationDialogOpen(false)}
  creator={selectedCreator}
  currentOffer={selectedCreator?.originalCounterOffer || 0}
  onRespond={handleNegotiationResponse}
  isSubmitting={isNegotiating}
/>
<ShareCampaignDialog
  open={shareDialogOpen}
  onClose={() => {
    setShareDialogOpen(false);
    setEmail('');
  }}
  campaignDetails={campaignDetails}
  email={email}
  setEmail={setEmail}
/>
    </>
  );
};

export default EditCampaignPage;