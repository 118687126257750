import React, { useState, useEffect } from "react";
import {
    Paper,
    Backdrop,
    CircularProgress,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    Box,
    Typography,
    TableBody,
    TableRow,
    Chip,
    Snackbar,
    Alert,
    TableFooter,
    TablePagination
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMutation, useQuery } from "react-query";
import API from "../../../../API";
import useIsDesktop from "../../../../Hooks/useIsDesktop";
import { StyledTableRow } from "../../../../Utils/styledcell";
import BrandEmailDialog from "./brandEmailDialog";
import AdminServiceDialog from "./adminServiceDialog";
import useAlertDialog from "../../../../Components/useAlertDialog";
import AlertDialog from "../../../../Components/AlertDialog";

const styles = {
    singleLine: { wordBreak: 'keep-all', whiteSpace: 'nowrap' },
};

const HideMobileCell = styled(TableCell)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'table-cell',
    },
}));

const rowsPerPage = 15;

export default function AdminServices({ }) {
    const [toastOpen, setToastOpen] = useState(false);
    const [page, setPage] = useState(0);
    const isDesktop = useIsDesktop();
    const [currentService, setCurrentService] = useState(null);
    const [openService, setOpenService] = useState(false);
    const { dialogState, openDialog, closeDialog } = useAlertDialog();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const { data: services, error, isFetching, refetch: refetchServices } = useQuery({
        queryKey: ['services'],
        queryFn: () => API.developer.serviceList({ all: true }),
        refetchInterval: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
    });

    const { mutate: serviceMutate, isLoading: serviceMutateLoading } = useMutation({
        mutationFn: ({ id, payload }) => API.developer.serviceUpdate(id, payload),
        onSuccess: () => {
            openDialog('Success', 'Service updated succesfully', closeDialog, closeDialog, 'Ok', null);
            refetchServices();
        },
        onError: (error) => {
            openDialog('Error', <>Error while updating service: <br /> {error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, 'Ok', null);
        }
    });

    const HandleEditService = (id) => {
        const found = services.find(service => service.id == id);
        if (!found)
            return;

        setCurrentService(found);
        setOpenService(true);
    }

    const HandleCloseService = () => {
        setCurrentService(null);
        setOpenService(false);
    }

    const HandleSaveService = (payload) => {
        if (!!currentService) {
            serviceMutate({
                id: currentService.id,
                payload: payload
            });
        }
        setOpenService(false);
        setCurrentService(null);
    }

    useEffect(() => {
        if (!!error) {
            console.error('An error occurred:', error);
            setToastOpen(true);
        }
    }, [error]);

    const onCloseDialog = () => {
        setSelectedEmail(null);
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={(isFetching) && !error}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert severity='error' variant='filled' sx={{ width: '100%' }}>
                    {error?.response?.data?.error || error?.message || ''}
                </Alert>
            </Snackbar>
            <AlertDialog alertState={dialogState}></AlertDialog>
            <Box sx={{ marginBlockStart: 1, maxWidth: '100em', marginInline: "auto" }}>
                <TableContainer component={Paper} elevation={1}>
                    <Table>
                        <TableHead>
                            <StyledTableRow>
                                <HideMobileCell align='left'>
                                    ID
                                </HideMobileCell>
                                <TableCell>
                                    Name
                                </TableCell>
                                <TableCell >
                                    Status
                                </TableCell>
                                <TableCell>
                                    Approval
                                </TableCell>
                                <HideMobileCell align='right'>
                                    Last Updated
                                </HideMobileCell>
                                <TableCell align='left'>
                                    Approval Date
                                </TableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {services && services.map((row, index) => (
                                <StyledTableRow key={index} onClick={() => HandleEditService(row.id)} hover>
                                    <TableCell align='left'>
                                        {row.id}
                                    </TableCell>
                                    <TableCell align='left'>
                                        <span style={styles.singleLine}>{row.user.username}</span>
                                    </TableCell>
                                    <TableCell align='left' sx={{ textTransform: 'capitalize' }}>
                                        <span style={styles.singleLine}>{row.status}</span>
                                    </TableCell>
                                    <TableCell align='left' sx={{ textTransform: 'capitalize' }}>
                                        <span style={styles.singleLine}>{row.approval_status}</span>
                                    </TableCell>
                                    <HideMobileCell align='right'>
                                        <span style={styles.singleLine}>{row.date_updated?.toLocaleString()}</span>
                                    </HideMobileCell>
                                    <TableCell align='left'>
                                        <span style={styles.singleLine}>{row.date_approved?.toLocaleString() || 'N/A'}</span>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={isDesktop ? 6 : 4}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[rowsPerPage]}
                                    count={0 || 0}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
            <AdminServiceDialog
                isOpen={openService}
                service={currentService}
                onCloseAction={HandleCloseService}
                onFormAction={HandleSaveService}
            >
            </AdminServiceDialog>
        </Box>
    );
};