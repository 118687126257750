import { Box, Container, Divider, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import Codebox from '../../Components/Codebox'
import { Link as RouterLink } from 'react-router-dom'
import routes from '../../Config/routes'
import tutorial_1 from '../../Components/globalAssets/docs_oauth_1.png'
import tutorial_2 from '../../Components/globalAssets/docs_oauth_2.png'
import { StyledTableRow } from '../../Utils/styledcell'

export default function DocsOAuth(props) {


    return (
        <Container sx={{ paddingBlock: 2 }}>
            <Typography variant='h3'>Login with Blitz</Typography>
            <Divider></Divider>
            <br />
            <Typography variant='h6'>What is Login with Blitz?</Typography>
            Login with Blitz its a feature loosely based on the standard OAuth authentication scheme, for users of the Blitz platform to use their existing data to log in
            into your applications. You will send your intended audience to our website, and our system will send them to a html route you specify on your Service settings,
            with a JWT token with their shared data.
            <br />
            <br />
            <Typography variant='h6'>Usage</Typography>
            <Divider></Divider>
            To use Login with Blitz, you'll redirect your user to the following URL, with the following query parameters.
            <Codebox>
                <Typography variant='codeblock'>
                    https://www.useblitz.co/creatorconnect/oauth?
                    <Typography variant='inherit' component='span' color='secondary'>service_id=SV-20241218154517-4512</Typography>
                    &<Typography variant='inherit' component='span' color='secondary'>state=45541121231113435</Typography>
                </Typography>
            </Codebox>
            <TableContainer component={Paper} elevation={1} sx={{ marginBlock: 2, maxWidth: '30rem' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                Query Parameters
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Parameter
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell>service_id</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The ID of your service that you can see on the edit page.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>state <Typography variant='inherit' component='span' color='primary'>(optional)</Typography></TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>A variable we send unchanged after the user agrees to Login with Blitz. Useful for identifying requests.</TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <img src={tutorial_1} style={{ width: '75%' }}></img>
            <br />
            Declining will bring users to their Blitz' CreatorConnect dashboard. Authorizing your access will sent them to the link you specified in the edit service form as your
            <Typography variant='inherit' component='span' color='secondary'><b> On Creator Authorize URL </b></Typography>.<br /><br />
            When users get redirected to your page, the generated JWT token and your state variable if specified will be sent as query parameters. <br/>
            Here is an example website running in localhost demostrating the retrieval of the data.
            <br />
            <img src={tutorial_2} style={{ width: '75%' }}></img>
            <br />
            <br /><br />
            <Typography variant='h6'>JWT Decoding</Typography>
            <Divider></Divider>
            The JWT token generated by our system follows the JWT standard, signed with asymmetric keys under the ES256 algorithm. Many
            libraries exist for JWT decoding, but they are basically, three period separated segments, encoded in Base64, containing a header, a payload, and a signature.<br/>
            The contents of the payload of our tokens are as follows:
            <TableContainer component={Paper} elevation={1} sx={{ marginBlock: 2, maxWidth: '30rem' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                Token Payload Claims
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Parameter
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell>iss</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The issuer of the token. Always 'blitz'.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>sub</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The id of the creator subscribed to your service, which you may need for several operations.
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>aud</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The intended audience of the JWT, which is always your service_id. Some JWT decryption libraries require you to supply
                            the correct one to correctly decrypt and verify it.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>exp</TableCell>
                            <TableCell>Float</TableCell>
                            <TableCell>The expiration time of the token as a Unix timestamp (Seconds since Epoch). One hour after issuing.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>iat</TableCell>
                            <TableCell>Float</TableCell>
                            <TableCell>The issuing time of the token as a Unix timestamp (Seconds since Epoch).</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>preferred_username</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The username this creator is registered with on our platform.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>email</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The email of the creator.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>gender</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The gender this creator identifies as.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>profile</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The link to this creator's Media Kit.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>picture</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The link to this creator's profile picture.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>location</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The region this creator's is located at.</TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            To verify the token, you'll need to use your public key that you can find at the service configuration form.<br/>
            Heres an example for loading and using the key for verification in Python, but you should be able to use your framework and programming language of choice.<br/><br/>
            <Codebox>
                <Typography variant='codeblock'>
                from cryptography.hazmat.primitives import serialization<br/>
                import jwt<br/>
                <br/>
                public_key = serialization.load_pem_public_key(public_key.encode())<br/>
                payload = jwt.decode(token, public_key, algorithms=['ES256'], audience='SV-20241218154517-4512')<br/>
                username = payload['preferred_username']=='TestCreator1'<br/>
                </Typography>
            </Codebox>
        </Container>
    )
}
