import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useIsDesktop from '../../../Hooks/useIsDesktop'
import { dateToDatetimeLocal, parseLocalFloat } from '../../../Utils/constants';
import { RateField } from '../../../Components/RateField';

export default function ServiceDialog({ service = null, onFormAction = (service) => 0, isOpen = true, onCloseAction = () => 0 }) {
    const isDesktop = useIsDesktop();
    const [serviceName, setServiceName] = useState('');
    const [homepageUrl, setHomepageUrl] = useState('');
    const [iconUrl, setIconUrl] = useState('');
    const [privacyUrl, setPrivacyUrl] = useState('');
    const [description, setDescription] = useState('');
    const [subscriptionCost, setSubscriptionCost] = useState('');
    const [authUrl, setAuthUrl] = useState('')

    useEffect(() => {
        if (isOpen) {
            if (!!service) {
                setServiceName(service.name);
                setHomepageUrl(service.homepage_url || '');
                setIconUrl(service.icon || '');
                setPrivacyUrl(service.privacy_policy_url || '');
                setAuthUrl(service.on_creator_signon_url || '');
                setDescription(service.description || '');
                setSubscriptionCost(service?.subscription_cost?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) || '');
            }
            else {
                setServiceName('');
                setHomepageUrl('');
                setIconUrl('');
                setPrivacyUrl('');
                setDescription('');
                setSubscriptionCost('');
                setAuthUrl('');
            }
        }
    }, [isOpen])

    const HandleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            name: serviceName || null,
            homepage_url: homepageUrl || null,
            icon: iconUrl || null,
            privacy_policy_url: privacyUrl || null,
            description: description || null,
            subscription_cost: parseLocalFloat(subscriptionCost||null) || null,
            on_creator_signon_url: authUrl || null
        }

        onFormAction(payload)
    }


    return (
        <Dialog open={isOpen} fullScreen={!isDesktop} maxWidth='false' onClose={onCloseAction}>
            <form onSubmit={HandleSubmit}>
                <DialogTitle>{!service ? 'New Service' : `Editing: ${service.name}`}</DialogTitle>
                <DialogContent dividers>

                    <Grid container width={'60rem'} spacing={2} rowSpacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label='ID' size='small' disabled name='readonly-id' value={service?.id || ''}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box height='24px'></Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                required
                                label='Name'
                                name='service-name'
                                value={serviceName}
                                disabled={!!service}
                                onChange={(e) => setServiceName(e.target.value)}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{
                                    borderRadius: '12.5% 0% 0% 12.5%',
                                    backgroundColor: '#ddd',
                                    border: `2px dashed #888`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    minHeight: '56px',
                                    height: '56px',
                                    minWidth: '56px',
                                    width: '56px',
                                    backgroundImage: `url("${iconUrl}")`
                                }}></Box>
                                <TextField
                                    fullWidth
                                    label='Icon URL'
                                    name='service-icon-url'
                                    type='url'
                                    value={iconUrl}
                                    onChange={(e) => setIconUrl(e.target.value)}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                        }
                                    }}
                                >
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label='Homepage URL'
                                name='service-homepage-url'
                                type='url'
                                value={homepageUrl}
                                onChange={(e) => setHomepageUrl(e.target.value)}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label='Privacy Policy URL'
                                name='service-privacy-url'
                                type='url'
                                value={privacyUrl}
                                onChange={(e) => setPrivacyUrl(e.target.value)}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Description'
                                name='service-description'
                                type='text'
                                multiline
                                minRows={4}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RateField
                                fullWidth
                                minValue={0}
                                label='Subscription cost (Monthly)'
                                name='service-subscription-cost'
                                value={subscriptionCost}
                                onChange={(value) => setSubscriptionCost(value)}
                            >
                            </RateField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box height='24px'></Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label='Created on'
                                name='read-only-creation-date'
                                type="datetime-local"
                                value={dateToDatetimeLocal(service?.date_created)}
                                disabled
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label='Last modified on'
                                name='read-only-update-date'
                                type="datetime-local"
                                value={dateToDatetimeLocal(service?.date_updated)}
                                disabled
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Public Decryption Key'
                                name='service-key'
                                type='text'
                                multiline
                                minRows={2}
                                value={service?.public_key}
                                disabled
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label='On Creator Authorize URL'
                                name='service-accept-url'
                                type='url'
                                value={authUrl}
                                onChange={(e) => setAuthUrl(e.target.value)}
                            >
                            </TextField>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='secondary' type='submit'>{!service ? 'Create' : 'Save'}</Button>
                    <Button variant='text' color='error' onClick={onCloseAction}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
