import React, { useState } from 'react';
import { Box, Toolbar, IconButton, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useIsDesktop from '../../../Hooks/useIsDesktop';
import HomeHeader from '../../../Components/HomeHeader';
import { drawerWidth } from '../../../Utils/constants';

const ReleaseScaffolding = ({ children, containerStyle = {}, sections = [] }) => {
  const isDesktop = useIsDesktop();
  const [isOpen, setIsOpen] = useState(false);

  const handleSectionClick = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    setIsOpen(false); // Close the drawer on section click
  };

  return (
    <>
      {/* Header with Menu Button */}
      <HomeHeader
        menuButton={
          !isDesktop && (
            <IconButton edge="start" onClick={() => setIsOpen(true)} sx={{ color: '#000' }}>
              <MenuIcon />
            </IconButton>
          )
        }
      />
      <Toolbar />

      {/* Sidebar Navigation */}
      <Drawer
        variant={isDesktop ? 'permanent' : 'temporary'}
        open={isDesktop || isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
                        position: 'relative',
                        width: drawerWidth,
                        flexShrink: 0,
                        zIndex: (theme)=>isDesktop ? theme.zIndex.appBar-1 : theme.zIndex.drawer,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}>
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {sections.map((section) => (
              <ListItem key={section.id} disablePadding>
                <ListItemButton onClick={() => handleSectionClick(section.id)}>
                  <ListItemText primary={section.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Main Content Area */}
      <Box
        id="TopPageContainer"
        sx={{
          ...containerStyle,
          minWidth: {
            xs: '100%',
            md: `calc(100% - ${drawerWidth}px)`,
          },
          marginInlineStart: {
            xs: 0,
            md: drawerWidth,
          },
          flexGrow: 1,
          padding: 2,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default ReleaseScaffolding;
