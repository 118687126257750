import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material'
import React, { useState } from 'react'

export default function ApikeysDialog({isOpen, onClose, onCreate}) {
    const [keyType, setKeyType] = useState('sandbox');

    const HandleCreateKey = () => {
        onCreate({type: keyType})
    }

    return (
        <Dialog open={isOpen}>
            <DialogTitle>Create Key</DialogTitle>
            <DialogContent sx={{width:'15rem'}}>
                <TextField
                    margin='dense'
                    fullWidth
                    select
                    label='Key Type'
                    value={keyType}
                    onChange={(e)=>setKeyType(e.target.value)}
                    >
                    <MenuItem value='sandbox'>Sandbox</MenuItem>
                    <MenuItem value='production'>Production</MenuItem>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='secondary' onClick={HandleCreateKey}>Create</Button>
                <Button variant='text' color='primary' onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
