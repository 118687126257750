import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
  Alert,
  Box,
  Divider,
  CircularProgress
} from "@mui/material";
import { useMutation } from "react-query";
import { styled } from "@mui/system";
import client from "../../../../../API";
import { getPONumber } from "../../../../../Utils/constants";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StyledDialogTitle = styled(DialogTitle)`
  background-color: #424242;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
  padding: 20px;
`;

const StyledDialogActions = styled(DialogActions)`
  background-color: #424242;
  padding: 20px;
`;

const OfferDialog = ({ open, onClose, offer }) => {
  const [daysSinceSent, setDaysSinceSent] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (offer && offer.created_at) {
      const sentDate = new Date(offer.created_at);
      const currentDate = new Date();
      const differenceInDays = (currentDate - sentDate) / (1000 * 3600 * 24);
      setDaysSinceSent(Math.round(differenceInDays));
    }
  }, [offer]);

  const completeOfferMutation = useMutation(
    () => client.deliverables.update(offer.id, { status: "Completed" }),
    {
      onMutate: () => setLoading(true),
      onSuccess: () => {
        setShowSuccess(true);
        setLoading(false);
        onClose();
      },
      onError: (error) => {
        console.error("Error completing offer:", error);
        setShowError(true);
        setErrorMessage(`Error completing offer: ${error?.response?.data?.error || error.message}`);
        setLoading(false);
      }
    }
  );

  const handleCompleteOffer = () => {
    completeOfferMutation.mutate();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <StyledDialogTitle>
        <Typography variant="h6">Offer Details</Typography>
        {offer.status === "Completed" && (
          <Box display="flex" alignItems="center" color="green">
            <CheckCircleOutlineIcon />
            <Typography variant="body1" ml={1}>Completed</Typography>
          </Box>
        )}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <InfoOutlinedIcon />
            <Typography variant="h6">Offer Summary</Typography>
          </Box>
          <Divider />
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">Description:</Typography>
            <Typography variant="body1">{offer.notes}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">Deliverable Type:</Typography>
            <Typography variant="body1">{offer.deliverable_type}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">Amount:</Typography>
            <Typography variant="body1" color="primary">${offer.amount}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">Due Date:</Typography>
            <Typography variant="body1">{offer.due_date}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">Platforms:</Typography>
            <Typography variant="body1">{offer.platforms?.join(', ')}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">Days Since Sent:</Typography>
            <Typography variant="body1">{daysSinceSent}</Typography>
          </Box>
        </Box>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose} color="secondary">Close</Button>
        {offer.status !== "Completed" && (
          <Button
            onClick={handleCompleteOffer}
            color="primary"
            variant="contained"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={24} /> : null}
          >
            {loading ? "Processing..." : "Mark as Completed"}
          </Button>
        )}
      </StyledDialogActions>
      <Snackbar open={showSuccess} autoHideDuration={6000} onClose={() => setShowSuccess(false)}>
        <Alert onClose={() => setShowSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Offer completed and payout issued successfully.
        </Alert>
      </Snackbar>
      <Snackbar open={showError} autoHideDuration={6000} onClose={() => setShowError(false)}>
        <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default OfferDialog;
