// src/components/Agency/AgencyCreator.jsx

import {
Backdrop,
CircularProgress,
Paper,
Tab,
Tabs,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import useIsDesktop from '../../Hooks/useIsDesktop';
import { useParams } from 'react-router-dom';
import AgencyCreatorEdit from './agencyCreatorTabs/agencyCreatorEdit';
import { useQuery } from 'react-query';
import API from '../../API';
import AlertDialog from '../../Components/AlertDialog';
import useAlertDialog from '../../Components/useAlertDialog';
import TabPanel from '../../Components/TabPanel';
import AgencyCreatorCampaigns from './agencyCreatorTabs/agencyCreatorCampaigns';
import AgencyCreatorPartnerships from './agencyCreatorTabs/agencyCreatorPartnerships';
import AgencyCreatorConversations from './agencyCreatorTabs/agencyCreatorConversations';
import AgencyCreatorPayouts from './agencyCreatorTabs/agencyPayoutCreator'; // Ensure correct path

const tabData = [
{ name: 'Edit', route: 'edit' },
{ name: 'Campaigns', route: 'campaign' },
{ name: 'Partnerships', route: 'partnership' },
{ name: 'Conversations', route: 'conversation' },
{ name: 'Payouts', route: 'payouts' }, // New Payouts Tab
];

export default function AgencyCreator({ props }) {
const [currentTab, setCurrentTab] = useState('edit');
const isDesktop = useIsDesktop();
const { creatorId } = useParams();
const { dialogState, openDialog, closeDialog } = useAlertDialog();

const {
data: creatorData,
isLoading,
refetch,
error,
} = useQuery(
['creators', creatorId],
() => API.agency.getCreator(creatorId),
{
refetchOnWindowFocus: false,
enabled: !!creatorId,
}
);

useEffect(() => {
if (error) {
openDialog(
    'Error',
    `Error fetching creator: ${
    error?.response?.data?.error || error.message
    }\n`,
    closeDialog,
    closeDialog,
    'Ok',
    null
);
console.error('Network error:', error);
}
}, [error, openDialog, closeDialog]);

return (
<>
{/* Loading Backdrop */}
<Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
    open={isLoading}
>
    <CircularProgress color="inherit" />
</Backdrop>

{/* Alert Dialog for Errors */}
<AlertDialog alertState={dialogState}></AlertDialog>

{/* Tabs Header */}
<Paper
    sx={{ paddingInline: { xs: 0, md: 2 }, position: 'sticky' }}
    elevation={2}
    square
>
    <Tabs
    value={currentTab}
    centered={isDesktop}
    onChange={(event, value) => setCurrentTab(value)}
    >
    {tabData.map((entry) => (
        <Tab
        key={entry.route}
        label={entry.name}
        value={entry.route}
        ></Tab>
    ))}
    </Tabs>
</Paper>

{/* Tab Panels */}
<TabPanel index="edit" value={currentTab}>
    <AgencyCreatorEdit creatorData={creatorData}></AgencyCreatorEdit>
</TabPanel>
<TabPanel index="campaign" value={currentTab}>
    <AgencyCreatorCampaigns creatorData={creatorData}></AgencyCreatorCampaigns>
</TabPanel>
<TabPanel index="partnership" value={currentTab}>
    <AgencyCreatorPartnerships creatorData={creatorData}></AgencyCreatorPartnerships>
</TabPanel>
<TabPanel index="conversation" value={currentTab}>
    <AgencyCreatorConversations creatorData={creatorData}></AgencyCreatorConversations>
</TabPanel>
<TabPanel index="payouts" value={currentTab}>
    <AgencyCreatorPayouts creatorData={creatorData}></AgencyCreatorPayouts> {/* New Payouts Panel */}
</TabPanel>
</>
);
}
