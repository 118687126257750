import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TableBody,
  TableContainer,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tabs,
  Tab,
  TextField,
  Paper,
  Link,
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Badge
} from "@mui/material";
import { StyledTableRow } from "../../Utils/styledcell";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useMutation } from "react-query";
import client from "../../API";
import { useIsMounted } from "../../Hooks/use-is-mounted";
import InvoiceDialog from "../Dashboard/invoicing/invoicedialog";
import InvoiceEdit from "../Dashboard/invoicing/editInvoice";
import VendorDialog from "./vendorintake";
import EditIcon from "@mui/icons-material/Edit";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import blitzLogo from "../../Components/globalAssets/platty.png";
import MuiDateField from "../../Components/MuiDateField";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import InvoiceActionDialog from "./invoiceaction";
import NotificationsIcon from '@mui/icons-material/Notifications'; // For notification badge icon

const ActionCardGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
`;

const StyledCard = styled(Card)`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const BlitzPay = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  // Invoice states
  const [statusFilter, setStatusFilter] = useState("");
  const [creatorFilter, setCreatorFilter] = useState("");
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 365)).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 365)).toISOString().split('T')[0]);
  const [invoices, setInvoices] = useState([]);
  const [incomingInvoices, setIncomingInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [campaignNames, setCampaignNames] = useState({});
  const [creatorNames, setCreatorNames] = useState({});
  const [allCreatorIds, setAllCreatorIds] = useState([]);
  const [accountBalance, setAccountBalance] = useState("Loading...");
  const [creditline, setCreditline] = useState("Loading...");
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [plaidLinkToken, setPlaidLinkToken] = useState('');

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Tab index for the main view: All Activity, Invoices, Incoming Invoices, Payouts
  const [tabIndex, setTabIndex] = useState(0);

  // Payout states
  const [payouts, setPayouts] = useState([]);
  const [isPayoutsLoading, setIsPayoutsLoading] = useState(true);

  // Vendor Dialog
  const [vendorDialogOpen, setVendorDialogOpen] = useState(false);

  // Invoice Action Dialog
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const handleOpenVendorDialog = () => {
    setVendorDialogOpen(true);
  };

  const handleCloseVendorDialog = () => {
    setVendorDialogOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseInvoiceDialog = () => {
    setOpen(false);
    fetchInvoices();
  };

  const handleEditDialogOpen = (invoice) => {
    setCurrentInvoice(invoice);
    setEditOpen(true);
  };

  const handleEditDialogClose = (refresh = false) => {
    setEditOpen(false);
    setCurrentInvoice(null);
    if (refresh) {
      fetchInvoices();
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleCreatorFilterChange = (event) => {
    setCreatorFilter(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const formatAmount = (amount) => {
    const num = parseFloat(amount || 0.0);
    const formattedAmount = num.toFixed(2);
    return `$${formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const getPaymentName = (invoice) => {
    if (invoice.campaign_id) {
      return campaignNames[invoice.campaign_id] || "Unknown Campaign";
    } else if (invoice.campaign_name) {
      return invoice.campaign_name;
    } else {
      return "Unknown Payment";
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // TODO -> Turn all of this mutations that do not mutate data in the server
  // to useQuery. If any processing is needed, use useMemo with the fetch as a dependency for correctness.

  
  // Mutations and fetches
  const { mutate: fetchCompanyData } = useMutation(client.companies.listFetch, {
    onSuccess: (data) => {
      if (data && data.balance !== undefined && data.credit_line !== undefined) {
        setAccountBalance(`$${parseFloat(data.balance).toFixed(2)}`);
        setCreditline(
          data.credit_line
            ? `$${parseFloat(data.credit_line).toFixed(2)}`
            : "No Credit Line Established"
        );
      } else {
        setAccountBalance("Data format error");
        setCreditline("Data format error");
      }
    },
    onError: (error) => {
      console.error("Error fetching company data:", error);
      setAccountBalance("Error loading balance");
      setCreditline("Error loading credit line");
    },
  });

  const { mutate: fetchInvoices } = useMutation(client.invoices.list, {
    onSuccess: (data) => {
      setInvoices(data.invoices || []);
      const creatorIdsFromInvoices = (data.invoices || [])
        .filter(invoice => invoice.creator_id)
        .map(invoice => invoice.creator_id);
      setAllCreatorIds(prev => Array.from(new Set([...prev, ...creatorIdsFromInvoices])));
    },
    onError: (error) => {
      console.error("Error fetching invoices:", error);
    },
  });

  const { mutate: fetchCampaigns } = useMutation(client.campaigns.list, {
    onSuccess: (data) => {
      const campaignNamesMap = {};
      data.forEach((campaign) => {
        if (campaign) {
          campaignNamesMap[campaign.id] = campaign.name;
        }
      });
      setCampaignNames(campaignNamesMap);
    },
    onError: (error) => {
      console.error("Error fetching campaigns:", error);
    },
  });

  const { mutate: fetchCreators } = useMutation(client.creators.list, {
    onSuccess: (data) => {
      const creatorNamesMap = {};
      data.forEach((creator) => {
        if (creator) {
          creatorNamesMap[creator.id] = creator.name;
        }
      });
      setCreatorNames(creatorNamesMap);
    },
    onError: (error) => {
      console.error("Error fetching creators:", error);
    },
  });

  const { mutate: fetchAllCreatorIds } = useMutation(client.payouts.list, {
    onSuccess: (data) => {
      console.log('All creators ids', data);
      const uniqueCreatorIds = Array.from(new Set(data.payouts.map((payout) => payout.creator_id)));
      setAllCreatorIds(prev => Array.from(new Set([...prev, ...uniqueCreatorIds])));
    },
    onError: (error) => {
      console.error("Error fetching creator IDs:", error);
    },
  });

  const { mutate: fetchIncomingInvoices } = useMutation(client.invoices.creatorList, {
    onSuccess: (data) => {
      setIncomingInvoices(data.incoming_invoices || []);
    },
    onError: (error) => {
      console.error("Error fetching incoming invoices:", error);
    },
  });

  const { mutate: fetchPayouts } = useMutation(client.payouts.list, {
    onSuccess: (data) => {
      setPayouts(data.payouts || []);
    },
    onError: (error) => {
      console.error("Error fetching payouts:", error);
      let errorMessage = "An error occurred, please try again.";
      if (error.code === "ERR_NETWORK") {
        errorMessage = "Network is disconnected!";
      } else if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    },
    onSettled: () => {
      setIsPayoutsLoading(false);
    },
  });

  // Mutation for invoice action (approve/decline)
  const { mutate: invoiceAction } = useMutation(client.invoices.invoiceAction, {
    onSuccess: (data) => {
      setSnackbarMessage(data.message || "Action completed successfully.");
      setSnackbarOpen(true);
      fetchIncomingInvoices(); // refresh incoming invoices
    },
    onError: (error) => {
      const errMsg = error.response?.data?.error || 'Error performing invoice action.';
      setSnackbarMessage(errMsg);
      setSnackbarOpen(true);
    }
  });

  useEffect(() => {
    if (!isMounted) return;
    fetchCompanyData();
    fetchInvoices();
    fetchCampaigns();
    fetchCreators();
    fetchAllCreatorIds();
    fetchIncomingInvoices();
    fetchPayouts();
  }, [isMounted]);

  useEffect(() => {
    // Filter invoices based on status, creator, date range
    setFilteredInvoices(
      invoices.filter(
        (invoice) =>
          (statusFilter === "" || invoice.status === statusFilter) &&
          (creatorFilter === "" || invoice.creator_id === creatorFilter) &&
          (new Date(invoice.created_at) >= new Date(startDate) && new Date(invoice.created_at) <= new Date(endDate))
      )
    );
  }, [invoices, statusFilter, creatorFilter, startDate, endDate]);
  useEffect(() => {
    const fetchLinkTokenUser = async () => {
      try {
        // Suppose your front-end client has something like "API.bank.getLinkTokenUser()"
        // which calls "/plaid/create_link_token/user" on the backend.
        const resp = await client.bank.getLinkTokenUser();
        setPlaidLinkToken(resp.link_token);
      } catch (err) {
        console.error("Error fetching user link token:", err);
      }
    };
  
    fetchLinkTokenUser();
  }, []);
  const handleLinkBankAccount = async () => {
    // Example user ID. Usually from your auth context, Redux store, or backend session.
    const userId = 123; 
  
    if (!plaidLinkToken) {
      alert('No link token found. Please try again later.');
      return;
    }
    if (!window.Plaid) {
      alert('Plaid script not loaded. Ensure <script src="https://cdn.plaid.com/link/v2/stable/link-initialize.js" /> is present.');
      return;
    }
  
    const plaidHandler = window.Plaid.create({
      token: plaidLinkToken,
      onSuccess: async (public_token, metadata) => {
        try {
          // Exchange the public_token for an access_token on the server
          await client.bank.userLink({
            public_token,
            entity_type: 'user',
            entity_id: userId
          });
          setSnackbarMessage('Bank account linked successfully!');
          setSnackbarOpen(true);
        } catch (err) {
          console.error('Error linking bank account:', err);
          alert(`Error linking bank account: ${err.message}`);
        }
      },
      onExit: (err, metadata) => {
        if (err) {
          console.error('Plaid Link exited with error:', err);
        } else {
          console.log('Plaid Link exited by user');
        }
      },
    });
  
    plaidHandler.open();
  };
    
  // Summary calculations for invoices
  const totalInvoices = invoices.length;
  const invoicesByStatus = invoices.reduce((acc, invoice) => {
    acc[invoice.status] = (acc[invoice.status] || 0) + 1;
    return acc;
  }, {});

  // Summary calculations for payouts
  const creatorsPaid = payouts.length;
  const amountInEscrow = payouts.reduce((acc, curr) => {
    if (["pending", "queued"].includes((curr.status || "").toLowerCase())) {
      return acc + curr.amount;
    } else {
      return acc;
    }
  }, 0);
  const paymentsThisMonth = payouts.reduce((acc, curr) => {
    const payoutDate = new Date(curr.payout_date);
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    if (payoutDate.getMonth() === currentMonth && payoutDate.getFullYear() === currentYear) {
      return acc + curr.amount;
    } else {
      return acc;
    }
  }, 0);
  const averagePaymentAmount = payouts.length > 0
    ? payouts.reduce((acc, curr) => acc + curr.amount, 0) / payouts.length
    : 0;

  const downloadPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();

    // Add header with logo and divider
    pdf.setFillColor(0, 0, 0); // Black background
    pdf.rect(0, 0, pdfWidth, 30, "F"); // Header rectangle

    pdf.addImage(blitzLogo, "PNG", 10, 5, 50, 20); // Adjust position as needed

    pdf.setFontSize(18);
    pdf.setTextColor(255, 255, 255); // White text color
    pdf.setFont("helvetica", "bold");
    pdf.text("Financial Statement", pdfWidth / 2, 20, { align: "center" });

    // Divider
    pdf.setDrawColor(255, 255, 255); // White divider color
    pdf.line(10, 30, pdfWidth - 10, 30);

    // Report generated timestamp
    const timestamp = new Date().toLocaleString();
    pdf.setFontSize(12);
    pdf.setTextColor(255, 255, 255);
    pdf.text(`Report generated at: ${timestamp}`, 10, 35);

    pdf.setTextColor(0, 0, 0); // Reset to black for content

    pdf.text(`Account Balance: ${accountBalance}`, 10, 50);
    pdf.text(`Line of Credit: ${creditline}`, 10, 60);

    // Filtered Invoices in PDF
    pdf.autoTable({
      startY: 70,
      head: [
        ["Payment Name", "PO Number", "Amount Due", "Status", "Created At", "Notes"]
      ],
      body: filteredInvoices.map(invoice => [
        getPaymentName(invoice),
        invoice.po_number || "N/A",
        formatAmount(invoice.amount_due),
        invoice.status,
        new Date(invoice.created_at).toLocaleDateString(),
        invoice.notes || ""
      ]),
      theme: "striped",
      headStyles: { fillColor: [66, 66, 66], textColor: [255,255,255] },
      bodyStyles: { fillColor: [230, 230, 230] },
      alternateRowStyles: { fillColor: [240, 240, 240] },
    });

    pdf.save("financial_statement.pdf");
  };

  // Combine Invoices and Payouts for "All Activity"
  const combinedActivity = [
    ...filteredInvoices.map(invoice => ({
      type: "Invoice",
      name: getPaymentName(invoice),
      po_number: invoice.po_number || "N/A",
      amount: invoice.amount_due,
      status: invoice.status,
      date: invoice.created_at,
      notes: invoice.notes,
      link: `https://www.useblitz.co/invoicing/${invoice.id}`,
      editable: invoice.status !== "Paid",
      onEdit: () => handleEditDialogOpen(invoice)
    })),
    ...(payouts.filter(p => {
      const dateCheck = new Date(p.payout_date) >= new Date(startDate) && new Date(p.payout_date) <= new Date(endDate);
      const statusCheck = !statusFilter || p.status === statusFilter;
      return dateCheck && statusCheck;
    }).map(payout => ({
      type: "Payout",
      name: payout.campaignname || "Creator Payout",
      po_number: "N/A",
      amount: payout.amount,
      status: payout.status,
      date: payout.payout_date,
      notes: payout.notes,
      link: "#",
      editable: false,
      onEdit: null
    })))
  ].sort((a, b) => new Date(b.date) - new Date(a.date)); // Descending order

  const handleOpenActionDialog = (invoiceId) => {
    setSelectedInvoiceId(invoiceId);
    setActionDialogOpen(true);
  };

  const handleCloseActionDialog = () => {
    setActionDialogOpen(false);
    setSelectedInvoiceId(null);
  };

  const handleActionSuccess = (message, newStatus) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
    fetchIncomingInvoices();
  };

  // Calculate total incoming invoices amount
  const totalIncomingAmount = incomingInvoices.length;


  return (
    <>
      <Paper elevation={2} square={true} sx={{ width: '100%', paddingBlockStart: 2, marginBlockEnd: 2}}>
        <Typography variant="h4" gutterBottom component="div" sx={{ textAlign: "center", position: 'sticky', left: 0 }}>
          Your BlitzPay Activity
        </Typography>
        <Box sx={{ width: '100%', overflowX: 'auto', paddingInline: { xs: 2, md: 4 }, marginBlockEnd:2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2, paddingBlock:1, flexWrap:'wrap' }}>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Create Invoice
            </Button>
            <Button variant="contained" color="primary" onClick={() => navigate("/pay")}>
              Pay a Creator
            </Button>
            <Button variant="contained" color="primary" onClick={handleOpenVendorDialog}>
              Add a Vendor
            </Button>
            <InvoiceDialog open={open} onClose={handleCloseInvoiceDialog} invoiceInfo={undefined} />
            <MuiDateField
              size='small'
              label="Start Date"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ minWidth: 240 }}
            />
            <MuiDateField
              size='small'
              label="End Date"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ minWidth: 240 }}
            />
            <Button
    variant="contained"
    color="secondary"
    onClick={downloadPDF}
    sx={{ minWidth: 240 }}
  >
    Download Financial Statement
  </Button>

  {/* New "Link Bank Account" button (blue)
  <Button
    variant="contained"
    color="primary"
      onClick={handleLinkBankAccount}
      sx={{ minWidth: 240 }}
    >
      Link Bank Account
    </Button>
     */}
          </Box>
        </Box>
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="All Activity" />
          <Tab label="Invoices" />
          <Tab
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                Incoming Invoices
                <Badge 
                  badgeContent={totalIncomingAmount}
                  color="secondary"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <NotificationsIcon />
                </Badge>
              </Box>
            }
          />
          <Tab label="Payouts" />
        </Tabs>
      </Paper>

      <ActionCardGrid sx={{marginInline:2}}>
        <StyledCard>
          <CardContent>
            <Typography variant="h6">Invoice Summary</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">Account Balance: {accountBalance}</Typography>
            <Typography variant="body1">Line of Credit: {creditline}</Typography>
            <Typography variant="body1">Total Invoices: {totalInvoices}</Typography>
            {Object.entries(invoicesByStatus).map(([status, count]) => (
              <Typography key={status}>
                {status}: {count}
              </Typography>
            ))}
          </CardContent>
        </StyledCard>
        <StyledCard>
          <CardContent>
            <Typography variant="h6">Payout Summary</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">Creators Paid: {creatorsPaid}</Typography>
            <Typography variant="body1">Amount in Escrow: ${amountInEscrow}</Typography>
            <Typography variant="body1">Payments This Month: ${paymentsThisMonth.toFixed(2)}</Typography>
            <Typography variant="body1">Avg Payment: ${averagePaymentAmount.toFixed(2)}</Typography>
          </CardContent>
        </StyledCard>
      </ActionCardGrid>

      {tabIndex === 0 && (
        <TableContainer sx={{ width: '80vw', marginInline: 'auto', marginBlockEnd: 2 }} component={Paper} elevation={1}>
          <Table sx={{ minWidth: 500 }} aria-label="all activity table" width={'100%'}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">PO Number</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Notes</TableCell>
                <TableCell align="center">Link</TableCell>
                <TableCell align="center">Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedActivity.map((item, index) => (
                <StyledTableRow key={index}>
                  <TableCell align="center">
                    {item.type}
                  </TableCell>
                  <TableCell align="center">
                    {item.name}
                  </TableCell>
                  <TableCell align="center">
                    {item.po_number}
                  </TableCell>
                  <TableCell align="center">
                    {formatAmount(item.amount)}
                  </TableCell>
                  <TableCell align="center">
                    {item.status}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(item.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {item.notes}
                  </TableCell>
                  <TableCell align="center">
                    {item.link !== "#" ? (
                      <Link href={item.link} target="_blank" rel="noopener noreferrer">
                        View
                      </Link>
                    ) : "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {item.editable && item.onEdit && (
                      <Button
                        onClick={item.onEdit}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {tabIndex === 1 && (
        <TableContainer sx={{ width: '80vw', marginInline: 'auto', marginBlockEnd: 2 }} component={Paper} elevation={1}>
          <Table sx={{ minWidth: 500 }} aria-label="invoices table" width={'100%'}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Payment Name</TableCell>
                <TableCell align="center">PO Number</TableCell>
                <TableCell align="center">Amount Due</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="center">Notes</TableCell>
                <TableCell align="center">Invoice Link</TableCell>
                <TableCell align="center">Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredInvoices.map((invoice) => (
                <StyledTableRow key={invoice.id}>
                  <TableCell align="center">
                    {getPaymentName(invoice)}
                  </TableCell>
                  <TableCell align="center">
                    {invoice.po_number || "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {formatAmount(invoice.amount_due)}
                  </TableCell>
                  <TableCell align="center">
                    {invoice.status}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(invoice.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {invoice.notes}
                  </TableCell>
                  <TableCell align="center">
                    <Link href={`https://www.useblitz.co/invoicing/${invoice.id}`} target="_blank" rel="noopener noreferrer">
                      View Invoice
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {invoice.status !== "Paid" && (
                      <Button
                        onClick={() => handleEditDialogOpen(invoice)}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {tabIndex === 2 && (
        <TableContainer sx={{ width: '80vw', marginInline: 'auto', marginBlockEnd: 2 }} component={Paper} elevation={1}>
          <Table sx={{ minWidth: 700 }} aria-label="incoming invoices table" width={'100%'}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Client Name</TableCell>
                <TableCell align="center">PO Number</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Payout Date</TableCell>
                <TableCell align="center">Notes</TableCell>
                <TableCell align="center">Invoice Link</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incomingInvoices.map((invoice) => (
                <StyledTableRow key={invoice.id}>
                  <TableCell align="center">
                    {invoice.client_name}
                  </TableCell>
                  <TableCell align="center">
                    {invoice.po_number || "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {formatAmount(invoice.amount)}
                  </TableCell>
                  <TableCell align="center">
                    {invoice.invoice_status}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(invoice.payout_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {invoice.notes}
                  </TableCell>
                  <TableCell align="center">
                    <Link href={`https://www.useblitz.co/creatorinvoice/${invoice.id}`} target="_blank" rel="noopener noreferrer">
                      View Invoice
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {invoice.invoice_status === "Pending" && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenActionDialog(invoice.id)}
                      >
                        Approve/Decline Invoice
                      </Button>
                    )}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {tabIndex === 3 && (
        <TableContainer sx={{ width: '80vw', marginInline: 'auto', overflowX: 'auto', marginBlockEnd:2 }} component={Paper}>
          {isPayoutsLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Table aria-label="payouts table">
              <TableHead>
                <TableRow>
                  <TableCell>Creator ID</TableCell>
                  <TableCell>Campaign Name</TableCell>
                  <TableCell>Payout Date</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>BlitzPay</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payouts.filter(p => {
                  const dateCheck = new Date(p.payout_date) >= new Date(startDate) && new Date(p.payout_date) <= new Date(endDate);
                  const statusCheck = !statusFilter || p.status === statusFilter;
                  return dateCheck && statusCheck;
                }).map((payout) => (
                  <StyledTableRow key={payout.payout_id || "N/A"}>
                    <TableCell>{payout.creator_id}</TableCell>
                    <TableCell>{payout.campaignname || "Creator Payout"}</TableCell>
                    <TableCell>{new Date(payout.payout_date).toLocaleDateString()}</TableCell>
                    <TableCell>{payout.status}</TableCell>
                    <TableCell>{payout.blitzpay ? "true" : "false"}</TableCell>
                    <TableCell>{formatAmount(payout.amount)}</TableCell>
                    <TableCell>{payout.notes}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}

      {currentInvoice && (
        <InvoiceEdit
          open={editOpen}
          onClose={handleEditDialogClose}
          invoiceInfo={currentInvoice}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={<Button color="inherit" size="small" onClick={handleCloseSnackbar}>Close</Button>}
      />

      <VendorDialog open={vendorDialogOpen} onClose={handleCloseVendorDialog} />

      <InvoiceActionDialog
        open={actionDialogOpen}
        onClose={handleCloseActionDialog}
        invoiceId={selectedInvoiceId}
        onSuccess={handleActionSuccess}
        invoiceActionMutation={invoiceAction}
      />
    </>
  );
};

export default BlitzPay;
