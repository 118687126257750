import React, { useState, useRef } from 'react';
import {
    Typography,
    Button,
    Backdrop,
    CircularProgress,
    Box,
    TextField,
    ThemeProvider,
    CssBaseline,
    Toolbar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import routes from '../../Config/routes';

import AlertDialog from '../../Components/AlertDialog';
import useAlertDialog from '../../Components/useAlertDialog';
import client from '../../API'
import HomeHeader from '../../Components/HomeHeader';
import { lightTheme } from '../../Utils/baseTheme';

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [validationError, setValidationError] = useState('');

    const { dialogState, openDialog, closeDialog } = useAlertDialog();
    const inputRef = useRef(null);
    const formRef = useRef(null);


    // Dialog state
    const [loading, setLoading] = useState(false);


    const redirectHome = () => {
        navigate(routes.home);
    }

    const OnSubmit = async (e) => {
        e.preventDefault();

        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return;
        }

        setLoading(true);
        const payload = {
            email,
        };

        try {
            const data = await client.users.forgotRequest(payload);
            openDialog('Info', 'If we find your account, you will be sent instructions to restore your password to your Email inbox. Check Spam if necessary', redirectHome, redirectHome);
        } catch (error) {
            console.error("Error during form submission:", error);
            openDialog('Error', <>There was an issue creating this password restore request: {error.response?.data?.error || error.message} <br />
                Try again.</>, closeDialog, closeDialog, 'Ok', null);
            setLoading(false);
        }
    }


    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <HomeHeader />
            <Toolbar></Toolbar>
            <Box sx={{ paddingInline: 2 }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AlertDialog alertState={dialogState}></AlertDialog>
                <form onSubmit={OnSubmit} ref={formRef} noValidate>
                    <Box sx={{ marginInline: 'auto', marginBlockStart: 4, marginBlockEnd: 2, display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '30em' }}>
                        <Box sx={{ marginBlockEnd: 1, textAlign: 'center' }}>
                            <Typography variant='h3' gutterBottom>Forgot my password</Typography>
                            <Typography variant='body1' color='text.secondary' gutterBottom>Write down the email you used to create your Blitz account and we will send you an email with instructions for restoring your account.</Typography>
                        </Box>
                        <Box sx={{display:'flex', flexDirection:'row', width:'100%', gap:1}}>
                            <TextField
                                label='Your Email'
                                name='username'
                                type='email'
                                autoComplete="username"
                                helperText={validationError}
                                required
                                value={email}
                                error={!!validationError}
                                onChange={e => setEmail(e.target.value)}
                                inputRef={inputRef}
                                fullWidth
                            >
                            </TextField>
                            <Button variant='contained' color='secondary' type='submit' sx={{ alignSelf: 'center' }}>Submit</Button>
                        </Box>


                    </Box>
                </form>
            </Box>
        </ThemeProvider>
    );
};

export default ForgotPassword;
