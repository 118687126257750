import React from 'react'
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import API from '../../../API';
import { Alert, Avatar, Backdrop, Box, Button, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import AlertDialog from '../../../Components/AlertDialog';
import useAlertDialog from '../../../Components/useAlertDialog';

export default function AppSpec({ }) {
    const { appId } = useParams();

    const {dialogState, openDialog, closeDialog} = useAlertDialog();

    const { data: appInfo, error, isLoading } = useQuery({
        queryKey: ['apps', 'get', appId],
        queryFn: () => API.developerPublic.serviceGet(appId),
        refetchOnWindowFocus: false,
        enabled: !!appId
    });

    const { data: sub, isLoading: subIsLoading, error: subError, refetch: subRefetch } = useQuery({
        queryKey: ['creator', 'subscriptions', appId],
        queryFn: () => API.developerPublic.subscriptionGet(appId),
        refetchOnWindowFocus: false,
        enabled: !!appInfo,
        retry: 1
    })

    const {mutate, isLoading: revokeLoading, error: revokeError} = useMutation({
        mutationFn: (input)=>API.developerPublic.subscriptionUpdate(appId, input),
        onSuccess: ()=>{
            subRefetch();
            openDialog('Success', 'You revoked your access to this app succesfully', closeDialog, closeDialog, 'Ok', null);
        },
        onError: (rvError)=>{
            openDialog('Error', <>An error happened while trying to revoke your access: <br/>{rvError?.response?.data?.error || rvError.message}</>, closeDialog, closeDialog, 'Ok', null);
        }
    })

    if (error || (subError && (!subError.response || subError.response.status != 404))) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', width:'100%' }}>
                <Alert severity="error">
                    Error retrieving app data: {
                        error?.response?.data?.error || subError?.response?.data?.error ||
                        error?.message || subError?.message
                    }
                </Alert>
            </Box>
        )
    }

    return (
        <Box>
            <AlertDialog alertState={dialogState}></AlertDialog>
            <Backdrop open={isLoading || subIsLoading}>
                <CircularProgress></CircularProgress>
            </Backdrop>
            {!!sub && <Alert severity='info' square>You're already subscribed to this App.</Alert>}
            {!!appInfo?.status != 'active' && <Alert severity='warning' square>This app is not verified by Blitz</Alert>}
            <Paper sx={{ marginInline: 'auto', marginBlock: 3, width: 'min-content' }} elevation={4}>
                <Box sx={{ width: '40rem', maxWidth: '90vw', display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
                    <Avatar
                        sx={{
                            width: { xs: 84, md: 84 },
                            height: { xs: 84, md: 84 },
                            boxShadow: 9,
                            marginInline: 'auto',
                            marginBlockStart: 5
                        }} variant='rounded' src={appInfo?.icon}>{!!appInfo?.name ? appInfo.name[0] : 'A'}</Avatar>
                    <Typography variant='h5' sx={{ marginBlockStart: 3 }}>{appInfo?.name}</Typography>
                    <Typography variant='body1' sx={{ marginBlockStart: 1 }}>{appInfo?.description}</Typography>
                    <Paper variant='outlined' sx={{ margin: 2, backgroundColor: 'action.selected' }}>
                        <Box sx={{ marginInline: 2, marginBlock: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button fullWidth variant='contained' color='secondary' href={appInfo?.homepage_url}>Homepage</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button fullWidth color='primary' sx={{ whiteSpace: 'nowrap' }}href={appInfo?.privacy_policy_url}>Privacy Policy</Button>
                                </Grid>
                                {!!sub && <Grid item xs={12}>
                                    <Button 
                                    variant='contained' 
                                    color='error' 
                                    fullWidth
                                    onClick={()=>mutate({active:false})}
                                    >Revoke Access</Button>
                                </Grid>}
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Paper>

        </Box>
    )
}
