import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Button,
  TextField,
  Snackbar,
  Alert,
  Container,
  Grid,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import API from '../../API';

const CreatorDealSpec = () => {
  const { dealId } = useParams();
  const [deal, setDeal] = useState(null);
  const [error, setError] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [editedDeliverables, setEditedDeliverables] = useState([]);

  const {
    data,
    isLoading,
    refetch,
  } = useQuery(
    ['deal', dealId],
    () => API.dealsIO.creatorSpecfetch(dealId),
    {
      onSuccess: (data) => {
        setDeal(data);
        setEditedDeliverables(data.deliverables || []);
      },
      onError: (error) => {
        console.error('Error fetching deal:', error);
        setError('Failed to load deal.');
      },
    }
  );

  const { mutate: updateDeal, isLoading: isUpdating } = useMutation(
    (updatedData) => API.dealsIO.creatorUpdate(dealId, updatedData),
    {
      onSuccess: () => {
        setUpdateSuccess(true);
        refetch();
      },
      onError: (error) => {
        console.error('Error updating deal:', error);
        setError('Failed to update deal.');
      },
    }
  );

  const handleRequirementEntryChange = (deliverableId, reqId, newEntry) => {
    setEditedDeliverables((prev) =>
      prev.map((del) => {
        if (del.id === deliverableId) {
          const updatedReqs = (del.requirements || []).map((req) =>
            req.id === reqId ? { ...req, entry: newEntry } : req
          );
          return { ...del, requirements: updatedReqs };
        }
        return del;
      })
    );
  };

  const handleSave = () => {
    updateDeal({ deliverables: editedDeliverables });
  };

  if (isLoading) {
    return (
      <Box
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh',
          bgcolor: 'background.default',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error || !deal) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100vw',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
          px: 2,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" color="error">
          {error || 'Error loading deal.'}
        </Typography>
      </Box>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Stack spacing={4}>
        {/* Header Section */}
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Deal Details
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Deal with Vendor ID: <strong>{deal.creator_vendor_id}</strong>
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.5 }}>
            Deal ID: {deal.id}
          </Typography>
        </Paper>

        {/* Deal Information */}
        <Paper elevation={1} sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Financial & Contract Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="text.primary">
                Amount Due: <strong>${deal.amount_due}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="text.primary">
                Amount Paid: <strong>${deal.amount_paid}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="text.primary">
                Months: <strong>{deal.amount_of_months}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="text.primary">
                Vendor ID: <strong>{deal.creator_vendor_id}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        {/* Deliverables Section */}
        <Stack spacing={2}>
          <Typography variant="h6">Deliverables</Typography>
          {editedDeliverables && editedDeliverables.length > 0 ? (
            <Grid container spacing={2}>
              {editedDeliverables.map((deliverable) => (
                <Grid item xs={12} key={deliverable.id}>
                  <Paper elevation={2} sx={{ p: 3 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      {deliverable.work_info}
                    </Typography>
                    <Stack direction="row" flexWrap="wrap" spacing={2} sx={{ mb: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Amount:</strong> ${deliverable.amount}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Due Date:</strong> {deliverable.due_date}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Status:</strong> {deliverable.status || 'Pending'}
                      </Typography>
                    </Stack>

                    {/* Requirements */}
                    {deliverable.requirements && deliverable.requirements.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                          Requirements:
                        </Typography>
                        {deliverable.requirements.map((req) => (
                          <Box
                            key={req.id}
                            sx={{ 
                              mb: 2,
                              display: 'flex', 
                              flexDirection: 'column', 
                              gap: 1 
                            }}
                          >
                            <Typography variant="body2" fontWeight="bold">
                              {req.name}
                            </Typography>
                            <TextField
                              label="Your Entry"
                              variant="outlined"
                              fullWidth
                              value={req.entry || ''}
                              onChange={(e) =>
                                handleRequirementEntryChange(deliverable.id, req.id, e.target.value)
                              }
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1" color="text.secondary">
              No deliverables available.
            </Typography>
          )}
        </Stack>

        {/* Save Button */}
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disabled={isUpdating}
            onClick={handleSave}
          >
            {isUpdating ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>
      </Stack>

      {/* Success Snackbar */}
      <Snackbar
        open={updateSuccess}
        autoHideDuration={4000}
        onClose={() => setUpdateSuccess(false)}
      >
        <Alert
          onClose={() => setUpdateSuccess(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Deal updated successfully.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CreatorDealSpec;
