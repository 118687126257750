import { IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function ApiKeyField({ apiKey, children, ...params }) {
    const [showKey, setShowKey] = useState('');

    const handleClickShowPassword = () => setShowKey((showKey) => !showKey);

    return (
        <TextField
            {...params}
            label='Api Key'
            disabled
            variant='outlined'
            InputProps={{
                type:(showKey ? 'text' : 'password'),
                endAdornment:
                    (<InputAdornment position="end">
                        <IconButton
                            aria-label={
                                showKey ? 'hide the key' : 'display the key'
                            }
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            {showKey ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>),
            }}
            value={apiKey}
        >

        </TextField>
    )
}
