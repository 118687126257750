import { Box, Card, CardContent, Link, Typography } from '@mui/material'
import React from 'react'

export default function PlatformCard({ handle, views, following, icon, link }) {


    return (
        <Card variant='outlined' sx={{ flex: 1 }}>
            <CardContent>
                <Typography variant='h6' color={'text.secondary'}>
                    <Link
                        href={link}
                        color='secondary'
                        target="_blank"
                        rel="noreferrer"
                        overflow='clip'
                        minWidth='0'
                        sx={{ textWrap: 'nowrap' }}
                    >
                        {icon} {handle}
                    </Link>
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 4
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Typography variant='h6'>Followers</Typography>
                        <Typography variant='h6'>{following}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Typography variant='h6'>AVG Viewers</Typography>
                        <Typography variant='h6'>{views}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}
