import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Button, 
  Box, 
  Typography, 
  CircularProgress,
  Alert,
  Avatar,
  IconButton,
  Link,
  useTheme
} from '@mui/material';
import { Upload as UploadIcon, InsertDriveFile, PictureAsPdf, Image as ImageIcon } from '@mui/icons-material';
import client from '../../API';

const renderFilePreview = (fileType, url) => {
  if (fileType.startsWith('image/')) {
    return <Avatar variant="rounded" src={url} sx={{ width: 100, height: 100 }} />;
  }
  
  const Icon = {
    'application/pdf': PictureAsPdf,
    'text/plain': InsertDriveFile,
  }[fileType] || InsertDriveFile;

  return (
    <Avatar variant="rounded" sx={{ width: 100, height: 100, bgcolor: 'background.default' }}>
      <Icon sx={{ fontSize: 40 }} color="action" />
    </Avatar>
  );
};

const UploadFileDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(null);
  const FILE_SIZE_LIMIT = 4.5 * 1024 * 1024;

  const { mutate: uploadFile, isLoading } = useMutation(
    (fileData) => client.files.post(fileData),
    {
      onSuccess: (data) => {
        setUploadedFile(data);
        setError(null);
      },
      onError: (error) => {
        setError(error.message || 'Failed to upload file');
      },
    }
  );

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setError(null);

    if (file.size > FILE_SIZE_LIMIT) {
      setError('File size exceeds the 4.5MB limit. Please upload a smaller file.');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64File = reader.result.split(',')[1];
      uploadFile({
        file: {
          name: file.name,
          content: base64File,
          type: file.type,
        },
      });
    };
  }, [uploadFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    disabled: isLoading,
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ bgcolor: theme.palette.primary.main, color: 'white' }}>
        Upload File
        <Typography variant="caption" sx={{ display: 'block', mt: 1, opacity: 0.9 }}>
          Supported formats: JPEG, PNG, PDF, TXT
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Box
          {...getRootProps()}
          sx={{
            border: `2px dashed ${isDragActive ? theme.palette.primary.main : theme.palette.divider}`,
            borderRadius: 2,
            p: 4,
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            bgcolor: isDragActive ? theme.palette.action.hover : 'background.default',
            '&:hover': {
              borderColor: theme.palette.primary.main,
              bgcolor: theme.palette.action.hover
            },
            mb: 3
          }}
        >
          <input {...getInputProps()} />
          <UploadIcon sx={{ fontSize: 40, mb: 1, color: 'text.secondary' }} />
          <Typography variant="body1">
            {isDragActive ? 'Drop file here' : 'Drag file here or click to browse'}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Max file size: 4.5MB
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {isLoading && (
          <Box sx={{ textAlign: 'center', p: 2 }}>
            <CircularProgress size={40} />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Uploading...
            </Typography>
          </Box>
        )}

        {uploadedFile && (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Uploaded Successfully!
            </Typography>
            {renderFilePreview(uploadedFile.type, uploadedFile.url)}
            <Box sx={{ mt: 2 }}>
              <Link
                href={uploadedFile.url}
                target="_blank"
                rel="noopener"
                sx={{ wordBreak: 'break-all' }}
              >
                {uploadedFile.filename}
              </Link>
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 3, gap: 2 }}>
        <Button 
          onClick={onClose} 
          variant="outlined" 
          color="secondary"
          disabled={isLoading}
        >
          Close
        </Button>
        {uploadedFile && (
          <Button 
            onClick={() => setUploadedFile(null)}
            variant="contained"
            color="primary"
          >
            Upload Another
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileDialog;