import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel, Radio, TextField, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import client from "../../API";

function InvoiceActionDialog({ open, onClose, invoiceId, onSuccess }) {
  const [action, setAction] = useState('approve'); // default selection
  const [approveWord, setApproveWord] = useState('');

  const { mutate: invoiceActionMutate, isLoading } = useMutation(
    ({ invoiceId, action }) => client.invoices.action({ invoiceId, action }),
    {
      onSuccess: (data) => {
        // data should contain {message, status} from the server
        onSuccess(data.message, data.status);
        onClose();
      },
      onError: (error) => {
        // error can be either a JSON error message or network error
        alert(error?.message || 'Error performing invoice action');
      },
    }
  );

  const handleActionChange = (event) => {
    setAction(event.target.value);
    setApproveWord('');
  };

  const handleApproveWordChange = (event) => {
    setApproveWord(event.target.value);
  };

  const handleSubmit = () => {
    invoiceActionMutate({ invoiceId, action });
  };

  const isApproveDisabled = action === 'approve' && approveWord !== 'Approve';

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Invoice Action</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Choose to approve or decline this invoice.
          If approving, please type out the word "Approve" (case-sensitive) in the field to confirm.
        </Typography>

        <RadioGroup value={action} onChange={handleActionChange}>
          <FormControlLabel value="approve" control={<Radio />} label="Approve Invoice" />
          <FormControlLabel value="decline" control={<Radio />} label="Decline Invoice" />
        </RadioGroup>

        {action === 'approve' && (
          <TextField
            label='Type "Approve" to confirm'
            variant='outlined'
            value={approveWord}
            onChange={handleApproveWordChange}
            fullWidth
            margin="normal"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isApproveDisabled || isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InvoiceActionDialog;
