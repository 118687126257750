import React from "react";
import TabPanelHidden from "../../../../Components/TabPanelHidden";
import CreatorPfp from "../../../../Components/CreatorData/creatorPfp";
import CreatorInfo from "../../../../Components/CreatorData/creatorInfo";

function ProfileTab({
  tabValue,
  index,
  creatorName,
  setCreatorName,
  profilePhotoUrl,
  setIsUploadDialogOpen,
  creatorRegion,
  setCreatorRegion,
  race,
  setRace,
  gender,
  setGender,
  location,
  setLocation,
  primaryMarket,
  setPrimaryMarket,
  notesContentStyle,
  setNotesContentStyle,
  contactEmail,
  setContactEmail,
  phoneNumber,
  setPhoneNumber,
  validationError,
}) {
  return (
    <TabPanelHidden value={tabValue} index={index} id={`tab-contents-${index}`}>
      <CreatorPfp
        creatorName={creatorName}
        profilePhotoUrl={profilePhotoUrl}
        // Trigger open of the main parent's file-upload dialog
        handleOpenUploadDialog={() => setIsUploadDialogOpen(true)}
      />

      <CreatorInfo
        creatorName={creatorName}
        setCreatorName={setCreatorName} // or null if read-only
        creatorRegion={creatorRegion}
        setCreatorRegion={setCreatorRegion}
        race={race}
        setRace={setRace}
        gender={gender}
        setGender={setGender}
        location={location}
        setLocation={setLocation}
        primaryMarket={primaryMarket}
        setPrimaryMarket={setPrimaryMarket}
        notesContentStyle={notesContentStyle}
        setNotesContentStyle={setNotesContentStyle}
        contactEmail={contactEmail}
        setContactEmail={setContactEmail}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        validationError={validationError}
        require={false}
      />
    </TabPanelHidden>
  );
}

export default ProfileTab;
