import { Box, styled } from "@mui/material";

const Codebox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2), 
    backgroundColor: '#000', 
    color: '#ddd', 
    borderWidth:'2px', 
    borderColor:'#ddd', 
    borderStyle:'solid',
    boxShadow:theme.shadows[2]
}));

export default Codebox;