import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  CircularProgress
} from "@mui/material";

const BrandNegotiationDialog = ({
  open,
  handleClose,
  creator,
  currentOffer,
  onRespond,
  isSubmitting
}) => {
  const [action, setAction] = useState(null);
  const [message, setMessage] = useState('');
  const [counterOffer, setCounterOffer] = useState('');
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    if (!open) {
      // Reset form when dialog closes
      setAction(null);
      setMessage('');
      setCounterOffer('');
      setValidationError('');
    }
  }, [open]);

  const validateOffer = (value) => {
    if (!value || isNaN(value) || parseFloat(value) <= 0) {
      return 'Please enter a valid offer amount';
    }
    return '';
  };

  const handleSubmit = () => {
    if (!action) {
      setValidationError('Please select an action');
      return;
    }

    if (action === 'counter') {
      const error = validateOffer(counterOffer);
      if (error) {
        setValidationError(error);
        return;
      }
    }

    const payload = {
      creator_id: creator.id,
      action,
      message,
      ...(action === 'counter' && { new_offer: parseFloat(counterOffer) })
    };

    onRespond(payload);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Paper elevation={3} sx={{ p: 2 }}>
        <DialogTitle>Respond to {creator?.creator}'s Counter</DialogTitle>
        
        <DialogContent dividers>
          <Typography variant="subtitle1" gutterBottom>
            Current Counter Offer: ${currentOffer}
          </Typography>

          {validationError && (
            <Typography color="error" sx={{ mb: 2 }}>
              {validationError}
            </Typography>
          )}

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant={action === 'accept' ? "contained" : "outlined"}
                color="success"
                onClick={() => setAction('accept')}
              >
                Accept Counter
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant={action === 'decline' ? "contained" : "outlined"}
                color="error"
                onClick={() => setAction('decline')}
              >
                Decline Counter
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant={action === 'counter' ? "contained" : "outlined"}
                color="warning"
                onClick={() => setAction('counter')}
              >
                Counter Offer
              </Button>
            </Grid>

            {action === 'counter' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="New Offer Amount ($)"
                  type="number"
                  value={counterOffer}
                  onChange={(e) => {
                    setCounterOffer(e.target.value.replace(/[^0-9.]/g, ''));
                    setValidationError('');
                  }}
                  inputProps={{ min: 0.01, step: 0.01 }}
                  error={!!validationError}
                  helperText={validationError}
                />
                <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
                  Platform fee (5%): ${(counterOffer * 0.05).toFixed(2)}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Additional Message (Optional)"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleSubmit}
            disabled={!action || (action === 'counter' && !counterOffer) || isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'Submit Response'}
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default BrandNegotiationDialog;