import React from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import ReleaseScaffolding from './releaseScaffold';

const releaseSections = [
  { id: 'beta-5', title: 'Blitz 5.0 - Blitz Lizard Release (January 28th, 2025)' },
  { id: 'beta-4', title: 'Blitz 4.0 - Platform Foundation Release' },
  { id: 'beta-3', title: 'Blitz 3.0 - MVP Launch Release' },

];

export default function BlitzReleaseNotes() {
  return (
    <ReleaseScaffolding sections={releaseSections}>
      <Container>
        {releaseSections.map((section) => (
          <Box key={section.id} id={section.id} sx={{ marginBottom: 4 }}>
            <Typography variant="h4">{section.title}</Typography>
            <Divider />
            
            {section.id === 'beta-5' ? (
              <>
                <Typography variant="h6" sx={{ mt: 2 }}>New Features</Typography>
                <ul>
                  <li>Enhanced AI Search for Creators: Improved edge case handling, synonym dictionaries for attributes (JSON formatted), and open-ended interaction design</li>
                  <li>User Preferences Overhaul: Customizable color themes, email settings, feature tabs, and search history-based creator recommendations (Alex)</li>
                  <li>Email Conversation System: Webhook integration for partnerships@ email threads, automated CRM logging, and improved helpdesk templates</li>
                  <li>AI-Powered Campaign Creation: Generate campaigns from email prompts using [companyName, campaignId] parameters with security enhancements</li>
                  <li>User Banking System: ACH balance uploads and automatic invoice payments from stored balances</li>
                  <li>Creator Banking Integration: Direct transfers to PayPal, bank accounts via Gusto API, and Stripe payouts (Ricky)</li>
                  <li>Azure Cloud Migration: Hosted AI processing, improved sourcing techniques, and create.useblitz domain porting (Ricky)</li>
                  <li>Advanced Data Exports: Per-creator detailed exports, campaign reporting replacements, and automated Google Drive partnerships documentation</li>
                  <li>Single Sign-On (SSO): Unified login across Blitz ecosystem applications</li>
                  <li>Flexible Email Sending: Select from creator-owned email addresses for campaigns and CRM blasts</li>
                </ul>

                <Typography variant="h6">Improvements</Typography>
                <ul>
                  <li>Agency Portal Upgrade: Optimized roster management with customizable layouts</li>
                  <li>Financial System Streamlining: Unified balance tracking across user types with new database tables (Ricky)</li>
                  <li>Myah AI Enhancements: Email follow-up automation, CRM integration, and upgraded LLM connections (Alex)</li>
                  <li>CRM Optimization: Improved re-engagement workflows and data cleaning processes</li>
                  <li>Agency-Creator Collaboration: Restricted deal visibility (price/conversations hidden) and pitch limitations</li>
                  <li>Media Kit Enhancements: Prominent blue underlined links for creator profiles (#0073e6)</li>
                  <li>Platform Growth: Reached 150 brand users and 240 creator users</li>
                </ul>

                <Typography variant="h6">Bug Fixes</Typography>
                <ul>
                  <li>Fixed duplicate "@" symbol in YouTube handle displays</li>
                  <li>Resolved UI inconsistencies in partnerships/deals creator interface</li>
                </ul>
              </>
            ) : section.id === 'beta-4' ? (
              <>
                <Typography variant="h6" sx={{ mt: 2 }}>New Features</Typography>
                <ul>
                  <li>AI Search Alpha: Initial implementation of creator discovery AI with basic filtering capabilities</li>
                  <li>Creator Connect UX: Redesigned collaboration interface with intuitive deal management (Ricky)</li>
                  <li>Financial Modules: Dedicated Money page for transactions and Collabs page for partnership tracking</li>
                  <li>Deal Factoring System: Advanced payment structuring and milestone tracking (Alex)</li>
                  <li>Scheduled Deliverables: Minimum 2-month campaign planning with integrated calendar</li>
                  <li>BlitzPay 2.0: Shared payment schedules, dual-party authorization, and link-based claims (Alex)</li>
                  <li>Marketplace Refresh: Simplified card layout, enhanced filters, and recommendation algorithm (Ricky/Alex)</li>
                  <li>Auth Unification: Consolidated credential system with Stripe-powered MRR tracking (Ricky)</li>
                  <li>Tiered Fee Structure: User status-based payment fees reflected in invoices and UI</li>
                  <li>Onboarding Overhaul: Single-page signup/login flows with reduced friction</li>
                </ul>

                <Typography variant="h6">Improvements</Typography>
                <ul>
                  <li>Platform Cleanup: Removed 18% inactive creators to improve deal success rates (Cody)</li>
                  <li>Educational Resources: Scalable onboarding documentation with agency background materials (Cody)</li>
                  <li>Distribution Network: Expanded partner integration through simplified signup flows</li>
                  <li>User Growth: Achieved 122/150 brand users and 202/225 creator users</li>
                </ul>

                <Typography variant="h6">Bug Fixes</Typography>
                <ul>
                  <li>Resolved payment schedule synchronization issues</li>
                  <li>Fixed marketplace card rendering inconsistencies</li>
                </ul>
              </>
            ) : section.id === 'beta-3' ? (
              <>
                <Typography variant="h6" sx={{ mt: 2 }}>New Features</Typography>
                <ul>
                  <li>Paid Campaign Tiers: Affiliate/gifting access with OpenAI-powered campaign facilitation</li>
                  <li>Talent Roster Import: CSV mapping tool for bulk creator onboarding (Snov.io-like interface)</li>
                  <li>Fan Engagement System: BuyMyBlitz numbers, SMS/WhatsApp integration, and tipping features</li>
                  <li>Hot Creator Algorithm: Weekly rotation of top 5 creators based on engagement metrics</li>
                  <li>Advanced Campaign Filters: Reach/demographic search powered by integrated API analytics</li>
                  <li>Multi-Currency Support: GBP, EUR, CNY, MXN, and CREDX blockchain rewards integration</li>
                  <li>Alito Campaign Manager: Email-driven campaign generation (CSV/Google Sheets/Blitz formats)</li>
                  <li>Creator Pitching System: Apollo API integration with CREDX-powered outreach (Alex)</li>
                  <li>Subscription Tiers: Alpha Free/Silver/Gold/Platinum levels with varying financing terms</li>
                  <li>Media Booking System: Creator inquiry workflow with automated CRM integration</li>
                </ul>

                <Typography variant="h6">Improvements</Typography>
                <ul>
                  <li>Search Page Overhaul: "Hot Creators" algorithm, recommended creators section, and PFPs display</li>
                  <li>Campaign Management: Pagination, collaboration features, and enhanced filtering categories</li>
                  <li>Creator Portal: Dedicated login (blitzpay.pro/creatorconnect) with GSuite integration</li>
                  <li>Payment Infrastructure: Stripe invoice automation and blitzpay.pro routing system</li>
                  <li>CRM Enhancements: Automated email triggers for campaign milestones and team notifications</li>
                  <li>Tax Compliance: Basic withholding calculations for US creators</li>
                  <li>Partner Integrations: 10K Projects, APG, and Concord feedback implementations</li>
                </ul>

                <Typography variant="h6">Bug Fixes</Typography>
                <ul>
                  <li>Resolved campaign duplication issues in CSV imports</li>
                  <li>Fixed currency conversion discrepancies in invoices</li>
                  <li>Patched SMS routing errors in creator conversations</li>
                </ul>
              </>
            ) : (
              <>
              </>
            )}
          </Box>
        ))}
      </Container>
    </ReleaseScaffolding>
  );
}