import React from "react";
import {
    Box,
    Card,
    CardContent,
    CardActions,
    Grid,
    TextField,
    Button,
    Typography,
    IconButton,
    CircularProgress,
    Chip,
    Divider,
    useTheme
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import { TimelineStatus } from "../../../../Utils/constants";

function formatDateToYYYYMMDD(dateTime) {
    const options = { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric', 
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone 
    };
    return new Intl.DateTimeFormat('en-US', options).format(dateTime);
}

const TimelineEntry = ({ event, onEditField }) => {
    const theme = useTheme();
    const isCompleted = event.status === 'complete';
    const isDisabled = event.ogStatus === 'complete';

    return (
        <Card 
            variant="outlined"
            sx={{
                mb: 2,
                borderLeft: `4px solid ${isCompleted ? theme.palette.success.main : theme.palette.primary.main}`,
                opacity: isDisabled ? 0.6 : 1,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: theme.shadows[2]
                }
            }}
        >
            <CardContent>
                <Typography 
                    variant="h6" 
                    gutterBottom
                    sx={{
                        textDecoration: isCompleted ? 'line-through' : 'none',
                        color: isCompleted ? theme.palette.text.secondary : 'inherit'
                    }}
                >
                    {event.objective}
                </Typography>

                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={event.notes}
                    onChange={(e) => onEditField(event.index, 'notes', e.target.value)}
                    placeholder="Add notes..."
                    multiline
                    rows={2}
                    disabled={isDisabled}
                    sx={{ mb: 2 }}
                />

                <Divider sx={{ my: 1 }} />

                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mt: 1
                }}>
                    <Chip 
                        label={formatDateToYYYYMMDD(event.projected_date)}
                        variant="outlined"
                        size="small"
                    />
                    <Typography 
                        variant="caption"
                        color={isCompleted ? 'success.main' : 'text.secondary'}
                    >
                        {isCompleted ? 'Completed' : 'Pending'}
                    </Typography>
                </Box>
            </CardContent>

            <CardActions sx={{ justifyContent: 'flex-end', pt: 0 }}>
                <Button
                    variant={isCompleted ? "contained" : "outlined"}
                    color={isCompleted ? "success" : "primary"}
                    onClick={() => onEditField(event.index, 'status', isCompleted ? TimelineStatus[1] : 'complete')}
                    disabled={isDisabled}
                    startIcon={isCompleted ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                    size="small"
                >
                    {isCompleted ? 'Completed' : 'Mark Complete'}
                </Button>
            </CardActions>
        </Card>
    );
};

const CreatorTimeline = ({ timelineEvents, onEditField, onSaveChanges, isSavingChanges }) => {
    if (!timelineEvents) return null;

    const currentTimeline = timelineEvents
        .map((event, index) => ({ ...event, index }))
        .sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date));

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                To Do List
            </Typography>

            <Grid container spacing={2}>
                {currentTimeline.map((event) => (
                    <Grid item xs={12} key={event.id}>
                        <TimelineEntry event={event} onEditField={onEditField} />
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSaveChanges}
                    disabled={isSavingChanges}
                    startIcon={isSavingChanges ? (
                        <CircularProgress size={20} />
                    ) : (
                        <SaveIcon />
                    )}
                    sx={{ minWidth: 120 }}
                >
                    {isSavingChanges ? 'Saving...' : 'Save Changes'}
                </Button>
            </Box>
        </Box>
    );
};

export default CreatorTimeline;