import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import CreatorHeader from './CreatorHeader';
import { Box, CssBaseline, IconButton, ThemeProvider, Toolbar } from '@mui/material';
import { lightTheme } from '../Utils/baseTheme';
import CreatorNavbar from './CreatorNavbar';
import useIsDesktop from '../Hooks/useIsDesktop';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import { drawerWidth } from '../Utils/constants';

const openedMixin = (theme) => ({
    minWidth: {
        xs: '100%',
        md: `calc(100% - ${drawerWidth})`,
    },
    marginInlineStart: {
        xs: 0,
        md: drawerWidth,
    },
    transition: theme.transitions.create(['min-width', 'margin-inline-start'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create(['min-width', 'margin-inline-start'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    minWidth: {
        xs: '100%',
        md: `calc(100% - ${theme.spacing(8)} + 1px)`,
    },
    marginInlineStart: {
        xs: 0,
        md: `calc(${theme.spacing(8)} - 1px)`,
    },
});

const CreatorConnectRoute = () => {
    const isDesktop = useIsDesktop();
    const [isOpen, setIsOpen] = useState(false);
    
    const isExtended = (!isDesktop || isOpen);

    const MenuButton = (
        <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={e=>setIsOpen(prev=>!prev)}
        >
            {isDesktop ? (isOpen ? (<KeyboardArrowLeftSharpIcon/>): (<KeyboardArrowRightSharpIcon/>)): (<MenuIcon />)}
        </IconButton>
    )
    return (
        <ThemeProvider theme={lightTheme}>
            <CreatorNavbar isDesktop={isDesktop} isOpen={isOpen} onClose={e=>setIsOpen(false)}></CreatorNavbar>
            <CssBaseline></CssBaseline>
            <CreatorHeader menuIcon={MenuButton}>
            </CreatorHeader>
            <Toolbar />
            <Box
                id='TopPageContainer'
                sx={theme=>({
                    ...(isExtended ? openedMixin(theme) : closedMixin(theme)),
                })}
            >
                <Outlet></Outlet>
            </Box>
        </ThemeProvider>
    );
};

export default CreatorConnectRoute;
