import React from 'react'
import { docsDrawerWidth, drawerWidth } from '../../Utils/constants'
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, ListSubheader, Toolbar } from '@mui/material'
import NavbarLink from '../../Components/NavbarLink'
import routes from '../../Config/routes'

export default function DocsNavbar({isDesktop, isOpen, onClose}) {

    return (
        <Drawer
            variant={isDesktop ? "permanent" : "temporary"}
            open={isDesktop || isOpen}
            onClose={onClose}
            sx={{
                position: 'relative',
                width: docsDrawerWidth,
                flexShrink: 0,
                zIndex: (theme)=>isDesktop ? theme.zIndex.appBar-1 : theme.zIndex.drawer,
                [`& .MuiDrawer-paper`]: { width: docsDrawerWidth, boxSizing: 'border-box' },
            }}>
            {isDesktop&&<Toolbar />}
            <Box sx={{ overflow: 'auto' }}>
                <List>
                    <ListSubheader>Getting Started</ListSubheader>
                    <NavbarLink primary={'Introduction'} to={routes.docsHome}></NavbarLink>
                    <Divider></Divider>
                    <NavbarLink primary={'1) Services'} to={routes.docsService}></NavbarLink>
                    <NavbarLink primary={'2) API Keys'} to={routes.docsKeys}></NavbarLink>
                    <NavbarLink primary={'3) Login with Blitz'} to={routes.docsOauth}></NavbarLink>
                    <ListSubheader>Endpoints</ListSubheader>
                    <Divider></Divider>
                    <NavbarLink primary={'Basic API Usage'} to={routes.docsEndpoints}></NavbarLink>
                    <NavbarLink primary={'Creators'} to={routes.docsEndCreators}></NavbarLink>
                </List>
            </Box>
        </Drawer>
    )
}
