import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  Avatar,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  PictureAsPdf,
  Description,
  Image as ImageIcon,
  InsertDriveFile,
} from "@mui/icons-material";
import UploadFileDialog from "./uploadfile";

const renderFileIcon = (fileType, url) => {
  const iconStyle = { fontSize: 40 };

  // Safely check if fileType exists before using startsWith
  if (fileType && fileType.startsWith("image/")) {
    return (
      <Avatar
        variant="rounded"
        sx={{ width: 80, height: 80, bgcolor: "transparent" }}
        src={url}
      >
        <ImageIcon style={iconStyle} color="primary" />
      </Avatar>
    );
  }

  const iconProps = {
    fontSize: "large",
    style: iconStyle,
    color: {
      "application/pdf": "error",
      "text/plain": "success",
    }[fileType] || "action",
  };

  return {
    "application/pdf": <PictureAsPdf {...iconProps} />,
    "text/plain": <Description {...iconProps} />,
  }[fileType] || <InsertDriveFile {...iconProps} />;
};

const SelectAssetDialog = ({ open, onClose, files, selectedAsset, onAssetClick, refetchFiles }) => {
  const theme = useTheme();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const handleUploadDialogClose = () => {
    setUploadDialogOpen(false);
    refetchFiles();
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: theme.palette.primary.main, color: "white" }}>
        Select an Asset
        <Chip 
          label={`${files?.length || 0} items`} 
          size="small"
          sx={{ ml: 2, color: "white", bgcolor: theme.palette.primary.dark }}
        />
      </DialogTitle>
      
      <DialogContent sx={{ p: 3, bgcolor: "#f5f5f5" }}>
        <Grid container spacing={3}>
          {files?.map((file) => (
            <Grid item xs={12} sm={6} md={4} key={file.id}>
              <Card
                onClick={() => onAssetClick(file)}
                sx={{
                  cursor: "pointer",
                  border: selectedAsset?.id === file.id 
                    ? `2px solid ${theme.palette.primary.main}`
                    : "none",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: theme.shadows[4],
                  },
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  gap: 2
                }}>
                  {renderFileIcon(file?.type, file?.url)}
                  <Typography 
                    variant="subtitle1" 
                    sx={{
                      fontWeight: 600,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical"
                    }}
                  >
                    {file.filename}
                  </Typography>
                  <Chip
                    label={(file?.type?.split("/")[1] || 'file').toUpperCase()}
                    size="small"
                    color="default"
                    variant="outlined"
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 3, gap: 2 }}>
        <Button 
          variant="contained"
          onClick={() => setUploadDialogOpen(true)}
          sx={{ mr: "auto" }}
        >
          Upload New File
        </Button>
        <Button 
          variant="outlined" 
          onClick={() => onClose(false)}
          color="secondary"
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          onClick={() => onClose(true)}
          color="primary"
        >
          Confirm Selection
        </Button>
      </DialogActions>

      <UploadFileDialog open={uploadDialogOpen} onClose={handleUploadDialogClose} />
    </Dialog>
  );
};

export default SelectAssetDialog;