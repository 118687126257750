import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  IconButton,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../../Hooks/creator-use-auth';
import { useNavigate } from 'react-router-dom';
import API from '../../API';

const CreatorDeals = () => {
  const { creatorToken } = useCreatorAuth();
  const token = creatorToken?.token;
  const navigate = useNavigate();

  const fetchDeals = async () => {
    if (!token) return [];
    try {
      const response = await API.dealsIO.creatorFetch();
      console.log("Full API Response:", response);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching deals:", error);
      return [];
    }
  };

  const { isLoading, error, data: deals = [], refetch: refetchDeals } = useQuery(
    ['deals', 'creator'],
    fetchDeals,
    {
      initialData: [],
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    refetchDeals();
  }, [refetchDeals]);

  if (isLoading) return <CircularProgress sx={{ color: 'white' }} />;
  if (error) {
    const errorMessage = error?.response?.data?.error || error.message;
    return <Typography sx={{ color: 'error' }}>Error fetching deals: {errorMessage}</Typography>;
  }

  const handleClickDeal = (dealId) => {
    console.log(`Navigating to deal ${dealId}`);
    navigate(`/creatorconnect/deals/${dealId}`);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ p: 2, marginBlock: 1 }}>
        Your Deals
      </Typography>
      <Grid container spacing={3} sx={{ paddingInline: { xs: 1, md: 4 } }}>
        {deals.length > 0 ? (
          deals.map((deal) => (
            <Grid item xs={12} sm={6} md={4} key={deal.id}>
              <Card>
                <CardHeader
                  title={deal.name || 'Unnamed Deal'}
                  subheader={`Amount Due: $${deal.amount_due}`}
                  titleTypographyProps={{ sx: { textTransform: 'capitalize' } }}
                />
                <CardContent>
                  <Typography variant="body2">Amount Paid: ${deal.amount_paid}</Typography>
                  <Typography variant="body2">Months: {deal.amount_of_months}</Typography>
                  <Typography variant="body2">Vendor: {deal.creator_vendor_id}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickDeal(deal.id)}
                  >
                    Manage Deal
                  </Button>
                  <IconButton>
                    <ShareIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography align="center">No deals available</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CreatorDeals;
