import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Container,
  Toolbar,
  Fade, // Imported from MUI
  Divider
} from "@mui/material";
import routes from "../../../Config/routes";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";

// Example images (replace with actual assets)
import bgPattern from "../../../Components/globalAssets/blitzLogo.png";
import aiImage from "../../../Components/globalAssets/flash.gif";
import marketplaceImage from "../../../Components/globalAssets/ppfLogo.png";
import businessSuiteImage from "../../../Components/globalAssets/blitzBoltBlack.png";
import moneyGrowthImage from "../../../Components/globalAssets/logo_placeholder.png";
import fastPaymentImage from "../../../Components/globalAssets/giphy.gif";

const ForCreators = () => {
  const navigate = useNavigate();
  const aiSalesManager = useRef(null);
  const getPaidFaster = useRef(null);
  const manageDeals = useRef(null);
  const businessSuite = useRef(null);

  useEffect(() => {
    if (location.hash) {
      const targetId = location.hash.substring(1);
      scrollToSection(targetId);
    }
  }, [location.hash]);

  const scrollToSection = (sectionId) => {
    switch (sectionId) {
      case "aiSalesManager":
        aiSalesManager.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "getPaidFaster":
        getPaidFaster.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "manageDeals":
        manageDeals.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "businessSuite":
        businessSuite.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const handleAccess = () => navigate(`${routes.creatorConnectOnboard}`);

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        color: "#000",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Background Pattern */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: `url(${bgPattern}) no-repeat center center/cover`,
          opacity: 0.05,
          zIndex: -1,
        }}
      />

      <HomeHeader />
      <Toolbar />

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: { xs: 4, md: 10 },
          paddingBottom: { xs: 4, md: 10 },
        }}
      >
        <Fade in={true} timeout={1000}>
          <Box sx={{ mb: { xs: 6, md: 8 }, textAlign: "center" }}>
            <Typography variant="h2" sx={{ fontWeight: "bold", mb: 2 }}>
              Why Creators Choose Blitz
            </Typography>
            <Typography
              variant="h6"
              sx={{ maxWidth: "700px", margin: "auto", lineHeight: 1.7 }}
            >
              You’re building your brand, producing engaging content, and
              inspiring audiences worldwide. But behind the scenes, managing
              your deals, payments, and growth strategy can be exhausting. Blitz
              is here to revolutionize how you run your creative business—so you
              can focus on what you do best: creating.
            </Typography>
          </Box>
        </Fade>

        <Divider sx={{ mb: { xs: 6, md: 8 } }} />

        <Fade in={true} timeout={1000}>
          <Box
            ref={aiSalesManager}
            sx={{
              mb: { xs: 6, md: 8 },
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: 4,
            }}
          >
            <Box flex={1}>
              <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                All-in-One AI Sales Manager
              </Typography>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.7 }}>
                Imagine having a 24/7 digital agent scouting for top-tier brand
                deals on your behalf—without expensive manager fees. Blitz’s AI
                Sales Manager identifies lucrative opportunities, automates
                negotiations, and streamlines communication. Spend less time
                hustling for deals and more time doing what inspires you.
              </Typography>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.7 }}>
                <strong>Key Highlights:</strong>
                <ul style={{ listStyle: "disc", marginLeft: "1.5rem" }}>
                  <li>
                    Smart Brand Matching: Get paired with companies that fit
                    your niche and creative style.
                  </li>
                  <li>
                    Automated Deal Pipeline: Let AI handle back-and-forth
                    negotiations and secure better terms.
                  </li>
                  <li>
                    Time-Saving Efficiency: Free yourself from tedious admin and
                    scale your revenue effortlessly.
                  </li>
                </ul>
              </Typography>
            </Box>
            <Box flex={1} sx={{ textAlign: "center" }}>
              <img
                src={aiImage}
                alt="AI Manager"
                style={{
                  width: "100%",
                  borderRadius: "16px",
                  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          </Box>
        </Fade>

        <Divider sx={{ mb: { xs: 6, md: 8 } }} />

        <Fade in={true} timeout={1000}>
          <Box
            ref={manageDeals}
            sx={{
              mb: { xs: 6, md: 8 },
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row-reverse" },
              alignItems: "center",
              gap: 4,
            }}
          >
            <Box flex={1}>
              <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                Access to the World's Leading Brands
              </Typography>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.7 }}>
                Step into a global ecosystem where top-tier companies actively
                seek fresh creative talent. With Blitz, you’re not just another
                profile on a platform—you’re part of a curated marketplace where
                brands discover, invest in, and celebrate creators.
              </Typography>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.7 }}>
                <strong>Expand Your Reach:</strong>
                <ul style={{ listStyle: "disc", marginLeft: "1.5rem" }}>
                  <li>
                    Global Visibility: Attract clients from all corners of the
                    world, without leaving your desk.
                  </li>
                  <li>
                    Brand Credibility: Collaborate with household names to
                    elevate your portfolio and audience trust.
                  </li>
                  <li>
                    Seamless Onboarding: Instantly sign digital contracts, track
                    project milestones, and get paid—all under one roof.
                  </li>
                </ul>
              </Typography>
            </Box>
            <Box flex={1} sx={{ textAlign: "center" }}>
              <img
                src={marketplaceImage}
                alt="Marketplace"
                style={{
                  width: "100%",
                  borderRadius: "16px",
                  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          </Box>
        </Fade>

        <Divider sx={{ mb: { xs: 6, md: 8 } }} />

        <Fade in={true} timeout={1000}>
          <Box
            ref={businessSuite}
            sx={{
              mb: { xs: 6, md: 8 },
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: 4,
            }}
          >
            <Box flex={1}>
              <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                Personalized Business Management Suite
              </Typography>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.7 }}>
                Running a creative business involves more than content creation.
                Blitz offers an end-to-end business suite tailored for
                creators—manage your contracts, handle legal paperwork, optimize
                taxes, and even explore investment opportunities, all in one
                place.
              </Typography>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.7 }}>
                <strong>Take Control:</strong>
                <ul style={{ listStyle: "disc", marginLeft: "1.5rem" }}>
                  <li>
                    Contracts & Legal: Access ready-to-use agreements designed
                    specifically for creators.
                  </li>
                  <li>
                    Financial Services: Simplify payments, taxes, and
                    investments with expert guidance.
                  </li>
                  <li>
                    Scalability: Build a sustainable business model that grows
                    with your ambitions.
                  </li>
                </ul>
              </Typography>
            </Box>
            <Box flex={1} sx={{ textAlign: "center" }}>
              <img
                src={businessSuiteImage}
                alt="Business Suite"
                style={{
                  width: "100%",
                  borderRadius: "16px",
                  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          </Box>
        </Fade>

        <Divider sx={{ mb: { xs: 6, md: 8 } }} />

        <Fade in={true} timeout={1000}>
          <Box
            sx={{
              mb: { xs: 6, md: 8 },
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row-reverse" },
              alignItems: "center",
              gap: 4,
            }}
          >
            <Box flex={1}>
              <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                Increase Your Revenue by 2-3x
              </Typography>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.7 }}>
                Blitz provides the tools, insights, and opportunities to
                supercharge your earnings. By cutting out inefficiencies and
                streamlining your deal flow, creators using Blitz often see
                their revenue climb 2-3x within months.
              </Typography>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.7 }}>
                <strong>Maximize Your Potential:</strong>
                <ul style={{ listStyle: "disc", marginLeft: "1.5rem" }}>
                  <li>
                    Better Deals: Command higher rates with data-backed insights
                    and negotiations.
                  </li>
                  <li>
                    Diverse Income Streams: Unlock new revenue channels beyond
                    sponsorships—events, merch, and more.
                  </li>
                  <li>
                    Consistent Growth: Turn one-off partnerships into long-term
                    business relationships.
                  </li>
                </ul>
              </Typography>
            </Box>
            <Box flex={1} sx={{ textAlign: "center" }}>
              <img
                src={moneyGrowthImage}
                alt="Financial Growth"
                style={{
                  width: "100%",
                  borderRadius: "16px",
                  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          </Box>
        </Fade>

        <Divider sx={{ mb: { xs: 6, md: 8 } }} />

        <Fade in={true} timeout={1000}>
          <Box
            ref={getPaidFaster}
            sx={{
              mb: { xs: 6, md: 8 },
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: 4,
            }}
          >
            <Box flex={1}>
              <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                Get Paid Faster with BlitzPay
              </Typography>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.7 }}>
                Tired of waiting weeks or even months for payments? BlitzPay
                ensures you receive funds quickly and transparently. Whether
                it's instant payouts or flexible net terms, we empower you to
                manage your income on your terms.
              </Typography>
              <Typography variant="body1" paragraph sx={{ lineHeight: 1.7 }}>
                <strong>Simplified Cash Flow:</strong>
                <ul style={{ listStyle: "disc", marginLeft: "1.5rem" }}>
                  <li>Instant Payouts: Get your earnings in minutes, not weeks.</li>
                  <li>
                    Flexible Terms: Choose net 30 or net 60 if it suits your
                    strategy—at no extra cost.
                  </li>
                  <li>
                    Global Reach: Pay and get paid from anywhere, in any
                    currency, effortlessly.
                  </li>
                </ul>
              </Typography>
            </Box>
            <Box flex={1} sx={{ textAlign: "center" }}>
              <img
                src={fastPaymentImage}
                alt="Fast Payments"
                style={{
                  width: "100%",
                  borderRadius: "16px",
                  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          </Box>
        </Fade>

        <Divider sx={{ mb: { xs: 6, md: 8 } }} />

        <Fade in={true} timeout={1000}>
          <Box sx={{ mb: { xs: 6, md: 8 }, textAlign: "center" }}>
            <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
              Discover the Power of Blitz
            </Typography>
            <Typography
              variant="h6"
              sx={{ maxWidth: "600px", margin: "auto", lineHeight: 1.7, mb: 3 }}
            >
              The future of creator empowerment is here. Join the Blitz movement
              and reclaim your time, multiply your earnings, and forge lasting
              partnerships that elevate your brand. Don’t just dream of
              success—create it, with Blitz.
            </Typography>
            <Button
              variant="contained"
              sx={{
                fontSize: "1.1rem",
                textTransform: "none",
                backgroundColor: "#7b2a2a",
                color: "#fff",
                py: 1.5,
                px: 4,
                "&:hover": {
                  backgroundColor: "#5a1e1e",
                  transform: "scale(1.05)",
                },
                transition: "all 0.3s ease",
              }}
              onClick={handleAccess}
            >
              Request Your Free Early Access
            </Button>
          </Box>
        </Fade>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default ForCreators;
