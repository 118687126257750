import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Toolbar,
  Typography,
  Switch,
  FormControlLabel,
  Card,
  CircularProgress,
  Backdrop,
  ThemeProvider,
  Button,
} from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { keyframes } from "@emotion/react";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import HomeHeader from "../../Components/HomeHeader";
import HomeFooter from "../../Components/HomeFooter";
import AlertDialog from "../../Components/AlertDialog.js";
import useAlertDialog from "../../Components/useAlertDialog.js";
import ConsentForm from "./popups/consentform.js";
import API from "../../API/index.js";
import GoogleButton from "../../Components/GoogleButton.js";
import { lightTheme } from "../../Utils/baseTheme.js";

const CreatorConnect = () => {
  const navigate = useNavigate();
  const { creator } = useParams();
  const location = useLocation();
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  // ----- NEW STATES FOR VERIFICATION -----
  const [isVerifying, setIsVerifying] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [managerName, setManagerName] = useState(null);

  const [creatorName, setCreatorName] = useState(
    sessionStorage.getItem("creatorName") || creator || ""
  );
  const [paypalConnected, setPaypalConnected] = useState(false);
  const [blitzPayConnected, setBlitzPayConnected] = useState(false);
  const [paymentEmail, setPaymentEmail] = useState("");
  const [stripeUserId, setStripeUserId] = useState(
    sessionStorage.getItem("stripeUserId")
  );
  const [isPartner, setIsPartner] = useState(false);
  const [consentForm, setConsentForm] = useState(false);
  const [googleRequest, setGoogleRequest] = useState("");
  const [loading, setLoading] = useState(false);

  const scope = [
    "https://www.googleapis.com/auth/gmail.modify",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/userinfo.email",
  ];

  const scopeString = scope.reduce(
    (accum, currSco, currIdx) => accum + (currIdx === 0 ? "" : " ") + currSco,
    ""
  );

  // ------------------------------------------------------------------------
  // 1) On mount, verify if creator is already in our DB
  // ------------------------------------------------------------------------
  useEffect(() => {
    if (creatorName) {
      verifyCreatorRegistration(creatorName);
    }
  }, [creatorName]);

  const verifyCreatorRegistration = async (name) => {
    if (!name) return;
    try {
      setIsVerifying(true);
      const response = await API.creatorConnect.verify(name);
      setAlreadyRegistered(response.data.registered ?? false);
      setManagerName(response.data.manager ?? null);
    } catch (err) {
      console.error("Error verifying creator: ", err);
    } finally {
      setIsVerifying(false);
    }
  };

  // ------------------------------------------------------------------------
  // Original Stripe flow
  // ------------------------------------------------------------------------
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const stripeCodeFromUrl = queryParams.get("code");
    if (stripeCodeFromUrl) {
      setStripeUserId(stripeCodeFromUrl);
      setBlitzPayConnected(true);
      sessionStorage.setItem("stripeUserId", stripeCodeFromUrl);
    }
  }, [location.search]);

  const handlePaypalSwitch = (event) => {
    setPaypalConnected(event.target.checked);
  };

  const handleBlitzPaySwitch = (event) => {
    if (event.target.checked) {
      const redirectUri = `https://www.useblitz.co/creatorconnect/redirect`;
      window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${encodeURIComponent(
        redirectUri
      )}`;
    } else {
      setBlitzPayConnected(false);
      setStripeUserId(null);
      sessionStorage.removeItem("stripeUserId");
    }
  };

  const handlePartnerSwitch = (e) => {
    if (e.target.checked) {
      setConsentForm(true);
    } else {
      setIsPartner(false);
    }
  };

  const handleConsentClose = (consented) => {
    setConsentForm(false);
    setIsPartner(consented);
  };

  const googleLogin = useGoogleLogin({
    onError: (errorResponse) => {
      openDialog(
        "Error",
        `Error getting Partner permissions: ${errorResponse}`,
        closeDialog,
        closeDialog,
        "Ok",
        null
      );
      setLoading(false);
    },
    scope: scopeString,
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: "https://www.useblitz.co/creatorconnect/redirect",
    state: googleRequest,
  });

  useEffect(() => {
    if (googleRequest && isPartner) {
      googleLogin();
    }
  }, [googleRequest, googleLogin, isPartner]);

  const handleCredentialResponse = (response) => {
    handleConnectSubmit(response.credential);
  };

  const handleLoginFailure = (error) => {
    console.error("Failed to login with Google:", error);
    setLoading(false);
    openDialog(
      "Error",
      `Google login failed, please try again.`,
      closeDialog,
      closeDialog,
      "Ok",
      null
    );
  };

  // ------------------------------------------------------------------------
  // 2) Submit form data
  // ------------------------------------------------------------------------
  const handleConnectSubmit = async (googleToken) => {
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }

    if (!paypalConnected && !blitzPayConnected) {
      openDialog(
        "Error",
        "Please connect at least one payment method.",
        closeDialog,
        closeDialog,
        "Ok",
        null
      );
      return;
    }

    setLoading(true);

    const payload = {
      username: creatorName,
      payout_preferred: paypalConnected
        ? "PayPal"
        : blitzPayConnected
        ? "Stripe"
        : null,
      email: paymentEmail,
      stripe_id: stripeUserId,
      google_token: googleToken,
      status: isPartner ? "Partner" : "Associate",
    };

    try {
      const data = await API.creatorConnect.connect(payload);
      if (isPartner) {
        // If isPartner, do the Google OAuth flow next
        setGoogleRequest(data.request_id);
        return;
      }
      openDialog(
        "Success",
        "You have successfully connected your account.",
        () => navigate("/start"),
        () => navigate("/start"),
        "Ok",
        null
      );
    } catch (error) {
      console.error("Error during form submission:", error);
      openDialog(
        "Error",
        `There was an error: ${error.response?.data?.error || error.message}`,
        closeDialog,
        closeDialog,
        "Ok",
        null
      );
      setLoading(false);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    await handleConnectSubmit(null);
  };

  // ------------------------------------------------------------------------
  // Some fancy gradient border styling
  // ------------------------------------------------------------------------
  const moveGradient = keyframes`
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  `;

  const rainbowBorder = {
    position: "relative",
    padding: "1em",
    marginBlock: 2,
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    overflow: "hidden",
    zIndex: 0,
    "&::before": {
      content: '""',
      position: "absolute",
      top: "-2px",
      left: "-2px",
      right: "-2px",
      bottom: "-2px",
      background:
        "linear-gradient(90deg, red, orange, yellow, green, cyan, blue, violet)",
      zIndex: -2,
      animation: `${moveGradient} 3s linear infinite`,
      backgroundSize: "200% 200%",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "4px",
      left: "4px",
      right: "4px",
      bottom: "4px",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      zIndex: -1,
    },
  };

  // ------------------------------------------------------------------------
  // RENDER
  // ------------------------------------------------------------------------
  // If we're verifying, just show a spinner
  if (isVerifying) {
    return (
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // If user is already registered, display a short message and disable the form
  const formIsDisabled = alreadyRegistered;

  return (
    <>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ThemeProvider theme={lightTheme}>
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            color: "text.primary",
          }}
        >
          <HomeHeader />
          <Toolbar />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ paddingInline: 2, maxWidth: "md", width: "100%" }}>
              {alreadyRegistered && (
                <Box
                  sx={{
                    p: 2,
                    mb: 3,
                    backgroundColor: "error.light",
                    color: "white",
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="h6" color="white">
                    This Creator User is already verified. If this is a mistake,
                    contact the Blitz team.
                  </Typography>
                </Box>
              )}
              <form
                id="creatorForm"
                onSubmit={handleFormSubmit}
                ref={formRef}
                noValidate
              >
                <Box
                  sx={{
                    marginInline: "auto",
                    marginBlockStart: 4,
                    marginBlockEnd: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    maxWidth: "30em",
                  }}
                >
                  <Box sx={{ marginBlockEnd: 1, textAlign: "center" }}>
                    <Typography variant="h3" color="textPrimary">
                      Creator Connect Form
                    </Typography>
                  </Box>
                  <TextField
                    label="Creator Name"
                    name="creator-name"
                    required
                    disabled
                    value={creatorName}
                    onChange={(e) => setCreatorName(e.target.value)}
                    inputRef={inputRef}
                    InputLabelProps={{ style: { color: "black" } }}
                    InputProps={{ style: { color: "black" } }}
                  />
                  <FormControlLabel
                    disabled={formIsDisabled}
                    control={
                      <Switch
                        checked={paypalConnected}
                        onChange={handlePaypalSwitch}
                        color="primary"
                      />
                    }
                    label="Connect PayPal"
                    sx={{ color: "black" }}
                  />
                  {paypalConnected && !formIsDisabled && (
                    <TextField
                      label="PayPal Email"
                      fullWidth
                      required
                      value={paymentEmail}
                      onChange={(e) => setPaymentEmail(e.target.value)}
                      InputLabelProps={{ style: { color: "black" } }}
                      InputProps={{ style: { color: "black" } }}
                    />
                  )}
                  <FormControlLabel
                    disabled={formIsDisabled}
                    control={
                      <Switch
                        checked={blitzPayConnected}
                        onChange={handleBlitzPaySwitch}
                        color="primary"
                      />
                    }
                    label="Connect Stripe"
                    sx={{ color: "black" }}
                  />
                  {blitzPayConnected && !formIsDisabled && (
                    <TextField
                      label="Stripe User ID"
                      fullWidth
                      value={stripeUserId}
                      disabled
                      InputLabelProps={{ style: { color: "black" } }}
                      InputProps={{ style: { color: "black" } }}
                    />
                  )}
                  <Card elevation={2} sx={{ ...rainbowBorder }}>
                    <Box sx={{ position: "relative", zIndex: 2 }}>
                      <FormControlLabel
                        disabled={formIsDisabled}
                        sx={{ marginInlineStart: 0, color: "black" }}
                        labelPlacement="start"
                        checked={isPartner}
                        onChange={handlePartnerSwitch}
                        label="Enable pitch feature, AI inbound assistance (can be toggled off later), lower Blitz instant pay fee, and high priority on marketplace here."
                        control={<Switch color="primary" />}
                      />
                    </Box>
                  </Card>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ mt: -1, ml: 2 }}
                  >
                    Creators who enable this feature get 2-5x more deals
                  </Typography>

                  {/* GoogleLogin or GoogleButton only if not disabled */}
                  {!isPartner && !formIsDisabled && (
                    <Box sx={{ alignSelf: "flex-end" }}>
                      <GoogleLogin
                        size="large"
                        onSuccess={handleCredentialResponse}
                        onError={handleLoginFailure}
                      />
                    </Box>
                  )}
                  {isPartner && !formIsDisabled && (
                    <Box sx={{ alignSelf: "flex-end" }}>
                      <GoogleButton type="submit" />
                    </Box>
                  )}

                  {/* Hide the submit button if already registered */}
                  {!formIsDisabled && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button variant="contained" type="submit" color="primary">
                        Submit
                      </Button>
                    </Box>
                  )}
                </Box>
              </form>
            </Box>
          </Box>
          <HomeFooter />
        </Box>
      </ThemeProvider>
      <ConsentForm open={consentForm} onClose={handleConsentClose} />
    </>
  );
};

export default CreatorConnect;
