import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Paper,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
  Tooltip,
  Tabs,
  Tab,
  Badge,
  List,
  ListItem,
  Alert,
  AlertTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";

import API from "../../../API";
import AlertDialog from "../../../Components/AlertDialog";
import useAlertDialog from "../../../Components/useAlertDialog";
import ConsentForm from "../popups/consentform";
import UploadFileDialogPFP from "../../onboardupload";
import TabPanelHidden from "../../../Components/TabPanelHidden";
import useIsDesktop from "../../../Hooks/useIsDesktop";

import {
  platformSelection,
  validatePlatformSelection,
} from "../../../Components/CreatorData/creatorValidation";
import {
  parseLocalFloat,
  parseLocalInt,
} from "../../../Utils/constants";

// -- Import the newly created child tab components
import PlatformsTab from "./usersettings/PlatformsTab"
import PaymentTab from "./usersettings/PaymentTab";
import ProfileTab from "./usersettings/ProfileTab";

// --------------------------------------
// Parent Component: EditCreator
// --------------------------------------
const EditCreator = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabValue = searchParams.get("tab") || "platforms";
  const isDesktop = useIsDesktop();

  // Dialog & UI hooks
  const { dialogState, openDialog, closeDialog } = useAlertDialog();
  const [isLoading, setIsLoading] = useState(false);
  const [showPlatformsTip, setShowPlatformsTip] = useState(false);

  // Payment method states
  const [paymentMethod, setPaymentMethod] = useState("PayPal");
  const [isStripeNew, setIsStripeNew] = useState(true);
  const [stripeUserId, setStripeUserId] = useState("");
  const [processEmails, setProcessEmails] = useState(true);
  const [paymentEmail, setPaymentEmail] = useState("");

  // Google & Consent
  const [userStatus, setUserStatus] = useState("");
  const [openConsent, setOpenConsent] = useState(false);
  const [googleRequest, setGoogleRequest] = useState("");

  // Creator data
  const [completedInfo, setCompletedInfo] = useState(true);
  const [creatorName, setCreatorName] = useState("");
  const [creatorRegion, setCreatorRegion] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [race, setRace] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [primaryMarket, setPrimaryMarket] = useState("");
  const [notesContentStyle, setNotesContentStyle] = useState("");
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");
  const [validationError, setValidationError] = useState("");

  // Platforms
  const [platforms, setPlatforms] = useState(platformSelection);
  // Instagram
  const [instagramLink, setInstagramLink] = useState("");
  const [instagramFollowerCount, setInstagramFollowerCount] = useState("");
  const [instagramBrandRate, setInstagramBrandRate] = useState("");
  const [instagramSongRate, setInstagramSongRate] = useState("");
  const [instagramFeedRate, setInstagramFeedRate] = useState("");
  const [instagramStoryRate, setInstagramStoryRate] = useState("");
  // TikTok
  const [tikTokLink, setTikTokLink] = useState("");
  const [tikTokFollowerCount, setTikTokFollowerCount] = useState("");
  const [tikTokBrandRate, setTikTokBrandRate] = useState("");
  const [tikTokSongRate, setTikTokSongRate] = useState("");
  // YouTube
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeFollowerCount, setYoutubeFollowerCount] = useState("");
  const [youtubeIntegrationRate, setYoutubeIntegrationRate] = useState("");
  const [youtubeShortsRate, setYoutubeShortsRate] = useState("");
  const [youtubeDedicatedRate, setYoutubeDedicatedRate] = useState("");
  // X
  const [xLink, setXLink] = useState("");
  const [xFollowerCount, setXFollowerCount] = useState("");
  const [xFeedPost, setXFeedPost] = useState("");
  const [xRepost, setXRepost] = useState("");
  // Podcast
  const [podcastLink, setPodcastLink] = useState("");
  const [podcastFollowerCount, setPodcastFollowerCount] = useState("");
  const [podcastPreroll, setPodcastPreroll] = useState("");
  const [podcastMidroll, setPodcastMidroll] = useState("");
  const [podcastPostroll, setPodcastPostroll] = useState("");
  const [podcastFull, setPodcastFull] = useState("");
  // Kick
  const [kickLink, setKickLink] = useState("");
  const [kickFollowerCount, setKickFollowerCount] = useState("");
  const [kickPlacement, setKickPlacement] = useState("");
  // Twitch
  const [twitchLink, setTwitchLink] = useState("");
  const [twitchFollowerCount, setTwitchFollowerCount] = useState("");
  const [twitchPlacement, setTwitchPlacement] = useState("");

  // File upload dialog
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  // For retrieving user data from backend
  const userData = useRef(null);

  // Google OAuth
  const scope = [
    "https://www.googleapis.com/auth/gmail.modify",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/userinfo.email",
  ];
  const scopeString = scope.join(" ");
  const googleLogin = useGoogleLogin({
    onError: (errorResponse) => {
      openDialog(
        "Error",
        `Error getting Partner permissions: ${errorResponse}`,
        closeDialog,
        closeDialog,
        "Ok",
        null
      );
    },
    scope: scopeString,
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: "https://www.useblitz.co/creatorconnect/redirect",
    state: googleRequest,
  });

  useEffect(() => {
    if (googleRequest && userStatus === "Partner") {
      googleLogin();
    }
  }, [googleRequest, userStatus, googleLogin]);

  // Check if user passed "platforms" highlight
  const high = searchParams.get("high");
  useEffect(() => {
    if (high === "platforms") {
      setShowPlatformsTip(true);
      setSearchParams((params) => ({ ...params, tab: "platforms" }));

      const timer = setTimeout(() => {
        setShowPlatformsTip(false);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [high, setSearchParams]);

  // --------------------------------------
  // 1) FETCH CREATOR DATA
  // --------------------------------------
  async function GetUserData() {
    try {
      setIsLoading(true);
      const response = await API.creatorConnect.getCreator();
      const user = response.user;
      userData.current = user;
      setCompletedInfo(user.completed_info);

      const creator = user.creator;

      // Basic info
      setContactEmail(creator.email);
      setPaymentEmail(user.paypal || "");
      setPhoneNumber(creator.phone_number || "1");
      setCreatorName(creator.creator);
      setCreatorRegion(creator.region || "");
      setPrimaryMarket(creator.primary_market || "");
      setNotesContentStyle(creator.notes_content_style || "");
      setRace(creator.ethnicity || "");
      setGender(creator.gender || "");
      setLocation(creator.geolocation || "");
      setProfilePhotoUrl(creator.pfphref);

      // Social platforms
      setPlatforms({
        TikTok: !!creator.tiktok_link,
        Instagram: !!creator.instagram_link,
        YouTube: !!creator.youtube_link,
        X: !!creator.x_link,
        Podcast: !!creator.podcast_link,
        Kick: !!creator.kick_link,
        Twitch: !!creator.twitch_link,
      });

      // Payment info
      setUserStatus(
        creator.status
          ? creator.status
          : user.refresh_token
          ? "Partner"
          : "Associate"
      );
      setPaymentMethod(user.payout_preferred || "");
      setProcessEmails(user.process_emails);
      setIsStripeNew(!user.stripe_id);
      setStripeUserId(user.stripe_id || "");

      // Instagram
      setInstagramLink(
        creator.instagram_link
          ? creator.instagram_link.replace("https://www.instagram.com/", "")
          : ""
      );
      setInstagramFollowerCount(formatNumber(creator.instagram));
      setInstagramBrandRate(creator.rate_ig_reelbrand?.toFixed(2) || "");
      setInstagramSongRate(creator.rate_ig_reelsound?.toFixed(2) || "");
      setInstagramFeedRate(creator.rate_ig_feedpost?.toFixed(2) || "");
      setInstagramStoryRate(creator.rate_ig_story?.toFixed(2) || "");

      // TikTok
      setTikTokLink(
        creator.tiktok_link
          ? creator.tiktok_link.replace("https://www.tiktok.com/@", "")
          : ""
      );
      setTikTokFollowerCount(formatNumber(creator.tiktok));
      setTikTokBrandRate(creator.rate_tt_brand?.toFixed(2) || "");
      setTikTokSongRate(creator.rate_tt_sound?.toFixed(2) || "");

      // YouTube
      setYoutubeLink(
        creator.youtube_link
          ? creator.youtube_link.replace("https://www.youtube.com/", "")
          : ""
      );
      setYoutubeFollowerCount(formatNumber(creator.youtube));
      setYoutubeIntegrationRate(creator.rate_yt_integ?.toFixed(2) || "");
      setYoutubeShortsRate(creator.rate_yt_short?.toFixed(2) || "");
      setYoutubeDedicatedRate(creator.rate_yt_dedicated?.toFixed(2) || "");

      // X
      setXLink(
        creator.x_link ? creator.x_link.replace("https://www.x.com/", "") : ""
      );
      setXFollowerCount(formatNumber(creator.follow_x));
      setXFeedPost(creator.rate_x_feedpost?.toFixed(2) || "");
      setXRepost(creator.rate_x_repost?.toFixed(2) || "");

      // Kick
      setKickLink(
        creator.kick_link
          ? creator.kick_link.replace("https://www.kick.com/", "")
          : ""
      );
      setKickFollowerCount(formatNumber(creator.follow_kick));
      setKickPlacement(creator.rate_kick_product?.toFixed(2) || "");

      // Twitch
      setTwitchLink(
        creator.twitch_link
          ? creator.twitch_link.replace("https://www.twitch.tv/", "")
          : ""
      );
      setTwitchFollowerCount(formatNumber(creator.twitch));
      setTwitchPlacement(creator.rate_twitch_product?.toFixed(2) || "");

      // Podcast
      setPodcastLink(creator.podcast_link || "");
      setPodcastFollowerCount(formatNumber(creator.follow_podcast));
      setPodcastPreroll(creator.rate_podcast_preroll?.toFixed(2) || "");
      setPodcastMidroll(creator.rate_podcast_midroll?.toFixed(2) || "");
      setPodcastPostroll(creator.rate_podcast_postroll?.toFixed(2) || "");
      setPodcastFull(creator.rate_podcast_full?.toFixed(2) || "");

      setIsLoading(false);
    } catch (error) {
      openDialog(
        "Error",
        `Network error: ${
          error.response?.data?.error || error.message
        }\nTry refreshing the page.`,
        closeDialog,
        closeDialog,
        "Ok",
        null
      );
      console.error("Network error:", error);
    }
  }

  useEffect(() => {
    GetUserData();
  }, []);

  // Utility to format numbers
  const formatNumber = (value) => {
    if (!value) return "";
    const number = parseInt(value, 10);
    return isNaN(number) ? "0" : number.toString();
  };

  // --------------------------------------
  // 2) LINKING VIA STRIPE (Placeholder)
  // --------------------------------------
  const handleLinkStripeBankAccount = async (paymentMethodId) => {
    try {
      // `stripeUserId` is the user’s real Stripe ID from DB
      // `entity_id` could be the user’s unique ID or username in your system
      const entityId = userData.current?.username;

      const res = await API.bank.linkStripeBankAccount({
        entity_type: "creator",
        entity_id: entityId,
        stripe_customer_id: stripeUserId,
        payment_method_id: paymentMethodId,
      });

      alert("Stripe bank account (or card) linked successfully!");
      console.log("Link response:", res);
    } catch (error) {
      console.error("Error linking Stripe bank account:", error);
      alert("Error linking Stripe bank account: " + error.message);
    }
  };

  // --------------------------------------
  // 3) EVENT HANDLERS FOR PAYMENT & STATUS
  // --------------------------------------
  const handlePaymentMethodChange = (event) => {
    const method = event.target.value;
    setPaymentMethod(method);
    sessionStorage.setItem("paymentMethod", method);
    sessionStorage.setItem("creatorName", creatorName);
  };

  const OnClickUpdateStripe = () => {
    openDialog(
      "Change Payment Data",
      "This will redirect you without saving any changes. Click Update to be redirected to update your payment info, or Cancel to continue editing and save.",
      goToStripeId,
      closeDialog,
      "Update",
      "Cancel"
    );
  };

  const goToStripeId = () => {
    // Example redirect to Stripe OAuth
    const redirectUri = `https://www.useblitz.co/creatorconnect/start/stripe`;
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`;
  };

  const OnSelectField = (e) => {
    if (e.target.value === "Associate") {
      openDialog(
        "Change Partnership",
        "This will delete your Google connection from our server, and we won't be managing your brand deals. Continue?",
        () => {
          setUserStatus("Associate");
          closeDialog();
        },
        closeDialog,
        "Yes",
        "Cancel"
      );
    } else {
      setOpenConsent(true);
    }
  };

  const OnConsentGoogle = (consented) => {
    setOpenConsent(false);
    if (!consented) {
      setUserStatus("Associate");
    } else {
      setUserStatus("Partner");
    }
  };

  // --------------------------------------
  // 4) SAVING CREATOR CHANGES
  // --------------------------------------
  const handleStartSaveCreator = () => {
    // Basic phone check
    if (phoneNumber.length < 11) {
      alert("This phone number is not valid.");
      handleChangeTab(null, "info");
      return;
    }

    // Validate platforms
    if (
      !validatePlatformSelection(
        platforms,
        (message) => alert(message),
        tikTokBrandRate,
        tikTokSongRate,
        instagramBrandRate,
        instagramSongRate,
        instagramFeedRate,
        instagramStoryRate,
        youtubeIntegrationRate,
        youtubeShortsRate,
        youtubeDedicatedRate,
        xFeedPost,
        xRepost,
        kickPlacement,
        twitchPlacement,
        podcastPreroll,
        podcastMidroll,
        podcastPostroll,
        podcastFull
      )
    ) {
      handleChangeTab(null, "platforms");
      return;
    }

    // If user changes to "Partner"
    if (
      userStatus === "Partner" &&
      userData.current?.creator?.status !== userStatus
    ) {
      openDialog(
        "Changing Partnership Status",
        "A Google pop-up will open for you to connect your Gmail account as a Blitz Partner.",
        handleSaveCreator,
        handleSaveCreator,
        "Ok",
        null
      );
    } else {
      handleSaveCreator();
    }
  };

  const handleSaveCreator = async () => {
    const formattedTikTokLink = tikTokLink
      ? `https://www.tiktok.com/@${tikTokLink}`
      : null;
    const formattedInstagramLink = instagramLink
      ? `https://www.instagram.com/${instagramLink}`
      : null;
    const formattedYouTubeLink = youtubeLink
      ? `https://www.youtube.com/${youtubeLink}`
      : null;
    const formattedXLink = xLink ? `https://www.x.com/${xLink}` : null;
    const formattedKickLink = kickLink
      ? `https://www.kick.com/${kickLink}`
      : null;
    const formattedTwitchLink = twitchLink
      ? `https://www.twitch.tv/${twitchLink}`
      : null;

    let payload;
    try {
      payload = {
        // Social followers + links
        tiktok: parseLocalInt(tikTokFollowerCount) || null,
        tiktok_link: formattedTikTokLink || null,
        instagram: parseLocalInt(instagramFollowerCount) || null,
        instagram_link: formattedInstagramLink || null,
        youtube: parseLocalInt(youtubeFollowerCount) || null,
        youtube_link: formattedYouTubeLink || null,
        twitch: parseLocalInt(twitchFollowerCount) || null,
        twitch_link: formattedTwitchLink || null,
        follow_x: parseLocalInt(xFollowerCount) || null,
        x_link: formattedXLink || null,
        follow_podcast: parseLocalInt(podcastFollowerCount) || null,
        podcast_link: podcastLink || null,
        follow_kick: parseLocalInt(kickFollowerCount) || null,
        kick_link: formattedKickLink || null,

        // Rates
        rate_tt_sound: parseLocalFloat(tikTokSongRate),
        rate_tt_brand: parseLocalFloat(tikTokBrandRate),
        rate_ig_feedpost: parseLocalFloat(instagramFeedRate),
        rate_ig_reelsound: parseLocalFloat(instagramSongRate),
        rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
        rate_ig_story: parseLocalFloat(instagramStoryRate),
        rate_yt_integ: parseLocalFloat(youtubeIntegrationRate),
        rate_yt_short: parseLocalFloat(youtubeShortsRate),
        rate_yt_dedicated: parseLocalFloat(youtubeDedicatedRate),
        rate_podcast_preroll: parseLocalFloat(podcastPreroll),
        rate_podcast_midroll: parseLocalFloat(podcastMidroll),
        rate_podcast_postroll: parseLocalFloat(podcastPostroll),
        rate_podcast_full: parseLocalFloat(podcastFull),
        rate_twitch_product: parseLocalFloat(twitchPlacement),
        rate_kick_product: parseLocalFloat(kickPlacement),
        rate_x_feedpost: parseLocalFloat(xFeedPost),
        rate_x_repost: parseLocalFloat(xRepost),

        // Info
        geolocation: location,
        gender: gender,
        ethnicity: race,
        primary_market: primaryMarket,
        region: creatorRegion,
        notescontent_style: notesContentStyle || null,
        contactEmail: contactEmail || null,
        paymentEmail: paymentEmail || null,
        phone_number: phoneNumber || null,
        payment_method: paymentMethod || null,
        stripe_id: stripeUserId,
        pfphref: profilePhotoUrl,
        status: userStatus,
        process_emails: processEmails,
      };
    } catch (error) {
      alert(`Error parsing this form: ${error}`);
      return;
    }

    setIsLoading(true);
    try {
      const data = await API.creatorConnect.editCreator(payload);
      if (
        userStatus === "Partner" &&
        userData.current?.creator?.status !== userStatus
      ) {
        // Trigger Google login
        setGoogleRequest(data.request_id);
        return;
      }

      if (paymentMethod === "PayPal" || !isStripeNew) {
        openDialog(
          "Success",
          "Creator data edited successfully",
          closeDialog,
          closeDialog,
          "Ok",
          null
        );
        await GetUserData();
        setIsLoading(false);
      } else {
        openDialog(
          "Success",
          "Creator data edited successfully. You will now be redirected to Stripe to set up BlitzPay",
          goToStripeId,
          goToStripeId,
          "Ok",
          null
        );
        setIsLoading(false);
      }
    } catch (error) {
      openDialog(
        "Error",
        `Network error: ${error?.response?.data?.error || error.message}`,
        closeDialog,
        closeDialog,
        "Ok",
        null
      );
      console.error("Network error:", error);
      setIsLoading(false);
    }
  };

  // --------------------------------------
  // 5) IMAGE UPLOAD DIALOG
  // --------------------------------------
  const handleOpenUploadDialog = () => {
    setIsUploadDialogOpen(true);
  };
  const handleCloseUploadDialog = (uploadedUrl) => {
    if (uploadedUrl) setProfilePhotoUrl(uploadedUrl);
    setIsUploadDialogOpen(false);
  };

  // --------------------------------------
  // Tab & Form Handling
  // --------------------------------------
  const handleChangeTab = (event, newValue) => {
    setSearchParams((params) => ({ ...params, tab: newValue }));
  };

  // Basic validation for badges
  let errorInfo = 0;
  let errorPlatform = 0;
  let errorPay = 0;

  const hasPhoneNumber = phoneNumber.length >= 9;
  const anyPlatform = Object.values(platforms).some((val) => val);
  const hasPayment =
    paymentMethod && (paymentMethod === "PayPal" ? paymentEmail : true);

  if (!isLoading) {
    if (
      !race ||
      !notesContentStyle ||
      !location ||
      !gender ||
      !primaryMarket ||
      !phoneNumber ||
      !creatorRegion ||
      !contactEmail ||
      phoneNumber.length < 11
    ) {
      errorInfo = 1;
    }
    if (!anyPlatform) {
      errorPlatform = 1;
    }
    if (paymentMethod === "PayPal" && !paymentEmail) {
      errorPay = 1;
    }
  }

  return (
    <>
      {/* Loading Spinner */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Alert Dialog & Consent Form */}
      <AlertDialog alertState={dialogState}></AlertDialog>
      <ConsentForm open={openConsent} onClose={OnConsentGoogle}></ConsentForm>

      {/* Tabs */}
      <Paper elevation={4} square>
        <Tabs
          centered={isDesktop}
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="tab navigation"
        >
          <Tab
            label={
              <Badge badgeContent={errorPlatform} color="error" variant="dot">
                Platforms
              </Badge>
            }
            value="platforms"
          />
          <Tab
            label={
              <Badge badgeContent={errorPay} color="error" variant="dot">
                Payment
              </Badge>
            }
            value="payment"
          />
          <Tab
            label={
              <Badge badgeContent={errorInfo} color="error" variant="dot">
                Profile
              </Badge>
            }
            value="info"
          />
        </Tabs>
      </Paper>

      {/* Warning if incomplete */}
      {!completedInfo && (
        <Alert
          variant="filled"
          severity="warning"
          square
          sx={{ marginBlock: 4 }}
        >
          <AlertTitle>Finish your account to be selected for campaigns:</AlertTitle>
          <List sx={{ listStyleType: "disc", pl: 4 }}>
            {!anyPlatform && (
              <ListItem disablePadding sx={{ display: "list-item" }}>
                Select a rate for a promotion in your selected platforms.
              </ListItem>
            )}
            {!hasPhoneNumber && (
              <ListItem disablePadding sx={{ display: "list-item" }}>
                Add your phone number.
              </ListItem>
            )}
            {!hasPayment && (
              <ListItem disablePadding sx={{ display: "list-item" }}>
                Add your Payment Information.
              </ListItem>
            )}
            {(!race ||
              !notesContentStyle ||
              !location ||
              !gender ||
              !primaryMarket ||
              !phoneNumber ||
              !creatorRegion ||
              !contactEmail ||
              phoneNumber.length < 11) && (
              <ListItem disablePadding sx={{ display: "list-item" }}>
                Fill out your information and bio.
              </ListItem>
            )}
          </List>
        </Alert>
      )}

      {/* Main Form */}
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          const form = e.target;
          if (!form.checkValidity()) {
            // if any <input> or <select> is invalid, show the appropriate tab
            const invalidElements = form.querySelectorAll(":invalid");
            const firstInvalidElement = invalidElements[0];
            if (firstInvalidElement) {
              const tabPanel = firstInvalidElement.closest('[id^="tab-contents-"]');
              if (tabPanel) {
                const newValue = tabPanel.id.replace("tab-contents-", "");
                handleChangeTab(null, newValue);
              }
              setTimeout(() => form.reportValidity(), 0);
            }
            return;
          }
          // If valid, begin saving
          handleStartSaveCreator();
        }}
      >
        <Paper
          elevation={1}
          sx={{
            paddingInline: 4,
            paddingBlockStart: 2,
            paddingBlockEnd: 10,
            marginBlockStart: 3,
            maxWidth: "50em",
            marginInline: "auto",
          }}
        >
          {/* Platforms Tab */}
          <PlatformsTab
            tabValue={tabValue}
            index="platforms"
            showPlatformsTip={showPlatformsTip}
            // platform props
            platforms={platforms}
            setPlatforms={setPlatforms}
            // Instagram
            instagramLink={instagramLink}
            setInstagramLink={setInstagramLink}
            instagramFollowerCount={instagramFollowerCount}
            setInstagramFollowerCount={setInstagramFollowerCount}
            instagramBrandRate={instagramBrandRate}
            setInstagramBrandRate={setInstagramBrandRate}
            instagramSongRate={instagramSongRate}
            setInstagramSongRate={setInstagramSongRate}
            instagramFeedRate={instagramFeedRate}
            setInstagramFeedRate={setInstagramFeedRate}
            instagramStoryRate={instagramStoryRate}
            setInstagramStoryRate={setInstagramStoryRate}
            // TikTok
            tikTokLink={tikTokLink}
            setTikTokLink={setTikTokLink}
            tikTokFollowerCount={tikTokFollowerCount}
            setTikTokFollowerCount={setTikTokFollowerCount}
            tikTokBrandRate={tikTokBrandRate}
            setTikTokBrandRate={setTikTokBrandRate}
            tikTokSongRate={tikTokSongRate}
            setTikTokSongRate={setTikTokSongRate}
            // YouTube
            youtubeLink={youtubeLink}
            setYoutubeLink={setYoutubeLink}
            youtubeFollowerCount={youtubeFollowerCount}
            setYoutubeFollowerCount={setYoutubeFollowerCount}
            youtubeIntegrationRate={youtubeIntegrationRate}
            setYoutubeIntegrationRate={setYoutubeIntegrationRate}
            youtubeShortsRate={youtubeShortsRate}
            setYoutubeShortsRate={setYoutubeShortsRate}
            youtubeDedicatedRate={youtubeDedicatedRate}
            setYoutubeDedicatedRate={setYoutubeDedicatedRate}
            // X
            xLink={xLink}
            setXLink={setXLink}
            xFollowerCount={xFollowerCount}
            setXFollowerCount={setXFollowerCount}
            xFeedPost={xFeedPost}
            setXFeedPost={setXFeedPost}
            xRepost={xRepost}
            setXRepost={setXRepost}
            // Podcast
            podcastLink={podcastLink}
            setPodcastLink={setPodcastLink}
            podcastFollowerCount={podcastFollowerCount}
            setPodcastFollowerCount={setPodcastFollowerCount}
            podcastPreroll={podcastPreroll}
            setPodcastPreroll={setPodcastPreroll}
            podcastMidroll={podcastMidroll}
            setPodcastMidroll={setPodcastMidroll}
            podcastPostroll={podcastPostroll}
            setPodcastPostroll={setPodcastPostroll}
            podcastFull={podcastFull}
            setPodcastFull={setPodcastFull}
            // Kick
            kickLink={kickLink}
            setKickLink={setKickLink}
            kickFollowerCount={kickFollowerCount}
            setKickFollowerCount={setKickFollowerCount}
            kickPlacement={kickPlacement}
            setKickPlacement={setKickPlacement}
            // Twitch
            twitchLink={twitchLink}
            setTwitchLink={setTwitchLink}
            twitchFollowerCount={twitchFollowerCount}
            setTwitchFollowerCount={setTwitchFollowerCount}
            twitchPlacement={twitchPlacement}
            setTwitchPlacement={setTwitchPlacement}
          />

          {/* Payment Tab */}
          <PaymentTab
        tabValue={tabValue}
        index="payment"
        paymentMethod={paymentMethod}
        handlePaymentMethodChange={handlePaymentMethodChange}
        paymentEmail={paymentEmail}
        setPaymentEmail={setPaymentEmail}
        isStripeNew={isStripeNew}
        OnClickUpdateStripe={OnClickUpdateStripe}
        
        // pass the user’s Stripe ID here
        stripeUserId={stripeUserId}
        
        // pass down our "attach" function, which calls the backend
        handleLinkStripeBankAccount={handleLinkStripeBankAccount}

        userStatus={userStatus}
        OnSelectField={OnSelectField}
        processEmails={processEmails}
        setProcessEmails={setProcessEmails}
      />

          {/* Profile Tab */}
          <ProfileTab
            tabValue={tabValue}
            index="info"
            creatorName={creatorName}
            // If you want to allow user to edit name, pass setCreatorName, otherwise pass null
            setCreatorName={setCreatorName}
            profilePhotoUrl={profilePhotoUrl}
            setIsUploadDialogOpen={setIsUploadDialogOpen}
            creatorRegion={creatorRegion}
            setCreatorRegion={setCreatorRegion}
            race={race}
            setRace={setRace}
            gender={gender}
            setGender={setGender}
            location={location}
            setLocation={setLocation}
            primaryMarket={primaryMarket}
            setPrimaryMarket={setPrimaryMarket}
            notesContentStyle={notesContentStyle}
            setNotesContentStyle={setNotesContentStyle}
            contactEmail={contactEmail}
            setContactEmail={setContactEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            validationError={validationError}
          />
        </Paper>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={(theme) => ({
            marginTop: 1,
            marginBottom: 2,
            position: "fixed",
            right: { xs: theme.spacing(4), md: theme.spacing(1) },
            bottom: theme.spacing(1),
          })}
        >
          Save Changes
        </Button>
      </form>

      {/* Upload Dialog */}
      <UploadFileDialogPFP
        open={isUploadDialogOpen}
        onClose={(uploadedUrl) => {
          if (uploadedUrl) setProfilePhotoUrl(uploadedUrl);
          setIsUploadDialogOpen(false);
        }}
      />
    </>
  );
};

export default EditCreator;
