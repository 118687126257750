//index.js in /Utils
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePassword = (password) => {
  return password.length >= 6 ? true : false;
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isCompanyBalanceValid = (input) => {
  if (!parseFloat(input) && parseFloat(input)!==0) return false;

  var pattern = /^\d{1,8}(\.\d{1,2})?$/;
  return pattern.test(input);
};

export const isValidCompanySeats = (input) => {
  var pattern = /^\d(,\d)*$/;
  return pattern.test(input);
};

export const isJSONObject = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }
  try {
    JSON.stringify(obj);
    return true;
  } catch (e) {
    return false;
  }
};

export const isJSONString = (str) => {
  if (typeof str !== "string") {
    return false;
  }
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

//Takes a list of object {name: string, value: int}
function CalculateRange(list){
  let lowest = list[0];
  let highest = list[0]
  list.forEach(promo => {
    if(promo.value < lowest.value)
      lowest = promo;
    else if (promo.value > highest.value)
      highest = promo;
  });
  return({lowest: lowest, highest: highest})
}


export function findMaxValue(arr) {
  let maxValue = 0;

  for (let i = 0; i < arr.length; i++) {
    maxValue = Math.max(arr[i].lowest.value, maxValue);
    maxValue = Math.max(arr[i].highest.value, maxValue);
  }

  return maxValue;
}