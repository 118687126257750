import React, { useEffect, useRef } from "react";
import { Box, Typography, Button, Container, Toolbar, Fade, Divider, Slide } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import paymentImage from "../../../Components/globalAssets/blackwhite.png";
import marketplaceImage from "../../../Components/globalAssets/logo_placeholder.png";
import routes from "../../../Config/routes";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";

const sectionStyles = {
  sectionContainer: {
    display: "flex",
    scrollMarginTop: "64px",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-evenly",
    alignItems: "center",
    px: { xs: 2, md: 4 },
    py: { xs: 6, md: 10 },
    position: "relative",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mb: { xs: 4, md: 0 },
    maxWidth: { xs: "200px", md: "350px" },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "30rem",
    alignItems: { xs: "center", md: "flex-start" },
    textAlign: { xs: "center", md: "left" },
  },
};

const Solutions = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const paymentRef = useRef(null);
  const marketplaceRef = useRef(null);
  const aiManagerRef = useRef(null);
  const acceleratedCollabRef = useRef(null);

  useEffect(() => {
    if (location.hash) {
      const targetId = location.hash.substring(1);
      scrollToSection(targetId);
    }
  }, [location.hash]);

  const scrollToSection = (sectionId) => {
    switch (sectionId) {
      case "blitzPay":
        paymentRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "creatorSourcing":
        marketplaceRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "aiCampaignManagement":
        aiManagerRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "acceleratedCollaborations":
        acceleratedCollabRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        color: "#000",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
          opacity: 0.1,
        }}
      />

      <HomeHeader />
      <Toolbar />

      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: { xs: 4, md: 8 },
          paddingBottom: { xs: 4, md: 8 },
        }}
      >
        <Fade in={true} timeout={1000}>
          <Box sx={{ mb: { xs: 4, md: 8 }, textAlign: "center" }}>
            <Typography variant="h2" sx={{ fontWeight: "bold", my: 2 }}>
              Our Solutions
            </Typography>
            <Typography variant="h6" sx={{ maxWidth: "600px", margin: "auto" }}>
              Empowering the next generation of creators, freelancers, and
              brands with streamlined payments, intelligent sourcing, and
              AI-driven campaign management—so you can thrive in the global gig
              economy.
            </Typography>
          </Box>
        </Fade>

        <Divider />

        {/* BlitzPay Section */}
        <Fade in={true} timeout={1000}>
          <Box ref={paymentRef} sx={sectionStyles.sectionContainer}>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Box sx={sectionStyles.imageContainer}>
                <img
                  src={paymentImage}
                  alt="Payment"
                  style={{
                    width: "100%",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Box>
            <Box sx={sectionStyles.textContainer}>
              <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
                BlitzPay – Modern Accounts Payable
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
                Transform your financial operations with BlitzPay. Instantly
                settle accounts with creators, contractors, and vendors from
                around the world. No more late checks, convoluted bank
                transfers, or high foreign exchange fees—just effortless
                transactions and total transparency.
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
                <strong>Key Benefits:</strong>
                <ul style={{ listStyle: "disc", marginLeft: "1.5rem" }}>
                  <li>Instant Payments with Multi-Currency Support</li>
                  <li>Flexible Net Terms (Net 30/60) for Free</li>
                  <li>Automated Invoicing & Compliance</li>
                </ul>
              </Typography>
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  textTransform: "none",
                  backgroundColor: "#7b2a2a",
                  color: "#fff",
                }}
                onClick={handleAccess}
              >
                Request Access
              </Button>
            </Box>
          </Box>
        </Fade>

        <Divider />

        {/* Creator Sourcing Section */}
        <Fade in={true} timeout={1000}>
          <Box
            ref={marketplaceRef}
            sx={{
              ...sectionStyles.sectionContainer,
              flexDirection: { xs: "column", md: "row-reverse" },
            }}
          >
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Box sx={sectionStyles.imageContainer}>
                <img
                  src={marketplaceImage}
                  alt="Marketplace"
                  style={{ width: "100%", borderRadius: "16px" }}
                />
              </Box>
            </Box>
            <Box sx={sectionStyles.textContainer}>
              <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
                Creator Sourcing at Global Scale
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
                Tap into a worldwide network of top-tier talent. Blitz gives you
                access to influencers, videographers, graphic designers, and
                developers—handpicked and ready to collaborate. Your brand’s
                next success story is just a few clicks away.
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
                <strong>What You’ll Love:</strong>
                <ul style={{ listStyle: "disc", marginLeft: "1.5rem" }}>
                  <li>Curated Talent Matches Based on Your Criteria</li>
                  <li>Seamless Onboarding & Contract Management</li>
                  <li>Built-In Compliance & Payment Tools</li>
                </ul>
              </Typography>
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  textTransform: "none",
                  backgroundColor: "#7b2a2a",
                  color: "#fff",
                }}
                onClick={handleAccess}
              >
                Request Access
              </Button>
            </Box>
          </Box>
        </Fade>

        <Divider />

        {/* AI Campaign Management Section */}
        <Fade in={true} timeout={1000}>
          <Box ref={aiManagerRef} sx={sectionStyles.sectionContainer}>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Box sx={sectionStyles.imageContainer}>
                <img
                  src={paymentImage}
                  alt="AI Manager"
                  style={{
                    width: "100%",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Box>
            <Box sx={sectionStyles.textContainer}>
              <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
                AI-Powered Campaign Management
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
                Revolutionize how you run influencer campaigns. Blitz’s
                AI-driven insights help you identify the right creators, predict
                campaign performance, and optimize spend—without guesswork.
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
                <strong>Highlights:</strong>
                <ul style={{ listStyle: "disc", marginLeft: "1.5rem" }}>
                  <li>Data-Driven Creator Selection & Forecasting</li>
                  <li>Automated Reporting & Performance Tracking</li>
                  <li>Cost Optimization & Campaign Scalability</li>
                </ul>
              </Typography>
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  textTransform: "none",
                  backgroundColor: "#7b2a2a",
                  color: "#fff",
                }}
                onClick={handleAccess}
              >
                Request Access
              </Button>
            </Box>
          </Box>
        </Fade>

        <Divider />

        {/* Accelerated Collaborations Section */}
        <Fade in={true} timeout={1000}>
          <Box
            ref={acceleratedCollabRef}
            sx={{
              ...sectionStyles.sectionContainer,
              flexDirection: { xs: "column", md: "row-reverse" },
            }}
          >
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Box sx={sectionStyles.imageContainer}>
                <img
                  src={marketplaceImage}
                  alt="Collaborations"
                  style={{ width: "100%", borderRadius: "16px" }}
                />
              </Box>
            </Box>
            <Box sx={sectionStyles.textContainer}>
              <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
                Accelerated Collaborations
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
                Launch campaigns at lightning speed. With streamlined workflows,
                pre-approved contracts, and integrated communications, Blitz
                helps you get from concept to viral launch faster than ever.
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
                <strong>Benefits:</strong>
                <ul style={{ listStyle: "disc", marginLeft: "1.5rem" }}>
                  <li>Rapid Onboarding of New Talent</li>
                  <li>Integrated Messaging & Collaboration Tools</li>
                  <li>Faster Go-to-Market Timeline</li>
                </ul>
              </Typography>
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  textTransform: "none",
                  backgroundColor: "#7b2a2a",
                  color: "#fff",
                }}
                onClick={handleAccess}
              >
                Request Access
              </Button>
            </Box>
          </Box>
        </Fade>

        <Divider sx={{ my: { xs: 4, md: 8 } }} />

        <Fade in={true} timeout={1000}>
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "800px",
              margin: "auto",
              py: { xs: 4, md: 8 },
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "bold", mb: 4 }}>
              Trusted by Innovators Worldwide
            </Typography>
            <Typography
              variant="body1"
              sx={{ mb: 6, fontStyle: "italic", lineHeight: 1.7 }}
            >
              "Before Blitz, managing payments and sourcing for our international
              campaigns was a nightmare. Now, everything’s unified, transparent,
              and lightning fast. Our creators love it—and so do we."
              <br />– Head of Digital, Sony Music
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#7b2a2a", color: "#fff" }}
              onClick={handleAccess}
            >
              Join Our Ecosystem
            </Button>
          </Box>
        </Fade>

        <Fade in={true} timeout={1000}>
          <Box
            sx={{
              textAlign: "center",
              py: { xs: 4, md: 8 },
              background: "linear-gradient(135deg, #7b2a2a 0%, #9a3f3f 100%)",
              borderRadius: "16px",
              color: "#fff",
              my: { xs: 4, md: 8 },
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
              Ready to Transform Your Workflow?
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                maxWidth: "600px",
                margin: "auto",
                lineHeight: 1.7,
              }}
            >
              Let Blitz handle the complexity of payments, sourcing, and
              management so you can focus on what you do best—creating
              exceptional content and growing your brand.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#fff",
                color: "#7b2a2a",
                fontWeight: "bold",
                py: 1.5,
                px: 4,
                textTransform: "none",
                fontSize: "1.1rem",
              }}
              onClick={handleAccess}
            >
              Get Started Today
            </Button>
          </Box>
        </Fade>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default Solutions;
