import React from 'react';
import { AppBar, Tabs, Tab, Box, Typography } from '@mui/material';
import CompanyDetailsView from './sections/companydetailsviews';
import CampaignDetailsView from './sections/campaignsdetailsviews';
import InvoiceDetailsView from './sections/invoicedetailsviews';
import PayoutDetailsView from './sections/payoutdetailsview';
import CreatorDetailsView from './sections/creatordetailsview';
import UserDetailsView from './sections/admindetailviews';
import MarketingView from './sections/marketingComponents/marketingdetailview';
import CreatorCRMView from './sections/creatorCRM/creatorCRMview';
import CreatorSMS from './sections/creatorCRM/creatorSMS';
import useAuth from '../../../Hooks/use-auth';
import UserAdminView from './sections/newUserParntershipComponents/userdetailsview';
import PartnershipsDetailsView from './sections/newUserParntershipComponents/partnershipsdetailsview';
import ReferralDetailsView from './sections/newUserParntershipComponents/referralsdetailsview';
import SMSChannels from './sections/newUserParntershipComponents/textchannels';
import ClientDetailsView from './sections/newUserParntershipComponents/clientdetailsview';  // Import the new ClientDetailsView
import CreatorEmailView from './sections/marketingComponents/creatoremails';
import InternalCampaigns from './sections/newUserParntershipComponents/internalcampaigns';
import Blocklist from './sections/blocklist';
import BrandEmails from './sections/brandemails';
import BlastEmail from './sections/marketingComponents/blastemail';
import { useSearchParams } from 'react-router-dom';
import AdminServices from './sections/services';

const tabLabels = [
  { label: 'Company Details', value: 'companies' },
  { label: 'Campaigns', value: 'campaigns' },
  { label: 'Payouts', value: 'payouts' },
  { label: 'Invoices', value: 'invoices' },
  { label: 'Creators', value: 'creators' },
  { label: 'KPIs', value: 'admin' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Blast Eamil', value: 'blastemail' },
  { label: 'Creator CRM', value: 'creatorCRM' },
  { label: 'Users', value: 'users' },
  { label: 'Partnerships', value: 'partnerships' },
  { label: 'Referals/Commissions', value: 'commissions' },
  { label: 'SMS Channels', value: 'smsChannels' },
  { label: 'Clients', value: 'clients' },  // New tab
  { label: 'Emails', value: 'creatorEmails' },  // New tab
  { label: 'Internal Campaigns', value: 'internalcampaigns' },  // New tab
  { label: 'CRM Blocklist', value: 'blocklist' },
  { label: 'AI emails', value: 'brandemails' },
  { label: 'Apps', value: 'services' }
];

const CompanyList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Get current tab value from search parameters; default to "0" if none exists
  const currentView = searchParams.get('tab') || 'companies';
  const setCurrentView = (newValue) => {
    setSearchParams((params) => ({ ...params, tab: newValue }));
  };
  const { getCurrrentUser } = useAuth();

  const renderView = () => {
    switch (currentView) {
      case 'creators':
        return <CreatorDetailsView />;
      case 'blocklist':
        return <Blocklist />;
      case 'admin':
        return <UserDetailsView />;
      case 'campaigns':
        return <CampaignDetailsView />;
      case 'payouts':
        return <PayoutDetailsView />;
      case 'invoices':
        return <InvoiceDetailsView />;
      case 'marketing':
        return <MarketingView />;
      case 'blastemail':
        return <BlastEmail />;
      case 'creatorCRM':
        return <CreatorCRMView />;
      case 'companies':
        return <CompanyDetailsView />;
      case 'users':
        return <UserAdminView />;
      case 'partnerships':
        return <PartnershipsDetailsView />;
      case 'commissions':
        return <ReferralDetailsView />;
      case 'smsChannels':
        return <SMSChannels />;
      case 'clients':  // New case
        return <ClientDetailsView />;
      case 'creatorEmails':  // New case
        return <CreatorEmailView />;
      case 'internalcampaigns':  // New case
        return <InternalCampaigns />;
      case 'brandemails':  // New case
        return <BrandEmails />;
      case 'services':  // New case
        return <AdminServices />;
      default:
        return <CompanyDetailsView />;
    }
  };

  return (
    <>
      {(getCurrrentUser()?.type ?? 'none') === 'admin' ? (
        <>
          <AppBar position="static" color='default'>
            <Tabs
              value={currentView}
              onChange={(e, newValue) => setCurrentView(newValue)}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabLabels.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </AppBar>
          {renderView()}
        </>
      ) : (
        <Box sx={{ textAlign: 'center', height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography color="textSecondary">Sorry, You are not allowed to view this page.</Typography>
        </Box>
      )}
    </>
  );
};

export default CompanyList;
