import React from "react";
import { Box, Button, Typography } from "@mui/material";
import aiManagerImage from "../../Components/globalAssets/blitzBoltBlack.png"; // Replace with actual image path
import routes from "../../Config/routes"; // Import the routes object
import { useNavigate } from "react-router-dom";
import { globalStyles } from "../../Utils/Styles";

const AIManagerSection = () => {
  const navigate = useNavigate();

  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  return (
    <Box
      sx={{
        ...globalStyles.wholeHeightMinusTop,
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
        justifyContent: 'space-evenly',
        alignItems: "center",
        color: "#000", // Light mode text color
        backgroundColor: "#FAF9F6", // Light mode background color
        px: 2
      }}
    >
      {/* Left Section with Image */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: { xs: 2, md: 0 },
          maxWidth:{xs: '200px', md:'400px'}
        }}
      >
        <img
          src={aiManagerImage} // Use your AI Manager-related image here
          alt="AI Manager"
          style={{
            width: "100%",
          }}
        />
      </Box>

      {/* Right Section with Text */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: '30rem',
          alignItems: { xs: "center", md: "flex-start" },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
          Blitz AI Agent for Creators
        </Typography>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Transform how you manage your deals with the Blitz AI Agent. This all-in-one tool helps creators clean up their inbox, source new deals, respond and follow up efficiently, and even book meetings with a single link.
        </Typography>
        <Typography variant="h6" sx={{ mb: 3 }}>
          With the Blitz AI Agent, creators who enable this feature see 2–3x more deals closed. Our intelligent system takes care of the repetitive tasks, allowing you to focus on creating great content.
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, fontStyle: "italic" }}>
          "Blitz AI Agent has doubled my productivity. I never miss an opportunity, and my inbox stays organized!" - @itsyaboyaustin4
        </Typography>
        <Button
          variant="contained"
          sx={{
            px: 4,
            py: 1.5,
            fontSize: "1rem",
            textTransform: "none",
            backgroundColor: "#7b2a2a", // Light mode primary color
            color: "#fff",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            '&:hover': {
              backgroundColor: "#5a1e1e",
            }
          }}
          onClick={handleAccess}
        >
          Enable AI Agent Now
        </Button>
      </Box>
    </Box>
  );
};

export default AIManagerSection;
