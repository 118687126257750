import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useMutation, useQuery } from "react-query";
import client from "../../../../API";
import { useCreatorAuth } from "../../../../Hooks/creator-use-auth";
import { styled } from "@mui/system";
import UploadFileDialog from "../creatorUtils/uploadfile"; 

// Example custom button
const CustomButton = styled(Button)`
  background-color: #616161;
  color: white;
  &:hover {
    background-color: #757575;
  }
`;

// Example card for deliverables
const DeliverableCard = ({ deliverable, onViewOffer, isUserMessage }) => (
  <Paper
    sx={{
      marginBottom: 1.5,
      padding: 2,
      backgroundColor: isUserMessage ? "#1976d2" : "#f0f0f0",
      color: isUserMessage ? "white" : "black",
      maxWidth: "75%",
      alignSelf: isUserMessage ? "flex-end" : "flex-start",
    }}
    elevation={2}
  >
    <Typography variant="h6">
      {`Deliverable: ${deliverable.deliverable_type}`}
    </Typography>
    <Typography variant="body2">Amount: {deliverable.amount || "N/A"}</Typography>
    <Typography variant="body2">Status: {deliverable.status}</Typography>
    <Typography variant="body2">Notes: {deliverable.notes}</Typography>
    <Typography variant="body2">
      Revision Amount: {deliverable.revision}
    </Typography>
    <Typography variant="body2">Due Date: {deliverable.due_date}</Typography>
    <Typography variant="body2">
      Platforms: {deliverable.platforms.join(", ")}
    </Typography>
    <Box sx={{ marginTop: 1 }}>
      <Button
        onClick={() => onViewOffer(deliverable)}
        color="primary"
        variant="contained"
        fullWidth
      >
        View Offer
      </Button>
    </Box>
  </Paper>
);

// Dialog to view an Offer or Deliverable
const ViewOfferDialog = ({
  open,
  onClose,
  deliverable,
  onUpdateDeliverable,
  onSendMessage,
}) => {
  const [status, setStatus] = useState(deliverable.status);
  const [amount, setAmount] = useState(deliverable.amount);
  const [revision, setRevision] = useState(deliverable.revision);
  const [isNegotiating, setIsNegotiating] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (status === "NEGOTIATE") {
      setIsNegotiating(true);
    } else {
      setIsNegotiating(false);
    }
  }, [status]);

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    if (newStatus === "NEGOTIATE") {
      setIsNegotiating(true);
    } else {
      setIsNegotiating(false);
    }
  };

  const handleAmountChange = (e) => setAmount(e.target.value);
  const handleRevisionChange = (e) => setRevision(e.target.value);

  const handleSubmit = () => {
    setLoading(true);
    // Simulate an async call
    setTimeout(() => {
      onUpdateDeliverable(deliverable.id, {
        status,
        amount,
        revision: isNegotiating ? revision : deliverable.revision,
      });
      onSendMessage(`Your offer was ${status.toLowerCase()}ed.`);
      setLoading(false);
      onClose();
    }, 1000);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>View Offer</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Deliverable Type: {deliverable.deliverable_type}
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Notes: {deliverable.notes}
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Due Date: {deliverable.due_date}
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Revision Amount: {deliverable.revision}
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Platforms: {deliverable.platforms.join(", ")}
        </Typography>

        <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select value={status} onChange={handleStatusChange} label="Status">
            <MenuItem value="ACCEPT">Accept</MenuItem>
            <MenuItem value="DECLINE">Decline</MenuItem>
            <MenuItem value="NEGOTIATE">Negotiate</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          margin="normal"
          label="Amount"
          value={amount}
          onChange={handleAmountChange}
          type="number"
          disabled={loading}
        />
        <Typography variant="caption" color="textPrimary">
          * Blitz takes a 5% fee on all payments when they are issued.
        </Typography>

        {isNegotiating && (
          <TextField
            fullWidth
            margin="normal"
            label="Revision Amount"
            value={revision}
            onChange={handleRevisionChange}
            type="number"
            disabled={loading}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreatorConversation = ({ partnershipId }) => {
  const { creatorToken } = useCreatorAuth();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedDeliverable, setSelectedDeliverable] = useState(null);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  // This ref allows auto-scrolling to the last message
  const messagesEndRef = useRef(null);

  // Fetch the partnership to get conversationId
  const {
    data: partnershipFetch,
    isLoading: loadingPartnership,
    error: partnershipError,
    refetch: partnershipRefetch,
  } = useQuery(
    ["partnerships", partnershipId],
    () => client.partnerships.fetchPartnership(partnershipId),
    { enabled: !!partnershipId }
  );

  const partnership = partnershipFetch?.partnership;
  const conversationId = partnership?.conversation_id || null;

  // Create conversation if none exists
  useEffect(() => {
    if (!conversationId && partnershipFetch) {
      client.partnershipConversations
        .create({
          partnership_id: partnershipId,
          creator_id: creatorToken.creator_user.username,
        })
        .then(() => partnershipRefetch())
        .catch((error) => {
          alert(
            `Error creating conversation: ${
              error?.response?.data?.error || error.message
            }`
          );
        });
    }
  }, [conversationId, partnershipFetch, partnershipRefetch, creatorToken, partnershipId]);

  // Fetch messages
  const {
    data: messagesData,
    error: messagesError,
    isLoading: loadingMessages,
    refetch: refetchMessages,
  } = useQuery(
    ["messages", conversationId],
    () => {
      if (!conversationId) return [];
      return client.partnershipConversations.getMessages(conversationId);
    },
    {
      enabled: !!conversationId,
      onSuccess: (data) => {
        const sorted = data.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        setMessages(sorted);
      },
    }
  );

  // Mutation to send message
  const sendMessageMutation = useMutation(
    ({ id, input }) => client.conversations.sendMessage(id, input),
    {
      onSuccess: () => {
        refetchMessages();
        setMessage("");
      },
      onError: (error) => {
        console.error("Error sending message:", error);
        if (error?.response?.data?.error === "forbidden") {
          alert(
            "Your message was flagged for containing prohibited content."
          );
          return;
        }
        alert(error?.response?.data?.error || error?.message);
      },
    }
  );

  // Mutation to update deliverable
  const updateDeliverableMutation = useMutation(
    ({ id, update }) => client.deliverables.update(id, update),
    {
      onSuccess: () => {
        setSelectedDeliverable(null);
        if (conversationId) {
          client.partnershipConversations
            .getMessages(conversationId)
            .then((data) => {
              const sorted = data.sort(
                (a, b) => new Date(a.created_at) - new Date(b.created_at)
              );
              setMessages(sorted);
            })
            .catch((err) => console.error("Error fetching messages:", err));
        }
      },
      onError: (error) => {
        console.error("Error updating deliverable:", error);
      },
    }
  );

  // Check prohibited content
  const isMessageAllowed = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const textWithoutUrls = text.replace(urlRegex, "");
    const prohibitedKeywords = ["text me", "contact me", "contact", "@gmail.com"];
    for (let keyword of prohibitedKeywords) {
      if (textWithoutUrls.toLowerCase().includes(keyword)) {
        return false;
      }
    }
    const phoneRegex = /(?:\d[\s-]?){10,}/;
    if (phoneRegex.test(textWithoutUrls)) {
      return false;
    }
    return true;
  };

  const handleSendMessage = () => {
    if (!conversationId || !message) return;
    if (!isMessageAllowed(message)) {
      alert(
        "Your message contains content that is not allowed. Please remove personal contact info."
      );
      return;
    }

    sendMessageMutation.mutate({
      id: conversationId,
      input: { message },
    });
  };

  // Auto-scroll to bottom on new messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Format message text to clickable links
  const formatMessage = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  // Deliverable & Offer
  const handleViewOffer = (deliverable) => setSelectedDeliverable(deliverable);
  const handleUpdateDeliverable = (deliverableId, update) => {
    updateDeliverableMutation.mutate({ id: deliverableId, update });
  };
  const handleSendStatusMessage = (statusMessage) => {
    if (!conversationId) return;
    sendMessageMutation.mutate({
      id: conversationId,
      input: { message: statusMessage },
    });
  };

  // File upload
  const handleFileUpload = async (fileData) => {
    // If your code returns a string or an object, handle accordingly
    if (typeof fileData === "string") {
      setMessage((prev) => `${prev}\n${fileData}`);
    } else if (fileData?.status === "file_too_large") {
      // Possibly show a popup
      setMessage((prev) => `${prev}\n${fileData.redirect_url}`);
    } else if (fileData?.status === "uploaded_to_firebase") {
      setMessage((prev) => `${prev}\n${fileData.url}`);
    }
  };

  if (loadingPartnership) {
    return (
      <Typography sx={{ padding: 2 }}>Loading partnership...</Typography>
    );
  }

  if (partnershipError) {
    return (
      <Typography color="error" sx={{ padding: 2 }}>
        Error loading partnership.
      </Typography>
    );
  }

  if (!partnership) {
    return (
      <Typography sx={{ padding: 2 }}>No partnership found.</Typography>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* Conversation messages */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          paddingX: 2,
          paddingY: 1,
          backgroundColor: "#ffffff",
        }}
      >
        {loadingMessages && <Typography>Loading messages...</Typography>}
        {messagesError && (
          <Typography color="error">Error loading messages</Typography>
        )}
        {messages.map((msg, index) => {
          const isUserMessage =
            msg.creator_id === creatorToken.creator_user.username;
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: isUserMessage ? "flex-end" : "flex-start",
                marginY: 1,
              }}
            >
              {msg.deliverable ? (
                <DeliverableCard
                  deliverable={msg.deliverable}
                  onViewOffer={handleViewOffer}
                  isUserMessage={isUserMessage}
                />
              ) : (
                <Paper
                  sx={{
                    maxWidth: "75%",
                    padding: 1.5,
                    backgroundColor: isUserMessage ? "#1976d2" : "#f0f0f0",
                    color: isUserMessage ? "white" : "black",
                  }}
                  elevation={2}
                >
                  {!isUserMessage && (
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      {msg.sender_first_name} {msg.sender_last_name}
                    </Typography>
                  )}
                  <Typography variant="body1">{formatMessage(msg.message)}</Typography>
                  <Typography variant="caption" sx={{ display: "block", marginTop: 0.5 }}>
                    {new Date(msg.created_at).toLocaleTimeString()}
                  </Typography>
                </Paper>
              )}
            </Box>
          );
        })}
        <div ref={messagesEndRef} />
      </Box>

      {/* Input & Upload Section */}
      <Box
        sx={{
          padding: 2,
          borderTop: "1px solid #ccc",
          backgroundColor: "#fafafa",
        }}
      >
        <Box sx={{ marginBottom: 1 }}>
          <CustomButton onClick={() => setIsUploadDialogOpen(true)}>
            Upload File
          </CustomButton>
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            fullWidth
            multiline
            maxRows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            label="Type a message"
            variant="outlined"
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
          />
          <IconButton
            onClick={handleSendMessage}
            color="primary"
            sx={{ marginLeft: 1 }}
          >
            {sendMessageMutation.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <SendIcon />
            )}
          </IconButton>
        </Box>
      </Box>

      {/* Dialog for viewing offer/deliverable updates */}
      {selectedDeliverable && (
        <ViewOfferDialog
          open={!!selectedDeliverable}
          onClose={() => setSelectedDeliverable(null)}
          deliverable={selectedDeliverable}
          onUpdateDeliverable={handleUpdateDeliverable}
          onSendMessage={handleSendStatusMessage}
        />
      )}

      {/* Upload File Dialog */}
      <UploadFileDialog
        open={isUploadDialogOpen}
        onClose={() => setIsUploadDialogOpen(false)}
        partnershipId={partnershipId}
        onFileUpload={handleFileUpload}
      />
    </Box>
  );
};

export default CreatorConversation;
