import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Checkbox,
  Alert,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Button,
  Backdrop,
  Snackbar,
  Chip,
  IconButton,
  Tabs,
  Tab,
  TableFooter,
  TablePagination
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import SendIcon from '@mui/icons-material/Send';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import API from "../../../API";
import useIsDesktop from "../../../Hooks/useIsDesktop";
import { ConfigValue } from '../../../Config';
import { StyledTableRow } from "../../../Utils/styledcell";

const HideMobileCell = styled(TableCell)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'table-cell',
  },
}));

const rowsPerPage = 5;
const styles = {
  singleLine: { wordBreak: 'keep-all', whiteSpace: 'nowrap' },
};

const fetchEmails = async ({ queryKey }) => {
  const [, page] = queryKey;
  const response = await API.creatorConnect.getEmails({ page, rows: rowsPerPage });
  return response;
};

const Pitch = () => {
  const { creatorToken } = useCreatorAuth();
  const creatorUsername = creatorToken?.creator_user?.username;

  const [pitchesSent, setPitchesSent] = useState(0);
  const [credits, setCredits] = useState(0);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [rate, setRate] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  const [isPitchLoading, setIsPitchLoading] = useState(false);

  // Default to Outbound tab
  const [tabValue, setTabValue] = useState(1);

  // State and logic for Inbound emails
  const [toastOpen, setToastOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const isDesktop = useIsDesktop();
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setToastOpen(false);
  };

  const { isError, data: inboundData, error, isFetching } = useQuery(
    ['creatorEmails', page],
    fetchEmails,
    {
      keepPreviousData: true,
      onError: () => setToastOpen(true),
      enabled: tabValue === 0 // Only fetch inbound emails if Inbound tab is active
    }
  );

  useEffect(() => {
    if (inboundData) {
      setRowCount(inboundData.total);
    }
  }, [inboundData]);

  const getEmailDomain = (email) => {
    if (!email) return '';
    const domain = email.split('@')[1];
    return domain || '';
  };

  // Fetch users by company
  const fetchUsersByCompany = async () => {
    const response = await fetch(
      `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/creatorUsers/users_by_company`
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    // Exclude Gmail addresses
    const filteredData = {};
    Object.keys(data).forEach((companyName) => {
      const filteredUsers = data[companyName].filter(
        (user) =>
          user.email &&
          !user.email.endsWith('@gmail.com') &&
          !user.email.endsWith('@gmail')
      );
      if (filteredUsers.length > 0) {
        filteredData[companyName] = filteredUsers;
      }
    });
    return filteredData;
  };

  // Fetch contacts
  const fetchContacts = async () => {
    const response = await fetch(
      'https://blitz-backend-nine.vercel.app/api/crm/contacts'
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    // Exclude Gmail addresses
    const filteredContacts = data.filter(
      (contact) =>
        contact.email &&
        !contact.email.endsWith('@gmail.com') &&
        !contact.email.endsWith('@gmail')
    );
    return filteredContacts;
  };

  const fetchCredxMapping = async (creatorUsername) => {
    try {
      const response = await fetch(
        `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/credits?creator_username=${creatorUsername}`
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.error}`);
      }
      const data = await response.json();
      setCredits(data.credits);
    } catch (error) {
      console.error('Error fetching credits:', error.message);
    }
  };

  useEffect(() => {
    if (creatorUsername) {
      fetchCredxMapping(creatorUsername);
    }
  }, [creatorUsername]);

  const {
    isLoading: isLoadingUsers,
    error: errorUsers,
    data: usersByCompany,
  } = useQuery('usersByCompany', fetchUsersByCompany, {
    keepPreviousData: true,
    enabled: tabValue === 1 // Only fetch when Outbound tab is active
  });

  const {
    isLoading: isLoadingContacts,
    error: errorContacts,
    data: contacts,
  } = useQuery('contacts', fetchContacts, {
    keepPreviousData: true,
    enabled: tabValue === 1 // Only fetch when Outbound tab is active
  });

  const processedUsersByCompany = usersByCompany;
  const processedContacts = contacts;

  const recommendations = useMemo(() => {
    if (!processedContacts) return [];
    const domainCounts = {};
    processedContacts.forEach((contact) => {
      if (contact.email) {
        const emailDomain = getEmailDomain(contact.email);
        if (emailDomain) {
          if (!domainCounts[emailDomain]) {
            domainCounts[emailDomain] = { count: 0, contacts: [] };
          }
          domainCounts[emailDomain].count += 1;
          domainCounts[emailDomain].contacts.push(contact);
        }
      }
    });

    const sortedDomains = Object.keys(domainCounts).sort(
      (a, b) => domainCounts[b].count - domainCounts[a].count
    );

    let contactsForRecommendations = [];
    sortedDomains.forEach((domain) => {
      contactsForRecommendations = contactsForRecommendations.concat(
        domainCounts[domain].contacts
      );
    });

    for (let i = contactsForRecommendations.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [contactsForRecommendations[i], contactsForRecommendations[j]] = [
        contactsForRecommendations[j],
        contactsForRecommendations[i],
      ];
    }

    return contactsForRecommendations.slice(0, 10);
  }, [processedContacts]);

  const handleCheckboxChange = (identifier) => {
    setSelectedCompanies((prevSelected) =>
      prevSelected.includes(identifier)
        ? prevSelected.filter((name) => name !== identifier)
        : [...prevSelected, identifier]
    );
  };

  const handleOpenDialog = () => {
    if (credits > 0 && selectedCompanies.length > 0) {
      setOpen(true);
    } else {
      alert(
        'Not enough credits or no company selected. Please select at least one company and ask our team for credits if needed.'
      );
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handlePitch = async () => {
    if (!ccEmail) {
      alert('CC Email is required.');
      return;
    }

    setIsPitchLoading(true);

    try {
      const response = await fetch(
        `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/subtract_credits`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            creator_username: creatorUsername,
            email_count: selectedCompanies.length,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
        setIsPitchLoading(false);
        return;
      }

      const data = await response.json();

      setPitchesSent(pitchesSent + selectedCompanies.length);
      setCredits(data.credits);

      for (const identifier of selectedCompanies) {
        // If identifier is a company
        if (processedUsersByCompany && processedUsersByCompany[identifier]) {
          const companyUsers = processedUsersByCompany[identifier];
          for (const user of companyUsers) {
            await fetch(
              `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/send_pitch_email`,
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  recipient_email: user.email,
                  subject,
                  body,
                  cc_email: ccEmail,
                  creator_username: creatorUsername,
                }),
              }
            );
          }
        } else {
          // Otherwise it's a contact
          const contact = processedContacts.find(
            (contact) => contact.email === identifier
          );
          if (contact) {
            await fetch(
              `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/send_pitch_email`,
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  recipient_email: contact.email,
                  subject,
                  body,
                  cc_email: ccEmail,
                  creator_username: creatorUsername,
                }),
              }
            );
          }
        }
      }

      handleCloseDialog();
    } catch (error) {
      console.error('Error sending pitch:', error.message);
    } finally {
      setIsPitchLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Loading and error states for Outbound
  const outboundLoading = (tabValue === 1) && (isLoadingUsers || isLoadingContacts);
  const outboundError = (tabValue === 1) && (errorUsers || errorContacts);

  return (
    <Box
      sx={{
        p: 4,
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
        AI Sales Manager
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Inbound and Outbound tabs" sx={{ mb: 4 }}>
        <Tab label="Inbound" />
        <Tab label="Outbound" />
      </Tabs>

      {tabValue === 0 && (
        <Box>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
            Inbound Emails
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Check out recent inbound brand emails here.
          </Typography>

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
            open={isFetching && !isError}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert severity='error' variant='filled' sx={{ width: '100%' }}>
              {error ? (error.response?.data.error || error.message) : ''}
            </Alert>
          </Snackbar>

          {inboundData && inboundData.emails && inboundData.emails.length > 0 ? (
            <Box sx={{ marginBlockStart: 1, maxWidth: '80em', marginInline: "auto" }}>
              <TableContainer component={Paper} elevation={1}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '15rem' }}>Sender</TableCell>
                      <TableCell sx={{ width: '30rem' }}>Content</TableCell>
                      <HideMobileCell align='right'>Date</HideMobileCell>
                      <HideMobileCell align='right'>Category</HideMobileCell>
                      <TableCell align='center'>Open</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inboundData.emails.map((row, index) => (
                      <StyledTableRow key={index}>
                        <TableCell sx={{ overflowX: 'hidden', width: '15rem', maxWidth: 0 }} align='left'>
                          <span style={styles.singleLine}>{row.sender}</span>
                        </TableCell>
                        <TableCell sx={{ overflowX: 'hidden', width: '30rem', maxWidth: 0 }} align='left'>
                          <span style={styles.singleLine}>
                            {row.subject} - <Typography variant='caption' color='text.secondary'>{row.snippet}</Typography>
                          </span>
                        </TableCell>
                        <HideMobileCell align='right'>
                          <span style={styles.singleLine}>{new Date(row.date_received).toLocaleString()}</span>
                        </HideMobileCell>
                        <HideMobileCell align='right'>
                          <Chip label={row.category} color='primary'></Chip>
                        </HideMobileCell>
                        <TableCell align='center'>
                          <Link href={`https://mail.google.com/mail/u/${row.receiver}/#all/${row.thread_id}`} target="_blank" underline="none">
                            <IconButton>
                              <OpenInNewIcon />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={isDesktop ? 5 : 3}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        count={rowCount}
                        onPageChange={(event, newPage) => setPage(newPage)}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            !isFetching && (
              <Typography sx={{ mt: 2 }}>
                In order to get inbound leads, signup for our partner program in your account settings.
              </Typography>
            )
          )}
        </Box>
      )}

      {tabValue === 1 && (
        <Box>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
            Outbound Pitches
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Select target companies or contacts to pitch your brand collaboration opportunities.
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 4,
            }}
          >
            <Typography variant="body1">Pitches Sent: {pitchesSent}</Typography>
            <Typography variant="body1">Credits: {credits}</Typography>
          </Box>

          {credits === 0 && (
            <Alert severity="info" sx={{ mb: 4 }}>
              If you want to pitch to brands, ask our team for some credits.
            </Alert>
          )}

          {outboundLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          )}

          {outboundError && (
            <Typography sx={{ color: 'error.main', mb: 4 }}>
              {errorUsers ? `Error fetching users: ${errorUsers.message}` : ''}
              {errorContacts ? `Error fetching contacts: ${errorContacts.message}` : ''}
            </Typography>
          )}

          {!outboundLoading && !outboundError && processedUsersByCompany && processedContacts && (
            <React.Fragment>
              {/* Recommendations */}
              <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Recommendations
              </Typography>
              <TableContainer component={Paper} elevation={2} sx={{ mb: 6 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedCompanies.length > 0 &&
                            selectedCompanies.length < recommendations.length
                          }
                          checked={
                            recommendations.length > 0 &&
                            selectedCompanies.length === recommendations.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedCompanies((prev) => [
                                ...prev,
                                ...recommendations.map((contact) => contact.email),
                              ]);
                            } else {
                              setSelectedCompanies((prev) =>
                                prev.filter(
                                  (id) =>
                                    !recommendations.map((contact) => contact.email).includes(id)
                                )
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Domain</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recommendations.length > 0 ? (
                      recommendations.map((contact) => {
                        if (!contact.email) return null;
                        const identifier = contact.email;
                        const displayName =
                          contact.company_name || getEmailDomain(contact.email);
                        const domain = getEmailDomain(contact.email);
                        return (
                          <TableRow key={contact.id}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedCompanies.includes(identifier)}
                                onChange={() => handleCheckboxChange(identifier)}
                              />
                            </TableCell>
                            <TableCell>{displayName}</TableCell>
                            <TableCell>
                              <Link
                                href={`https://${domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {domain}
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography>No recommendations available</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Verified Blitz Users */}
              <Typography variant="h6" gutterBottom>
                Verified Blitz Users - 2 credits per pitch
              </Typography>
              <TableContainer component={Paper} sx={{ mb: 6 }} elevation={2}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedCompanies.length > 0 &&
                            selectedCompanies.length <
                              Object.keys(processedUsersByCompany || {}).length
                          }
                          checked={
                            processedUsersByCompany &&
                            Object.keys(processedUsersByCompany).length > 0 &&
                            selectedCompanies.length ===
                              Object.keys(processedUsersByCompany).length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedCompanies((prev) => [
                                ...prev,
                                ...Object.keys(processedUsersByCompany),
                              ]);
                            } else {
                              setSelectedCompanies((prev) =>
                                prev.filter(
                                  (id) =>
                                    !Object.keys(processedUsersByCompany).includes(id)
                                )
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Domain</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processedUsersByCompany &&
                    Object.keys(processedUsersByCompany).length > 0 ? (
                      Object.keys(processedUsersByCompany).map((companyName) => {
                        const identifier = companyName;
                        const user = processedUsersByCompany[companyName][0];
                        const domain = getEmailDomain(user.email);
                        return (
                          <TableRow key={companyName}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedCompanies.includes(identifier)}
                                onChange={() => handleCheckboxChange(identifier)}
                              />
                            </TableCell>
                            <TableCell>{companyName}</TableCell>
                            <TableCell>
                              <Link
                                href={`https://${domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {domain}
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography>No users available</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Culture Club Connections */}
              <Typography variant="h6" gutterBottom>
                Culture Club Connections - 1 credit per pitch
              </Typography>
              <TableContainer component={Paper} elevation={2}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedCompanies.length > 0 &&
                            selectedCompanies.length < processedContacts.length
                          }
                          checked={
                            processedContacts && processedContacts.length > 0 &&
                            selectedCompanies.length === processedContacts.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedCompanies((prev) => [
                                ...prev,
                                ...processedContacts.map((contact) => contact.email),
                              ]);
                            } else {
                              setSelectedCompanies((prev) =>
                                prev.filter(
                                  (id) =>
                                    !processedContacts.map((contact) => contact.email).includes(id)
                                )
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Domain</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processedContacts && processedContacts.length > 0 ? (
                      processedContacts.map((contact) => {
                        if (!contact.email) return null;
                        const identifier = contact.email;
                        const displayName =
                          contact.company_name || getEmailDomain(contact.email);
                        const domain = getEmailDomain(contact.email);
                        return (
                          <TableRow key={contact.id}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedCompanies.includes(identifier)}
                                onChange={() => handleCheckboxChange(identifier)}
                              />
                            </TableCell>
                            <TableCell>{displayName}</TableCell>
                            <TableCell>
                              <Link
                                href={`https://${domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {domain}
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography>No contacts available</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {selectedCompanies.length > 0 && credits > 0 && (
                <Fab
                  color="primary"
                  aria-label="Pitch"
                  onClick={handleOpenDialog}
                  sx={{ position: 'fixed', bottom: 16, right: 16 }}
                >
                  <SendIcon />
                </Fab>
              )}

              <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Send Pitch</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please enter the details for your pitch.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Subject"
                    fullWidth
                    variant="outlined"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  <TextField
                    margin="dense"
                    label="Body"
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={6}
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    helperText="Write something meaningful for brands to know about your page! They will be sent instructions on how to book."
                  />
                  <TextField
                    margin="dense"
                    label="Rate"
                    fullWidth
                    variant="outlined"
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                  />
                  <TextField
                    margin="dense"
                    label="Your Email (CC)"
                    fullWidth
                    variant="outlined"
                    required
                    value={ccEmail}
                    onChange={(e) => setCcEmail(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog}>Cancel</Button>
                  <Button
                    variant="contained"
                    onClick={handlePitch}
                    disabled={isPitchLoading}
                  >
                    {isPitchLoading ? (
                      <CircularProgress size={24} sx={{ color: 'white' }} />
                    ) : (
                      'Send Pitch'
                    )}
                  </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Pitch;
