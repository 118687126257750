import React, { useState } from 'react';
import {
    Box, Button, Dialog, DialogActions, DialogContent,
    DialogTitle, TextField, MenuItem, Typography, CircularProgress, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import client from '../../../../../API'; // Adjust the import path as necessary

const BlastEmail = () => {
    const [open, setOpen] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [senders, setSenders] = useState([]);
    const [emailData, setEmailData] = useState({
        creatorUsername: '',
        subject: '',
        body: '',
        recipients: '',
    });
    const [error, setError] = useState('');

    // Fetch all email blasts
    const { data: emailBlasts, isLoading: loadingEmailBlasts, refetch: refetchEmailBlasts } = useQuery(
        'fetchEmailBlasts',
        () => client.creatorConnect.fetchEmailBlasts(),
        {
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    // Fetch the sender emails
    const fetchSenders = async () => {
        try {
            setError('');
            const res = await client.creatorConnect.getAuthorizedSenders();
            setSenders(res);
        } catch (err) {
            console.error('Error fetching sender emails:', err);
            setError('Failed to load sender emails. Please try again later.');
        }
    };

    // Mutation to send bulk email
    const sendBulkEmailMutation = useMutation(
        (emailData) => client.creatorConnect.sendBulkEmail(emailData),
        {
            onSuccess: () => {
                alert('Emails sent successfully!');
                handleClose();
                refetchEmailBlasts(); // Refresh email blasts table after sending
            },
            onError: (err) => {
                console.error('Error sending emails:', err);
                alert('Failed to send emails. Please check the details and try again.');
            },
        }
    );

    const handleOpen = () => {
        setOpen(true);
        fetchSenders();
    };

    const handleClose = () => {
        setOpen(false);
        setEmailData({
            creatorUsername: '',
            subject: '',
            body: '',
            recipients: '',
        });
        setSenders([]);
        setError('');
    };

    const handlePreviewOpen = () => {
        setPreviewOpen(true);
    };

    const handlePreviewClose = () => {
        setPreviewOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmailData({ ...emailData, [name]: value });
    };

    const handleSendEmail = () => {
        const { creatorUsername, subject, body, recipients } = emailData;

        if (!creatorUsername || !subject || !body || !recipients) {
            alert('Please fill in all fields.');
            return;
        }

        // Split and validate recipient emails
        const recipientList = recipients
            .split(',')
            .map((email) => email.trim())
            .filter((email) => email.includes('@')); // Basic validation for emails

        if (recipientList.length === 0) {
            alert('Please provide valid email addresses.');
            return;
        }

        // Ensure line breaks and styling are preserved
        const styledBody = body.replace(/\n/g, '<br />');

        sendBulkEmailMutation.mutate({
            creator_username: creatorUsername,
            subject,
            body: styledBody,
            recipients: recipientList, // Send as array to backend
        });
    };

    return (
        <Box>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Blast Email
            </Button>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Blast Email</DialogTitle>
                <DialogContent>
                    {loadingEmailBlasts ? (
                        <CircularProgress />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <>
                            <TextField
                                name="creatorUsername"
                                label="Creator Username"
                                select
                                fullWidth
                                value={emailData.creatorUsername}
                                onChange={handleChange}
                                margin="normal"
                            >
                                {senders.length > 0 ? (
                                    senders.map((sender) => (
                                        <MenuItem key={sender.username} value={sender.username}>
                                            {sender.username} ({sender.email || 'No Email'})
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>No senders available</MenuItem>
                                )}
                            </TextField>
                            <TextField
                                name="subject"
                                label="Subject"
                                fullWidth
                                value={emailData.subject}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                name="body"
                                label="Body"
                                multiline
                                rows={4}
                                fullWidth
                                value={emailData.body}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                name="recipients"
                                label="Recipients (comma-separated emails)"
                                fullWidth
                                value={emailData.recipients}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <Button 
                                variant="outlined" 
                                color="secondary" 
                                onClick={handlePreviewOpen} 
                                style={{ marginTop: '10px' }}
                            >
                                Preview Email
                            </Button>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={handleSendEmail}
                        color="primary"
                        variant="contained"
                        disabled={sendBulkEmailMutation.isLoading}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={previewOpen} onClose={handlePreviewClose} maxWidth="md" fullWidth>
                <DialogTitle>Email Preview</DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            backgroundColor: '#f4f4f4',
                            padding: '20px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                        }}
                        dangerouslySetInnerHTML={{
                            __html: `
                                <div style="font-family: Arial, sans-serif; max-width: 600px; margin: auto; background: #ffffff; padding: 20px; border-radius: 8px;">
                                    <div style="text-align: center; background-color: #000000; color: #ffffff; padding: 20px 0; border-radius: 8px 8px 0 0;">
                                        <img src="placeholder-image.png" alt="Creator Profile Picture" style="max-width: 150px; border-radius: 50%; margin-bottom: 10px;">
                                        <h1>${emailData.subject}</h1>
                                    </div>
                                    <div style="margin: 20px 0; color: #000000;">
                                        <p>${emailData.body.replace(/\n/g, '<br />')}</p>
                                    </div>
                                    <div style="margin-top: 20px; font-style: italic; color: #000000;">
                                        <p>Regards,</p>
                                        <p>${emailData.creatorUsername} Team</p>
                                    </div>
                                    <div style="text-align: center; margin-top: 20px; font-size: 12px; color: #777;">
                                        <p>© 2025 Blitz Team. All rights reserved.</p>
                                    </div>
                                </div>
                            `
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePreviewClose}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Email Blasts Table */}
            <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                    Email Blasts History
                </Typography>
                {loadingEmailBlasts ? (
                    <CircularProgress />
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Sender</TableCell>
                                    <TableCell>Subject</TableCell>
                                    <TableCell>Body</TableCell>
                                    <TableCell>Send Date</TableCell>
                                    <TableCell>User Id</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {emailBlasts?.email_blasts?.map((blast) => (
                                    <TableRow key={blast.id}>
                                        <TableCell>{blast.id}</TableCell>
                                        <TableCell>{blast.sender}</TableCell>
                                        <TableCell>{blast.subject}</TableCell>
                                        <TableCell>{blast.body}</TableCell>
                                        <TableCell>
                                            {new Date(blast.send_date).toLocaleString()}
                                        </TableCell>
                                        <TableCell>{blast.sender_jwt_identity}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Box>
    );
};

export default BlastEmail;
