import React, { useState } from 'react';
import { useQuery } from 'react-query';
import client from '../../API';
import UploadFileDialog from '../../Components/tools/uploadfile';
import CreateAssetDialog from '../../Components/tools/createasset';
import {
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Paper,
  Divider,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  useTheme
} from '@mui/material';
import {
  PictureAsPdf,
  Description,
  Image,
  UploadFile,
  Add,
  Folder,
  InsertDriveFile
} from '@mui/icons-material';

const AssetsManager = () => {
  const theme = useTheme();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [createAssetDialogOpen, setCreateAssetDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [prettyView, setPrettyView] = useState(true);
  const [activeTab, setActiveTab] = useState("all");

  const { data: files, refetch: refetchFiles } = useQuery('files', () => client.files.fetch(''));


  const openUploadDialog = () => setUploadDialogOpen(true);
  const closeUploadDialog = () => {
    setUploadDialogOpen(false);
    refetchFiles();
  };

  const openCreateAssetDialog = () => setCreateAssetDialogOpen(true);
  const closeCreateAssetDialog = () => {
    setCreateAssetDialogOpen(false);
    refetchFiles();
  };

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const togglePrettyView = () => setPrettyView((prev) => !prev);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const isImageFile = (filename) => {
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'];
    return imageExtensions.some(ext => filename.toLowerCase().endsWith(ext));
  };

  const filterFiles = (files, filter) => {
    if (!files) return [];
    switch (filter) {
      case 'html':
        return files.filter(file => file.filename.toLowerCase().endsWith('.html'));
      case 'pdf':
        return files.filter(file => file.filename.toLowerCase().endsWith('.pdf'));
      case 'image':
        return files.filter(file => isImageFile(file.filename));
      case 'misc':
        return files.filter(file => {
          const filename = file.filename.toLowerCase();
          return !filename.endsWith('.html') && !filename.endsWith('.pdf') && !isImageFile(filename);
        });
      default:
        return files;
    }
  };

  const renderFileIcon = (filename) => {
    if (isImageFile(filename)) {
      return <Image fontSize="large" />;
    }
    if (filename.toLowerCase().endsWith('.pdf')) {
      return <PictureAsPdf fontSize="large" />;
    }
    if (filename.toLowerCase().endsWith('.html') || filename.toLowerCase().endsWith('.htm')) {
      return <Description fontSize="large" />;
    }
    return <Description fontSize="large" />;
  };

  const renderFilePreview = (file) => {
    if (isImageFile(file.filename)) {
      return (
        <img
          src={file.file_url}
          alt={file.filename}
          style={{ width: '100%', height: 'auto', maxHeight: '200px' }}
        />
      );
    } else {
      return renderFileIcon(file.filename);
    }
  };

  const filteredFiles = filterFiles(files, activeTab);

  return (
    <Box sx={{ p: 4, maxWidth: 1600, margin: '0 auto' }}>
      <Box sx={{
        mb: 4,
        p: 4,
        borderRadius: 4,
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
        boxShadow: 3
      }}>
        <Typography variant="h3" component="h1" sx={{ 
          color: 'white', 
          mb: 2,
          fontWeight: 700,
          letterSpacing: 1
        }}>
          Digital Assets
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={openUploadDialog}
            startIcon={<UploadFile />}
            sx={{
              bgcolor: 'white',
              color: theme.palette.primary.main,
              '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' }
            }}
          >
            Upload New
          </Button>
          <Button
            variant="contained"
            onClick={openCreateAssetDialog}
            startIcon={<Add />}
            sx={{
              bgcolor: 'white',
              color: theme.palette.primary.main,
              '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' }
            }}
          >
            Create Asset
          </Button>
        </Box>
      </Box>

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 4 
      }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTabs-indicator': {
              height: 3,
              borderRadius: 2
            }
          }}
        >
          <Tab label="All Assets" value="all" icon={<Folder />} />
          <Tab label="HTML" value="html" icon={<Description />} />
          <Tab label="PDF" value="pdf" icon={<PictureAsPdf />} />
          <Tab label="Images" value="image" icon={<Image />} />
          <Tab label="Other" value="misc" icon={<InsertDriveFile />} />
        </Tabs>

        <FormControlLabel
          control={
            <Switch 
              checked={prettyView} 
              onChange={togglePrettyView}
              color="secondary"
            />
          }
          label="Gallery View"
          sx={{ ml: 2 }}
        />
      </Box>

      {prettyView ? (
        <Grid container spacing={3}>
          {filteredFiles?.map((file) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={file.id}>
              <Card
                onClick={() => window.open(file.file_url, '_blank')}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 6,
                    cursor: 'pointer'
                  }
                }}
              >
                {renderFilePreview(file)}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {file.filename}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {new Date(file.created_at).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: 4 }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead sx={{ bgcolor: theme.palette.action.selected }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>File Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Type</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Upload Date</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFiles?.map((file) => (
                <TableRow 
                  key={file.id}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {renderFileIcon(file.filename)}
                      {file.filename}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={file.filename.split('.').pop().toUpperCase()}
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(file.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="outlined" 
                      size="small"
                      onClick={() => window.open(file.file_url, '_blank')}
                    >
                      Preview
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <UploadFileDialog open={uploadDialogOpen} onClose={closeUploadDialog} />
      <CreateAssetDialog open={createAssetDialogOpen} onClose={closeCreateAssetDialog} />
    </Box>
  );
};

export default AssetsManager;