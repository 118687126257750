// DealIOCardList.js
import React, { memo } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  CardMedia,
  Checkbox,
  IconButton,
  Chip,
  Avatar,
  Stack,
  Divider,
  useTheme,
  Box // Add this import
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import profilePhoto from '../../Components/globalAssets/ppfLogo.png';

const statusColorMap = {
  Paid: 'success',
  Unpaid: 'warning',
  Completed: 'info',
  Overdue: 'error'
};

const getStatus = (amountDue, amountPaid) => {
  const remaining = amountDue - amountPaid;
  if (remaining <= 0) return 'Paid';
  if (remaining > 0 && new Date() < new Date()) return 'Overdue';
  return 'Unpaid';
};

const DealIOCardList = memo(function DealIOCardList({
  dealIOs,
  handleOpenDialog,
  handleShareDealIO,
  handleCopyDealIO,
  handleCheckboxChange,
  selectedDealIOs = [],
}) {
  const theme = useTheme();

  return (
    <Grid container spacing={3}>
      {dealIOs.map((dealIO) => {
        const owner = dealIO.user_data.find((user) => user.role === 'Owner');
        const status = getStatus(dealIO.amount_due, dealIO.amount_paid);
        
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={dealIO.id}>
            <Card sx={{ 
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: 6
              }
            }}>
              <Box sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.getContrastText(theme.palette.primary.main),
                p: 1,
                textAlign: 'center',
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4
              })}>
                <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                  Deal IO
                </Typography>
              </Box>

              <CardMedia
                component="img"
                height="200"
                image={profilePhoto}
                alt={owner?.name || 'No Owner'}
                onClick={() => handleOpenDialog(dealIO)}
                sx={{ 
                  cursor: 'pointer',
                  objectFit: 'cover',
                  borderBottom: `1px solid ${theme.palette.divider}`
                }}
              />
              
              <CardContent onClick={() => handleOpenDialog(dealIO)} sx={{ cursor: 'pointer', flexGrow: 1 }}>
                <Stack spacing={1}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Chip 
                      label={status} 
                      color={statusColorMap[status]} 
                      size="small" 
                      sx={{ fontWeight: 'bold' }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      #{dealIO.id}
                    </Typography>
                  </Stack>

                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {dealIO.name || 'Untitled Deal'}
                  </Typography>

                  {owner && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar sx={{ width: 24, height: 24, fontSize: '0.8rem' }}>
                        {owner.name.charAt(0)}
                      </Avatar>
                      <Typography variant="body2">
                        {owner.name}
                      </Typography>
                    </Stack>
                  )}

                  <Divider sx={{ my: 1 }} />

                  <Stack spacing={0.5}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body2" fontWeight="bold">Vendor ID:</Typography>
                      <Typography variant="body2">{dealIO.creator_vendor_id}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body2" fontWeight="bold">Due:</Typography>
                      <Typography variant="body2">
                        ${parseFloat(dealIO.amount_due).toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body2" fontWeight="bold">Paid:</Typography>
                      <Typography variant="body2" color="success.main">
                        ${parseFloat(dealIO.amount_paid).toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body2" fontWeight="bold">Deliverables:</Typography>
                      <Typography variant="body2">
                        {dealIO.deliverables.length}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </CardContent>

              <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
                <Checkbox
                  checked={selectedDealIOs.includes(dealIO.id)}
                  onChange={() => handleCheckboxChange(dealIO.id)}
                  size="small"
                />
                <Stack direction="row" spacing={1}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShareDealIO(dealIO.id);
                    }}
                    size="small"
                  >
                    <ShareIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyDealIO(dealIO.id);
                    }}
                    size="small"
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
});

export default DealIOCardList;