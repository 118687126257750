import React, { useState } from 'react';
import { Box, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useIsDesktop from '../../Hooks/useIsDesktop';
import DocsNavbar from './DocsNavbar';
import HomeHeader from '../../Components/HomeHeader';
import { docsDrawerWidth, drawerWidth } from '../../Utils/constants';

const Scaffolding = ({ children, containerStyle = {}, onHelp }) => {
    const isDesktop = useIsDesktop();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <DocsNavbar isOpen={isOpen} isDesktop={isDesktop} onClose={() => setIsOpen(false)}></DocsNavbar>
            <HomeHeader
                menuButton={!isDesktop &&
                    <IconButton edge="start" onClick={() => setIsOpen(true)} sx={{ color: '#000' }}>
                        <MenuIcon />
                    </IconButton>
                }></HomeHeader>
            <Toolbar></Toolbar>
            <Box
                id='TopPageContainer'
                sx={{
                    ...containerStyle,
                    minWidth: {
                        xs: '100%',
                        md: `calc(100% - ${docsDrawerWidth})`,
                    },
                    marginInlineStart: {
                        xs: 0,
                        md: docsDrawerWidth,
                    },
                }}>
                {children}
            </Box>
        </>
    );
};

export default Scaffolding;
