import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  CircularProgress
} from '@mui/material';
import { Share, Send } from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import client from '../../../../API';

const ShareCampaignDialog = ({ 
  open, 
  onClose, 
  campaignDetails, 
  email, 
  setEmail 
}) => {
  const queryClient = useQueryClient();
  const { mutate: campaignShare, isLoading: isSharing } = useMutation(
    (input) => client.campaigns.share(campaignDetails?.id, input),
    {
      onSuccess: () => {
        alert("Campaign shared successfully!");
        onClose();
        queryClient.invalidateQueries(['campaign', campaignDetails?.id]);
      },
      onError: (error) => {
        alert(`Error sharing campaign: ${error.response?.data?.error || error.message}`);
      },
    }
  );

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleShare = () => {
    if (validateEmail(email)) {
      campaignShare({ email });
    } else {
      alert("Please enter a valid email address");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ 
        bgcolor: 'primary.main', 
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
        <Share fontSize="small" />
        Share Campaign
        <Typography variant="subtitle2" sx={{ color: 'white', ml: 1 }}>
          {campaignDetails?.name}
        </Typography>
      </DialogTitle>
      
      <DialogContent sx={{ mt: 2 }}>
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          label="Recipient Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: isSharing && <CircularProgress size={20} />
          }}
        />
        
        {campaignDetails?.shared_with?.length > 0 && (
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="subtitle2" gutterBottom>
                Already shared with:
              </Typography>
              <Box sx={{ maxHeight: 150, overflow: 'auto' }}>
                {campaignDetails.shared_with.map((email, index) => (
                  <Chip
                    key={index}
                    label={email}
                    size="small"
                    sx={{ m: 0.5 }}
                    onDelete={() => console.log('TODO: Implement unshare')}
                  />
                ))}
              </Box>
            </CardContent>
          </Card>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button 
          onClick={onClose} 
          color="secondary"
          disabled={isSharing}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          onClick={handleShare}
          startIcon={<Send />}
          disabled={!email || isSharing}
        >
          {isSharing ? 'Sharing...' : 'Share Campaign'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareCampaignDialog;