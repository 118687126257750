// CreatorHeader.jsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';

import blitzLogo from './globalAssets/logo_placeholder.png';
import routes from "../Config/routes";

const CreatorHeader = ({ menuIcon }) => {
  const navigate = useNavigate();

  return (
    <AppBar position="fixed" id="CreatorHeader" sx={{ bgcolor: 'primary.main'}}>
      <Toolbar>
        {/* Menu Button */}
        {menuIcon}

        {/* Blitz Logo */}
        <IconButton
          color="inherit"
          aria-label="logo"
          onClick={() => navigate(routes.creatorConnectStart)}
          sx={{ p: 0, mr: 2 }}
        >
          <img
            src={blitzLogo}
            alt="Blitz Logo"
            style={{ height: "40px" }}
          />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default CreatorHeader;
