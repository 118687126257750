import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import client from "../../../../API";
import { useQuery } from "react-query";
import {
  Typography,
  Box,
  Divider,
  Toolbar,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Backdrop,
  Button
} from "@mui/material";
import TabPanel from "../../../../Components/TabPanel.js";
import useIsDesktop from "../../../../Hooks/useIsDesktop.js";
import PageTitle from "../../../../Components/PageTitle.js";
import CRMDialog from "../../../Misc/crmComponents/crmPopup.js";
import HomeHeader from "../../../../Components/HomeHeader.js";
import AgencyDetailsPanel from "./agencydetails.js";

const AgencyDetailsPage = () => {
  const { manager } = useParams(); // manager = agencyName
  const [agencyDetails, setAgencyDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [viewMode, setViewMode] = useState("pretty");
  const [showCRMDialog, setShowCRMDialog] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const isDesktop = useIsDesktop();
  const hasShownMessage = useRef(false);

  // Store roster settings in state
  const [rosterSettings, setRosterSettings] = useState(null);

  // 1) Fetch roster settings from public endpoint (or non-auth)
  const {
    isLoading: isLoadingSettings,
    isError: isSettingsError
  } = useQuery(
    ["publicRosterSettings", manager],
    () => client.agency.getPublicRosterSettings(manager), 
    {
      onSuccess: (res) => {
        // If we get data, store in state
        setRosterSettings(res);
        console.log("Full Data Report", res)

      },
      onError: (err) => {
        // If 404 => fallback to defaults
        if (err?.response?.status !== 404) {
          console.error("Failed to fetch public roster settings:", err);
        }
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  // 2) Fetch the agency's creators data
  const {
    data,
    error,
    isLoading: isLoadingCreators,
    refetch,
    isFetching
  } = useQuery(
    ["agencyDetails", manager],
    () => client.creators.listManager(manager),
    {
      onSuccess: (data) => {
        setAgencyDetails({ ...data });
      },
      onError: (error) => {
        console.error("Failed to fetch agency details:", error);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  // Tab & view-mode handlers
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleChangeView = (event, newValue) => {
    if (newValue === null) {
      newValue = viewMode === "pretty" ? "list" : "pretty";
    }
    setViewMode(newValue);
  };
  const handleCloseCRMDialog = () => setShowCRMDialog(false);

  if (isLoadingCreators || !agencyDetails) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // 3) Apply roster settings or fallback defaults
  const mainColor = rosterSettings?.color_scheme?.main || "#6F72FF";
  const accentColor = rosterSettings?.color_scheme?.accent || "#BA88FF";
  const rosterName = rosterSettings?.name || `Creator Roster by: ${manager}`;
  const rosterDescription =
    rosterSettings?.description ||
    "A curated list of creators and all their social stats.";
  const rosterLogo = rosterSettings?.logo || null;

  return (
    <>
      <HomeHeader />
      <Toolbar />
      <PageTitle title={`${manager} - Blitz`} />

      {/* Top Section / Hero */}
      <Box
        sx={{
          background: `linear-gradient(135deg, ${mainColor} 20%, ${accentColor} 100%)`,
          color: "#FFF",
          textAlign: "center",
          p: { xs: 4, md: 5 },
          mb: 4,
          borderRadius: 2,
        }}
      >
        {/* Optional Logo */}
        {rosterLogo && (
          <Box sx={{ mb: 2 }}>
            <img
              src={rosterLogo}
              alt="Agency Logo"
              style={{ maxHeight: "80px", objectFit: "contain" }}
            />
          </Box>
        )}

        <Typography variant="h3" gutterBottom>
          {rosterName}
        </Typography>
        <Typography variant="h6">{rosterDescription}</Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            mt: 3,
            flexWrap: "wrap",
          }}
        >
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleChangeView}
            aria-label="creator-view"
          >
            <ToggleButton value="list">List</ToggleButton>
            <ToggleButton value="pretty">Cards</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ mx: { xs: 1, md: 4 }, mb: 4 }}>
        <Paper
          elevation={1}
          sx={{
            width: { xs: "100%", md: "fit-content" },
            mx: "auto",
            mb: 2,
          }}
        >
        
        </Paper>
        <Divider sx={{ mb: 2 }} />

        <TabPanel value={selectedTab} index={0}>
          <AgencyDetailsPanel
            agencyDetails={agencyDetails}
            viewMode={viewMode}
            accentColor={accentColor} // pass accent color to child
          />
        </TabPanel>
      </Box>

      {/* CRM Dialog (optional) */}
      {showCRMDialog && (
        <CRMDialog
          isOpen={showCRMDialog}
          handleClose={handleCloseCRMDialog}
          origin={`${manager} - roster`}
        />
      )}

      {/* Instructions Dialog (optional) */}
      <Dialog open={showInstructions} onClose={() => setShowInstructions(false)}>
        <DialogTitle>Instructions</DialogTitle>
        <DialogContent>
          <Typography>
            Welcome to the Agency Roster page! 
            Use "List" or "Cards" to toggle the display. 
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInstructions(false)}>Got it!</Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetching || isLoadingSettings}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AgencyDetailsPage;
