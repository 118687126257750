import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Switch,
  IconButton,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  TableBody,
  CircularProgress,
  CardActions,
  Pagination,
  Paper,
  Chip,
  Avatar,
  Stack,
  Divider,
  Tooltip
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Delete as DeleteIcon, ContentCopy as CopyIcon, Share as ShareIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import client from "../../../API";
import profilePhoto from "../../../Components/globalAssets/ppfLogo.png";
import { StyledTableRow, StyledTableCell } from "../../../Utils/styledcell";
import useAuth from "../../../Hooks/use-auth";
import useAlertDialog from '../../../Components/useAlertDialog';
import AlertDialog from '../../../Components/AlertDialog';
import useIsDesktop from '../../../Hooks/useIsDesktop';

function formatCampaignSum(sum) {
  const numericSum =
    typeof sum === "number"
      ? sum
      : parseFloat(sum.replace(/[^\d.]/g, ""));
  return `$${numericSum
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

const statusColorMap = {
  Active: 'success',
  Launched: 'success',
  Pending: 'warning',
  Draft: 'warning',
  Completed: 'info',
  Archived: 'default',
  DELETED: 'error',
  deleted: 'error'
};

const Collaborations = () => {
  const isDesktop = useIsDesktop();

  const [searchQuery, setSearchQuery] = useState('');
  const [tabValue, setTabValue] = useState('All');
  const [isPrettyView, setIsPrettyView] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [actionStatus, setActionStatus] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [currentManager, setCurrentManager] = useState("");
  const navigate = useNavigate();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const currentUserId = currentUser.id;
  const userCompany = currentUser.company_name;
  const isTcc = userCompany === "TCC";

  const [page, setPage] = useState(0);
  const itemsPerPage = 20;

  const { data: managers, isLoading: isLoadingManagers, refetch: fetchManagers } = useQuery(
    'managers',
    client.companies.listUsers,
    {
      refetchOnWindowFocus: false,
      initialData: [],
      enabled: isTcc,
    },
  );

  const { data: campaignsData, isLoading: isLoadingCampaigns, refetch: fetchCampaigns } = useQuery(
    'campaigns',
    client.campaigns.list,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: partnershipsData, isLoading: isLoadingPartnerships, refetch: fetchPartnerships } = useQuery(
    'partnerships',
    client.partnerships.list,
    {
      refetchOnWindowFocus: false,
    }
  );

  // Reset page on filter change
  useEffect(() => {
    setPage(0);
  }, [searchQuery, searchStatus, currentManager, tabValue])

  const collaborations = useMemo(() => {
    if (!campaignsData || !partnershipsData) return [];

    const campaignsWithType = campaignsData
      .map(campaign => ({
        ...campaign,
        type: 'campaign',
        pfphref: campaign.creators?.[0]?.pfphref || profilePhoto,
      }));

    const partnershipsWithType = partnershipsData
      .filter(partnership => partnership.status !== 'DELETED')
      .map(partnership => ({
        ...partnership,
        type: 'partnership',
        pfphref: partnership.pfphref || profilePhoto,
      }));

    return [...campaignsWithType, ...partnershipsWithType].sort((a, b) => {
      const dateA = getSortDate(a);
      const dateB = getSortDate(b);
      if (dateB - dateA !== 0) {
        return dateB - dateA; // Sort by proposal_date (most recent first)
      } else {
        const finishDateA = a.finish_date ? new Date(a.finish_date) : null;
        const finishDateB = b.finish_date ? new Date(b.finish_date) : null;
        if (finishDateA && finishDateB) {
          return finishDateA - finishDateB; // Sort by finish_date (earliest first)
        } else {
          return 0;
        }
      }
    }).filter((item) => {
      // Filter archived/deleted if not in Archived tab
      if (tabValue !== 'Archived') {
        if ((item.type === 'campaign' && item.campaign_status === 'Archived') ||
          (item.type === 'partnership' && item.status === 'DELETED')) {
          return false;
        }
      }

      // Filter by tab value
      let tabMatch = true;
      if (tabValue === 'Campaigns') {
        tabMatch = item.type === 'campaign';
      } else if (tabValue === 'Partnerships') {
        tabMatch = item.type === 'partnership';
      } else if (tabValue === 'Archived') {
        if (item.type === 'campaign') {
          tabMatch = item.campaign_status === 'Archived';
        } else if (item.type === 'partnership') {
          tabMatch = item.status === 'DELETED' || item.status === 'Archived';
        }
      }

      let statusMatch = true;
      if (searchStatus !== '') {
        switch (searchStatus) {
          case 'active':
            if (item.type === 'campaign') {
              statusMatch = item.campaign_status === 'Launched';
            } else if (item.type === 'partnership') {
              statusMatch = item.status === 'Active';
            }
            break;
          case 'pending':
            if (item.type === 'campaign') {
              statusMatch = item.campaign_status === 'Draft';
            } else if (item.type === 'partnership') {
              statusMatch = item.status === 'pending';
            }
            break;
          case 'completed':
            if (item.type === 'campaign') {
              statusMatch = item.campaign_status === 'Completed';
            } else if (item.type === 'partnership') {
              statusMatch = item.status === 'completed';
            }
            break;
        }
      }

      // Filter by search query
      const searchMatch = item.name.toLowerCase().includes(searchQuery.toLowerCase());

      // Apply manager filter for campaigns if isTcc is true
      let managerMatch = true;
      if (isTcc && currentManager !== '') {
        managerMatch = item.type === 'campaign' && item.campaign_manager && item.campaign_manager.email === currentManager;
      }

      return tabMatch && searchMatch && managerMatch && statusMatch;
    });
  }, [campaignsData, partnershipsData, profilePhoto, searchQuery, tabValue, currentManager, searchStatus, isTcc]);

  const loading = isLoadingCampaigns || isLoadingPartnerships;

  // Function to get the date for sorting
  function getSortDate(item) {
    const proposalDate = new Date(item.proposal_date);
    const finishDate = item.finish_date ? new Date(item.finish_date) : null;
    if (!isNaN(proposalDate.getTime())) {
      return proposalDate;
    } else if (finishDate && !isNaN(finishDate.getTime())) {
      return finishDate;
    } else {
      return new Date(0);
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { mutate: deleteCampaign } = useMutation(client.campaigns.delete, {
    onSuccess: () => {
      openDialog('Success', "Campaign deleted successfully!", closeDialog, closeDialog, 'Ok');
      fetchCampaigns();
    },
    onError: (error) => {
      console.error("Error deleting campaign:", error);
      openDialog('Error', <>Error deleting campaign:<br />{error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, 'Ok');
    },
  });

  const { mutate: updateCampaignStatus } = useMutation(client.campaigns.update, {
    onSuccess: () => {
      openDialog('Success', "Campaign status updated successfully!", closeDialog, closeDialog, 'Ok');
      fetchCampaigns();
    },
    onError: (error) => {
      console.error("Error updating campaign:", error);
      openDialog('Error', <>Error updating status:<br />{error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, 'Ok');
    },
  });

  const { mutate: updatePartnershipStatus } = useMutation(
    ({ id, status }) => client.partnerships.updateStatus(id, { status }),
    {
      onSuccess: () => {
        openDialog('Success', "Partnership status updated successfully!", closeDialog, closeDialog, 'Ok');
        fetchPartnerships();
      },
      onError: (error) => {
        console.error("Error updating partnership status:", error);
        openDialog('Error', <>Error updating status:<br />{error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, 'Ok');
      },
    }
  );

  const applyAction = () => {
    if (selectedIds.length === 0) {
      openDialog("Info", "Please select items to apply action.", closeDialog, closeDialog, 'Ok');
      return;
    }
    if (actionStatus.length === 0) {
      openDialog("Info", "Please choose an action.", closeDialog, closeDialog, 'Ok');
      return;
    }

    selectedIds.forEach((id) => {
      const [type, itemId] = id.split('-');
      if (type === 'campaign') {
        if (actionStatus === 'delete' || actionStatus === 'DELETED') {
          deleteCampaign({ id: [itemId] });
        } else {
          const status = actionStatus;
          updateCampaignStatus({ campaignIds: [parseInt(itemId)], status });
        }
      } else if (type === 'partnership') {
        if (actionStatus === 'delete' || actionStatus === 'DELETED') {
          updatePartnershipStatus({ id: itemId, status: 'DELETED' });
        } else {
          updatePartnershipStatus({ id: itemId, status: actionStatus });
        }
      }
    });

    setSelectedIds([]);
  };

  const deleteOnConfirm = (item) => {
    if (item.type === 'campaign') {
      deleteCampaign({ id: [item.id] });
    } else if (item.type === 'partnership') {
      updatePartnershipStatus({ id: item.id, status: 'DELETED' });
    }
  }

  const handleDelete = (item) => {
    openDialog('Confirm', <>Are you sure you want to delete "{item.name}"?</>, () => deleteOnConfirm(item), closeDialog, 'Yes', 'No');
  };

  const handleItemClick = (item) => {
    if (item.type === 'campaign') {
      navigate(`/editcampaign/${item.id}`);
    } else if (item.type === 'partnership') {
      navigate(`/partnerships/${item.id}`);
    }
  };

  const handleSelectChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleShareCampaign = useCallback((campaignId) => {
    const url = `${window.location.origin}/campaigns/${campaignId}`;
    navigator.clipboard.writeText(url);
    openDialog("Info", "Share link copied to clipboard!", closeDialog, closeDialog, 'Ok');
  }, [closeDialog, openDialog]);

  const handleCopyCampaign = useCallback(async (campaignId) => {
    try {
      const data = await client.campaigns.copy({ campaignId });
      openDialog("Success", data.message, closeDialog, closeDialog, 'Ok');
      fetchCampaigns();
    } catch (error) {
      console.error("Failed to copy campaign:", error);
      openDialog("Error", `Error copying campaign:\n${error?.response?.data?.error || error.message}`, closeDialog, closeDialog, 'Ok');
    }
  }, [fetchCampaigns, closeDialog, openDialog]);

  const getStatusChip = (item) => {
    const status = item.type === 'campaign' ? item.campaign_status : item.status;
    const label = status ? status.charAt(0).toUpperCase() + status.slice(1) : 'N/A';
    const color = statusColorMap[status] || 'default';
    return <Chip label={label} color={color} size="small" />;
  };

  return (
    <>
      <AlertDialog alertState={dialogState}></AlertDialog>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper square elevation={4} sx={{ marginBlockEnd: 2, paddingBlockStart: 2 }}>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ textAlign: "center", mb: 2 }}
            >
              Your Collaborations
            </Typography>

            {/* Filters */}
            <Box
              sx={{
                width: "100%",
                overflowX: "auto",
                paddingInline: 2, 
                scrollbarWidth:'thin'
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2, width: 'fit-content', margin: 'auto'
               }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search by name"
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                  sx={{ minWidth: 240 }}
                />

                <TextField
                  select
                  value={searchStatus}
                  onChange={(event) => setSearchStatus(event.target.value)}
                  label="Search by status"
                  variant="outlined" size="small" sx={{ minWidth: 240 }}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="active">Active/Launched</MenuItem>
                  <MenuItem value="pending">Pending/Draft</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                </TextField>

                {isTcc && managers && managers.length > 0 && (
                  <FormControl variant="outlined" size="small" sx={{ minWidth: 240 }}>
                    <InputLabel id="label-managers">Manager</InputLabel>
                    <Select
                      value={currentManager}
                      onChange={(e) => setCurrentManager(e.target.value)}
                      label="Manager"
                    >
                      <MenuItem value={""}>None</MenuItem>
                      {managers.map((manager, index) => (
                        <MenuItem value={manager.email} key={index}>
                          {manager.first_name} {manager.last_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <Typography>Simple View</Typography>
                <Switch
                  checked={!isPrettyView}
                  onChange={(event) => setIsPrettyView(!event.target.checked)}
                  color="secondary"
                />

                <FormControl variant="outlined" size="small" sx={{ minWidth: 240 }}>
                  <InputLabel id="action-status-label">Change Status To</InputLabel>
                  <Select
                    labelId="action-status-label"
                    value={actionStatus}
                    onChange={(event) => setActionStatus(event.target.value)}
                    label="Change Status To"
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="Archived">Archived</MenuItem>
                    <MenuItem value="DELETED">Delete</MenuItem>
                  </Select>
                </FormControl>

                <Button
                  variant="contained"
                  onClick={applyAction}
                  disabled={selectedIds.length === 0}
                >
                  Apply
                </Button>
              </Box>
            </Box>

            {/* Tabs */}
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered={isDesktop}
              allowScrollButtonsMobile={true}
              variant={isDesktop ? 'standard' : 'scrollable'}
            >
              <Tab label="All" value="All" />
              <Tab label="Campaigns" value="Campaigns" />
              <Tab label="Partnerships" value="Partnerships" />
              <Tab label="Archived" value="Archived" />
            </Tabs>
          </Paper>

          {/* Display content */}
          <Box>
            {isPrettyView ? (
              <Box sx={{marginInline:{xs:1, md:2}}}>
                {collaborations.length > 0 ? (
                  <Grid container spacing={2}>
                    {collaborations.slice(page * itemsPerPage, Math.min((page * itemsPerPage) + itemsPerPage, collaborations.length)).map((item) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={`${item.type}-${item.id}`}>
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: '100%',
                            boxShadow: 3,
                            transition: 'transform 0.2s',
                            '&:hover': { transform: 'translateY(-5px)' }
                          }}
                        >
                          <Box
                            sx={(theme) => ({
                              backgroundColor: item.type === 'campaign' ? theme.palette.secondary.main : theme.palette.primary.main,
                              color: theme.palette.getContrastText(item.type === 'campaign' ? theme.palette.secondary.main : theme.palette.primary.main),
                              p: 1,
                              textAlign: 'center',
                              borderTopLeftRadius: 4,
                              borderTopRightRadius: 4,
                            })}
                          >
                            <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                              {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                            </Typography>
                          </Box>

                          <CardMedia
                            component="img"
                            height="250"
                            image={item.pfphref || profilePhoto}
                            alt={item.name}
                            onClick={() => handleItemClick(item)}
                            sx={{ cursor: "pointer", objectFit: 'cover' }}
                          />

                          <CardContent
                            onClick={() => handleItemClick(item)}
                            sx={{ cursor: "pointer", flexGrow: 1 }}
                          >
                            <Typography variant="h6" component='div' sx={{ fontWeight: 'bold', mb: 1 }}>
                              {item.name}
                            </Typography>

                            <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                              {getStatusChip(item)}
                            </Stack>

                            {item.type === 'campaign' && (
                              <>
                                {item.campaign_manager?.name && (
                                  <Stack direction="row" spacing={1} alignItems="center">
                                    <Avatar sx={{ width: 24, height: 24, fontSize: '0.8rem' }}>
                                      {item.campaign_manager.name.charAt(0)}
                                    </Avatar>
                                    <Typography variant="body2">
                                      Manager: {item.campaign_manager?.name || 'N/A'}
                                    </Typography>
                                  </Stack>
                                )}
                                <Typography variant="body2">
                                  Sum: {formatCampaignSum(item.campaign_sum)}
                                </Typography>
                                {isTcc && (
                                  <Typography variant="body2">
                                    Owner: {item.user?.username}
                                  </Typography>
                                )}
                              </>
                            )}
                            {item.type === 'partnership' && (
                              <>
                                <Typography variant="body2">
                                  Creator: {item.creator}
                                </Typography>
                                {item.total_sum && (
                                  <Typography variant="body2">
                                    Total Sum: ${parseFloat(item.total_sum).toFixed(2)}
                                  </Typography>
                                )}
                                {isTcc && (
                                  <Typography variant="body2">
                                    Owner: {item.email}
                                  </Typography>
                                )}
                              </>
                            )}
                          </CardContent>

                          <Divider />
                          <CardActions sx={{ justifyContent: 'space-between', paddingX: 2 }}>
                            <Checkbox
                              checked={selectedIds.includes(`${item.type}-${item.id}`)}
                              onChange={() => handleSelectChange(`${item.type}-${item.id}`)}
                            />
                            {item.type === 'campaign' && (
                              <Stack direction="row" spacing={1}>
                                <Tooltip title="Share">
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleShareCampaign(item.id);
                                    }}
                                  >
                                    <ShareIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Copy">
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCopyCampaign(item.id);
                                    }}
                                  >
                                    <CopyIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            )}
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDelete(item);
                                }}
                                color="error"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant="h6" align="center" sx={{ mt: 4 }}>
                    No collaborations available
                  </Typography>
                )}
              </Box>
            ) : (
              <TableContainer component={Paper} elevation={1}>
                <Table aria-label="collaborations table" width={"100%"}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Select</StyledTableCell>
                      <StyledTableCell>Type</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>By User</StyledTableCell>
                      <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {collaborations.length > 0 ? (
                      collaborations.slice(page * itemsPerPage, Math.min((page * itemsPerPage) + itemsPerPage, collaborations.length)).map((item) => (
                        <StyledTableRow
                          key={`${item.type}-${item.id}`}
                          hover
                          onClick={() => handleItemClick(item)}
                        >
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              checked={selectedIds.includes(`${item.type}-${item.id}`)}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent the click from bubbling to the row
                                handleSelectChange(`${item.type}-${item.id}`);
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</StyledTableCell>
                          <StyledTableCell>{item.name}</StyledTableCell>
                          <StyledTableCell>
                            {getStatusChip(item)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {(item.type === 'campaign' ? item.user?.username : item.email) || 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(item);
                              }}
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <TableRow>
                        <StyledTableCell colSpan="6" align="center">
                          No collaborations available
                        </StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {collaborations.length > itemsPerPage && (
              <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  count={Math.ceil(collaborations.length / itemsPerPage)}
                  page={page + 1}
                  onChange={(event, value) => setPage(value - 1)}
                  color="primary"
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Collaborations;
