import React, { useState } from 'react';
import { 
    Box, 
    Button, 
    Card, 
    CardContent, 
    Grid, 
    Typography, 
    Avatar, 
    List, 
    ListItem, 
    ListItemAvatar, 
    ListItemText, 
    Divider, 
    Skeleton,
    IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../API';
import routes from '../../Config/routes';
import CreatorDialog from '../Dashboard/Search/creatorintake'; // Ensure the correct path
import useAuth from "../../Hooks/use-auth";
import RosterSettingsDialog from './agencyRosterSettings';
import { Settings as SettingsIcon } from '@mui/icons-material';

export default function AgencyStart() {
    const navigate = useNavigate();
    const [openCreatorDialog, setOpenCreatorDialog] = useState(false);
    const { getCurrrentUser } = useAuth();
    const [openRosterSettings, setOpenRosterSettings] = useState(false); // <--- NEW

    const userInfo = getCurrrentUser();

    // Fetch creators data
    const { data: creators, isLoading: isLoadingCreators } = useQuery({
        queryKey: ['creators'],
        queryFn: () => API.creators.list({ only_agency: true }),
        refetchOnWindowFocus: false
    });

    const agencyName = userInfo?.company_name;

    // Fetch payout data
    const { data: payouts, isLoading: isLoadingPayouts } = useQuery({
        queryKey: ['agency-payouts'],
        queryFn: async () => {
          const response = await API.agency.payouts();
          return response.data;
        },
        refetchOnWindowFocus: false,
    });
      
    // Calculate summary data for creators
    const summaryData = React.useMemo(() => {
        if (!creators) return { total: 0, active: 0, pending: 0 };
        const total = creators.length;
        const active = creators.filter(c => c.status === 'active').length;
        const pending = creators.filter(c => c.status === 'pending').length;
        return { total, active, pending };
    }, [creators]);

    // Filter out cancelled payouts and compute total
    const totalNonCancelledPayouts = React.useMemo(() => {
        if (!payouts) return 0;
        return payouts.filter((p) => p.status !== 'Cancelled').length;
    }, [payouts]);

    // Get recent creators (e.g., last 5)
    const recentCreators = React.useMemo(() => {
        if (!creators) return [];
        return creators.slice(0, 5); // Adjust if needed
    }, [creators]);

    // Handlers for CreatorDialog
    const handleOpenCreatorDialog = () => {
        setOpenCreatorDialog(true);
    };

    const handleCloseCreatorDialog = () => {
        setOpenCreatorDialog(false);
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Agency Dashboard
            </Typography>

            {/* Summary Cards */}
            <Grid container spacing={3} sx={{ marginBottom: 4 }}>
                {/* Total Creators */}
                <Grid item xs={12} sm={4}>
                    <Card elevation={3} sx={{ backgroundColor: '#424242' }}>
                        <CardContent>
                            <Typography variant="h6" color="common.white">
                                Total Creators
                            </Typography>
                            <Typography variant="h3" color="common.white">
                                {isLoadingCreators ? <Skeleton width={40} /> : summaryData.total}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Active Creators */}
                <Grid item xs={12} sm={4}>
                    <Card elevation={3} sx={{ backgroundColor: '#1E88E5' }}>
                        <CardContent>
                            <Typography variant="h6" color="common.white">
                                Active Creators
                            </Typography>
                            <Typography variant="h3" color="common.white">
                                {isLoadingCreators ? <Skeleton width={40} /> : summaryData.active}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Payouts (Non-Cancelled) */}
                <Grid item xs={12} sm={4}>
                    <Card elevation={3} sx={{ backgroundColor: '#FB8C00' }}>
                        <CardContent>
                            <Typography variant="h6" color="common.white">
                                Payouts (Non-Cancelled)
                            </Typography>
                            <Typography variant="h3" color="common.white">
                                {isLoadingPayouts ? <Skeleton width={40} /> : totalNonCancelledPayouts}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Recent Creators */}
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Card elevation={3}>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                <Typography variant="h6">Recent Creators</Typography>
                                <Button 
                                    variant="contained" 
                                    size="small" 
                                    onClick={() => navigate(routes.agencyCreators)}
                                >
                                    View All
                                </Button>
                            </Box>
                            <Divider />
                            <List>
                                {isLoadingCreators && [1, 2, 3, 4, 5].map((item) => (
                                    <ListItem key={item}>
                                        <ListItemAvatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary={<Skeleton width="60%" />} 
                                            secondary={<Skeleton width="40%" />} 
                                        />
                                    </ListItem>
                                ))}

                                {!isLoadingCreators && recentCreators.map((creator) => (
                                    <ListItem key={creator.id}>
                                        <ListItemAvatar>
                                            <Avatar src={creator.pfphref || undefined}>
                                                {!creator.pfphref && creator.creator.charAt(0).toUpperCase()}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary={creator.creator} 
                                            secondary={creator.notes_content_style || 'No additional info'} 
                                        />
                                    </ListItem>
                                ))}

                                {!isLoadingCreators && recentCreators.length === 0 && (
                                    <ListItem>
                                        <ListItemText primary="No recent creators found." />
                                    </ListItem>
                                )}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Quick Actions (Right-hand side menu) */}
                <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ height: '100%' }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Quick Actions
                            </Typography>
                            <Divider sx={{ marginBottom: 2 }} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button 
                                        variant="outlined" 
                                        fullWidth 
                                        onClick={() => navigate(routes.agencyCreators)}
                                        startIcon={<Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>👥</Avatar>}
                                    >
                                        Manage Creators
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button 
                                        variant="outlined" 
                                        fullWidth 
                                        onClick={() => navigate('/dashboard')}
                                        startIcon={<Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>📊</Avatar>}
                                    >
                                        View Reports
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button 
                                        variant="outlined" 
                                        fullWidth 
                                        onClick={() => navigate('/user')}
                                        startIcon={<Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>⚙️</Avatar>}
                                    >
                                        Settings
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button 
                                        variant="outlined" 
                                        fullWidth 
                                        onClick={handleOpenCreatorDialog}
                                        startIcon={<Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>➕</Avatar>}
                                    >
                                        Add Creator
                                    </Button>
                                </Grid>

                                {/* NEW: Settings Icon Button */}
                {/* Settings Icon and Public Roster */}
<Grid item xs={12}>
  <Box 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      gap: 2, 
      flexDirection: 'column'
    }}
  >
    <IconButton 
      color="primary"
      onClick={() => setOpenRosterSettings(true)}
    >
      <SettingsIcon />
    </IconButton>
    <Typography variant="body2">
      Roster Settings
    </Typography>

    <Button 
      variant="contained"
      size="large"
      onClick={() => navigate(`/roster/${agencyName}`)}
    >
      Preview Public Roster
    </Button>
  </Box>
</Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Creator Dialog */}
            <CreatorDialog open={openCreatorDialog} onClose={handleCloseCreatorDialog} />
             {/* NEW: Roster Settings Dialog */}
      <RosterSettingsDialog 
        open={openRosterSettings} 
        onClose={() => setOpenRosterSettings(false)}
        agencyName={agencyName}
      />
        </Box>
    );
}
