import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import TabPanelHidden from "../../../../Components/TabPanelHidden";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import API from "../../../../API"; // where your 'bank' object lives

function PaymentTab({
  tabValue,
  index,
  paymentEmail,
  setPaymentEmail,
  isStripeNew,
  OnClickUpdateStripe,
  stripeUserId,
  handleLinkStripeBankAccount,
  userStatus,
  OnSelectField,
  processEmails,
  setProcessEmails,
}) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  return (
    <TabPanelHidden value={tabValue} index={index} id={`tab-contents-${index}`}>
      <Box sx={{ mb: 2, p: 2, border: "1px solid #ccc" }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          PayPal
        </Typography>
        <TextField
          fullWidth
          margin="dense"
          label="PayPal Payment Email"
          value={paymentEmail}
          onChange={(e) => setPaymentEmail(e.target.value)}
        />
      </Box>

      <Box sx={{ mb: 2, p: 2, border: "1px solid #ccc" }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Stripe (BlitzPay)
        </Typography>

        {stripeUserId && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Stripe ID: <strong>{stripeUserId}</strong>
          </Typography>
        )}

        <StripeSetupIntentForm
          stripe={stripePromise}
          stripeUserId={stripeUserId}
          handleLinkStripeBankAccount={handleLinkStripeBankAccount}
        />

        <Box sx={{ display: "flex", gap: "1rem", mt: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={OnClickUpdateStripe}
          >
            {isStripeNew ? "Add Payment Information" : "Update Payment Information"}
          </Button>
        </Box>
      </Box>

      <FormControl fullWidth margin="dense">
        <InputLabel>Status</InputLabel>
        <Select label="Status" value={userStatus} onChange={OnSelectField}>
          <MenuItem value="Associate">Associate</MenuItem>
          <MenuItem value="Partner">Partner</MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel
        checked={processEmails}
        onChange={() => setProcessEmails((prev) => !prev)}
        control={<Checkbox />}
        label="Enable Auto-emailer"
      />
    </TabPanelHidden>
  );
}

function StripeSetupIntentForm({ stripe, stripeUserId, handleLinkStripeBankAccount }) {
  const [clientSecret, setClientSecret] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripeUserId) return;
    createSetupIntent();
  }, [stripeUserId]);

  const createSetupIntent = async () => {
    try {
        setIsLoading(true);
        const resp = await API.bank.createStripeSetupIntent({ stripe_account_id: stripeUserId });
        const { client_secret } = resp.data;
        if (!client_secret) {
            setStatusMessage("No client_secret returned from server.");
        } else {
            setClientSecret(client_secret);
        }
        setIsLoading(false);
    } catch (error) {
        setStatusMessage(error.response?.data?.error || error.message);
        setIsLoading(false);
    }
};


  if (isLoading) {
    return <Typography>Creating SetupIntent...</Typography>;
  }
  if (!clientSecret) {
    return statusMessage ? (
      <Typography color="error">{statusMessage}</Typography>
    ) : null;
  }

  const elementsOptions = {
    clientSecret,
    appearance: { theme: "stripe" },
  };

  return (
    <Elements stripe={stripe} options={elementsOptions}>
      <SetupFormInner handleLinkStripeBankAccount={handleLinkStripeBankAccount} />
    </Elements>
  );
}

function SetupFormInner({ handleLinkStripeBankAccount }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsConfirming(true);
    setMessage("");

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });

    if (error) {
      setMessage(error.message || "Something went wrong while confirming setup.");
      setIsConfirming(false);
      return;
    }

    if (setupIntent) {
      const { payment_method } = setupIntent;
      try {
        await handleLinkStripeBankAccount({
          stripe_account_id: stripeUserId,
          payment_method_id: payment_method,
      });
        setMessage("Payment method successfully linked!");
      } catch (linkErr) {
        setMessage(linkErr.response?.data?.error || linkErr.message);
      } finally {
        setIsConfirming(false);
      }
    }
  };

  return (
    <Box sx={{ mt: 2, p: 1, border: "1px solid #ccc" }} component="form" onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!stripe || isConfirming}
        sx={{ mt: 2 }}
      >
        {isConfirming ? "Linking..." : "Link Payment Method"}
      </Button>

      {message && (
        <Typography color="error" sx={{ mt: 1 }}>
          {message}
        </Typography>
      )}
    </Box>
  );
}

export default PaymentTab;
