import React from "react";
import { Box, Typography, Container, Toolbar } from "@mui/material";
import HomeHeader from "../../Components/HomeHeader";
import { globalStyles } from "../../Utils/Styles";
import HomeFooter from "../../Components/HomeFooter";
import { StyledDivider as Divider } from "../../Components/LightDivider";

const BlitzSummary = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        color: "#000",
      }}
    >
      <HomeHeader />
      <Toolbar />

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          color: "#000",
          paddingY: { xs: 4, md: 8 },
        }}
      >
        {/* Blitz Summary Header */}
        <Box
          sx={{
            textAlign: "center",
            mb: { xs: 5, md: 8 },
            background: "linear-gradient(135deg, #7b2a2a 0%, #a23c3c 100%)",
            color: "#fff",
            borderRadius: 2,
            py: { xs: 4, md: 6 },
            px: { xs: 2, md: 4 },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              mb: 2,
              textShadow: "0 2px 4px rgba(0,0,0,0.3)",
            }}
          >
            Blitz Summary
          </Typography>
          <Typography variant="h6" sx={{ opacity: 0.9 }}>
            Pay - Create - Accelerate
          </Typography>
        </Box>

        {/* About Blitz Section */}
        <Box
          sx={{
            mb: { xs: 5, md: 8 },
            backgroundColor: "#ffffff",
            borderRadius: 2,
            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
            p: { xs: 3, md: 5 },
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            About Blitz
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
            Blitz is a comprehensive AI-powered collaboration management platform
            designed to streamline operations in the creator economy. By
            offering the world’s largest influencer marketplace, enabling brands
            and agencies to effortlessly launch unlimited campaigns, and
            providing automation for partnerships and financial management,
            Blitz redefines how the creator economy operates.
          </Typography>
        </Box>

        {/* Accounts Payable / Vendors Overview */}
        <Box
          sx={{
            mb: { xs: 5, md: 8 },
            backgroundColor: "#fafafa",
            borderRadius: 2,
            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
            p: { xs: 3, md: 5 },
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Accounts Payable / Vendors Overview
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
            BlitzPay, a key feature within Blitz, enables companies worldwide to
            speed up and enhance their collaboration efforts. By allowing for
            advanced payments to creators for a nominal fee, businesses can
            foster trust, shorten payment cycles, and keep their creative
            workflows running smoothly.
          </Typography>
        </Box>

        {/* Marketplace & Collaboration Features */}
        <Box
          sx={{
            mb: { xs: 5, md: 8 },
            backgroundColor: "#ffffff",
            borderRadius: 2,
            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
            p: { xs: 3, md: 5 },
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Marketplace & Collaboration Features
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
            <strong>AI-Powered Campaign Management:</strong> Leverage data-driven
            insights to choose the perfect collaborators.
            <br />
            <strong>Data-Driven Reporting:</strong> Make informed decisions with
            detailed metrics and analytics.
            <br />
            <strong>Immense Personalization:</strong> Tailor partnerships,
            contracts, and legal arrangements to your unique needs.
            <br />
            <strong>Seamless Integration:</strong> Enjoy efficient workflows
            that integrate with your existing tools.
            <br />
            <strong>Transparency & Compliance:</strong> Negotiate confidently
            with clear pricing and secure compliance.
            <br />
            <strong>Scalability & Growth:</strong> Scale effortlessly as your
            brand or creator network expands.
          </Typography>
        </Box>

        {/* For Creators */}
        <Box
          sx={{
            mb: { xs: 5, md: 8 },
            backgroundColor: "#fafafa",
            borderRadius: 2,
            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
            p: { xs: 3, md: 5 },
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            For Creators
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
            <strong>AI Manager:</strong> Improve your deal flow and discover
            high-quality brand opportunities instantly.
            <br />
            <strong>Access Top Brands:</strong> Collaborate with leading
            companies seeking your creative talents.
            <br />
            <strong>Business Management Suite:</strong> Handle contracts, legal,
            tax, and investments all in one place.
            <br />
            <strong>Financial Services:</strong> Streamline finances, optimize
            taxes, and plan investments for long-term stability.
          </Typography>
        </Box>

        {/* Creators - Increase Your Revenue */}
        <Box
          sx={{
            mb: { xs: 5, md: 8 },
            backgroundColor: "#ffffff",
            borderRadius: 2,
            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
            p: { xs: 3, md: 5 },
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Creators - Increase Your Revenue by 2-3x
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
            By leveraging Blitz’s powerful suite of tools and resources, you can
            dramatically boost your earnings. Creators have reported a 2-3x
            increase in revenue, empowered by streamlined deal flows, improved
            financial management, and better brand partnerships. With Blitz,
            unlocking sustainable income growth is just a few clicks away.
          </Typography>
        </Box>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default BlitzSummary;
