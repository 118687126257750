import React from "react";
import { Typography, Tooltip } from "@mui/material";
import TabPanelHidden from "../../../../Components/TabPanelHidden";
import CreatorPlatforms from "../../../../Components/CreatorData/creatorPlatforms";

// The PlatformsTab handles the "Platforms & Rates" content,
// previously located in the "platforms" <TabPanelHidden>.
function PlatformsTab({
  tabValue,
  index,
  showPlatformsTip,
  // platform states & setters
  platforms,
  setPlatforms,
  instagramLink,
  setInstagramLink,
  instagramFollowerCount,
  setInstagramFollowerCount,
  instagramBrandRate,
  setInstagramBrandRate,
  instagramSongRate,
  setInstagramSongRate,
  instagramFeedRate,
  setInstagramFeedRate,
  instagramStoryRate,
  setInstagramStoryRate,
  tikTokLink,
  setTikTokLink,
  tikTokFollowerCount,
  setTikTokFollowerCount,
  tikTokBrandRate,
  setTikTokBrandRate,
  tikTokSongRate,
  setTikTokSongRate,
  youtubeLink,
  setYoutubeLink,
  youtubeFollowerCount,
  setYoutubeFollowerCount,
  youtubeIntegrationRate,
  setYoutubeIntegrationRate,
  youtubeShortsRate,
  setYoutubeShortsRate,
  youtubeDedicatedRate,
  setYoutubeDedicatedRate,
  xLink,
  setXLink,
  xFollowerCount,
  setXFollowerCount,
  xFeedPost,
  setXFeedPost,
  xRepost,
  setXRepost,
  podcastLink,
  setPodcastLink,
  podcastFollowerCount,
  setPodcastFollowerCount,
  podcastPreroll,
  setPodcastPreroll,
  podcastMidroll,
  setPodcastMidroll,
  podcastPostroll,
  setPodcastPostroll,
  podcastFull,
  setPodcastFull,
  kickLink,
  setKickLink,
  kickFollowerCount,
  setKickFollowerCount,
  kickPlacement,
  setKickPlacement,
  twitchLink,
  setTwitchLink,
  twitchFollowerCount,
  setTwitchFollowerCount,
  twitchPlacement,
  setTwitchPlacement,
}) {
  return (
    <TabPanelHidden
      value={tabValue}
      index={index}
      id={`tab-contents-${index}`}
    >
      <Tooltip
        open={showPlatformsTip}
        title={
          <Typography variant="subtitle1">
            Add rates and links for your platforms here!
          </Typography>
        }
        placement="top"
        arrow
      >
        <Typography variant="h5" sx={{ mb: 1, mt: 1 }}>
          Platforms & Rates
        </Typography>
      </Tooltip>

      <CreatorPlatforms
        // pass the needed props
        platforms={platforms}
        setPlatforms={setPlatforms}
        // Instagram
        instagramLink={instagramLink}
        setInstagramLink={setInstagramLink}
        instagramFollowerCount={instagramFollowerCount}
        setInstagramFollowerCount={setInstagramFollowerCount}
        instagramBrandRate={instagramBrandRate}
        setInstagramBrandRate={setInstagramBrandRate}
        instagramSongRate={instagramSongRate}
        setInstagramSongRate={setInstagramSongRate}
        instagramFeedRate={instagramFeedRate}
        setInstagramFeedRate={setInstagramFeedRate}
        instagramStoryRate={instagramStoryRate}
        setInstagramStoryRate={setInstagramStoryRate}
        // TikTok
        tikTokLink={tikTokLink}
        setTikTokLink={setTikTokLink}
        tikTokFollowerCount={tikTokFollowerCount}
        setTikTokFollowerCount={setTikTokFollowerCount}
        tikTokBrandRate={tikTokBrandRate}
        setTikTokBrandRate={setTikTokBrandRate}
        tikTokSongRate={tikTokSongRate}
        setTikTokSongRate={setTikTokSongRate}
        // YouTube
        youtubeLink={youtubeLink}
        setYoutubeLink={setYoutubeLink}
        youtubeFollowerCount={youtubeFollowerCount}
        setYoutubeFollowerCount={setYoutubeFollowerCount}
        youtubeIntegrationRate={youtubeIntegrationRate}
        setYoutubeIntegrationRate={setYoutubeIntegrationRate}
        youtubeShortsRate={youtubeShortsRate}
        setYoutubeShortsRate={setYoutubeShortsRate}
        youtubeDedicatedRate={youtubeDedicatedRate}
        setYoutubeDedicatedRate={setYoutubeDedicatedRate}
        // X
        xLink={xLink}
        setXLink={setXLink}
        xFollowerCount={xFollowerCount}
        setXFollowerCount={setXFollowerCount}
        xFeedPost={xFeedPost}
        setXFeedPost={setXFeedPost}
        xRepost={xRepost}
        setXRepost={setXRepost}
        // Podcast
        podcastLink={podcastLink}
        setPodcastLink={setPodcastLink}
        podcastFollowerCount={podcastFollowerCount}
        setPodcastFollowerCount={setPodcastFollowerCount}
        podcastPreroll={podcastPreroll}
        setPodcastPreroll={setPodcastPreroll}
        podcastMidroll={podcastMidroll}
        setPodcastMidroll={setPodcastMidroll}
        podcastPostroll={podcastPostroll}
        setPodcastPostroll={setPodcastPostroll}
        podcastFull={podcastFull}
        setPodcastFull={setPodcastFull}
        // Kick
        kickLink={kickLink}
        setKickLink={setKickLink}
        kickFollowerCount={kickFollowerCount}
        setKickFollowerCount={setKickFollowerCount}
        kickPlacement={kickPlacement}
        setKickPlacement={setKickPlacement}
        // Twitch
        twitchLink={twitchLink}
        setTwitchLink={setTwitchLink}
        twitchFollowerCount={twitchFollowerCount}
        setTwitchFollowerCount={setTwitchFollowerCount}
        twitchPlacement={twitchPlacement}
        setTwitchPlacement={setTwitchPlacement}
      />
    </TabPanelHidden>
  );
}

export default PlatformsTab;
