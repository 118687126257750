import React, { useState } from "react";
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Button,
  CircularProgress,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import CampaignsContainers from "./creatorContainers.js";
import BookingDialog from "../../../Misc/bookingdialog"; 
import client from "../../../../API"; 
import { platformRatesListFromCreator } from "../../../../Utils/constants.js";

const AgencyDetailsPanel = ({ agencyDetails, viewMode, accentColor }) => {
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [promotionData, setPromotionData] = useState([]);
  const [isLoadingCreator, setIsLoadingCreator] = useState(false);

  // Filter & Sorting states
  const [searchQuery, setSearchQuery] = useState("");
  const [platformFilter, setPlatformFilter] = useState("all");
  const [sortOption, setSortOption] = useState("nameAsc");

  const creators = agencyDetails.creators || [];

  // Invite or booking
  const handleInviteClick = async (creatorName) => {
    setIsLoadingCreator(true);
    try {
      const creatorData = await client.creators.fetchDetails(creatorName);
      setSelectedCreator(creatorData.creator);
      const rateList = platformRatesListFromCreator(creatorData.creator);
      setPromotionData(rateList);
      setBookingDialogOpen(true);
    } catch (error) {
      console.error("Failed to fetch creator details:", error);
      alert("Failed to fetch creator details. Please try again.");
    } finally {
      setIsLoadingCreator(false);
    }
  };

  const handleBookingSubmission = async (bookingDetails) => {
    try {
      const result = await client.partnerships.book({
        name: `${bookingDetails.partnershipName} - ${bookingDetails.selectedPromotion}`,
        creator: bookingDetails.creatorId,
        description: bookingDetails.details,
        email: bookingDetails.email,
        proposal_date: bookingDetails.selectedDate,
        total_sum: bookingDetails.rate,
        unique_code: `promo_${bookingDetails.creatorId}_${new Date().getTime()}`,
        notes: bookingDetails.details,
        finish_date: bookingDetails.selectedDate,
      });
      console.log("Booking successful:", result);
      alert("Booking successful! Check your email for details.");
      setBookingDialogOpen(false);
      return true;
    } catch (error) {
      console.error("Error submitting booking:", error);
      alert(
        `Error submitting booking: ${error?.response?.data?.error || error?.message}`
      );
      return false;
    }
  };

  // Calculate total reach
  const calculateTotalReach = (creator) => {
    const youtube = parseInt(creator.youtube) || 0;
    const instagram = parseInt(creator.instagram) || 0;
    const tiktok = parseInt(creator.tiktok) || 0;
    return youtube + instagram + tiktok;
  };

  // Filter & Sort
  const getFilteredSortedCreators = () => {
    let filtered = [...creators];

    // search
    if (searchQuery.trim() !== "") {
      filtered = filtered.filter((c) =>
        c.creator.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // platform filter
    if (platformFilter !== "all") {
      filtered = filtered.filter((c) => {
        switch (platformFilter) {
          case "youtube":
            return c.youtube && parseInt(c.youtube) > 0;
          case "instagram":
            return c.instagram && parseInt(c.instagram) > 0;
          case "tiktok":
            return c.tiktok && parseInt(c.tiktok) > 0;
          default:
            return true;
        }
      });
    }

    // sorting
    filtered.sort((a, b) => {
      const totalA = calculateTotalReach(a);
      const totalB = calculateTotalReach(b);
      switch (sortOption) {
        case "nameAsc":
          return a.creator.localeCompare(b.creator);
        case "nameDesc":
          return b.creator.localeCompare(a.creator);
        case "reachAsc":
          return totalA - totalB;
        case "reachDesc":
          return totalB - totalA;
        default:
          return 0;
      }
    });

    return filtered;
  };

  const filteredCreators = getFilteredSortedCreators();

  if (!creators || creators.length === 0) {
    return <Typography>No creators found.</Typography>;
  }

  return (
    <>
      {/* Filter / Sort controls */}
      <Paper
        sx={{
          p: 2,
          mb: 2,
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
          justifyContent: { xs: "center", md: "flex-start" },
        }}
        elevation={3}
      >
        <TextField
          label="Search by Creator Name"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: 200 }}
        />

        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Platform</InputLabel>
          <Select
            value={platformFilter}
            label="Platform"
            onChange={(e) => setPlatformFilter(e.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="youtube">YouTube</MenuItem>
            <MenuItem value="instagram">Instagram</MenuItem>
            <MenuItem value="tiktok">TikTok</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 130 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortOption}
            label="Sort By"
            onChange={(e) => setSortOption(e.target.value)}
          >
            <MenuItem value="nameAsc">Name (A-Z)</MenuItem>
            <MenuItem value="nameDesc">Name (Z-A)</MenuItem>
            <MenuItem value="reachAsc">Reach (Low-High)</MenuItem>
            <MenuItem value="reachDesc">Reach (High-Low)</MenuItem>
          </Select>
        </FormControl>
      </Paper>

      {/* Show list or grid */}
      {viewMode === "list" ? (
        <Paper sx={{ maxWidth: "800px", margin: "0 auto" }}>
          <List>
            {filteredCreators.map((creator, index) => (
              <React.Fragment key={index}>
                <ListItem
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <ListItemText
                    primary={creator.creator ?? ""}
                    secondary={`Total Reach: ${calculateTotalReach(
                      creator
                    ).toLocaleString()}`}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexWrap: "wrap",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    {creator.youtube && (
                      <Chip
                        label={`YouTube: ${creator.youtube}`}
                        color="primary"
                      />
                    )}
                    {creator.instagram && (
                      <Chip
                        label={`Instagram: ${creator.instagram}`}
                        color="secondary"
                      />
                    )}
                    {creator.tiktok && (
                      <Chip label={`TikTok: ${creator.tiktok}`} color="success" />
                    )}
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      href={`https://www.useblitz.co/creators/${creator.creator}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Media Kit
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleInviteClick(creator.creator)}
                    >
                      Invite
                    </Button>
                  </Box>
                </ListItem>
                {index < filteredCreators.length - 1 && (
                  <Divider component="li" />
                )}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      ) : (
        <CampaignsContainers
          creators={filteredCreators}
          handleInviteClick={handleInviteClick}
          accentColor={accentColor} // pass accent color
        />
      )}

      {/* Booking Dialog */}
      <BookingDialog
        open={bookingDialogOpen}
        onClose={() => setBookingDialogOpen(false)}
        submitBooking={handleBookingSubmission}
        creator={selectedCreator}
        promotionData={promotionData}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingCreator}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AgencyDetailsPanel;
