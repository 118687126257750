import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation } from "react-query";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import client from "../../../../API";

const UploadFileDialog = ({ open, onClose, partnershipId, onFileUpload }) => {
  // 1) We are removing the local FILE_SIZE_LIMIT check.
  //    The backend /postCreatorNew route will handle file size logic.

  // 2) Use the /postCreatorNew route:
  //    - If the file is <= 4.5MB, the server returns:
  //        { status: "uploaded_to_firebase", url: "...", file_id: ... }
  //    - If the file is > 4.5MB, the server returns:
  //        { status: "file_too_large", redirect_url: "..." }
  //      so we can instruct the user to upload via Google Drive.

  const { mutate: uploadFile } = useMutation(
    (fileData) =>
      client.files.postCreatorFile({
        file: fileData.file,
        partnership_id: fileData.partnershipId,
      }),
    {
      onSuccess: (data) => {
        console.log("File upload response:", data);

        // Pass the entire response object back up so the parent can handle
        // popup + redirect if it's a large file, or normal logic if it's Firebase.
        if (data.status === "file_too_large") {
          onFileUpload(data);
        } else if (data.status === "uploaded_to_firebase") {
          onFileUpload(data);
        } else {
          alert("Unexpected response from the server.");
          console.warn("Server response:", data);
        }

        // Close the dialog after we handle the response
        onClose();
      },
      onError: (error) => {
        console.error("Error uploading file:", error);
        alert(`Error uploading file: ${error.message}`);
      },
    }
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Take the first file
      const file = acceptedFiles[0];
      if (!file) return;

      // Convert the file to base64
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64File = reader.result.split(",")[1];
        uploadFile({
          file: {
            name: file.name,
            content: base64File,
            type: file.type,
          },
          partnershipId: partnershipId,
        });
      };
    },
    [uploadFile, partnershipId]
  );

  // Setup react-dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent>
        <div
          {...getRootProps()}
          style={{ border: "2px dashed #000", padding: "20px", cursor: "pointer" }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>
              Drag 'n' drop a file here, or click to select a file.
              <br />
              (Large files will automatically be handled via Google Drive.)
            </p>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileDialog;
