import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, CircularProgress, Divider, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import ServiceDialog from './serviceDialog';
import { useMutation, useQuery } from 'react-query';
import API from '../../../API';
import ServicesTable from './servicesTable';
import AlertDialog from '../../../Components/AlertDialog';
import useAlertDialog from '../../../Components/useAlertDialog';
import ApikeysTable from './apikeysTable';
import ApikeysDialog from './apikeysDialog';
import { Link as RouterLink } from 'react-router-dom'
import routes from '../../../Config/routes';

export default function DeveloperPanel(props) {
    const [currentService, setCurrentService] = useState(null);
    const [openService, setOpenService] = useState(false);
    const [openKey, setOpenKey] = useState(false);
    const { dialogState, openDialog, closeDialog } = useAlertDialog();
    const { data: services, isLoading: servicesIsLoading, error: servicesError, refetch: servicesRefetch } = useQuery({
        queryKey: ['services'],
        queryFn: ()=>API.developer.serviceList(),
        refetchOnWindowFocus: false,
    });

    const { data: keys, isLoading: keysLoading, error: keysError, refetch: keysRefetch } = useQuery({
        queryKey: ['apikeys'],
        queryFn: API.developer.apikeyList,
        refetchOnWindowFocus: false,
    });

    const { mutate: serviceMutate, isLoading: serviceMutateLoading } = useMutation({
        mutationFn: ({ id, payload }) => API.developer.serviceUpdate(id, payload),
        onSuccess: () => {
            openDialog('Success', 'Service updated succesfully', closeDialog, closeDialog, 'Ok', null);
            servicesRefetch();
        },
        onError: (error) => {
            openDialog('Error', <>Error while updating service: <br /> {error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, 'Ok', null);
        }
    });

    const { mutate: serviceCreate, isLoading: serviceCreateLoading } = useMutation({
        mutationFn: (payload) => API.developer.serviceCreate(payload),
        onSuccess: () => {
            openDialog('Success', 'Service created succesfully', closeDialog, closeDialog, 'Ok', null);
            servicesRefetch();
        },
        onError: (error) => {
            openDialog('Error', <>Error while creating service: <br /> {error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, 'Ok', null);
        }
    });

    const { mutate: keyCreate, isLoading: keyCreateLoading } = useMutation({
        mutationFn: (payload) => API.developer.apikeyCreate(payload),
        onSuccess: () => {
            keysRefetch();
        },
        onError: (error) => {
            openDialog('Error', <>Error while creating key: <br /> {error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, 'Ok', null);
        }
    });

    const { mutate: keyDelete, isLoading: keyDeleteLoading } = useMutation({
        mutationFn: (id) => API.developer.apikeyDelete(id),
        onSuccess: () => {
            keysRefetch();
        },
        onError: (error) => {
            openDialog('Error', <>Error while deleting key: <br /> {error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, 'Ok', null);
        }
    });

    useEffect(() => {
        if (servicesError)
            alert(servicesError?.response?.data?.error || servicesError.message);

        if (keysError)
            alert(keysError?.response?.data?.error || keysError.message);
    }, [servicesError, keysError]);

    const HandleUpdateServiceState = (id, status) => {
        const found = services.find(service => service.id == id);
        if (!found)
            return;
        if (status != found.status) {

            const UpdateServiceStatus = () => {
                closeDialog();
                serviceMutate({
                    id: id,
                    payload: { status: status }
                })
            }

            openDialog('Alert', `You sure you want to update service ${found.name} to ${status.toLocaleUpperCase()}?`,
                () => UpdateServiceStatus(), closeDialog, 'Yes', 'Cancel');
        }
    }

    const HandleReviewService = (id) => {
        const found = services.find(service => service.id == id);
        if (!found)
            return;
        if (!found.homepage_url || !found.description || !found.privacy_policy_url || !found.icon || !found.on_creator_signon_url) {
            openDialog('Error', 'Please finish filling out your service information to be able to send it to review.',
                closeDialog, closeDialog, 'Ok', null);
            return
        }

        if (found.approval_status != 'approval' && found.approval_status != 'pending') {
            const UpdateServiceStatus = () => {
                closeDialog();
                serviceMutate({
                    id: id,
                    payload: { approval_status: 'pending' }
                }, {
                    onSuccess: () => {
                        openDialog('Success', <>Your Third Party Service was sent for review and approval. <br /> Check your email for your resolution.</>,
                            closeDialog, closeDialog, 'Ok', null);
                    }
                })
            }

            openDialog('Alert', `You sure you want to sent your service ${found.name} for review.`,
                () => UpdateServiceStatus(), closeDialog, 'Yes', 'Cancel');
        }
    }

    const HandleSaveService = (payload) => {
        if (!!currentService) {
            serviceMutate({
                id: currentService.id,
                payload: payload
            });
        }
        else {
            serviceCreate(payload);
        }
        setOpenService(false);
        setCurrentService(null);
    }

    const HandleEditService = (id) => {
        const found = services.find(service => service.id == id);
        if (!found)
            return;

        setCurrentService(found);
        setOpenService(true);
    }

    const HandleCloseService = () => {
        setCurrentService(null);
        setOpenService(false);
    }

    const HandleCloseKey = () => {
        setOpenKey(false);
    }

    const HandleOpenKey = () => {
        setOpenKey(true);
    }

    const HandleSaveKey = (payload) => {
        keyCreate(payload, {
            onSuccess: () => {
                openDialog('Success', 'API key created succesfully', closeDialog, closeDialog, 'Ok', null);
            }
        });
        setOpenKey(false);
    }

    const HandleDeleteKey = (id) => {
        console.log('id', id);
        const found = keys.find(key => key.id == id);
        if (!found)
            return;
        keyDelete(id, {
            onSuccess: () => {
                openDialog('Success', 'API key deleted succesfully', closeDialog, closeDialog, 'Ok', null);
            }
        });
    }

    const HandleRefreshKey = (id) => {
        const found = keys.find(key => key.id == id);
        if (!found)
            return;
        const key_type = found.type;

        keyDelete(id, {
            onSuccess: () => {
                keyCreate({ type: key_type }, {
                    onSuccess: () => {
                        openDialog('Success', 'Key refreshed succesfully', closeDialog, closeDialog, 'Ok', null);
                    }
                })
            }
        });
        setOpenKey(false);
    }


    return (
        <Box sx={{ padding: 2 }}>
            <AlertDialog alertState={dialogState}></AlertDialog>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={servicesIsLoading || serviceMutateLoading || serviceCreateLoading || keysLoading || keyCreateLoading || keyDeleteLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <ServiceDialog
                isOpen={openService}
                service={currentService}
                onCloseAction={HandleCloseService}
                onFormAction={HandleSaveService}
            >
            </ServiceDialog>
            <ApikeysDialog
                isOpen={openKey}
                onClose={HandleCloseKey}
                onCreate={HandleSaveKey}
            >
            </ApikeysDialog>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h5'>
                    For Developers
                </Typography>
                <Link component={RouterLink} to={routes.docsHome}>Documentation <LaunchIcon fontSize='inherit'></LaunchIcon></Link>
            </Box>
            <Divider sx={{ marginBlockEnd: 2 }}></Divider>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panelservices-content"
                    id="panelservices-header"
                >
                    Services
                </AccordionSummary>
                <AccordionDetails>
                    <ServicesTable
                        services={services}
                        onUpdateState={HandleUpdateServiceState}
                        onEdit={HandleEditService}
                        onRequestReview={HandleReviewService}
                        onNew={() => setOpenService(true)}
                    ></ServicesTable>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panelapikeys-content"
                    id="panelapikeys-header"
                >
                    API Keys
                </AccordionSummary>
                <AccordionDetails>
                    <ApikeysTable
                        keys={keys}
                        onNew={HandleOpenKey}
                        onDelete={HandleDeleteKey}
                        onRefresh={HandleRefreshKey}
                    ></ApikeysTable>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
