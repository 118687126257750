import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React from 'react'
import { StyledTableRow } from '../../../Utils/styledcell'
import ApiKeyField from './apiKeyField'
import Delete from '@mui/icons-material/Delete';
import Refresh from '@mui/icons-material/Refresh';
import Add from '@mui/icons-material/Add';
import { dateToDatetimeLocal } from '../../../Utils/constants';

export default function ApikeysTable({ keys = [], onRefresh = (id) => 0, onDelete = (id) => 0, onNew = () => 0 }) {


    return (
        <TableContainer component={Paper} elevation={1}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Key
                        </TableCell>
                        <TableCell>
                            Type
                        </TableCell>
                        <TableCell>
                            Generated at.
                        </TableCell>
                        <TableCell align='right'>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!keys && keys.map(key => (
                        <StyledTableRow key={key.id}>
                            <TableCell>
                                <ApiKeyField fullWidth apiKey={key.id}></ApiKeyField>
                            </TableCell>
                            <TableCell>
                                <Typography variant='button'>{key.type}</Typography>
                            </TableCell>
                            <TableCell>
                                <TextField disabled type="datetime-local" value={dateToDatetimeLocal(key.date_created)}>
                                </TextField>
                            </TableCell>
                            <TableCell align='right'>
                                <IconButton onClick={() => onRefresh(key.id)}>
                                    <Refresh></Refresh>
                                </IconButton>
                                <IconButton color='error' onClick={() => onDelete(key.id)}>
                                    <Delete></Delete>
                                </IconButton>
                            </TableCell>
                        </StyledTableRow>))}
                </TableBody>
                <TableFooter>
                    <StyledTableRow>
                        <TableCell colSpan={4} align='right'>
                            <IconButton color='success' onClick={onNew}>
                                <Add></Add>
                            </IconButton>
                        </TableCell>
                    </StyledTableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}
