import React from 'react';

import { Outlet } from 'react-router-dom';
import Scaffolding from './Scaffolding';

const DocsRoute = ({ }) => {
    return (
        <Scaffolding>
            <Outlet></Outlet>
        </Scaffolding>
    );
};

export default DocsRoute;
