import React from "react";
import { Box, Button, Typography, Fade } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes from "../../Config/routes";
import { globalStyles } from "../../Utils/Styles";
import paymentImage from "../../Components/globalAssets/blackwhite.png";

const PaymentSection = () => {
  const navigate = useNavigate();
  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  return (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          position: "relative",
          ...globalStyles.wholeHeightMinusTop,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-evenly",
          alignItems: "center",
          background: "linear-gradient(180deg, #FAF9F6 0%, #ECECEB 100%)",
          px: { xs: 2, md: 8 },
          py: { xs: 6, md: 12 },
          overflow: "hidden",
          color: "#000",
        }}
      >
        {/* Decorative Radial Gradient Background Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              "radial-gradient(circle at 30% 30%, rgba(123,42,42,0.1), transparent 60%)",
            zIndex: 0,
          }}
        />

        {/* Left Section with Image */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: { xs: 4, md: 0 },
            maxWidth: { xs: "200px", md: "350px" },
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              borderRadius: "50%",
              overflow: "hidden",
              width: "100%",
              boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            }}
          >
            <img
              src={paymentImage}
              alt="Payment"
              style={{
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        </Box>

        {/* Right Section with Text */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "40rem",
            alignItems: { xs: "center", md: "flex-start" },
            textAlign: { xs: "center", md: "left" },
            zIndex: 1,
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
            Blitz: The New Way to Pay and Get Paid
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 3 }}>
            Whether you're a creator, freelancer, or global team member, Blitz
            ensures instant, hassle-free transactions. Choose immediate payouts
            for a small fee, or opt for Net 30/60 terms at no cost. No more
            waiting, no more complications.
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 3 }}>
            Trusted by top brands worldwide, Blitz streamlines payment processes
            so you can focus on what matters: growing your business and
            delighting your clients.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 4,
              fontStyle: "italic",
              color: "rgba(0,0,0,0.7)",
            }}
          >
            "Blitz transformed our payment workflows. Our international
            freelancers love the speed and transparency—and we love the
            simplicity." – Meta
          </Typography>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#7b2a2a",
              color: "#fff",
              py: 1.5,
              px: 3,
              fontSize: "1.1rem",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#5a1e1e",
                transform: "scale(1.05)",
              },
              transition: "all 0.3s ease",
            }}
            onClick={handleAccess}
          >
            Request Access Now
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

export default PaymentSection;
