import { Box, Container, Divider, Link, Typography } from '@mui/material'
import React from 'react'
import Codebox from '../../Components/Codebox'
import { Link as RouterLink } from 'react-router-dom'
import routes from '../../Config/routes'
import tutorial_1 from '../../Components/globalAssets/docs_apikeys_1.png'

export default function DocsApikeys(props) {

    return (
        <Container sx={{ paddingBlock: 2 }}>
            <Typography variant='h3'>API Keys</Typography>
            <Divider></Divider>
            <br />
            <Typography variant='h6'>What are API keys?</Typography>
            API keys are unique credentials that allow developers to securely interact with the Blitz Third Party API. These keys act as authorization tokens, 
            enabling your applications or services to access platform data and perform operations on behalf of influencers.<br />
            <b>Types of API Keys</b>
            <ul>
                <li>Sandbox Keys:
                    <ul>
                        <li>Used for development and testing.</li>
                        <li>Allows access to mock data and simulated API responses.</li>
                        <li>Ensures your application works correctly before moving to production.</li>
                    </ul>
                </li>
                <li>Production Keys:
                    <ul>
                        <li>Used for live environments.</li>
                        <li>Grants access to real data.</li>
                        <li>Requires careful management to prevent unauthorized access.</li>
                    </ul>
                </li>
            </ul>
            Once you're given developer access to the Blitz Third Party API, you can go to your <Link component={RouterLink} to={`${routes.user}?tab=dev`}>User Information page</Link> to
            find a new 'Developer' tab, where you'll find
            your services and API Keys. Expand the API Keys section, and hit the Plus icon to create your first API Key.
            <br />
            <img src={tutorial_1} style={{ width: '75%' }}></img>
            <br />
            <Typography variant='h6'>API Key form</Typography>
            <Divider></Divider>
            <ul>
                <li><b>Type</b>: The type of API Key. You'll only be able to create production key once you have an Approved service.</li>
            </ul>
            Once an API Key has been generated you'll be able to see it on the list.
            <br /><br/>
            <Typography variant='h6'>API Keys actions.</Typography>
            <Divider></Divider>
            <ul>
                <li><b>Regenerate</b>: This will give you a new unique key. The old one will be deleted.</li>
                <li><b>Delete</b>: This will remove your API Key</li>
            </ul>
            A regeneration of an API Key is basically, a removal followed by a creation of a key with the same type. The option is included for convenience.
            <br /><br/>
            <Typography variant='h6'>API Key best practices.</Typography>
            <Divider></Divider>
            <ul>
                <li>Keep Your Keys Secure: Never expose your API keys in public repositories or client-side code.</li>
                <li>Use Separate Keys: Use Sandbox keys for testing and Production keys only for live services.</li>
                <li>Rotate Regularly: Periodically refresh your keys to enhance security.</li>
            </ul>
            The best way to access our API is to have a secure backend server that makes calls to our API. That way, you ensure only YOU have access to your private keys.<br/>
            By managing your API keys effectively, you ensure the security and integrity of your applications while enabling smooth integration with the Blitz platform.
            <br />


        </Container>
    )
}
