import React, { useState, useRef } from 'react';
import {
    Typography,
    Button,
    Backdrop,
    CircularProgress,
    Box,
    TextField,
    ThemeProvider,
    CssBaseline,
    Toolbar
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../Config/routes';

import AlertDialog from '../../Components/AlertDialog';
import useAlertDialog from '../../Components/useAlertDialog';
import client from '../../API'
import HomeHeader from '../../Components/HomeHeader';
import { lightTheme } from '../../Utils/baseTheme';

const RestorePassword = () => {
    const navigate = useNavigate();
    const { requestId } = useParams();

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [validationError, setValidationError] = useState('');

    const { dialogState, openDialog, closeDialog } = useAlertDialog();
    const inputRef = useRef(null);
    const formRef = useRef(null);


    // Dialog state
    const [loading, setLoading] = useState(false);


    const redirectLogin = () => {
        navigate(routes.login);
    }

    const OnSubmit = async (e) => {
        e.preventDefault();

        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return;
        }

        if(password!=passwordRepeat){
            setValidationError('Your passwords don\'t match!')
            return;
        }

        setLoading(true);
        const payload = {
            password,
            request: requestId
        };

        try {
            const data = await client.users.forgotAction(payload);
            openDialog('Info', 'Your password has been updated succesfully', redirectLogin, redirectLogin);
        } catch (error) {
            console.error("Error during form submission:", error);
            openDialog('Error', <>There was issue saving your new password: {error.response?.data?.error || error.message} <br />
                Try again.</>, closeDialog, closeDialog, 'Ok', null);
            setLoading(false);
        }
    }


    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <HomeHeader />
            <Toolbar></Toolbar>
            <Box sx={{ paddingInline: 2 }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AlertDialog alertState={dialogState}></AlertDialog>
                <form onSubmit={OnSubmit} ref={formRef} noValidate>
                    <Box sx={{ marginInline: 'auto', marginBlockStart: 4, marginBlockEnd: 2, display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '30em' }}>
                        <Box sx={{ marginBlockEnd: 1, textAlign: 'center' }}>
                            <Typography variant='h3' gutterBottom>Forgot my password</Typography>
                            <Typography variant='body1' color='text.secondary' gutterBottom>Write your new password and click save.</Typography>
                        </Box>

                            <TextField
                                label='Your new Password'
                                name='account-password'
                                type='password'
                                autoComplete='new-password'
                                helperText={validationError}
                                required
                                value={password}
                                error={!!validationError}
                                onChange={e => setPassword(e.target.value)}
                                inputRef={inputRef}
                                fullWidth
                            >
                            </TextField>
                            <TextField
                                label='Confirm Password'
                                name='account-password'
                                type='password'
                                autoComplete='new-password'
                                required
                                value={passwordRepeat}
                                error={!!validationError}
                                onChange={e => setPasswordRepeat(e.target.value)}
                                fullWidth
                            >
                            </TextField>
                            <Button variant='contained' color='secondary' type='submit' sx={{ alignSelf:'flex-end' }}>Save</Button>
                    </Box>
                </form>
            </Box>
        </ThemeProvider>
    );
};

export default RestorePassword;
